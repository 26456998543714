import { put, call } from 'redux-saga/effects'
import { api } from 'services/api'
import { push } from 'connected-react-router'
import { toast } from 'react-toastify'

import { Creators as TutorialsActions } from 'store/ducks/tutorials'

export function* getTutorials() {
  try {
    const { data } = yield call(api.get, '/tutorials')
    yield put(TutorialsActions.getTutorialsSuccess(data))
  } catch (err) {
    yield put(TutorialsActions.getTutorialsFailure(err.message))
  }
}

export function* getTutorialByID(action) {
  try {
    const { data } = yield call(api.get, `/tutorials/${action.payload}`)
    yield put(TutorialsActions.getTutorialSuccess(data))
  } catch (err) {
    yield put(TutorialsActions.getTutorialFailure(err))
  }
}

export function* createTutorial(action) {
  try {
    const { data } = yield call(api.post, `/tutorials`, action.payload)
    yield put(TutorialsActions.createTutorialSuccess(data))
    yield put(push('/tutoriais/itens'))
    toast.success('Tutorial criado com sucesso!')
  } catch (err) {
    toast.error('Erro ao criar tutorial')
    yield put(TutorialsActions.createTutorialFailure(err))
  }
}

export function* updateTutorial(action) {
  try {
    const { data } = yield call(
      api.put,
      `/tutorials/${action.payload.id}`,
      action.payload
    )
    yield put(TutorialsActions.updateTutorialSuccess(data))
    yield put(push('/tutoriais/itens'))
    toast.success('Tutorial editado com sucesso!')
  } catch (err) {
    toast.error('Erro ao editar tutorial')
    yield put(TutorialsActions.updateTutorialFailure(err))
  }
}

export function* deleteTutorial(action) {
  try {
    const { data } = yield call(api.delete, `/tutorials/${action.payload.id}`)
    yield put(TutorialsActions.deleteTutorialSuccess(data))
    yield put(TutorialsActions.getTutorialsRequest())
    toast.success('Tutorial removido com sucesso')
  } catch (err) {
    toast.error('Erro ao remover tutorial')
    yield put(TutorialsActions.deleteTutorialFailure(err))
  }
}
