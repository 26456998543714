import { currencyMask } from 'utils'
import React from 'react'
import { Input } from 'reactstrap'

const CurrentInput = (props) => (
  <Input
    type="text"
    placeholder="Digite aqui o valor"
    name="currency"
    onChange={(e) => {
      !!props.validation && props.validation(e.target.value)
      currencyMask(e)
    }}
    {...props}
  />
)

export default CurrentInput
