import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import DefaultPage from "components/DefaultPage";

import CreateBonificationForm from "components/Bonifications/CreateBonificationForm";

import { Creators as BonificationActions } from "store/ducks/bonifications";

const CreateBonification = () => {
  const dispatch = useDispatch();

  const handleCreateBonification = useCallback(
    (item) => {
      dispatch(BonificationActions.createBonificationRequest(item));
    },
    [dispatch]
  );

  return (
    <DefaultPage
      pageTitle="Bonificações"
      pageDescription="Criar bonificação"
      pageSubDescription="Criar uma nova bonificação"
    >
      <CreateBonificationForm submitForm={handleCreateBonification} />
    </DefaultPage>
  );
};

export default CreateBonification;
