import { put, call } from 'redux-saga/effects'
import { api } from 'services/api'
import { push } from 'connected-react-router'
import { toast } from 'react-toastify'

import { Creators as GenericSearchActions } from 'store/ducks/generic_search'

export function* getGenericSearch(action) {
  try {
    const { source, condition, param } = action.payload
    const { data } = yield call(
      api.get,
      `/generic_search?source=${source}&condition=${condition}&param=${param}`
    )
    yield put(GenericSearchActions.getGenericSearchSuccess(data))
  } catch (err) {
    toast.error('Erro ao efetuar a busca')
    yield put(GenericSearchActions.getGenericSearchFailure(err.message))
  }
}
