import Profiles from "pages/Profiles";
import CreateProfile from "pages/Profiles/Create";
import EditProfile from "pages/Profiles/Edit";

import { BASE_URL } from "shared/constants/config";

export default [
  { path: `${BASE_URL}/perfis`, component: Profiles },
  { path: `${BASE_URL}/perfis/criar`, component: CreateProfile },
  { path: `${BASE_URL}/perfis/editar/:id`, component: EditProfile },
];
