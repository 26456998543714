import Users from 'pages/Users'
import CreateUser from 'pages/Users/Create'

import EditUser from 'pages/Users/Edit'
import Accredited from 'pages/Users/Accredited'

import EditUserAccredited from 'pages/Users/Accredited/Edit'

import UserProfile from 'pages/Users/Profile'

import { BASE_URL } from 'shared/constants/config'

export default [
  { path: `${BASE_URL}/usuarios`, component: Users },
  { path: `${BASE_URL}/perfil`, component: UserProfile },
  { path: `${BASE_URL}/usuarios/criar`, component: CreateUser },
  { path: `${BASE_URL}/usuarios/editar/:id`, component: EditUser },
  { path: `${BASE_URL}/credenciados`, component: Accredited },
  { path: `${BASE_URL}/credenciados/editar/:id`, component: EditUserAccredited}
]
