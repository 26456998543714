import Services from "pages/Services";
import CreateService from "pages/Services/Create";
import EditService from "pages/Services/Edit";

import { BASE_URL } from "shared/constants/config";

export default [
  { path: `${BASE_URL}/servicos`, component: Services },
  { path: `${BASE_URL}/servicos/criar`, component: CreateService },
  { path: `${BASE_URL}/servicos/editar/:id`, component: EditService },
];
