import React, { useEffect, useCallback, useState } from "react";
import { Form, Label, Input, Col, Button, Row } from "reactstrap";
import Toggle from "react-toggle";

import { useForm } from "react-hook-form";
import Select from "react-select";
import InputMask from "react-input-mask";
import { Link } from 'react-router-dom'

const EditUserForm = ({
  submitForm,
  profileList,
  planList,
  userList,
  currentValue,
}) => {
  const [optionsSelectProfile, setOptionsSelectProfile] = useState([]);
  const [optionsSelectPlan, setOptionsSelectPlan] = useState([]);
  const [optionsSelectSponsor, setOptionsSelectSponsor] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({

    defaultValues: {
      profile: currentValue.profile,
      sponsor: currentValue.sponsor,
      plan: currentValue.plan,
      cpf: currentValue.cpf ? currentValue.cpf : " ",
      email: currentValue.email,
      name: currentValue.name,
      password: currentValue.password,
      phone: currentValue.phone ? currentValue.phone : " ",
      whatsapp: currentValue.whatsapp ? currentValue.whatsapp : " ",
      birthDate: currentValue.birthDate ? currentValue.birthDate : " ",
      companyName: currentValue.companyName,
      cnpj: currentValue.cnpj ? currentValue.cnpj : " ",
      establishment: currentValue.establishment,
      geolocation: currentValue.geolocation,
      icon: currentValue.icon,
      isActive: currentValue.isActive,
      approvedAccredited: currentValue.approvedAccredited

    },
  });

  const { ref: cpfRef, ...cpf } = register("cpf");
  const { ref: emailRef, ...email } = register("email", { required: true });
  const { ref: nameRef, ...name } = register("name", { required: true });

  const { ref: phoneRef, ...phone } = register("phone", { required: true });
  const { ref: whatsappRef, ...whatsapp } = register("whatsapp");
  const { ref: birthDateRef, ...birthDate } = register("birthDate");

  const { ref: companyNameRef, ...companyName } = register("companyName");
  const { ref: cnpjRef, ...cnpj } = register("cnpj");
  const { ref: establishmentRef, ...establishment } = register(
    "establishment"
  );
  const [isActiveText, setIsActiveText] = useState(currentValue.isActive);

  const { ref: geolocationRef, ...geolocation } = register("geolocation");
  const { ref: iconRef, ...icon } = register("icon");
  const handleToggle = useCallback(
    (value) => {
      setIsActiveText(value);
      setValue("isActive", value);
    },
    [setValue]
  );
  useEffect(() => {
    let options = [];
    for (let i in profileList) {
      options.push({
        value: profileList[i].id,
        label: profileList[i].name,
      });
    }
    setOptionsSelectProfile(options);
  }, [profileList]);

  useEffect(() => {
    let options = [];
    for (let i in planList) {
      options.push({
        value: planList[i].id,
        label: planList[i].name,
        isDisabled: true
      });
    }
    setOptionsSelectPlan(options);
  }, [planList]);

  useEffect(() => {
    let options = [];
    for (let i in userList) {
      options.push({
        value: userList[i].id,
        label: userList[i].name,
      });
    }
    setOptionsSelectSponsor(options);
  }, [userList]);

  const defaultValueSelectSponsor =
    optionsSelectSponsor[
    optionsSelectSponsor.findIndex(
      (oss) => oss.value === currentValue?.sponsor?.[0]?.id
    )
    ];

  const defaultValueSelectProfile =
    optionsSelectProfile[
    optionsSelectProfile.findIndex(
      (oss) => oss.value === currentValue?.profile?.[0]?.id
    )
    ];

  const defaultValueSelectPlan =
    optionsSelectPlan[
    optionsSelectPlan.findIndex(
      (oss) => oss.value === currentValue?.plan?.[0]?.id
    )
    ];
  const handleToggleAccredited = (value) => setToggleAccredited(value)
  useEffect(() => {
    let options = [];
    for (let i in planList) {
      options.push({
        value: planList[i].id,
        label: planList[i].name,
        isDisabled: true
      });
    }
    setOptionsSelectPlan(options);
  }, [planList]);
  const [approvedAccredited, setToggleAccredited] = useState(
    currentValue && currentValue.approvedAccredited
  )

  // currentValue && currentValue.approvedAccredited
  //   ? handleToggleAccredited(currentValue.approvedAccredited)
  //   : handleToggleAccredited(false)


  return (
    <Form
      className="needs-validation"
      noValidate=""
      onSubmit={handleSubmit(submitForm)}
    >
      <div className="form-row">
        <Col md="3 mb-3">
          <Label htmlFor="validationCustom01">Empresa Aprovada</Label>

          <input
            className="checkbox_animated"
            type="checkbox"
            onChange={(e) => handleToggleAccredited(e.target.checked)}
          />
        </Col>
        <Col md="3 mb-3">
          <Label htmlFor="validationCustom01">Nome</Label>
          <Input
            className="form-control"
            name="name"
            type="text"
            placeholder="Nome"
            innerRef={nameRef}
            {...name}
          />
          <span>{errors.name && "Nome é obrigatório"}</span>
        </Col>
        <Col md="3 mb-3">
          <Label htmlFor="validationCustom02">E-mail</Label>
          <Input
            className="form-control"
            name="description"
            type="text"
            placeholder="E-mail"
            innerRef={emailRef}
            {...email}
          />

          <span>{errors.email && "E-mail é obrigatório"}</span>
        </Col>
        <Col md="3 mb-3">
          <Label htmlFor="validationCustom02">Data de Nascimento</Label>
          <Input
            className="form-control"
            name="birthDate"
            type="text"
            placeholder="Data de Nascimento"
            innerRef={birthDateRef}
            {...birthDate}
            tag={InputMask}
            mask="99/99/9999"
          />

          <span>{errors.birthDate && "Data de nascimento é obrigatório"}</span>
        </Col>
        <Col md="3 mb-3">
          <Label htmlFor="validationCustom02">CPF</Label>
          <Input
            className="form-control"
            name="cpf"
            type="text"
            placeholder="CPF"
            innerRef={cpfRef}
            {...cpf}
            tag={InputMask}
            mask="999.999.999-99"
          />

          {/* <span>{errors.cpf && "CPF é obrigatório"}</span> */}
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom02">CNPJ</Label>
          <Input
            className="form-control"
            name="cnpj"
            type="text"
            placeholder="CNPJ"
            innerRef={cnpjRef}
            {...cnpj}
            tag={InputMask}
            mask="99.999.999/9999-99"
          />

          {/* <span>{errors.cpf && "CPF é obrigatório"}</span> */}
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom02">Nome da Empresa</Label>
          <Input
            className="form-control"
            name="description"
            type="text"
            placeholder="Nome da Empresa"
            innerRef={companyNameRef}
            {...companyName}
          />

          {/* <span>{errors.cpf && "CPF é obrigatório"}</span> */}
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom02">Tipo do Estabelecimento</Label>
          <Input
            className="form-control"
            name="establishment"
            type="text"
            placeholder="Tipo do Estabelecimento"
            innerRef={establishmentRef}
            {...establishment}
          />

          {/* <span>{errors.cpf && "CPF é obrigatório"}</span> */}
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom02">Telefone</Label>
          <Input
            className="form-control"
            name="phone"
            type="text"
            placeholder="Telefone"
            innerRef={phoneRef}
            {...phone}
            tag={InputMask}
            mask="(99) 9999-9999"
          />

          <span>{errors.phone && "Telefone é obrigatório"}</span>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom02">Whatsapp</Label>
          <Input
            className="form-control"
            name="whatsapp"
            type="text"
            placeholder="Whatsapp"
            innerRef={whatsappRef}
            {...whatsapp}
            tag={InputMask}
            mask="(99) 99999-9999"
          />

          {/* <span>{errors.phone && "Telefone é obrigatório"}</span> */}
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom02">Geolocalização</Label>
          <Input
            className="form-control"
            name="geolocation"
            type="text"
            placeholder="Geolocalização"
            innerRef={geolocationRef}
            {...geolocation}
          />

          {/* <span>{errors.phone && "Telefone é obrigatório"}</span> */}
        </Col>
        {/* {!!currentValue.password && (
          <Col md="2 mb-3">
            <Label htmlFor="validationCustom02">Senha</Label>
            <Input
              className="form-control"
              name="password"
              type="text"
              placeholder="Senha"
              innerRef={passwordRef}
              {...password}
            />

            <span>{errors.password && "Senha é obrigatório"}</span>
          </Col>
        )} */}
      </div>
      <div className="form-row row-gap">
        {!!defaultValueSelectSponsor && (
          <Col>
            <h5>Patrocinador</h5>
            <Select
              options={optionsSelectSponsor}
              onChange={(e) => setValue("sponsor", [{ id: e.value }])}
              defaultValue={defaultValueSelectSponsor}
            />
          </Col>
        )}
        {!!defaultValueSelectProfile && (
          <Col>
            <h5>Perfil</h5>
            <Select
              options={optionsSelectProfile}
              onChange={(e) => setValue("profile", [{ id: e.value }])}
              defaultValue={defaultValueSelectProfile}
            />
          </Col>
        )}

        {!!defaultValueSelectPlan && (
          <Col>
            <h5>Habilitação</h5>
            <Select

              options={optionsSelectPlan}
              onChange={(e) => setValue("plan", [{ id: e.value }])}
              defaultValue={defaultValueSelectPlan}
            />
          </Col>
        )}
        <Col md="2 mb-3" style={{ marginLeft: "20px" }}>
          <Label htmlFor="validationCustom02">Status</Label>
          <Row
            style={{
              marginLeft: "0px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Toggle
              id={currentValue.id}
              defaultChecked={currentValue.isActive}
              name="isActive"
              {...register("isActive")}
              onChange={(e) => handleToggle(e.target.checked)}
            />
            <span style={{ marginLeft: "15px" }}>
              {isActiveText ? "Ativo" : "Inativo"}
            </span>
            <span>{errors.isActive && "Status é obrigatório"}</span>
          </Row>
        </Col>
      </div>
      <div className="form-row row-gap">
        <Button color="primary">Enviar</Button>
        <Link to="/usuarios">
          <Button color="default" style={{ border: 'none' }}>
            Cancelar
          </Button>
        </Link>
      </div>
    </Form>
  );
};

export default EditUserForm;
