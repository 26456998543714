import { put, call } from 'redux-saga/effects'
import { api } from 'services/api'
import { toast } from 'react-toastify'

import { Creators as ReportActions } from 'store/ducks/report'

export function* getReports(action) {
  try {
    const { data } = yield call(
      api.post,
      `/generic_search/report_data`,
      action.payload
    )
    yield put(ReportActions.getReportDataSuccess(data))
    data.length === 0 &&
      toast.info('Nenhum dado encontrado para o filtro atual')
  } catch (err) {
    toast.error('Erro ao gerar dados para o relatório')
    yield put(ReportActions.getReportDataFailure(err.message))
  }
}
export function* getEntities(action) {
  try {
    const { data } = yield call(api.get, `/generic_search/entities`)
    yield put(ReportActions.getEntitiesSuccess(data))
  } catch (err) {
    yield put(ReportActions.getEntitiesFailure(err.message))
  }
}
export function* getEntityColumns(action) {
  try {
    const { data } = yield call(
      api.get,
      `/generic_search/entities/${action.payload}/columns`
    )
    yield put(ReportActions.getEntityColumnsSuccess(data))
  } catch (err) {
    yield put(ReportActions.getEntityColumnsFailure(err.message))
  }
}
