export const Types = {
  GET_CASHBACKS_REQUEST: "cashback/GET_CASHBACKS_REQUEST",
  GET_CASHBACKS_SUCCESS: "cashback/GET_CASHBACKS_SUCCESS",
  GET_CASHBACKS_FAILURE: "cashback/GET_CASHBACKS_FAILURE",

  GET_CASHBACK_REQUEST: "cashback/GET_CASHBACK_REQUEST",
  GET_CASHBACK_SUCCESS: "cashback/GET_CASHBACK_SUCCESS",
  GET_CASHBACK_FAILURE: "cashback/GET_CASHBACK_FAILURE",

  CREATE_CASHBACK_REQUEST: "cashback/CREATE_CASHBACK_REQUEST",
  CREATE_CASHBACK_SUCCESS: "cashback/CREATE_CASHBACK_SUCCESS",
  CREATE_CASHBACK_FAILURE: "cashback/CREATE_CASHBACK_FAILURE",

  UPDATE_CASHBACK_REQUEST: "cashback/UPDATE_CASHBACK_REQUEST",
  UPDATE_CASHBACK_SUCCESS: "cashback/UPDATE_CASHBACK_SUCCESS",
  UPDATE_CASHBACK_FAILURE: "cashback/UPDATE_CASHBACK_FAILURE",

  DELETE_CASHBACK_REQUEST: "cashback/DELETE_CASHBACK_REQUEST",
  DELETE_CASHBACK_SUCCESS: "cashback/DELETE_CASHBACK_SUCCESS",
  DELETE_CASHBACK_FAILURE: "cashback/DELETE_CASHBACK_FAILURE",
};

const INITIAL_STATE = {
  list: [],
  cashback: {},
  loading: false,
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_CASHBACKS_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.GET_CASHBACKS_SUCCESS:
      return { ...state, loading: false, list: action.payload, error: null };

    case Types.GET_CASHBACKS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.GET_CASHBACK_REQUEST:
      return { ...state, loading: true, cashback: {}, error: null };

    case Types.GET_CASHBACK_SUCCESS:
      return {
        ...state,
        loading: false,
        cashback: action.payload,
        error: null,
      };

    case Types.GET_CASHBACK_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.CREATE_CASHBACK_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.CREATE_CASHBACK_SUCCESS:
      return { ...state, loading: false, error: null };

    case Types.CREATE_CASHBACK_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.UPDATE_CASHBACK_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.UPDATE_CASHBACK_SUCCESS:
      return { ...state, loading: false, cashback: {}, error: null };

    case Types.UPDATE_CASHBACK_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.DELETE_CASHBACK_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.DELETE_CASHBACK_SUCCESS:
      return { ...state, loading: false, error: null };

    case Types.DELETE_CASHBACK_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export const Creators = {
  getCashbacksRequest: (payload) => ({
    type: Types.GET_CASHBACKS_REQUEST,
    payload,
  }),

  getCashbacksSuccess: (payload) => ({
    type: Types.GET_CASHBACKS_SUCCESS,
    payload,
  }),

  getCashbacksFailure: (payload) => ({
    type: Types.GET_CASHBACKS_FAILURE,
    payload,
  }),

  getCashbackRequest: (payload) => ({
    type: Types.GET_CASHBACK_REQUEST,
    payload,
  }),

  getCashbackSuccess: (payload) => ({
    type: Types.GET_CASHBACK_SUCCESS,
    payload,
  }),

  getCashbackFailure: (payload) => ({
    type: Types.GET_CASHBACK_FAILURE,
    payload,
  }),

  createCashbackRequest: (payload) => ({
    type: Types.CREATE_CASHBACK_REQUEST,
    payload,
  }),

  createCashbackSuccess: (payload) => ({
    type: Types.CREATE_CASHBACK_SUCCESS,
    payload,
  }),

  createCashbackFailure: (payload) => ({
    type: Types.CREATE_CASHBACK_FAILURE,
    payload,
  }),

  updateCashbackRequest: (payload) => ({
    type: Types.UPDATE_CASHBACK_REQUEST,
    payload,
  }),

  updateCashbackSuccess: (payload) => ({
    type: Types.UPDATE_CASHBACK_SUCCESS,
    payload,
  }),

  updateCashbackFailure: (payload) => ({
    type: Types.UPDATE_CASHBACK_FAILURE,
    payload,
  }),

  deleteCashbackRequest: (payload) => ({
    type: Types.DELETE_CASHBACK_REQUEST,
    payload,
  }),

  deleteCashbackSuccess: (payload) => ({
    type: Types.DELETE_CASHBACK_SUCCESS,
    payload,
  }),

  deleteCashbackFailure: (payload) => ({
    type: Types.DELETE_CASHBACK_FAILURE,
    payload,
  }),
};
