import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import DataTables from 'components/Datatable'
import TableActions from 'components/TableActions'

import { Creators as NotificationsActions } from 'store/ducks/notifications'
import { notificationDataTableColumns } from 'shared/constants/datatable'

import DefaultListPage from 'components/DefaultPage'

const List = () => {
  const dispatch = useDispatch()
  const { list } = useSelector((state) => state.notifications)

  useEffect(() => {
    dispatch(NotificationsActions.getNotificationsRequest())
  }, [dispatch])

  return (
    <DefaultListPage
      pageTitle="Notificações"
      pageDescription="Gerenciar notificações"
      pageSubDescription="Adicione, ou veja as notificações já enviadas no sistema"
    >
      <TableActions linkToCreate={'/notificacoes/criar'} />
      <DataTables
        list={list}
        customDataTableColumns={notificationDataTableColumns}
        noActions
        hasUpdateAction={false}
      />
    </DefaultListPage>
  )
}

export default List
