import React, { memo } from 'react'
import { Col, Row } from 'reactstrap'
import ValueCard from './Card'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBrazilianRealSign } from '@fortawesome/free-solid-svg-icons'
import { formatToCurrency } from 'utils'

const Values = ({ data }) => (
  <div className="values">
    <Row>

      <Col lg="3">
        <ValueCard
          icon={
            <FontAwesomeIcon
              icon={faBrazilianRealSign}
              color="rgb(34, 95, 107)"
              fontSize="20px"
            />
          }
          title="Total de entradas"
          value={data?.cashback && formatToCurrency(data?.cashback)}
        />
      </Col>
      <Col lg="3">
        <ValueCard
          icon={
            <FontAwesomeIcon
              icon={faBrazilianRealSign}
              color="rgb(34, 95, 107)"
              fontSize="20px"
            />
          }
          title="Total de saídas"
          value={data?.withdraw && formatToCurrency(data?.withdraw)}
        />
      </Col>
      <Col lg="3">
        <ValueCard
          icon={
            <FontAwesomeIcon
              icon={faBrazilianRealSign}
              color="rgb(34, 95, 107)"
              fontSize="20px"
            />
          }
          title="Saldo retido"
          value={
            data?.blockedBalance && formatToCurrency((data?.blockedBalance).toString())
          }
        />
      </Col>
      <Col lg="3">
        <ValueCard
          icon={
            <FontAwesomeIcon
              icon={faBrazilianRealSign}
              color="rgb(34, 95, 107)"
              fontSize="20px"
            />
          }
          title="Lançamentos Futuro"
          value={
            data?.cashbackPending && formatToCurrency(data?.cashbackPending)
          }
        />
      </Col>
      
    </Row>
  </div>
)

export default memo(Values)
