import { put, call } from 'redux-saga/effects'
import { api } from 'services/api'
import { push } from 'connected-react-router'
import { toast } from 'react-toastify'

import { Creators as GraduationActions } from 'store/ducks/graduations'

export function* getGraduations() {
  try {
    const { data } = yield call(api.get, '/graduations')
    yield put(GraduationActions.getGraduationsSuccess(data))
  } catch (err) {
    yield put(GraduationActions.getGraduationsFailure(err.message))
  }
}

export function* getGraduationByID(action) {
  try {
    const { data } = yield call(api.get, `/graduations/${action.payload}`)
    yield put(GraduationActions.getGraduationSuccess(data))
  } catch (err) {
    yield put(GraduationActions.getGraduationFailure(err))
  }
}

export function* createGraduation(action) {
  try {
    const { data } = yield call(api.post, `/graduations`, action.payload)
    yield put(GraduationActions.createGraduationSuccess(data))
    yield put(push('/graduacoes'))
    toast.success('Graduação criada com sucesso!')
  } catch (err) {
    toast.error('Erro ao criar posição')
    yield put(GraduationActions.createGraduationFailure(err))
  }
}

export function* updateGraduation(action) {
  try {
    const { data } = yield call(
      api.put,
      `/graduations/${action.payload.id}`,
      action.payload
    )
    yield put(GraduationActions.updateGraduationSuccess(data))
    yield put(push('/graduacoes'))
    toast.success('Graduação editado com sucesso!')
  } catch (err) {
    toast.error('Erro ao editar posição')
    yield put(GraduationActions.updateGraduationFailure(err))
  }
}

export function* deleteGraduation(action) {
  try {
    const { data } = yield call(api.delete, `/graduations/${action.payload.id}`)
    yield put(GraduationActions.deleteGraduationSuccess(data))
    yield put(GraduationActions.getGraduationsRequest())
    toast.success('Graduação removida com sucesso')
  } catch (err) {
    toast.error('Erro ao remover graduação')
    yield put(GraduationActions.deleteGraduationFailure(err))
  }
}
