import React, { useCallback } from 'react'
import Breadcrumb from 'components/Layout/breadcrumb'
import { Container, Row, Col } from 'reactstrap'

import Layout from 'components/Layout'

import Indicators from 'components/Dashboard/Indicators'
import Invite from 'components/Dashboard/Invite'
import Summary from 'components/Dashboard/Summary'
import GeneralResume from 'components/Dashboard/GeneralResume'
import RankingNetwork from 'components/Dashboard/RankingNetwork'
import Greetings from 'components/Dashboard/Greetings'

import { useSelector, useDispatch } from 'react-redux'

import { Creators as UserActions } from 'store/ducks/users'

import useDashboard from 'shared/hooks/useDashboard'
import useFunctionRole from 'shared/hooks/useFunctionRole'


const Dashboard = () => {
  const dispatch = useDispatch()
  const {
    auth: { auth }
  } = useSelector((state) => state)

  const handleCreateUserInvite = useCallback(
    (item) => {
      item.from = auth && auth.id

      dispatch(UserActions.createUserInviteRequest(item))
    },
    [auth, dispatch]
  )

  const { summaryDataChart, greetingsData } = useDashboard()
  const { isUserAdmin } = useFunctionRole()

  return (
    <Layout>
      <Breadcrumb title="Dashboard" />
      <Container fluid={true}>
        <Row>
          <Col xl="4" lg="4">
            {greetingsData && (
              <Greetings
                icon={greetingsData.icon}
                name={greetingsData.name}
                plan={greetingsData.plan}
              />
            )}
          </Col>
          {isUserAdmin ? (
            <Col xl="8" lg="8">
              {summaryDataChart && <Summary data={summaryDataChart} />}
            </Col>
          ) : (
            <Col sm="6" lg="8" xl="8" md="6" className="xl-50 box-col-6">
              <Invite submitForm={handleCreateUserInvite} />
            </Col>
          )}
        </Row>
        <Row>
          <Indicators />
        </Row>
         <Row>
         <Col xs="6">
              <h3>Consumo dos Últimos 30 dias</h3>
            </Col>
        </Row>
        <Row>
          <GeneralResume />
        </Row>
        <Row>
          <Col xl="12" md="12" className="xl-100 box-col-12">
            <RankingNetwork />
          </Col>
        </Row>
        <Row>
          {isUserAdmin && (
            <Col sm="6" lg="6" xl="6" md="6" className="xl-50 box-col-6">
              <Invite submitForm={handleCreateUserInvite} />
            </Col>
          )}
        </Row>
      </Container>
    </Layout>
  )
}

export default Dashboard
