import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import CreateProfileForm from "components/Profiles/CreateProfileForm";

import DefaultPage from "components/DefaultPage";

import { Creators as ProfileActions } from "store/ducks/profiles";
import { Creators as UserActions } from "store/ducks/actions";

const CreateProfile = () => {
  const dispatch = useDispatch();
  const { list } = useSelector((state) => state.actions);

  const handleCreateProfile = useCallback(
    (item) => {
      let bodyIDs = [];
      if (!!item.actions) {
        item.actions.map((item) => bodyIDs.push({ id: item }));
        delete item.actions;
      }
      let body = { ...item, actions: bodyIDs };

      dispatch(ProfileActions.createProfileRequest(body));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(UserActions.getActionsRequest());
  }, [dispatch]);

  return (
    <DefaultPage
      pageTitle="Perfis"
      pageDescription="Criar perfil"
      pageSubDescription="Criar um novo perfil"
    >
      <CreateProfileForm submitForm={handleCreateProfile} listActions={list} />
    </DefaultPage>
  );
};

export default CreateProfile;
