import { put, call } from 'redux-saga/effects'
import { api } from 'services/api'

import { Creators as WithdrawActions } from 'store/ducks/withdraw'

export function* getWithdrawRequests(action) {
  const search = action.payload.search?`&search=${action.payload.search}`:''
  try {
    const { data } = yield call(
      api.get,
      `/moneys/type/withdraw?page=${action.payload.page}&limit=${action.payload.limit}${search}`
    )
    yield put(
      WithdrawActions.getWithdrawRequestsSuccess({
        data: data[0],
        count: data[1]
      })
    )
  } catch (err) {
    yield put(WithdrawActions.getWithdrawRequestsFailure(err))
  }
}
