import React, { memo } from 'react'
import { Chart } from 'react-google-charts'
import { Card } from 'reactstrap'

const Summary = ({ data }) => (
  <div className="summary">
    <Card className="card">
      <strong>Resumo</strong>
      <Chart
        width={'100%'}
        height={'350px'}
        chartType="PieChart"
        data={data}
        options={{
          colors: ['#a927f9', '#3498db', '#51bb25', '#f8d62b', '#e67e22'],
          is3D: true,
          legend: { position: 'bottom' },
          pieSliceText: 'label'
        }}
        rootProps={{ 'data-testid': '2' }}
      />
    </Card>
  </div>
)

export default memo(Summary)
