import React, { useCallback, useEffect, useState } from "react";
import { Form, Label, Input, Col, Button, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import Select from "react-select";

import { formatToSelect } from "utils";

const CreateGraduationForm = ({ submitForm, usersList, positionsList }) => {
  const [optionsUsersSelect, setOptionsUsersSelect] = useState([]);
  const [optionsPositionsSelect, setOptionsPositionsSelect] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const { ref: scoreRef, ...score } = register("score", { required: true });

  // const formattedToSelect = (list) => {
  //   let options = [];
  //   for (let i in usersList) {
  //     options.push({
  //       value: usersList[i].id,
  //       label: usersList[i].name,
  //     });
  //   }
  //   return options;
  // };

  useEffect(() => {
    setOptionsUsersSelect(formatToSelect(usersList));
  }, [usersList]);

  useEffect(() => {
    setOptionsPositionsSelect(formatToSelect(positionsList));
  }, [positionsList]);

  return (
    <Form
      className="needs-validation"
      noValidate=""
      onSubmit={handleSubmit(submitForm)}
    >
      <Row>
        <Col xl="4">
          <Label htmlFor="validationCustom01">Usuário</Label>
          <Select
            options={optionsUsersSelect}
            onChange={(e) => setValue("user", [{ id: e.value }])}
          />
        </Col>
        <Col>
          <Label htmlFor="validationCustom01">Posição</Label>
          <Select
            options={optionsPositionsSelect}
            onChange={(e) => setValue("position", [{ id: e.value }])}
          />
        </Col>
        <Col>
          <Label htmlFor="validationCustom01">Pontuação</Label>
          <Input
            className="form-control"
            name="name"
            type="text"
            placeholder="Pontuação"
            innerRef={scoreRef}
            {...score}
          />
          <span>{errors.score && "Pontuação é obrigatório"}</span>
        </Col>
      </Row>
      <div className="form-row row-gap">
        <Button color="primary">Enviar</Button>
      </div>
    </Form>
  );
};

export default CreateGraduationForm;
