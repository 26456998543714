import React, { useCallback, useEffect, useState } from 'react'

import { Card, CardBody } from 'reactstrap'

import { Clock } from 'react-feather'

const Greetings = ({ icon, name, plan }) => {
  const [daytimes, setDayTimes] = useState()

  const today = new Date()
  const curHr = today.getHours()

  useEffect(() => {
    if (curHr < 12) {
      setDayTimes('Bom dia')
    } else if (curHr < 18) {
      setDayTimes('Boa tarde')
    } else {
      setDayTimes('Boa noite')
    }
  }, [curHr])

  const dateOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  }

  const currentDate = new Date()

  const formatName = (value) => {
    let toLow = value.toLowerCase()
    const formatted = toLow.charAt(0).toUpperCase() + toLow.slice(1)
    return formatted
  }

  return (
    <Card className="o-hidden profile-greeting">
      <CardBody style={{ filter: 'brightness(1)' }}>
        <div className="media">
          <div className="badge-groups w-100">
            <div className="badge f-12">
              <Clock
                style={{ width: '16px', height: '16px' }}
                className="mr-1"
              />
              <span id="txt">
                {currentDate.toLocaleDateString('pt-BR', dateOptions)}
              </span>
            </div>
          </div>
        </div>
        <div className="greeting-user text-center">
          <div className="profile-vector">
            <img className="img-fluid" src={
                            icon && icon.indexOf("https://onneroyal.blob.core.windows.net/imagens/user-default.svg") === -1
                            ? icon
                              : require('assets/images/user/user.png')
                          }
            
            
            alt="" />
          </div>
          <h4 className="f-w-600">
            <span id="greeting">
              {daytimes}, {name && formatName(name).split(' ')[0]}{' '}
            </span>

            <span className="right-circle">
              <i className="fa fa-check-circle f-14 middle"></i>
            </span>
          </h4>
          <p>
            <span className="current-plan">
              Habilitação atual: {plan && plan.description ? plan.description : ''}
            </span>
          </p>
          <p>
            <span style={{ color: '#fff', fontStyle: 'italic' }}>
              "Tenha a coragem de seguir seu coração e sua intuição. Eles de
              alguma forma já sabem o que você realmente quer se tornar. Tudo o
              mais é secundário."
            </span>
          </p>
          <p>
            <span style={{ color: '#fff' }}>Steve Jobs</span>
          </p>
        </div>
      </CardBody>
    </Card>
  )
}

export default Greetings
