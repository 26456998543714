import React from "react";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ModalDelete = ({
  isOpen,
  title,
  content,
  modalToggle,
  deleteItem,
  item,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={modalToggle} centered>
      <ModalHeader toggle={modalToggle}>{title}</ModalHeader>
      <ModalBody>{content}</ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={modalToggle}>
          Cancelar
        </Button>
        <Button color="danger" onClick={() => deleteItem(item)}>
          Excluir
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalDelete;
