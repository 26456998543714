import React, { memo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Button = ({ title, icon, selected, value, handleSelectMethod }) => {
  return (
    <button
      className={selected ? 'method-button selected' : 'method-button'}
      onClick={() => handleSelectMethod(value)}
    >
      <FontAwesomeIcon icon={icon} fontSize="20px" />
      <span>{title}</span>
    </button>
  )
}

export default memo(Button)
