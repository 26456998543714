import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux'

import DataTables from "components/Datatable";
import TableActions from "components/TableActions";
import ModalDelete from "components/ModalDelete";

import { Creators as StoresActions } from "store/ducks/stores";
import { storesDataTableColumns } from "shared/constants/datatable";

import DefaultListPage from "components/DefaultPage";


const Stores = () => {
  const dispatch = useDispatch();
  const { list,loading } = useSelector((state) => state.stores);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [currentPage, setCurrentPage] = useState(0)



  const handleOpenModal = (value) => {
    setModalOpen(true);
    setModalData({
      title: "Excluir loja",
      content: `Você tem certeza em excluir o loja "${value.name}"?`,
      item: value,
    });
  };

  const handleSearch = useCallback(
    (value) => {
      if (value !== '') {
        dispatch(StoresActions.getStoresRequest({ search: value, page: 0, limit: 10 }))
      } else if (list) {
        dispatch(StoresActions.getStoresRequest({ page: 0, limit: 10 }))
      }
    },
    [dispatch, list]
  )

  const handleClearSearch = useCallback(() => {
    dispatch(StoresActions.getStoresRequest({ page: 0, limit: 10 }))
  }, [dispatch])

  const handleChangeRowsPerPage = useCallback(
    (currentPage, size) => {
      dispatch(StoresActions.getStoresRequest({ page: currentPage, limit: size }))
    },
    [dispatch]
  )

  const handleChangePage = useCallback(
    (page) => {
      setCurrentPage(page)
      dispatch(StoresActions.getStoresRequest({ page: page, limit: 10 }))
    },
    [dispatch]
  )

  //Populate default list
  useEffect(() => {
    dispatch(StoresActions.getStoresRequest({ page: 0, limit: 10 }))
  }, [dispatch])

  //Reset store state
  useEffect(() => {
    dispatch(StoresActions.getStoresRequest({ page: 0, limit: 10 }))
  }, [dispatch])

  return (
    <DefaultListPage
      pageTitle="Lojas"
      pageDescription="Gerenciar lojas"
      pageSubDescription="  edite lojas"
    >
      <TableActions
        linkToCreate={'/usuarios/criar'}
        hasSearch
        hiddenAddButton
        handleSearch={handleSearch}
        handleClearSearch={handleClearSearch}
      />
      <DataTables
        list={list.data}
        count={list.count}
        customDataTableColumns={storesDataTableColumns}
        handleOpenModal={handleOpenModal}
        editURL="/stores/edit"
        onlyEditable={true}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        hasPagination
        currentPage={currentPage}
        loading={loading}
      />

      <ModalDelete
        isOpen={modalOpen}
        modalToggle={() => setModalOpen(false)}
        title={modalData.title}
        content={modalData.content}

        item={modalData.item}
      />
    </DefaultListPage>
  );
};

export default Stores;
