import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux'

import DataTables from "components/Datatable";
import TableActions from "components/TableActions";
import ModalDelete from "components/ModalDelete";

import { Creators as MoneyActions } from "store/ducks/money";
import { transactionsDataTableColumns } from "shared/constants/datatable";

import DefaultListPage from "components/DefaultPage";


const Sales = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const INTIAL_PAGE = 0;
  const INITIAL_LIMIT = 10;
  const [currentPage, setCurrentPage] = useState(INTIAL_PAGE);
  const dispatch = useDispatch();
  const {
    auth: { auth },
    money: { moneySalesAccredited, loading }
  } = useSelector((state) => state)

  const handleChangeRowsPerPage = useCallback(
    (currentPage, size) => {
      dispatch(
        MoneyActions.getMoneySalesByAccreditedRequest(
          `${auth.id}?page=${currentPage}&limit=${size}`
        )
      );
    },
    [auth.id, dispatch]
  );

  const handleChangePage = useCallback(
    (page) => {
      setCurrentPage(page);
      dispatch(
        MoneyActions.getMoneySalesByAccreditedRequest(
          `${auth.id}?page=${page}&limit=${INITIAL_LIMIT}`
        )
      );
    },
    [auth.id, dispatch]
  );

  useEffect(() => {
    dispatch(
      MoneyActions.getMoneySalesByAccreditedRequest(
        `${auth.id}?page=${INTIAL_PAGE}&limit=${INITIAL_LIMIT}`
      )
    );
  }, [dispatch, auth.id]);



  const handleOpenModal = (value) => {
    setModalOpen(true);
    setModalData({
      title: "Excluir usuário",
      content: `Você tem certeza em excluir o usuário "${value.name}"?`,
      item: value,
    });
  };

  return (
    <DefaultListPage
      pageTitle="Vendas"
      pageDescription="Gerenciar vendas"
      pageSubDescription="Adicione, remove, edite alguns dos itens abaixo"
    >
      <TableActions linkToCreate={"/sales/create"} />


      <DataTables
        list={moneySalesAccredited?.list}
        customDataTableColumns={transactionsDataTableColumns}
        noActions={true}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        handleOpenModal={handleOpenModal}
        hasPagination
        loading={loading}
        currentPage={currentPage}
        count={moneySalesAccredited?.count}
      />

      <ModalDelete
        isOpen={modalOpen}
        modalToggle={() => setModalOpen(false)}
        title={modalData.title}
        content={modalData.content}

        item={modalData.item}
      />
    </DefaultListPage>
  );
};

export default Sales;
