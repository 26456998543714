import { createStore, applyMiddleware, compose } from 'redux'

import createSagaMiddleware from 'redux-saga'

import { AUTH_KEYS } from '../services/auth'

import rootReducer from './ducks'
import rootSaga from './sagas'

import history from '../routes/history'

import { routerMiddleware } from 'connected-react-router'

const saveToLocalStorage = (state) => {
  const serializedState = JSON.stringify(state)
  localStorage.setItem(AUTH_KEYS.STATE, serializedState)
}

const loadFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem(AUTH_KEYS.STATE)
    if (serializedState === null) return undefined
    let state = JSON.parse(serializedState)

    return state
  } catch (e) {
    return undefined
  }
}

const sagaMiddleware = createSagaMiddleware()

const persistedState = loadFromLocalStorage()

const middlewares = applyMiddleware(sagaMiddleware, routerMiddleware(history))

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const enhancers =
  // eslint-disable-next-line no-undef
  process.env.NODE_ENV === 'development'
    ? composeEnhancers(middlewares)
    : middlewares

const store = createStore(rootReducer(history), persistedState, enhancers)

store.subscribe(() => saveToLocalStorage(store.getState()))

sagaMiddleware.run(rootSaga)

export default store
