export const Types = {
  LOGIN_REQUEST: 'auth/LOGIN_REQUEST',
  LOGIN_SUCCESS: 'auth/LOGIN_SUCCESS',
  LOGIN_FAILURE: 'auth/LOGIN_FAILURE',

  LOGOUT: 'auth/LOGOUT',

  UPDATE_AUTH_USER_REQUEST: 'auth/UPDATE_AUTH_USER_REQUEST',
  UPDATE_AUTH_USER_SUCCESS: 'auth/UPDATE_AUTH_USER_SUCCESS',
  UPDATE_AUTH_USER_FAILURE: 'auth/UPDATE_AUTH_USER_FAILURE',

  UPDATE_AUTH_USER_PASSWORD_REQUEST: 'auth/UPDATE_AUTH_USER_PASSWORD_REQUEST',
  UPDATE_AUTH_USER_PASSWORD_SUCCESS: 'auth/UPDATE_AUTH_USER_PASSWORD_SUCCESS',
  UPDATE_AUTH_USER_PASSWORD_FAILURE: 'auth/UPDATE_AUTH_USER_PASSWORD_FAILURE',

  UPDATE_USER_PROFILE_IMAGE_REQUEST: 'auth/UPLOAD_USER_PROFILE_IMAGE_REQUEST',
  UPDATE_USER_PROFILE_IMAGE_SUCCESS: 'auth/UPLOAD_USER_PROFILE_IMAGE_SUCCESS',
  UPDATE_USER_PROFILE_IMAGE_FAILURE: 'auth/UPLOAD_USER_PROFILE_IMAGE_FAILURE',

  REGISTER_USER_REQUEST: 'auth/REGISTER_USER_REQUEST',
  REGISTER_USER_SUCCESS: 'auth/REGISTER_USER_SUCCESS',
  REGISTER_USER_FAILURE: 'auth/REGISTER_USER_FAILURE'
}

const INITIAL_STATE = {
  auth: {},
  user: {},
  loading: false,
  error: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      }

    case Types.LOGIN_SUCCESS:
      return {
        ...state,
        auth: action.payload,
        loading: false
      }

    case Types.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    case Types.LOGOUT:
      return (state = null)

    case Types.UPDATE_AUTH_USER_REQUEST:
      return { ...state, loading: true, error: null }

    case Types.UPDATE_AUTH_USER_SUCCESS:
      return { ...state, auth: action.payload, loading: false, error: null }

    case Types.UPDATE_AUTH_USER_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case Types.UPDATE_AUTH_USER_PASSWORD_REQUEST:
      return { ...state, loading: true, error: null }

    case Types.UPDATE_AUTH_USER_PASSWORD_SUCCESS:
      return { ...state, loading: false, error: null }

    case Types.UPDATE_AUTH_USER_PASSWORD_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case Types.UPDATE_USER_PROFILE_IMAGE_REQUEST:
      return { ...state, loading: true, error: null }

    case Types.UPDATE_USER_PROFILE_IMAGE_SUCCESS:
      return { ...state, loading: false, error: null }

    case Types.UPDATE_USER_PROFILE_IMAGE_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case Types.REGISTER_USER_REQUEST:
      return { ...state, loading: true, error: null }

    case Types.REGISTER_USER_SUCCESS:
      return { ...state, loading: false, error: null, auth: action.payload }

    case Types.REGISTER_USER_FAILURE:
      return { ...state, loading: false, error: action.payload }

    default:
      return state
  }
}

export const Creators = {
  loginRequest: (value) => ({
    type: Types.LOGIN_REQUEST,
    payload: value
  }),

  loginSuccess: (payload) => ({
    type: Types.LOGIN_SUCCESS,
    payload
  }),

  loginFailure: (error) => ({
    type: Types.LOGIN_FAILURE,
    payload: error
  }),

  logout: () => ({
    type: Types.LOGOUT
  }),

  updateAuthUserRequest: (payload) => ({
    type: Types.UPDATE_AUTH_USER_REQUEST,
    payload
  }),

  updateAuthUserSuccess: (payload) => ({
    type: Types.UPDATE_AUTH_USER_SUCCESS,
    payload
  }),

  updateAuthUserFailure: (payload) => ({
    type: Types.UPDATE_AUTH_USER_FAILURE,
    payload
  }),

  updateAuthUserPasswordRequest: (payload) => ({
    type: Types.UPDATE_AUTH_USER_PASSWORD_REQUEST,
    payload
  }),

  updateAuthUserPasswordSuccess: (payload) => ({
    type: Types.UPDATE_AUTH_USER_PASSWORD_SUCCESS,
    payload
  }),

  updateAuthUserPasswordFailure: (payload) => ({
    type: Types.UPDATE_AUTH_USER_PASSWORD_FAILURE,
    payload
  }),

  uploadAuthUserProfileImageRequest: (payload) => ({
    type: Types.UPDATE_USER_PROFILE_IMAGE_REQUEST,
    payload
  }),

  uploadAuthUserProfileImageSuccess: (payload) => ({
    type: Types.UPDATE_USER_PROFILE_IMAGE_SUCCESS,
    payload
  }),

  uploadAuthUserProfileImageFailure: (payload) => ({
    type: Types.UPDATE_USER_PROFILE_IMAGE_FAILURE,
    payload
  }),

  registerUserRequest: (payload) => ({
    type: Types.REGISTER_USER_REQUEST,
    payload
  }),

  registerUserSuccess: (payload) => ({
    type: Types.REGISTER_USER_SUCCESS,
    payload
  }),

  registerUserFailure: (payload) => ({
    type: Types.REGISTER_USER_FAILURE,
    payload
  })
}
