import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import DefaultPage from "components/DefaultPage";

import CreatePlanForm from "components/Plans/CreatePlanForm";

import { Creators as PlanActions } from "store/ducks/plans";
import { Creators as LevelActions } from "store/ducks/levels";

const CreatePlan = () => {
  const dispatch = useDispatch();
  const { list } = useSelector((state) => state.levels);

  const handleCreatePlan = useCallback(
    (item) => {
      let bodyIDs = [];
      if (!!item.levels) {
        item.levels.map((item) => bodyIDs.push({ id: item }));
        delete item.levels;
      }
      let body = { ...item, levels: bodyIDs };
      dispatch(PlanActions.createPlanRequest(body));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(LevelActions.getLevelsRequest());
  }, [dispatch]);

  return (
    <DefaultPage
      pageTitle="Habilitações"
      pageDescription="Criar Habilitação"
      pageSubDescription="Criar um Habilitação"
    >
      <CreatePlanForm submitForm={handleCreatePlan} listLevels={list} />
    </DefaultPage>
  );
};

export default CreatePlan;
