import React, { memo } from 'react'
import { Label, Row, Col } from 'reactstrap'

const SelectEntityColumns = ({
  handleChooseEntityColumns,
  columns,
  loadingColumns,
  register,
  selectedColumns
}) => {
  const Checkbox = ({ item, index }) => (
    <div className="main-checkbox">
      <div className="checkbox-label">
        <Label className="d-block" for={item.name} key={index}>
          <input
            className="checkbox_animated"
            type="checkbox"
            value={item.name}
            {...register('selectColumns')}
          />
          <span>{item.name}</span>
        </Label>
      </div>
      {selectedColumns && selectedColumns.includes(item.name) && (
        <div className="checkbox-field">
          <input
            style={{ width: '100%' }}
            className="form-control"
            name="description"
            type="text"
            placeholder={item.name}
            {...register(`${item.name}`)}
          />
        </div>
      )}
    </div>
  )

  return loadingColumns ? (
    <span>Carregando...</span>
  ) : (
    <Row>
      <Col>
        <Label for="colunas">Colunas</Label>
        {columns.map((column, index) => (
          <Checkbox item={column} index={index} key={index} />
        ))}
      </Col>
    </Row>
  )
}

export default memo(SelectEntityColumns)
