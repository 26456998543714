import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DataTables from "components/Datatable";
import TableActions from "components/TableActions";
import ModalDelete from "components/ModalDelete";

import { Creators as GraduationsActions } from "store/ducks/graduations";
import { graduationsDatatableColumns } from "shared/constants/datatable";

import DefaultListPage from "components/DefaultPage";

const Graduations = () => {
  const dispatch = useDispatch();
  const { list, loading } = useSelector((state) => state.graduations);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});

  useEffect(() => {
    dispatch(GraduationsActions.getGraduationsRequest());
  }, [dispatch]);

  const handleDeleteItem = useCallback(
    (item) => {
      dispatch(GraduationsActions.deleteGraduationRequest(item));
      setModalOpen(false);
    },
    [dispatch]
  );

  const handleOpenModal = (value) => {
    setModalOpen(true);
    setModalData({
      title: "Excluir graduação",
      content: `Você tem certeza em excluir a graduação "${value.name}"?`,
      item: value,
    });
  };

  return (
    <DefaultListPage
      pageTitle="Graduações"
      pageDescription="Gerenciar graduações"
      pageSubDescription="Adicione, remove, edite alguns dos itens abaixo"
    >
      <TableActions linkToCreate={"/graduacoes/criar"} />
      <DataTables
        list={list}
        editURL="/graduacoes/editar"
        handleOpenModal={handleOpenModal}
        customDataTableColumns={graduationsDatatableColumns}
        loading={loading}
      />
      <ModalDelete
        isOpen={modalOpen}
        modalToggle={() => setModalOpen(false)}
        title={modalData.title}
        content={modalData.content}
        deleteItem={handleDeleteItem}
        item={modalData.item}
      />
    </DefaultListPage>
  );
};

export default Graduations;
