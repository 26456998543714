import React from "react";
import { Form, Label, Input, Col, Button } from "reactstrap";

import { useForm } from "react-hook-form";

import InputMask from "react-input-mask";

const CreateInvoiceForm = ({
  submitForm,
  invoiceEstimeted,
  invoice

}) => {


  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      amount: invoiceEstimeted?.invoiceAmount,
      date: invoiceEstimeted?.date
    },
  });


  const { ref: descriptionRef, ...description } = register("description");
  const { ref: amountRef, ...amount } = register("amount", { required: true });
  const { ref: dateRef, ...date } = register("date", { required: true });


  return (
    <Form
      className="needs-validation"
      noValidate=""
      onSubmit={handleSubmit(submitForm)}
    >
      <div className="form-row">
        <Col md="3 mb-3">
          <Label htmlFor="validationCustom01">Descrição</Label>
          <Input
            className="form-control"
            name="description"
            type="text"
            placeholder="Descrição"
            innerRef={descriptionRef}
            {...description}
          />
          <span>{errors.description && "Descrição é obrigatório"}</span>
        </Col>
        <Col md="3 mb-3">
          <Label htmlFor="validationCustom02">Valor</Label>
          <Input
            className="form-control"
            name="amount"
            type="number"
            placeholder="Valor"
            innerRef={amountRef}
            min="0.00"
            step="0.001"
            max="999999999999.00"
            presicion={2}   //very important
            onChange={() => {
              console.log('page rate changed');
            }}
            {...amount}
          />

          <span>{errors.amount && "Amount é obrigatório"}</span>
        </Col>
        <Col md="3 mb-3">
          <Label htmlFor="validationCustom02">Data de Vencimento</Label>
          <Input
            className="form-control"
            name="date"
            type="text"
            placeholder="Data de Vencimento"
            innerRef={dateRef}
            {...date}
            tag={InputMask}
            mask="99/99/9999"
          />

          <span>{errors.birthDate && "Data de Vencimento é obrigatório"}</span>
        </Col>

      </div>
      <div className="form-row row-gap">
        <Button color="primary">Enviar</Button>
      </div>
    </Form>
  );
};

export default CreateInvoiceForm;
