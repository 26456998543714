import React, { useEffect, useRef } from 'react'
import { Circle } from 'react-feather'
import Moment from 'react-moment'
import 'moment/locale/pt-br'

const Item = ({ id, description, date, handleRemoveNotification }) => (
  <span className="item" onClick={() => handleRemoveNotification(id)}>
    <div className="description">
      <Circle style={{ color: 'green' }} size={10} /> {description}
    </div>
    <small>
      <Moment fromNow>{date}</Moment>
    </small>
  </span>
)

const NotificationList = ({
  list,
  onClickOutside,
  handleRemoveNotification
}) => {
  const ref = useRef(null)
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside()
      }
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [onClickOutside])

  return (
    <div className="wrapper" ref={ref}>
      <div className="list">
        <span className="title">Notificações</span>
        <div className="items">
          {list.length > 0 ? (
            list.map((item) => (
              <Item
                key={item.id}
                id={item.id}
                description={item.description}
                date={item.delivereDate}
                handleRemoveNotification={handleRemoveNotification}
              />
            ))
          ) : (
            <span>Não há novas notificações!</span>
          )}
        </div>
      </div>
    </div>
  )
}

export default NotificationList
