import React from 'react'

import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { PrivateRoute } from './privateRoute'
import history from './history'
//Auth
import Register from 'pages/Register'
import Login from 'pages/Login'
import Dashboard from 'pages/Dashboard'
import SalesRoutes from './sales'
import StoresRoutes from './stores'
import UsersRoutes from './users'
import SupportRoutes from './support'
import ActionsRoutes from './actions'
import ProfilesRoutes from './profiles'
import PlansRoutes from './plans'
import BonificationsRoutes from './bonifications'
import ReportRoutes from './report'
import CashbacksRoutes from './cashbacks'
import LevelsRoutes from './levels'
import PositionsRoutes from './positions'
import GraduationsRoutes from './graduations'
import PaymentMethodsRoutes from './paymentMethods'
import DeadBeatRoutes from './deadbeat'
import FinancialRoutes from './financial'
import ServicesRoutes from './services'
import WithdrawRoutes from './withdraw'
import InvoiceRoutes from './invoice'
import FAQRoutes from './faq'
import TutorialsRoutes from './tutorials'
import MaterialsRoutes from './materials'
import NotificationRoutes from './notifications'

import Network from 'pages/Network'
import NetworkUser from 'pages/Network/User'

import Checkout from 'pages/Checkout'

import { isAuthenticated } from 'services/auth'
import Logout from 'pages/Logout'

const Routes = () => {
  const BASE_URL = `${process.env.PUBLIC_URL}`

  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route exact path={`${BASE_URL}/`}>
          {isAuthenticated() ? (
            <Redirect to={`${BASE_URL}/dashboard`} />
          ) : (
            <Redirect to={`${BASE_URL}/login`} />
          )}
        </Route>
        <Route exact path={`${BASE_URL}/register`} component={Register} />
        <Route exact path={`${BASE_URL}/login`} component={Login} />

        <PrivateRoute
          exact
          path={`${BASE_URL}/dashboard`}
          component={Dashboard}
        />
        {StoresRoutes.map((props, index) => (
          <PrivateRoute exact {...props} key={index} />
        ))}
        {SalesRoutes.map((props, index) => (
          <PrivateRoute exact {...props} key={index} />
        ))}
        {InvoiceRoutes.map((props, index) => (
          <PrivateRoute exact {...props} key={index} />
        ))}
        {UsersRoutes.map((props, index) => (
          <PrivateRoute exact {...props} key={index} />
        ))}
        {ProfilesRoutes.map((props, index) => (
          <PrivateRoute exact {...props} key={index} />
        ))}
        {ActionsRoutes.map((props, index) => (
          <PrivateRoute exact {...props} key={index} />
        ))}
        {PlansRoutes.map((props, index) => (
          <Route exact {...props} key={index} />
        ))}
        {BonificationsRoutes.map((props, index) => (
          <PrivateRoute exact {...props} key={index} />
        ))}
        {CashbacksRoutes.map((props, index) => (
          <PrivateRoute exact {...props} key={index} />
        ))}
        {LevelsRoutes.map((props, index) => (
          <PrivateRoute exact {...props} key={index} />
        ))}
        {PositionsRoutes.map((props, index) => (
          <PrivateRoute exact {...props} key={index} />
        ))}
        {GraduationsRoutes.map((props, index) => (
          <PrivateRoute exact {...props} key={index} />
        ))}
        {PaymentMethodsRoutes.map((props, index) => (
          <PrivateRoute exact {...props} key={index} />
        ))}
        {DeadBeatRoutes.map((props, index) => (
          <PrivateRoute exact {...props} key={index} />
        ))}
        {FinancialRoutes.map((props, index) => (
          <PrivateRoute exact {...props} key={index} />
        ))}
        {ServicesRoutes.map((props, index) => (
          <PrivateRoute exact {...props} key={index} />
        ))}
        {SupportRoutes.map((props, index) => (
          <PrivateRoute exact {...props} key={index} />
        ))}

        {WithdrawRoutes.map((props, index) => (
          <PrivateRoute exact {...props} key={index} />
        ))}
        {ReportRoutes.map((props, index) => (
          <PrivateRoute exact {...props} key={index} />
        ))}

        {FAQRoutes.map((props, index) => (
          <PrivateRoute exact {...props} key={index} />
        ))}

        {TutorialsRoutes.map((props, index) => (
          <PrivateRoute exact {...props} key={index} />
        ))}
        {MaterialsRoutes.map((props, index) => (
          <PrivateRoute exact {...props} key={index} />
        ))}
        {NotificationRoutes.map((props, index) => (
          <PrivateRoute exact {...props} key={index} />
        ))}

        <Route exact path={`${BASE_URL}/rede`} component={Network} />
        <Route
          exact
          path={`${BASE_URL}/rede/downline`}
          component={NetworkUser}
        />

        <Route exact path={`${BASE_URL}/logout`} component={Logout} />
        <PrivateRoute
          exact
          path={`${BASE_URL}/checkout`}
          component={Checkout}
        />
      </Switch>
    </ConnectedRouter>
  )
}

export default Routes
