import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import DefaultPage from "components/DefaultPage";

import CreateServiceForm from "components/Services/CreateServiceForm";

import { Creators as ServicesActions } from "store/ducks/services";

const CreateAction = () => {
  const dispatch = useDispatch();

  const handleCreateService = useCallback(
    (item) => {
      dispatch(ServicesActions.createServiceRequest(item));
    },
    [dispatch]
  );

  return (
    <DefaultPage
      pageTitle="Serviços"
      pageDescription="Criar serviço"
      pageSubDescription="Criar um novo serviço no sistema"
    >
      <CreateServiceForm submitForm={handleCreateService} />
    </DefaultPage>
  );
};

export default CreateAction;
