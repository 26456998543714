import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap'
import {
  modalContentStyle,
  modalActionsStyle,
  modalTextAreaStyle
} from './style'

const ModalDecline = ({ modalOpen, modalToggle }) => (
  <Modal isOpen={modalOpen} toggle={modalToggle} centered>
    <ModalBody>
      <div>
        <div style={modalContentStyle}>
          <strong style={{ fontSize: '16px' }}>Mensagem ao usuário</strong>
          <textarea
            style={modalTextAreaStyle}
            className="form-control"
            placeholder="Descreva aqui o motivo."
          />
        </div>
        <div style={modalActionsStyle}>
          <Button color="primary" onClick={() => modalToggle(false)}>
            Cancelar
          </Button>
          <Button color="success" onClick={() => modalToggle(false)}>
            Enviar
          </Button>
        </div>
      </div>
    </ModalBody>
  </Modal>
)

export default ModalDecline
