import React, { memo } from 'react'
import { Input, Label } from 'reactstrap'

const SelectEntities = ({
  handleChooseEntity,
  entities,
  loadingEntities,
  reset,
  getValues
}) =>
  loadingEntities ? (
    <span>Carregando...</span>
  ) : (
    <>
      <Label for="tabelas">Tabelas</Label>
      <Input
        type="select"
        onChange={(e) => {
          handleChooseEntity(e.target.value)
          let currentValues = getValues()
          delete currentValues.selectColumns
          reset(currentValues)
        }}
      >
        <option value="">Selecione</option>
        {entities.map((entity, index) => (
          <option key={index} value={`${entity.id}-${entity.entitieName}`}>
            {entity.name}
          </option>
        ))}
      </Input>
    </>
  )

export default memo(SelectEntities)
