import React, { memo } from 'react'
import DataTable from 'react-data-table-component'
import { Trash2, Edit } from 'react-feather'
import { useHistory } from 'react-router-dom'

import TablePagination from '@material-ui/core/TablePagination'
import LoaderSpinner from 'components/LoaderSpinner'

const DataTables = ({
  list,
  count,
  editURL,
  customDataTableColumns,
  noActions,
  onlyEditable,
  hasPagination,
  handleOpenModal,
  handleChangeRowsPerPage,
  handleChangePage,
  currentPage,
  defaultActions = true,
  customActions,
  loading,
  hasUpdateAction = true
}) => {
  const history = useHistory()

  const formatDate = (value) => {
    const date = new Date(value)
    return date.toLocaleDateString('pt-BR')
  }

  const tableColumns = [
    ...customDataTableColumns,
    {
      name: 'Data de criação',
      selector: (item) => formatDate(item.createdAt),
      sortable: true,
      center: true
    },
    hasUpdateAction && {
      name: 'Data de Atualização',
      selector: (item) => formatDate(item.updatedAt),
      sortable: true,
      center: true
    },
    !noActions && {
      name: 'Ações',
      selector: (item) => (
        <div style={{ display: 'flex', gap: '0 5px' }}>
          {defaultActions ? (
            <>
              <Edit
                size={20}
                style={{ cursor: 'pointer' }}
                onClick={() => history.push(`${editURL}/${item.id}`)}
              />
              {!onlyEditable && (
                <Trash2
                  size={20}
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleOpenModal(item)}
                />
              )}
            </>
          ) : (
            customActions(item)
          )}
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      center: true
    }
  ]

  const clearTableColumns = tableColumns.filter((item) => item !== false)

  let retorno = loading ? (
    <LoaderSpinner />
  ) : (
    <>
      <DataTable
        data={list}
        columns={clearTableColumns}
        striped={true}
        center={true}
        persistTableHead
      />
      {hasPagination && (
        <TablePagination
          component="div"
          count={count}
          page={currentPage}
          onPageChange={(e, newPage) => handleChangePage(newPage)}
          rowsPerPage={list?.length}
          onRowsPerPageChange={(e) =>
            handleChangeRowsPerPage(currentPage, e.target.value)
          }
          labelRowsPerPage="Itens por página"
        />
      )}
    </>
  )

  return retorno
}

export default memo(DataTables)
