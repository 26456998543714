export const Types = {
  GET_PAYMENT_METHODS_REQUEST: "payment_methods/GET_PAYMENT_METHODS_REQUEST",
  GET_PAYMENT_METHODS_SUCCESS: "payment_methods/GET_PAYMENT_METHODS_SUCCESS",
  GET_PAYMENT_METHODS_FAILURE: "payment_methods/GET_PAYMENT_METHODS_FAILURE",

  GET_PAYMENT_METHOD_REQUEST: "payment_methods/GET_PAYMENT_METHOD_REQUEST",
  GET_PAYMENT_METHOD_SUCCESS: "payment_methods/GET_PAYMENT_METHOD_SUCCESS",
  GET_PAYMENT_METHOD_FAILURE: "payment_methods/GET_PAYMENT_METHOD_FAILURE",

  CREATE_PAYMENT_METHOD_REQUEST:
    "payment_methods/CREATE_PAYMENT_METHOD_REQUEST",
  CREATE_PAYMENT_METHOD_SUCCESS:
    "payment_methods/CREATE_PAYMENT_METHOD_SUCCESS",
  CREATE_PAYMENT_METHOD_FAILURE:
    "payment_methods/CREATE_PAYMENT_METHOD_FAILURE",

  UPDATE_PAYMENT_METHOD_REQUEST:
    "payment_methods/UPDATE_PAYMENT_METHOD_REQUEST",
  UPDATE_PAYMENT_METHOD_SUCCESS:
    "payment_methods/UPDATE_PAYMENT_METHOD_SUCCESS",
  UPDATE_PAYMENT_METHOD_FAILURE:
    "payment_methods/UPDATE_PAYMENT_METHOD_FAILURE",

  DELETE_PAYMENT_METHOD_REQUEST:
    "payment_methods/DELETE_PAYMENT_METHOD_REQUEST",
  DELETE_PAYMENT_METHOD_SUCCESS:
    "payment_methods/DELETE_PAYMENT_METHOD_SUCCESS",
  DELETE_PAYMENT_METHOD_FAILURE:
    "payment_methods/DELETE_PAYMENT_METHOD_FAILURE",
};

const INITIAL_STATE = {
  list: [],
  method: {},
  loading: false,
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_PAYMENT_METHODS_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.GET_PAYMENT_METHODS_SUCCESS:
      return { ...state, loading: false, list: action.payload, error: null };

    case Types.GET_PAYMENT_METHODS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.GET_PAYMENT_METHOD_REQUEST:
      return { ...state, loading: true, method: {}, error: null };

    case Types.GET_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        loading: false,
        method: action.payload,
        error: null,
      };

    case Types.GET_PAYMENT_METHOD_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.CREATE_PAYMENT_METHOD_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.CREATE_PAYMENT_METHOD_SUCCESS:
      return { ...state, loading: false, error: null };

    case Types.CREATE_PAYMENT_METHOD_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.UPDATE_PAYMENT_METHOD_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.UPDATE_PAYMENT_METHOD_SUCCESS:
      return { ...state, loading: false, method: {}, error: null };

    case Types.UPDATE_PAYMENT_METHOD_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.DELETE_PAYMENT_METHOD_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.DELETE_PAYMENT_METHOD_SUCCESS:
      return { ...state, loading: false, error: null };

    case Types.DELETE_PAYMENT_METHOD_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export const Creators = {
  getPaymentMethodsRequest: (payload) => ({
    type: Types.GET_PAYMENT_METHODS_REQUEST,
    payload,
  }),

  getPaymentMethodsSuccess: (payload) => ({
    type: Types.GET_PAYMENT_METHODS_SUCCESS,
    payload,
  }),

  getPaymentMethodsFailure: (payload) => ({
    type: Types.GET_PAYMENT_METHODS_FAILURE,
    payload,
  }),

  getPaymentMethodRequest: (payload) => ({
    type: Types.GET_PAYMENT_METHOD_REQUEST,
    payload,
  }),

  getPaymentMethodSuccess: (payload) => ({
    type: Types.GET_PAYMENT_METHOD_SUCCESS,
    payload,
  }),

  getPaymentMethodFailure: (payload) => ({
    type: Types.GET_PAYMENT_METHOD_FAILURE,
    payload,
  }),

  createPaymentMethodRequest: (payload) => ({
    type: Types.CREATE_PAYMENT_METHOD_REQUEST,
    payload,
  }),

  createPaymentMethodSuccess: (payload) => ({
    type: Types.CREATE_PAYMENT_METHOD_SUCCESS,
    payload,
  }),

  createPaymentMethodFailure: (payload) => ({
    type: Types.CREATE_PAYMENT_METHOD_FAILURE,
    payload,
  }),

  updatePaymentMethodRequest: (payload) => ({
    type: Types.UPDATE_PAYMENT_METHOD_REQUEST,
    payload,
  }),

  updatePaymentMethodSuccess: (payload) => ({
    type: Types.UPDATE_PAYMENT_METHOD_SUCCESS,
    payload,
  }),

  updatePaymentMethodFailure: (payload) => ({
    type: Types.UPDATE_PAYMENT_METHOD_FAILURE,
    payload,
  }),

  deletePaymentMethodRequest: (payload) => ({
    type: Types.DELETE_PAYMENT_METHOD_REQUEST,
    payload,
  }),

  deletePaymentMethodSuccess: (payload) => ({
    type: Types.DELETE_PAYMENT_METHOD_SUCCESS,
    payload,
  }),

  deletePaymentMethodFailure: (payload) => ({
    type: Types.DELETE_PAYMENT_METHOD_FAILURE,
    payload,
  }),
};
