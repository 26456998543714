import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import DefaultPage from "components/DefaultPage";

import { Creators as SupportsActions } from "store/ducks/support";

import CreateSupportForm from "components/Support/CreateSupportForm";
import auth from "store/ducks/auth";

const CreateSupport = () => {
  const dispatch = useDispatch();
  const {
    auth: { auth }
  } = useSelector((state) => state)
  const handleCreateSupport = useCallback(
    (item) => {
      dispatch(SupportsActions.createSupportRequest({
        ...item, user: {
          id: auth.id
        },
        file: item.file

      }));
    },
    [auth.id, dispatch]
  );

  return (
    <DefaultPage
      pageTitle="Suporte"
      pageDescription="Criar suporte"
      pageSubDescription="Criar uma novo chamado de suporte"
    >
      <CreateSupportForm
        submitForm={handleCreateSupport}
      />
    </DefaultPage>
  );
};

export default CreateSupport;
