
import { put, call } from 'redux-saga/effects'
import { api } from 'services/api'
import { push } from 'connected-react-router'
import { toast } from 'react-toastify'

import { Creators as InvoiceActions } from 'store/ducks/invoice'

export function* getinvoice() {
  try {
    const { data } = yield call(api.get, '/invoices')
    yield put(InvoiceActions.getInvoiceSuccess(data))
  } catch (err) {
    yield put(InvoiceActions.getInvoiceFailure(err.message))
  }
}
export function* getInvoiceByUser(action) {
  try {
    const { data } = yield call(api.get, `/invoices/user/${action.payload.id}`)
    yield put(InvoiceActions.getInvoiceByUserSuccess(data))
  } catch (err) {
    yield put(InvoiceActions.getInvoiceByUserFailure(err.message))
  }
}
export function* getInvoiceEstimetedByUser(action) {
  try {
    const { data } = yield call(api.get, `/invoices/estimeted/${action.payload}`)
    yield put(InvoiceActions.getInvoiceEstimetedByUserSuccess(data))
  } catch (err) {
    yield put(InvoiceActions.getInvoiceEstimetedByUserFailure(err.message))
  }
}
export function* getinvoiceByID(action) {
  try {
    const { data } = yield call(api.get, `/invoices/${action.payload}`)
    yield put(InvoiceActions.getInvoiceByIdSuccess(data))
  } catch (err) {
    yield put(InvoiceActions.getInvoiceFailure(err))
  }
}

export function* createinvoice(action) {
  try {
    const { data } = yield call(api.post, `/invoices`, action.payload)
    yield put(InvoiceActions.createinvoiceSuccess(data))
    yield put(push('/invoice'))
    toast.success('invoice criado com sucesso!')
  } catch (err) {
    toast.error('Erro ao criar fatura')
    yield put(InvoiceActions.createinvoiceFailure(err))
  }
}

export function* updateinvoice(action) {
  try {
    const { data } = yield call(
      api.put,
      `/invoice/${action.payload.id}`,
      action.payload
    )
    yield put(InvoiceActions.updateinvoiceSuccess(data))
    yield put(push('/invoices'))
    toast.success('invoice editado com sucesso!')
  } catch (err) {
    toast.error('Erro ao editar fatura')
    yield put(InvoiceActions.updateinvoiceFailure(err))
  }
}

export function* deleteinvoice(action) {
  try {
    const { data } = yield call(api.delete, `/invoices/${action.payload.id}`)
    yield put(InvoiceActions.deleteInvoiceSuccess(data))
    yield put(InvoiceActions.getInvoiceRequest())
    toast.success('invoice removido com sucesso')
  } catch (err) {
    toast.error('Erro ao remover fatura')
    yield put(InvoiceActions.deleteinvoiceFailure(err))
  }
}
