import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'

import DefaultPage from 'components/DefaultPage'
import Form from './Form'

import { Creators as FAQActions } from 'store/ducks/faq'

import { checkIsActive } from 'utils'

const EditQuestion = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const { question } = useSelector((state) => state.faq)

  const [isActive, setIsActive] = useState(false)

  const handleActive = useCallback((value) => setIsActive(value), [])

  const handleUpdateQuestion = useCallback(
    (item) => {
      const body = {
        ...item,
        isActive: checkIsActive(isActive, question && question.isActive)
      }

      dispatch(FAQActions.updateQuestionRequest({ ...body, id }))
    },
    [dispatch, id, isActive, question]
  )

  useEffect(() => {
    dispatch(FAQActions.getQuestionRequest(id))
  }, [dispatch, id])

  return (
    <DefaultPage
      pageTitle="Perguntas"
      pageDescription="Editar pergunta"
      pageSubDescription="Editar pergunta para o FAQ"
    >
      {!isEmpty(question) && (
        <Form
          submitForm={handleUpdateQuestion}
          handleActive={handleActive}
          currentValue={question}
        />
      )}
    </DefaultPage>
  )
}

export default EditQuestion
