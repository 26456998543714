import { put, call } from 'redux-saga/effects'
import { api } from 'services/api'
import { push } from 'connected-react-router'
import { toast } from 'react-toastify'

import { Creators as Actions } from 'store/ducks/actions'

export function* getActions() {
  try {
    const { data } = yield call(api.get, '/actions')
    yield put(Actions.getActionsSuccess(data))
  } catch (err) {
    yield put(Actions.getActionsFailure(err.message))
  }
}

export function* getActionByID(action) {
  try {
    const { data } = yield call(api.get, `/actions/${action.payload}`)
    yield put(Actions.getActionSuccess(data))
  } catch (err) {
    yield put(Actions.getActionFailure(err))
  }
}

export function* createAction(action) {
  try {
    const { data } = yield call(api.post, `/actions`, action.payload)
    yield put(Actions.createActionSuccess(data))
    yield put(push('/acoes'))
    toast.success('Ação criada com sucesso!')
  } catch (err) {
    toast.error('Erro ao criar ação')
    yield put(Actions.createActionFailure(err))
  }
}

export function* updateAction(action) {
  try {
    const { data } = yield call(
      api.put,
      `/actions/${action.payload.id}`,
      action.payload
    )
    yield put(Actions.updateActionSuccess(data))
    yield put(push('/acoes'))
    toast.success('Ação editada com sucesso!')
  } catch (err) {
    toast.error('Erro ao editar ação')
    yield put(Actions.updateActionFailure(err))
  }
}

export function* deleteAction(action) {
  try {
    const { data } = yield call(api.delete, `/actions/${action.payload.id}`)
    yield put(Actions.deleteActionSuccess(data))
    yield put(Actions.getActionsRequest())
    toast.success('Ação removida com sucesso')
  } catch (err) {
    toast.error('Erro ao remover ação')
    yield put(Actions.deleteActionFailure(err))
  }
}
