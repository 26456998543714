import React from 'react'
import { Link } from 'react-router-dom'

import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarContent
} from 'react-pro-sidebar'

const Sidemenu = ({
  collapse,
  toggled,
  handleToggleSidebar,
  handleCollapse,
  menuItems
}) => {
  return (
    <ProSidebar
      className="new-sidebar"
      image={false}
      collapsed={collapse}
      toggled={toggled}
      onToggle={handleToggleSidebar}
      breakPoint="md"
      onMouseEnter={() => handleCollapse(false)}
      onMouseLeave={() => handleCollapse(true)}
      style={{ height: '100vh' }}
    >
      <SidebarHeader>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          {collapse ? (
            <img
              src={require('assets/images/logo/quanta-logo-icon.png')}
              alt="brand-logo"
              width="35px"
              style={{ marginTop: '15px' }}
            />
          ) : (
            <img
              src={require('assets/images/logo/quanta-new.png')}
              alt="brand-logo"
              width="120px"
              style={{ marginTop: '12px' }}
            />
          )}
        </div>
      </SidebarHeader>

      <SidebarContent>
        <Menu iconShape="circle">
          {menuItems.map((item, index) =>
            item.subItems ? (
              <SubMenu title={item.name} icon={item.icon} key={index}>
                {item.subItems.map((subItem, index) => (
                  <MenuItem key={index}>
                    <Link to={subItem.path} />
                    {subItem.name}
                  </MenuItem>
                ))}
              </SubMenu>
            ) : (
              <MenuItem icon={item.icon} key={index}>
                {item.external ? (
                  <a href={item.path} target="_blank" rel="noopener noreferrer">
                    {item.name}
                  </a>
                ) : (
                  <>
                    {item.name}
                    <Link to={item.path} />
                  </>
                )}
              </MenuItem>
            )
          )}
        </Menu>
      </SidebarContent>
    </ProSidebar>
  )
}

export default Sidemenu
