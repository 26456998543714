import React from 'react'

const Fallback = () => (
  <div className="fallback">
    <div className="brand">
      <img
        src={require('assets/images/logo/quanta-bq-light.png')}
        alt="quanta-logo"
      />
    </div>
    <div className="message">
      <h1>Oooooooops!</h1>
      <span>
        Um erro inesperado aconteceu, estamos redirecionando você para a página
        de login
      </span>
    </div>
  </div>
)

export default Fallback
