import React from 'react'
import { Button } from 'reactstrap'

import { CSVLink } from 'react-csv'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'

function GenerateReportButton({
  canDownload,
  selectedColumns,
  reportData,
  reset,
  handleResetAll
}) {
  const getReportName = () => {
    const date = new Date()
    return `Relatório - ${date.toLocaleDateString('pt-BR')}`
  }

  const columns = selectedColumns === undefined ? [] : selectedColumns

  return (
    <div className="generate_button">
      {!canDownload ? (
        <Button color="primary" disabled={columns?.length === 0} type="submit">
          Gerar relatório
        </Button>
      ) : (
        <CSVLink
          download={getReportName()}
          data={reportData}
          style={{
            letterSpacing: '0px',
            display: 'flex',
            gap: '0 0.5rem'
          }}
          className="btn btn-success"
          onClick={() => {
            handleResetAll()
            reset()
          }}
        >
          Baixar relatório
          <FontAwesomeIcon icon={faDownload} style={{ marginTop: '2px' }} />
        </CSVLink>
      )}
    </div>
  )
}

export default GenerateReportButton
