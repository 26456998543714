export const Types = {
  GET_POSITIONS_REQUEST: "positions/GET_POSITIONS_REQUEST",
  GET_POSITIONS_SUCCESS: "positions/GET_POSITIONS_SUCCESS",
  GET_POSITIONS_FAILURE: "positions/GET_POSITIONS_FAILURE",

  GET_POSITION_REQUEST: "positions/GET_POSITION_REQUEST",
  GET_POSITION_SUCCESS: "positions/GET_POSITION_SUCCESS",
  GET_POSITION_FAILURE: "positions/GET_POSITION_FAILURE",

  CREATE_POSITION_REQUEST: "positions/CREATE_POSITION_REQUEST",
  CREATE_POSITION_SUCCESS: "positions/CREATE_POSITION_SUCCESS",
  CREATE_POSITION_FAILURE: "positions/CREATE_POSITION_FAILURE",

  UPDATE_POSITION_REQUEST: "positions/UPDATE_POSITION_REQUEST",
  UPDATE_POSITION_SUCCESS: "positions/UPDATE_POSITION_SUCCESS",
  UPDATE_POSITION_FAILURE: "positions/UPDATE_POSITION_FAILURE",

  DELETE_POSITION_REQUEST: "positions/DELETE_POSITION_REQUEST",
  DELETE_POSITION_SUCCESS: "positions/DELETE_POSITION_SUCCESS",
  DELETE_POSITION_FAILURE: "positions/DELETE_POSITION_FAILURE",
};

const INITIAL_STATE = {
  list: [],
  position: {},
  loading: false,
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_POSITIONS_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.GET_POSITIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
        error: null,
      };

    case Types.GET_POSITIONS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.GET_POSITION_REQUEST:
      return { ...state, loading: true, position: {}, error: null };

    case Types.GET_POSITION_SUCCESS:
      return {
        ...state,
        loading: false,
        position: action.payload,
        error: null,
      };

    case Types.GET_POSITION_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.CREATE_POSITION_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.CREATE_POSITION_SUCCESS:
      return { ...state, loading: false, error: null };

    case Types.CREATE_POSITION_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.UPDATE_POSITION_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.UPDATE_POSITION_SUCCESS:
      return { ...state, loading: false, level: {}, error: null };

    case Types.UPDATE_POSITION_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.DELETE_POSITION_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.DELETE_POSITION_SUCCESS:
      return { ...state, loading: false, error: null };

    case Types.DELETE_POSITION_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export const Creators = {
  getPositionsRequest: (payload) => ({
    type: Types.GET_POSITIONS_REQUEST,
    payload,
  }),

  getPositionsSuccess: (payload) => ({
    type: Types.GET_POSITIONS_SUCCESS,
    payload,
  }),

  getPositionsFailure: (payload) => ({
    type: Types.GET_POSITIONS_FAILURE,
    payload,
  }),

  getPositionRequest: (payload) => ({
    type: Types.GET_POSITION_REQUEST,
    payload,
  }),

  getPositionSuccess: (payload) => ({
    type: Types.GET_POSITION_SUCCESS,
    payload,
  }),

  getPositionFailure: (payload) => ({
    type: Types.GET_POSITION_FAILURE,
    payload,
  }),

  createPositionRequest: (payload) => ({
    type: Types.CREATE_POSITION_REQUEST,
    payload,
  }),

  createPositionSuccess: (payload) => ({
    type: Types.CREATE_POSITION_SUCCESS,
    payload,
  }),

  createPositionFailure: (payload) => ({
    type: Types.CREATE_POSITION_FAILURE,
    payload,
  }),

  updatePositionRequest: (payload) => ({
    type: Types.UPDATE_POSITION_REQUEST,
    payload,
  }),

  updatePositionSuccess: (payload) => ({
    type: Types.UPDATE_POSITION_SUCCESS,
    payload,
  }),

  updatePositionFailure: (payload) => ({
    type: Types.UPDATE_POSITION_FAILURE,
    payload,
  }),

  deletePositionRequest: (payload) => ({
    type: Types.DELETE_POSITION_REQUEST,
    payload,
  }),

  deletePositionSuccess: (payload) => ({
    type: Types.DELETE_POSITION_SUCCESS,
    payload,
  }),

  deletePositionFailure: (payload) => ({
    type: Types.DELETE_POSITION_FAILURE,
    payload,
  }),
};
