import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";

import { Form, Label, Input, Col, Button, Row } from "reactstrap";

import Toggle from "react-toggle";
import "react-toggle/style.css";

const EditServiceForm = ({ submitForm, currentValue }) => {
  const [isActiveText, setIsActiveText] = useState(currentValue.isActive);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: currentValue.name,
      isActive: currentValue.isActive,
    },
  });

  const { ref: nameRef, ...name } = register("name", { required: true });

  const handleToggle = useCallback(
    (value) => {
      setIsActiveText(value);
      setValue("isActive", value);
    },
    [setValue]
  );

  return (
    <Form className="needs-validation" onSubmit={handleSubmit(submitForm)}>
      <div className="form-row">
        <Col md="2 mb-3">
          <Label htmlFor="validationCustom01">Nome</Label>
          <Input
            className="form-control"
            name="name"
            type="text"
            placeholder="Nome"
            innerRef={nameRef}
            {...name}
            defaultValue={currentValue.name}
          />
          <span>{errors.name && "Nome é obrigatório"}</span>
        </Col>
        <Col md="2 mb-3" style={{ marginLeft: "20px" }}>
          <Label htmlFor="validationCustom02">Status</Label>
          <Row
            style={{
              marginLeft: "0px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Toggle
              id={currentValue.id}
              defaultChecked={currentValue.isActive}
              name="isActive"
              {...register("isActive")}
              onChange={(e) => handleToggle(e.target.checked)}
            />
            <span style={{ marginLeft: "15px" }}>
              {isActiveText ? "Ativo" : "Inativo"}
            </span>
            <span>{errors.isActive && "Status é obrigatório"}</span>
          </Row>
        </Col>
      </div>

      <Button color="primary">Enviar</Button>
    </Form>
  );
};

export default EditServiceForm;
