import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from 'reactstrap'

import Layout from 'components/Layout'
import Breadcrumbs from 'components/Layout/breadcrumb'

import Values from 'components/Financial/Values'
import Actions from 'components/Financial/Actions'
import Summary from 'components/Financial/Summary'
import Movements from 'components/Financial/Movements'
import History from 'components/Financial/History'

import { Creators as WalletActions } from 'store/ducks/wallet'
import { Creators as BankAccountActions } from 'store/ducks/bankAccounts'
import { Creators as MoneyActions } from 'store/ducks/money'

const Financial = () => {
  const percent = 0.10;

  const dispatch = useDispatch()
  const {
    auth: { auth },
    wallet: { wallet },
    bankAccounts: { list },
    money: { moneyByUser, moneyUserByTypeSummary }
  } = useSelector((state) => state)

  useEffect(() => {
    dispatch(WalletActions.getWalletByUserRequest(auth.id))
  }, [auth.id, dispatch])

  useEffect(() => {
    dispatch(BankAccountActions.getBankAccountRequest(auth.id))
  }, [auth.id, dispatch])

  useEffect(() => {
    dispatch(MoneyActions.getMoneyByUserRequest(`${auth.id}?page=0&limit=5`))
  }, [auth.id, dispatch])

  useEffect(() => {
    dispatch(MoneyActions.getMoneyUserByTypeSummaryRequest(auth.id))
  }, [auth.id, dispatch])


  const dataToValues = {
    balance: wallet?.amount,
    blockedBalance: (wallet?.amount - wallet?.retainedAmount < 0 ? 0.0 : wallet?.amount - wallet?.retainedAmount),

    // withdraw: moneyByUser?.cashbackStats?.totalWithdrawMoneyValue,
    // cashback: moneyByUser?.cashbackStats?.totalCashbackValue,
    // outs: moneyByUser?.cashbackStats?.outs,
    // entrances: moneyByUser?.cashbackStats?.entrances,
    withdraw: moneyByUser?.cashbackStats?.outs,
    cashback: moneyByUser?.cashbackStats?.entrances,

    cashbackPending: moneyByUser?.cashbackStats?.totalPendingCashbackValue
  }

  const dataToActions = {
    bankAccounts: list
  }

  const summaryData = useMemo(() => {
    const formatted = [
      ['Movements', 'type'],
      ['Bônus Cashback', parseFloat(moneyUserByTypeSummary.totalTypeCashback)],
      [
        'Bônus Cashback Residual',
        parseFloat(moneyUserByTypeSummary.totalTypeCashbackResidual)
      ],
      [
        'Bônus Habilitação',
        parseFloat(moneyUserByTypeSummary.totalTypeBonusAccession)
      ],
      [
        'Bônus Habilitação Residual',
        parseFloat(moneyUserByTypeSummary.totalTypeBonusAccessionResidual)
      ],
      [
        'Bônus Empresas',
        parseFloat(moneyUserByTypeSummary.totalTypeBonusAccredited)
      ]
    ]

    return formatted
  }, [
    moneyUserByTypeSummary.totalTypeBonusAccession,
    moneyUserByTypeSummary.totalTypeBonusAccessionResidual,
    moneyUserByTypeSummary.totalTypeBonusAccredited,
    moneyUserByTypeSummary.totalTypeCashback,
    moneyUserByTypeSummary.totalTypeCashbackResidual
  ])

  const movementsData = useMemo(
    () => moneyUserByTypeSummary,
    [moneyUserByTypeSummary]
  )

  return (
    <Layout>
      <Breadcrumbs title="Financeiro" />
      <Row className="financial">
        <Col lg="9">
          <Row>
            <Col>
              <Values data={dataToValues} />
            </Col>
          </Row>
          <Row>
            <Col lg="8">
              <Movements data={movementsData} />
            </Col>
            <Col lg="4">
              <Summary data={summaryData} />
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <History list={moneyByUser.list} />
            </Col>
          </Row>
        </Col>
        <Col lg="3">
          <Actions data={dataToActions} dataValues={dataToValues} />
        </Col>
      </Row>
    </Layout>
  )
}

export default Financial
