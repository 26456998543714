export const Types = {
  GET_SERVICES_REQUEST: "services/GET_SERVICES_REQUEST",
  GET_SERVICES_SUCCESS: "services/GET_SERVICES_SUCCESS",
  GET_SERVICES_FAILURE: "services/GET_SERVICES_FAILURE",

  GET_SERVICE_REQUEST: "services/GET_SERVICE_REQUEST",
  GET_SERVICE_SUCCESS: "services/GET_SERVICE_SUCCESS",
  GET_SERVICE_FAILURE: "services/GET_SERVICE_FAILURE",

  CREATE_SERVICE_REQUEST: "services/CREATE_SERVICE_REQUEST",
  CREATE_SERVICE_SUCCESS: "services/CREATE_SERVICE_SUCCESS",
  CREATE_SERVICE_FAILURE: "services/CREATE_SERVICE_FAILURE",

  UPDATE_SERVICE_REQUEST: "services/UPDATE_SERVICE_REQUEST",
  UPDATE_SERVICE_SUCCESS: "services/UPDATE_SERVICE_SUCCESS",
  UPDATE_SERVICE_FAILURE: "services/UPDATE_SERVICE_FAILURE",

  DELETE_SERVICE_REQUEST: "services/DELETE_SERVICE_REQUEST",
  DELETE_SERVICE_SUCCESS: "services/DELETE_SERVICE_SUCCESS",
  DELETE_SERVICE_FAILURE: "services/DELETE_SERVICE_FAILURE",
};

const INITIAL_STATE = {
  list: [],
  service: {},
  loading: false,
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_SERVICES_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.GET_SERVICES_SUCCESS:
      return { ...state, loading: false, list: action.payload, error: null };

    case Types.GET_SERVICES_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.GET_SERVICE_REQUEST:
      return { ...state, loading: true, service: {}, error: null };

    case Types.GET_SERVICE_SUCCESS:
      return { ...state, loading: false, service: action.payload, error: null };

    case Types.GET_SERVICE_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.CREATE_SERVICE_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.CREATE_SERVICE_SUCCESS:
      return { ...state, loading: false, error: null };

    case Types.CREATE_SERVICE_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.UPDATE_SERVICE_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.UPDATE_SERVICE_SUCCESS:
      return { ...state, loading: false, service: {}, error: null };

    case Types.UPDATE_SERVICE_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.DELETE_SERVICE_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.DELETE_SERVICE_SUCCESS:
      return { ...state, loading: false, error: null };

    case Types.DELETE_SERVICE_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export const Creators = {
  getServicesRequest: (payload) => ({
    type: Types.GET_SERVICES_REQUEST,
    payload,
  }),

  getServicesSuccess: (payload) => ({
    type: Types.GET_SERVICES_SUCCESS,
    payload,
  }),

  getServicesFailure: (payload) => ({
    type: Types.GET_SERVICES_FAILURE,
    payload,
  }),

  getServiceRequest: (payload) => ({
    type: Types.GET_SERVICE_REQUEST,
    payload,
  }),

  getServiceSuccess: (payload) => ({
    type: Types.GET_SERVICE_SUCCESS,
    payload,
  }),

  getServiceFailure: (payload) => ({
    type: Types.GET_SERVICE_FAILURE,
    payload,
  }),

  createServiceRequest: (payload) => ({
    type: Types.CREATE_SERVICE_REQUEST,
    payload,
  }),

  createServiceSuccess: (payload) => ({
    type: Types.CREATE_SERVICE_SUCCESS,
    payload,
  }),

  createServiceFailure: (payload) => ({
    type: Types.CREATE_SERVICE_FAILURE,
    payload,
  }),

  updateServiceRequest: (payload) => ({
    type: Types.UPDATE_SERVICE_REQUEST,
    payload,
  }),

  updateServiceSuccess: (payload) => ({
    type: Types.UPDATE_SERVICE_SUCCESS,
    payload,
  }),

  updateServiceFailure: (payload) => ({
    type: Types.UPDATE_SERVICE_FAILURE,
    payload,
  }),

  deleteServiceRequest: (payload) => ({
    type: Types.DELETE_SERVICE_REQUEST,
    payload,
  }),

  deleteServiceSuccess: (payload) => ({
    type: Types.DELETE_SERVICE_SUCCESS,
    payload,
  }),

  deleteServiceFailure: (payload) => ({
    type: Types.DELETE_SERVICE_FAILURE,
    payload,
  }),
};
