import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import DefaultPage from 'components/DefaultPage'
import { v4 as uuidv4 } from 'uuid'
import { Creators as MoneyActions } from 'store/ducks/money'
import CreateSalesForm from 'components/Sales/CreateSalesForm'
import { Creators as UserActions } from 'store/ducks/users'

const CreateSales = () => {
  const dispatch = useDispatch()
  const {
    auth: { auth }
  } = useSelector((state) => state)
  const { usersByName, loading } = useSelector((state) => state.users)

  const handleUserList = useCallback(
    (name) => {
      !!name && dispatch(UserActions.getUsersByNameRequest(name))
    },
    [dispatch]
  )

  const handleCreateSales = useCallback(
    (value) => {
      dispatch(
        MoneyActions.createMoneyRequest({
          ...value,
          orderId: uuidv4(),
          user: value.user[0],
          accredited:
          {
            id: auth.id
          }
          ,
          status: 'pending',
          type: 'sales_cashback_accredited',
          successMessage: 'Venda enviada com sucesso!',
          errorMessage: 'Erro ao enviar soliticação',
          redirectPath: '/sales/create'
        })
      )
    },
    [auth.id, dispatch]
  )

  return (
    <DefaultPage
      pageTitle="Vendas"
      pageDescription="Registrar Venda"
      pageSubDescription="Registrar uma nova venda"
    >
      <CreateSalesForm
        submitForm={handleCreateSales}
        sponsorsList={usersByName?.data}
        sponsorsLoading={loading}
        handleUserList={handleUserList}
      />
    </DefaultPage>
  )
}

export default CreateSales
