import React, { useEffect, useState } from "react";
import { Form, Label, Input, Col, Button } from "reactstrap";
import { useForm } from "react-hook-form";
import { debounce } from "lodash";

import Select from "react-select";

const CreateSalesForm = ({
  submitForm,
  sponsorsList,
  handleUserList,
  sponsorsLoading,
}) => {

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const [optionsSelectSponsor, setOptionsSelectSponsor] = useState([]);

  const { ref: descriptionRef, ...description } = register("description", { required: true });
  const { ref: amountRef, ...amount } = register("amount", { required: true });
  const { ref: cashbackRef, ...cashback } = register("cashback", { required: true });

  useEffect(() => {
    let options = [];
    for (let i in sponsorsList) {
      options.push({
        value: sponsorsList[i].id,
        label: sponsorsList[i].name,
      });
    }
    setOptionsSelectSponsor(options);
  }, [sponsorsList]);

  const loadSuggestions = debounce(handleUserList, 500);
  return (
    <Form
      className="needs-validation"
      noValidate=""
      onSubmit={handleSubmit(submitForm)}
    >
      <div className="form-row">
        <Col md="3 mb-3">
          <Label htmlFor="validationCustom01">Descrição</Label>
          <Input
            className="form-control"
            name="description"
            type="text"
            placeholder="Descrição"
            innerRef={descriptionRef}
            {...description}
          />
          <span>{errors.description && "Descrição é obrigatória"}</span>
        </Col>
        <Col md="3 mb-3">
          <Label htmlFor="validationCustom02">Valor</Label>
          <Input
            className="form-control"
            name="amount"
            type="number"
            placeholder="Valor"
            innerRef={amountRef}
            min="0.00"
            step="0.001"
            max="999999999999.00"
            presicion={2}   //very important
            onChange={() => {
              console.log('page rate changed');
            }}
            {...amount}
          />

          <span>{errors.amount && "Valor é obrigatório"}</span>
        </Col>
        <Col md="3 mb-3">
          <Label htmlFor="validationCustom01">Cliente</Label>
          <Select
            options={optionsSelectSponsor}
            onChange={(e) => {
              !!e && setValue("user", [{ id: e.value }]);
            }}
            onInputChange={(e) => loadSuggestions(e)}
            onMenuOpen={() => setOptionsSelectSponsor([])}
            isLoading={sponsorsLoading}
            isClearable
          />
          <span>{errors.description && "Cliente é obrigatório"}</span>
        </Col>
        <Col md="3 mb-3">
          <Label htmlFor="validationCustom01">%Cashback</Label>
          <Input
            className="form-control"
            name="description"
            type="text"
            placeholder="Descrição"
            innerRef={cashbackRef}
            {...cashback}
          />
          <span>{errors.description && "Descrição é obrigatória"}</span>
        </Col>
      </div>
      <div className="form-row row-gap">
        <Button color="primary">Enviar</Button>
      </div>
    </Form>
  );
};

export default CreateSalesForm;
