import React, { memo } from 'react'
import { Form, Input, Col, Button, Row, Label } from 'reactstrap'
import InputMask from 'react-input-mask'
import { useForm } from 'react-hook-form'

const Personal = ({
  data,
  currentValue,
  personalButtonText,
  canEditPersonal,
  toggleAccredited,
  handleUpdateUser,
  handleButtonText,
  handleCanEdit,
  handleToggleAccredited
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      email: currentValue.email,
      name: currentValue.name,
      phone: currentValue.phone ? currentValue.phone : ' ',
      whatsapp: currentValue.whatsapp ? currentValue.whatsapp : ' ',
      cnpj: currentValue.cnpj,
      beAccredited: currentValue?.beAccredited,
      establishment: currentValue?.establishment,
      zip: currentValue?.zip,
      city: currentValue?.city,
      state: currentValue?.state,
      street: currentValue?.street,
      number: currentValue?.numbers
    }
  })

  const { ref: emailRef, ...email } = register('email')
  const { ref: phoneRef, ...phone } = register('phone')
  const { ref: whatsappRef, ...whatsapp } = register('whatsapp')
  const { ref: passwordRef, ...password } = register('password')
  const { ref: cnpjRef, ...cnpj } = register('cnpj', {
    validate: {
      required: (value) => {
        if (!value && toggleAccredited) return 'CNPJ da empresa é obrigatório'
        return true
      }
    }
  })

  const { ref: establishmentRef, ...establishment } = register(
    'establishment',
    {
      validate: {
        required: (value) => {
          if (!value && toggleAccredited) return 'Estabelecimento é obrigatório'
          return true
        }
      }
    }
  )
  const { ref: zipRef, ...zip } = register('zip', {
    validate: {
      required: (value) => {
        if (!value && toggleAccredited) return 'CEP da empresa é obrigatório'
        return true
      }
    }
  })
  const { ref: cityRef, ...city } = register('city', {
    validate: {
      required: (value) => {
        if (!value && toggleAccredited) return 'Cidade da empresa é obrigatório'
        return true
      }
    }
  })
  const { ref: stateRef, ...state } = register('state', {
    validate: {
      required: (value) => {
        if (!value && toggleAccredited) return 'Estado da empresa é obrigatório'
        return true
      }
    }
  })
  const { ref: streetRef, ...street } = register('street', {
    validate: {
      required: (value) => {
        if (!value && toggleAccredited)
          return 'Endereço da empresa é obrigatório'
        return true
      }
    }
  })
  const { ref: numberRef, ...number } = register('number', {
    validate: {
      required: (value) => {
        if (!value && toggleAccredited) return 'Número da empresa é obrigatório'
        return true
      }
    }
  })

  return (
    <Form
      className="needs-validation"
      onSubmit={
        canEditPersonal
          ? handleSubmit(handleUpdateUser)
          : (e) => {
              e.preventDefault()
              handleButtonText('Salvar')
              handleCanEdit(true)
            }
      }
    >
      <div className="items">
        <Row>
          <Col>
            <div className="item">
              <span>Nome:</span>
              <Input type="text" defaultValue={data.name} disabled />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="item">
              <span>E-mail:</span>
              <Input
                type="text"
                defaultValue={data.email}
                disabled={!canEditPersonal}
                innerRef={emailRef}
                {...email}
              />
            </div>
          </Col>
        </Row>
        <Row className="document">
          <Col sm="12" md="4">
            <div className="item">
              <span>CPF:</span>
              <Input
                type="text"
                defaultValue={data.documentNumber}
                disabled
                tag={InputMask}
                mask="999.999.999-99"
              />
            </div>
          </Col>
          <Col>
            <div className="item">
              <span>Telefone:</span>
              <Input
                type="text"
                defaultValue={data.phone}
                disabled={!canEditPersonal}
                innerRef={phoneRef}
                {...phone}
              />
            </div>
          </Col>
          <Col>
            <div className="item">
              <span>Whatsapp:</span>
              <Input
                type="text"
                defaultValue={data.whatsapp}
                disabled={!canEditPersonal}
                innerRef={whatsappRef}
                {...whatsapp}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <div className="item">
              <span>Alterar senha:</span>
              <Input
                type="password"
                placeholder="●●●●●●●"
                disabled={!canEditPersonal}
                innerRef={passwordRef}
                name="password"
                {...password}
              />
            </div>
          </Col>

          <Col>
            <div
              className="item"
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem 0',
                height: ' 58px',
                paddingTop: '28px'
              }}
            >
              <Label className="d-block" for="beAccredited">
                {!currentValue.beAccredited && (
                  <input
                    className="checkbox_animated"
                    type="checkbox"
                    onChange={(e) => handleToggleAccredited(e.target.checked)}
                  />
                )}
                <span>
                  {!currentValue.beAccredited
                    ? 'Me tornar credenciado'
                    : 'Já credenciado.'}
                </span>
              </Label>
            </div>
          </Col>
        </Row>
        {toggleAccredited && (
          <Row className="beAccredited">
            <Col sm="12" md="6">
              <div className="item">
                <span>CNPJ:</span>
                <Input
                  type="text"
                  disabled={!canEditPersonal}
                  innerRef={cnpjRef}
                  name="cnpj"
                  defaultValue={currentValue.cnpj}
                  placeholder="Digite aqui o CNPJ da empresa"
                  {...cnpj}
                  tag={InputMask}
                  mask="99.999.999/9999-99"
                />
                <small style={{ color: 'red' }}>
                  {errors.cnpj && errors.cnpj.message}
                </small>
              </div>
            </Col>
            <Col sm="12" md="6">
              <div className="item">
                <span>Nome do Estabelecimento:</span>
                <Input
                  type="text"
                  disabled={!canEditPersonal}
                  innerRef={establishmentRef}
                  name="establishment"
                  defaultValue={currentValue.establishment}
                  placeholder="Digite aqui o nome da empresa"
                  {...establishment}
                />
                <small style={{ color: 'red' }}>
                  {errors.establishment && errors.establishment.message}
                </small>
              </div>
            </Col>
            <Col sm="12" md="6">
              <div className="item">
                <span>CEP:</span>
                <Input
                  type="text"
                  disabled={!canEditPersonal}
                  innerRef={zipRef}
                  name="zip"
                  defaultValue={currentValue.zip}
                  placeholder="Digite aqui o cep"
                  {...zip}
                />
                <small style={{ color: 'red' }}>
                  {errors.zip && errors.zip.message}
                </small>
              </div>
            </Col>
            <Col sm="12" md="6">
              <div className="item">
                <span>Cidade:</span>
                <Input
                  type="text"
                  disabled={!canEditPersonal}
                  innerRef={cityRef}
                  name="city"
                  defaultValue={currentValue.city}
                  placeholder="Digite aqui a cidade"
                  {...city}
                />
                <small style={{ color: 'red' }}>
                  {errors.city && errors.city.message}
                </small>
              </div>
            </Col>
            <Col sm="12" md="6">
              <div className="item">
                <span>Estado:</span>
                <Input
                  type="text"
                  disabled={!canEditPersonal}
                  innerRef={stateRef}
                  name="state"
                  defaultValue={currentValue.state}
                  placeholder="Digite aqui o estado"
                  {...state}
                />
                <small style={{ color: 'red' }}>
                  {errors.state && errors.state.message}
                </small>
              </div>
            </Col>
            <Col sm="12" md="6">
              <div className="item">
                <span>Rua:</span>
                <Input
                  type="text"
                  disabled={!canEditPersonal}
                  innerRef={streetRef}
                  name="street"
                  defaultValue={currentValue.street}
                  placeholder="Digite aqui a rua"
                  {...street}
                />
                <small style={{ color: 'red' }}>
                  {errors.street && errors.street.message}
                </small>
              </div>
            </Col>
            <Col sm="12" md="6">
              <div className="item">
                <span>Número:</span>
                <Input
                  type="text"
                  disabled={!canEditPersonal}
                  innerRef={numberRef}
                  name="number"
                  defaultValue={currentValue.number}
                  placeholder="Digite aqui o número"
                  {...number}
                />
                <small style={{ color: 'red' }}>
                  {errors.number && errors.number.message}
                </small>
              </div>
            </Col>
          </Row>
        )}
      </div>
      <div className="actions">
        <Button color="primary">{personalButtonText}</Button>
      </div>
    </Form>
  )
}
export default memo(Personal)
