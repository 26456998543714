import React, { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import InputMask from 'react-input-mask'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button
} from 'reactstrap'

import {
  SignIn,
  CreateAccount,
  PrivacyPolicy,
  Patrocinador,
  login,
  Credenciado
} from '../../constant'

import { Creators as AuthActions } from 'store/ducks/auth'
import { Creators as UserActions } from 'store/ducks/users'
import { useEffect } from 'react'

const Register = () => {
  const dispatch = useDispatch()
  const { userByEmail } = useSelector((state) => state.users)

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
    watch
  } = useForm()
  const [togglePassword, setTogglePassword] = useState(false)
  const [toggleRepeatPassword, setToggleRepeatPassword] = useState(false)
  const [toggleAccredited, setToggleAccredited] = useState(false)
  const [terms, setTerms] = useState({
    privacy: false,
    partnership: false
  })

  const { ref: emailRef, ...email } = register('email', { required: true })
  const { ref: nameRef, ...name } = register('name', { required: true })
  const { ref: passwordRef, ...password } = register('password', {
    required: true
  })
  const { ref: sponsorRef, ...sponsor } = register('sponsor', {
    required: 'Patrocinador é obrigatório'
  })
  const { ref: cnpjRef, ...cnpj } = register('cnpj', {
    validate: {
      required: (value) => {
        if (!value && toggleAccredited) return 'CNPJ da empresa é obrigatório'
        return true
      }
    }
  })
  const { ref: companyAddressRef, ...companyAddress } = register(
    'companyAddress',
    {
      validate: {
        required: (value) => {
          if (!value && toggleAccredited)
            return 'Endereço da empresa é obrigatório'
          return true
        }
      }
    }
  )

  const handleRegisterUser = useCallback(
    (item) => {
      dispatch(AuthActions.registerUserRequest(item))
    },
    [dispatch]
  )

  const formErrorStyle = { color: 'red' }

  const typedPassword = watch('password')
  const typedSponsor = watch('sponsor')

  const [searched, setSearched] = useState()

  useEffect(() => {
    if (typedSponsor) {
      dispatch(UserActions.getUserByEmailRequest(typedSponsor))
      setSearched(true)
    } else {
      clearErrors('sponsor')
      setSearched(false)
    }
  }, [clearErrors, dispatch, typedSponsor])

  useEffect(() => {
    if (Object.keys(userByEmail).length === 0 && searched) {
      setError('sponsor', { message: 'Patrocinador não encontrado' })
    } else {
      clearErrors('sponsor')
    }
  }, [clearErrors, searched, setError, userByEmail])

  return (
    <Container fluid={true} className="p-0">
      <Row>
        <Col xs="12">
          <div className="login-card">
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '0 0 25px 0'
                }}
              >
                <img
                  className="img-fluid for-light"
                  src={require('../../assets/images/logo/quanta-default.png')}
                  alt="quanta-logo"
                  style={{ width: '180px' }}
                />
                <img
                  className="img-fluid for-dark"
                  src={require('../../assets/images/logo/quanta-dark.png')}
                  alt="quanta-logo-white"
                  style={{ width: '230px' }}
                />
              </div>
              <div className="login-main">
                <Form onSubmit={handleSubmit(handleRegisterUser)}>
                  <h4>{'Crie sua conta!'}</h4>
                  <p>{'Preencha os campos com os dados.'}</p>
                  <div className="checkbox ml-3">
                    <Input
                      id="checkbox2"
                      type="checkbox"
                      onChange={(e) => setToggleAccredited(e.target.checked)}
                    />
                    <Label className="text-muted" for="checkbox2">
                      Gostaria de ser um {Credenciado}
                    </Label>
                  </div>
                  {toggleAccredited && (
                    <>
                      <FormGroup>
                        <Label className="col-form-label">CNPJ</Label>
                        <Input
                          className="form-control"
                          type="text"
                          name="CNPJ"
                          placeholder="CNPJ"
                          innerRef={cnpjRef}
                          {...cnpj}
                          tag={InputMask}
                          mask="99.999.999/9999-99"
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label className="col-form-label">
                          Endereço da empresa
                        </Label>
                        <Input
                          className="form-control"
                          type="text"
                          name="companyAddress"
                          placeholder="Endereço da empresa"
                          innerRef={companyAddressRef}
                          {...companyAddress}
                        />
                      </FormGroup>
                    </>
                  )}
                  <FormGroup>
                    <Label className="col-form-label">{Patrocinador}</Label>
                    <Input
                      className="form-control"
                      type="text"
                      name="sponsor"
                      placeholder="Patrocinador"
                      innerRef={sponsorRef}
                      {...sponsor}
                    />
                    <small style={formErrorStyle}>
                      {errors.sponsor && errors.sponsor.message}
                    </small>
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">{login}</Label>
                    <Input
                      className="form-control"
                      name="email"
                      type="text"
                      placeholder="E-mail"
                      innerRef={emailRef}
                      {...email}
                    />
                    <small style={formErrorStyle}>
                      {errors.email && 'E-mail é obrigatório'}
                    </small>
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">Nome</Label>
                    <Input
                      className="form-control"
                      name="name"
                      type="text"
                      placeholder="Nome"
                      innerRef={nameRef}
                      {...name}
                    />
                    <small style={formErrorStyle}>
                      {errors.name && 'Nome é obrigatório'}
                    </small>
                  </FormGroup>
                  <FormGroup style={{ position: 'relative' }}>
                    <Label className="col-form-label">Senha</Label>
                    <Input
                      className="form-control"
                      type={togglePassword ? 'text' : 'password'}
                      name="password"
                      innerRef={passwordRef}
                      {...password}
                      required=""
                      placeholder="*********"
                    />
                    <div
                      style={{
                        position: 'absolute',
                        top: '42px',
                        right: '10px',
                        cursor: 'pointer'
                      }}
                      onClick={() => setTogglePassword(!togglePassword)}
                    >
                      <small>Mostrar</small>
                    </div>
                    <small style={formErrorStyle}>
                      {errors.password && 'Senha é obrigatória'}
                    </small>
                  </FormGroup>
                  <FormGroup style={{ position: 'relative' }}>
                    <Label className="col-form-label">Repetir a senha</Label>
                    <Input
                      className="form-control"
                      type={toggleRepeatPassword ? 'text' : 'password'}
                      name="login[password]"
                      placeholder="*********"
                      onChange={(e) =>
                        e.target.value !== typedPassword
                          ? setError('repeatPass')
                          : clearErrors('repeatPass')
                      }
                    />
                    <div
                      style={{
                        position: 'absolute',
                        top: '42px',
                        right: '10px',
                        cursor: 'pointer'
                      }}
                      onClick={() =>
                        setToggleRepeatPassword(!toggleRepeatPassword)
                      }
                    >
                      <small>Mostrar</small>
                    </div>
                    <small style={formErrorStyle}>
                      {errors.repeatPass && ' As senhas não coincidem'}
                    </small>
                  </FormGroup>
                  <div className="form-group mb-0">
                    <div className="checkbox ml-3">
                      <Input
                        id="privacy"
                        type="checkbox"
                        onChange={(e) =>
                          setTerms({ ...terms, privacy: e.target.checked })
                        }
                      />
                      <Label className="text-muted" for="privacy">
                        {'Concorda com'}
                        <a
                          className="ml-2"
                          href="/QB-TERMOS_DE_USO_E_BONIFICACAO_ESPECiFICA_v072022.pdf"
                          target="_blank"
                        >
                          {PrivacyPolicy}
                        </a>
                      </Label>
                    </div>
                    <div className="checkbox ml-3">
                      <Input
                        id="partnership"
                        type="checkbox"
                        onChange={(e) =>
                          setTerms({ ...terms, partnership: e.target.checked })
                        }
                      />
                      <Label className="text-muted" for="partnership">
                        {'Concorda com'}
                        <a
                          className="ml-2"
                          href="/QB_CONTRATO_DE_PARCERIA_PARA_MARKETING_DE_FIDELIZACAO_COM_CASHBACK_v2022.pdf"
                          target="_blank"
                        >
                          Contrato de Parceria para Marketing de Fidelização com
                          Cashback
                        </a>
                      </Label>
                    </div>
                    <Button
                      color="primary"
                      className="btn-block"
                      type="submit"
                      disabled={
                        !Object.values(terms).every((value) => value === true)
                      }
                    >
                      {CreateAccount}
                    </Button>
                  </div>
                  <p className="mt-4 mb-0">
                    Já tem uma conta?{' '}
                    <Link className="ml-2" to="/login">
                      {SignIn}
                    </Link>
                  </p>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Register
