
import Support from "pages/Support";
import CreateSupport from "pages/Support/Create";
import EditSupport from "pages/Support/Edit";

import { BASE_URL } from "shared/constants/config";

export default [
  { path: `${BASE_URL}/suporte`, component: Support },
  { path: `${BASE_URL}/suporte/criar`, component: CreateSupport },
  { path: `${BASE_URL}/suporte/editar/:id`, component: EditSupport },
];
