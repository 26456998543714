import React from 'react'
import Accordion from 'components/Accordion'
import Layout from 'components/Layout'

const ContentPage = ({ pageTitle, items, hasAccordion, children }) => {
  return (
    <Layout>
      <div className="content-main">
        <div className="header-main">
          <h5>{pageTitle}</h5>
        </div>
        {hasAccordion ? <Accordion items={items} /> : children}
      </div>
    </Layout>
  )
}

export default ContentPage
