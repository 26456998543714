import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Row, Col, Card, CardFooter, Media, Button } from 'reactstrap'

import axios from 'axios'

import { Link } from 'react-router-dom'

import Layout from 'components/Layout'
import Breadcrumb from 'components/Layout/breadcrumb'

import { Creators as NetworkActions } from 'store/ducks/network'

import { formatToCurrency } from 'utils'

const Network = () => {
  const dispatch = useDispatch()
  const {
    network: { networkByOwner },
    auth: { auth }
  } = useSelector((state) => state)
  const [cards, setCards] = useState([])

  useEffect(() => {
    axios
      .get(`${process.env.PUBLIC_URL}/api/usercard.json`)
      .then((res) => setCards(res.data))
  }, [])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id')

    dispatch(NetworkActions.getNetworkByOwnerRequest(id))


  }, [dispatch, auth.id])

  return (
    <Layout>
      <Breadcrumb title="Rede" />
      <Container fluid={true}>
        <Row>
          {networkByOwner.users?.length > 0 ? (
            networkByOwner.users.map((item, i) => (
              <Col md="3" lg="3" xl="3" className="box-col-6" key={i}>
                <Card className="custom-card">
                  <div className="card-profile">
                    <Media
                      body
                      className="rounded-circle"
                      src={
                        item.icon && item.icon.indexOf("https://onneroyal.blob.core.windows.net/imagens/user-default.svg") === -1
                          ? item.icon
                          : require('assets/images/user/user.png')
                      }
                      alt=""
                    />
                  </div>
                  <ul className="card-social">
                    {item.isActive ? (
                      <div style={{ padding: '20px 0' }}>
                        <i className="fa fa-circle font-success f-12" />{' '}
                        <small style={{ marginLeft: '5px' }}>Ativo</small>
                      </div>
                    ) : (
                      <div style={{ padding: '10px 0' }}>
                        <i className="fa fa-circle font-danger f-12" />
                        <small style={{ marginLeft: '5px' }}> Inativo</small>
                      </div>
                    )}
                  </ul>
                  <div className="text-center profile-details">
                    <h4>{item.name}</h4>
                    <h6>
                      {item.graduation?.position?.name
                        ? item.graduation?.position?.name
                        : 'Usuário não graduado'}
                    </h6>
                  </div>
                  <CardFooter className="row">
                    <Col sm="12 col-4">
                      <h6>Pontuação</h6>
                      <span className="counter">
                        {item.graduation?.score ? item.graduation?.score : 0}
                      </span>
                    </Col>
                    <Col sm="12 col-4">
                      <h6>Cashback Gerado</h6>
                      <small>
                        <span className="counter">
                          {formatToCurrency(item.generatedCashbackAmount)}
                        </span>
                      </small>
                    </Col>
                    <Col
                      sm="12 col-4"
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <span>{item.email}</span>
                      <span>{item.phone}</span>
                    </Col>
                    <Col
                      sm="12 col-4"
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >

                      <a href={'/rede/downline?id=' + item.id}>
                        <Button className="btn btn-primary">Downline</Button>
                      </a>
                    </Col>
                  </CardFooter>
                </Card>
              </Col>
            ))
          ) : (
            <span style={{ marginLeft: '15px' }}>
              Você não possui usuários na rede.
            </span>
          )}
        </Row>
      </Container>
    </Layout>
  )
}

export default Network
