import { put, call } from 'redux-saga/effects'
import { api } from 'services/api'
import { push } from 'connected-react-router'
import { toast } from 'react-toastify'

import { Creators as PlansActions } from 'store/ducks/plans'

export function* getPlans() {
  try {
    const { data } = yield call(api.get, '/plans')
    yield put(PlansActions.getPlansSuccess(data))
  } catch (err) {
    yield put(PlansActions.getPlansFailure(err.message))
  }
}

export function* getPlanByID(action) {
  try {
    const { data } = yield call(api.get, `/plans/${action.payload}`)
    yield put(PlansActions.getPlanSuccess(data))
  } catch (err) {
    yield put(PlansActions.getPlanFailure(err))
  }
}

export function* createPlan(action) {
  try {
    const { data } = yield call(api.post, `/plans`, action.payload)
    yield put(PlansActions.createPlanSuccess(data))
    yield put(push('/planos'))
    toast.success('Habilitação criado com sucesso!')
  } catch (err) {
    toast.error('Erro ao criar Habilitação')
    yield put(PlansActions.createPlanFailure(err))
  }
}

export function* updatePlan(action) {
  try {
    const { data } = yield call(
      api.put,
      `/plans/${action.payload.id}`,
      action.payload
    )
    yield put(PlansActions.updatePlanSuccess(data))
    yield put(push('/planos'))
    toast.success('Habilitação editado com sucesso!')
  } catch (err) {
    toast.error('Erro ao editar Habilitação')
    yield put(PlansActions.updatePlanFailure(err))
  }
}

export function* deletePlan(action) {
  try {
    const { data } = yield call(api.delete, `/plans/${action.payload.id}`)
    yield put(PlansActions.deletePlanSuccess(data))
    yield put(PlansActions.getPlansRequest())
    toast.success('Habilitação removido com sucesso')
  } catch (err) {
    toast.error('Erro ao remover Habilitação')
    yield put(PlansActions.deletePlanFailure(err))
  }
}
