import React, { useEffect, useState } from "react";
import { Form, Label, Input, Col, Button, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import Select from "react-select";

import { formatToSelect } from "utils";

const EditGraduationForm = ({
  submitForm,
  positionsList,
  usersList,
  currentValue,
}) => {
  const [optionsUsersSelect, setOptionsUsersSelect] = useState([]);
  const [optionsPositionsSelect, setOptionsPositionsSelect] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: { score: currentValue.score },
  });

  const { ref: scoreRef, ...score } = register("score", { required: true });

  const defaultValueUser =
    optionsUsersSelect[
      optionsUsersSelect.findIndex(
        (oss) => oss.value === currentValue?.user?.[0]?.id
      )
    ];

  const defaultValuePosition =
    optionsPositionsSelect[
      optionsPositionsSelect.findIndex(
        (oss) => oss.value === currentValue?.position?.[0]?.id
      )
    ];

  const canShowDefaultValue = (itemToCheck, defaultValue) => {
    if (!currentValue?.itemToCheck?.[0]?.id) {
      return true;
    } else {
      return !!defaultValue;
    }
  };

  useEffect(() => {
    setOptionsUsersSelect(formatToSelect(usersList));
  }, [usersList]);

  useEffect(() => {
    setOptionsPositionsSelect(formatToSelect(positionsList));
  }, [positionsList]);

  return (
    <Form
      className="needs-validation"
      noValidate=""
      onSubmit={handleSubmit(submitForm)}
    >
      <Row>
        {canShowDefaultValue("user", defaultValueUser) && (
          <Col xl="4">
            <Label htmlFor="validationCustom01">Usuário</Label>
            <Select
              options={optionsUsersSelect}
              onChange={(e) => setValue("user", [{ id: e.value }])}
              defaultValue={defaultValueUser}
            />
          </Col>
        )}
        {canShowDefaultValue("position", defaultValuePosition) && (
          <Col>
            <Label htmlFor="validationCustom01">Posição</Label>
            <Select
              options={optionsPositionsSelect}
              onChange={(e) => setValue("position", [{ id: e.value }])}
              defaultValue={defaultValuePosition}
            />
          </Col>
        )}

        <Col>
          <Label htmlFor="validationCustom01">Pontuação</Label>
          <Input
            className="form-control"
            name="name"
            type="text"
            placeholder="Pontuação"
            innerRef={scoreRef}
            {...score}
          />
          <span>{errors.score && "Pontuação é obrigatório"}</span>
        </Col>
      </Row>
      <div className="form-row row-gap">
        <Button color="primary">Enviar</Button>
      </div>
    </Form>
  );
};

export default EditGraduationForm;
