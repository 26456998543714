import { put, call } from 'redux-saga/effects'
import { api } from 'services/api'
import { push } from 'connected-react-router'
import { toast } from 'react-toastify'

import { Creators as Stores } from 'store/ducks/stores'

export function* getStores(action) {

  const search = action.payload.search ? `&search=${action.payload.search}` : ''
  try {
    const { data } = yield call(api.get, `/stores?page=${action.payload.page}&limit=${action.payload.limit}${search}`)
    yield put(Stores.getStoresSuccess({ data: data[0], count: data[1] }))
  } catch (err) {
    yield put(Stores.getStoresFailure(err.message))
  }
}

export function* getStoreByID(store) {
  try {
    const { data } = yield call(api.get, `/stores/${store.payload}`)
    yield put(Stores.getStoreSuccess(data))
  } catch (err) {
    yield put(Stores.getStoreFailure(err))
  }
}

export function* createStore(store) {
  try {
    const { data } = yield call(api.post, `/stores`, store.payload)
    yield put(Stores.createStoreSuccess(data))
    yield put(push('/stores'))
    toast.success('Ação criada com sucesso!')
  } catch (err) {
    toast.error('Loja  ao criar ação')
    yield put(Stores.createStoreFailure(err))
  }
}

export function* updateStore(action) {
  const currentFile = new FormData()
  currentFile.append('file', action.payload.file[0])

  try {
    const response = yield call(api.post, `/stores/file`, currentFile, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    delete action.payload.file
    const body = { ...action.payload, icon: response.data.fileUrl }





    const { data } = yield call(
      api.put,
      `/stores/${action.payload.id}`,
      body
    )
    yield put(Stores.updateStoreSuccess(data))
    yield put(push('/stores'))
    toast.success('Ação editada com sucesso!')
  } catch (err) {
    toast.error('Erro ao editar ação')
    yield put(Stores.updateStoreFailure(err))
  }
}

export function* deleteStore(store) {
  try {
    const { data } = yield call(api.delete, `/stores/${store.payload.id}`)
    yield put(Stores.deleteStoreSuccess(data))
    yield put(Stores.getStoresRequest())
    toast.success('Loja removida com sucesso')
  } catch (err) {
    toast.error('Erro ao remover ação')
    yield put(Stores.deleteStoreFailure(err))
  }
}
