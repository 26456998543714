export const Types = {
  GET_ENTITIES_REQUEST: 'report/GET_ENTITIES_REQUEST',
  GET_ENTITIES_SUCCESS: 'report/GET_ENTITIES_SUCCESS',
  GET_ENTITIES_FAILURE: 'report/GET_ENTITIES_FAILURE',

  GET_ENTITY_COLUMNS_REQUEST: 'report/GET_ENTITY_COLUMNS_REQUEST',
  GET_ENTITY_COLUMNS_SUCCESS: 'report/GET_ENTITY_COLUMNS_SUCCESS',
  GET_ENTITY_COLUMNS_FAILURE: 'report/GET_ENTITY_COLUMNS_FAILURE',

  GET_REPORT_DATA_REQUEST: 'report/GET_REPORT_DATA_REQUEST',
  GET_REPORT_DATA_SUCCESS: 'report/GET_REPORT_DATA_SUCCESS',
  GET_REPORT_DATA_FAILURE: 'report/GET_REPORT_DATA_FAILURE'
}

const INITIAL_STATE = {
  entities: [],
  columns: [],
  reportData: [],
  loadingEntities: false,
  loadingColumns: false,
  loadingReportData: false,
  error: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_ENTITIES_REQUEST:
      return { ...state, loadingEntities: true, error: null, entities: [] }

    case Types.GET_ENTITIES_SUCCESS:
      return {
        ...state,
        loadingEntities: false,
        error: null,
        entities: action.payload
      }

    case Types.GET_ENTITIES_FAILURE:
      return {
        ...state,
        loadingEntities: false,
        error: action.payload,
        entities: []
      }

    case Types.GET_ENTITY_COLUMNS_REQUEST:
      return { ...state, loadingColumns: true, error: null, columns: [] }

    case Types.GET_ENTITY_COLUMNS_SUCCESS:
      return {
        ...state,
        loadingColumns: false,
        error: null,
        columns: action.payload
      }

    case Types.GET_ENTITY_COLUMNS_FAILURE:
      return {
        ...state,
        loadingColumns: false,
        columns: [],
        error: action.payload
      }

    case Types.GET_REPORT_DATA_REQUEST:
      return { ...state, loadingReportData: true, reportData: [], error: null }

    case Types.GET_REPORT_DATA_SUCCESS:
      return {
        ...state,
        loadingReportData: false,
        reportData: action.payload,
        error: null
      }

    case Types.GET_REPORT_DATA_FAILURE:
      return {
        ...state,
        loadingReportData: false,
        reportData: [],
        error: action.payload
      }

    default:
      return state
  }
}

export const Creators = {
  getEntitiesRequest: (payload) => ({
    type: Types.GET_ENTITIES_REQUEST,
    payload
  }),

  getEntitiesSuccess: (payload) => ({
    type: Types.GET_ENTITIES_SUCCESS,
    payload
  }),

  getEntitiesFailure: (payload) => ({
    type: Types.GET_ENTITIES_FAILURE,
    payload
  }),

  getEntityColumnsRequest: (payload) => ({
    type: Types.GET_ENTITY_COLUMNS_REQUEST,
    payload
  }),

  getEntityColumnsSuccess: (payload) => ({
    type: Types.GET_ENTITY_COLUMNS_SUCCESS,
    payload
  }),

  getEntityColumnsFailure: (payload) => ({
    type: Types.GET_ENTITY_COLUMNS_FAILURE,
    payload
  }),

  getReportDataRequest: (payload) => ({
    type: Types.GET_REPORT_DATA_REQUEST,
    payload
  }),

  getReportDataSuccess: (payload) => ({
    type: Types.GET_REPORT_DATA_SUCCESS,
    payload
  }),

  getReportDataFailure: (payload) => ({
    type: Types.GET_REPORT_DATA_FAILURE,
    payload
  })
}
