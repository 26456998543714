import { put, call } from 'redux-saga/effects'
import { api } from 'services/api'
import { push } from 'connected-react-router'
import { toast } from 'react-toastify'

import { Creators as FAQActions } from 'store/ducks/faq'

export function* getQuestions() {
  try {
    const { data } = yield call(api.get, '/faq')
    yield put(FAQActions.getQuestionsSuccess(data))
  } catch (err) {
    yield put(FAQActions.getQuestionsFailure(err.message))
  }
}

export function* getQuestionByID(action) {
  try {
    const { data } = yield call(api.get, `/faq/${action.payload}`)
    yield put(FAQActions.getQuestionSuccess(data))
  } catch (err) {
    yield put(FAQActions.getQuestionFailure(err))
  }
}

export function* createQuestion(action) {
  try {
    const { data } = yield call(api.post, `/faq`, action.payload)
    yield put(FAQActions.createQuestionSuccess(data))
    yield put(push('/faq/itens'))
    toast.success('Pergunta criada com sucesso!')
  } catch (err) {
    toast.error('Erro ao criar pergunta')
    yield put(FAQActions.createQuestionFailure(err))
  }
}

export function* updateQuestion(action) {
  try {
    const { data } = yield call(
      api.put,
      `/faq/${action.payload.id}`,
      action.payload
    )
    yield put(FAQActions.updateQuestionSuccess(data))
    yield put(push('/faq/itens'))
    toast.success('Pergunta editada com sucesso!')
  } catch (err) {
    toast.error('Erro ao editar pergunta')
    yield put(FAQActions.updateQuestionFailure(err))
  }
}

export function* deleteQuestion(action) {
  try {
    const { data } = yield call(api.delete, `/faq/${action.payload.id}`)
    yield put(FAQActions.deleteQuestionSuccess(data))
    yield put(FAQActions.getQuestionsRequest())
    toast.success('Pergunta removida com sucesso')
  } catch (err) {
    toast.error('Erro ao remover pergunta')
    yield put(FAQActions.deleteQuestionFailure(err))
  }
}
