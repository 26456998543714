export const Types = {
  GET_SALES_REQUEST: "sales/GET_SALES_REQUEST",
  GET_SALES_SUCCESS: "sales/GET_SALES_SUCCESS",
  GET_SALES_FAILURE: "sales/GET_SALES_FAILURE",

  CREATE_SALES_REQUEST: "sales/CREATE_SALES_REQUEST",
  CREATE_SALES_SUCCESS: "sales/CREATE_SALES_SUCCESS",
  CREATE_SALES_FAILURE: "sales/CREATE_SALES_FAILURE",

  UPDATE_SALES_REQUEST: "sales/UPDATE_SALES_REQUEST",
  UPDATE_SALES_SUCCESS: "sales/UPDATE_SALES_SUCCESS",
  UPDATE_SALES_FAILURE: "sales/UPDATE_SALES_FAILURE",

  DELETE_SALES_REQUEST: "sales/DELETE_SALES_REQUEST",
  DELETE_SALES_SUCCESS: "sales/DELETE_SALES_SUCCESS",
  DELETE_SALES_FAILURE: "sales/DELETE_SALES_FAILURE",
};

const INITIAL_STATE = {
  list: [],
  cashback: {},
  loading: false,
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_SALES_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.GET_SALES_SUCCESS:
      return { ...state, loading: false, list: action.payload, error: null };

    case Types.GET_SALES_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.CREATE_SALES_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.CREATE_SALES_SUCCESS:
      return { ...state, loading: false, error: null };

    case Types.CREATE_SALES_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.UPDATE_SALES_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.UPDATE_SALES_SUCCESS:
      return { ...state, loading: false, cashback: {}, error: null };

    case Types.UPDATE_SALES_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.DELETE_SALES_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.DELETE_SALES_SUCCESS:
      return { ...state, loading: false, error: null };

    case Types.DELETE_SALES_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export const Creators = {
  getSalesRequest: (payload) => ({
    type: Types.GET_SALES_REQUEST,
    payload,
  }),

  getSalesSuccess: (payload) => ({
    type: Types.GET_SALES_SUCCESS,
    payload,
  }),

  getSalesFailure: (payload) => ({
    type: Types.GET_SALES_FAILURE,
    payload,
  }),

  createSalesRequest: (payload) => ({
    type: Types.CREATE_SALES_REQUEST,
    payload,
  }),

  createSalesSuccess: (payload) => ({
    type: Types.CREATE_SALES_SUCCESS,
    payload,
  }),

  createSalesFailure: (payload) => ({
    type: Types.CREATE_SALES_FAILURE,
    payload,
  }),

  updateSalesRequest: (payload) => ({
    type: Types.UPDATE_SALES_REQUEST,
    payload,
  }),

  updateSalesSuccess: (payload) => ({
    type: Types.UPDATE_SALES_SUCCESS,
    payload,
  }),

  updateSalesFailure: (payload) => ({
    type: Types.UPDATE_SALES_FAILURE,
    payload,
  }),

  deleteSalesRequest: (payload) => ({
    type: Types.DELETE_SALES_REQUEST,
    payload,
  }),

  deleteSalesSuccess: (payload) => ({
    type: Types.DELETE_SALES_SUCCESS,
    payload,
  }),

  deleteSalesFailure: (payload) => ({
    type: Types.DELETE_SALES_FAILURE,
    payload,
  }),
};
