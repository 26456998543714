import React, { memo, useRef } from 'react'
import { Plus } from 'react-feather'
import { useHistory } from 'react-router-dom'
import { Button } from 'reactstrap'
import { debounce } from 'lodash'

import { Input } from 'reactstrap'

const TableActions = ({
  linkToCreate,
  handleSearch,
  handleClearSearch,
  hasSearch,
  hiddenAddButton
}) => {
  const history = useHistory()

  const search = hasSearch ? debounce(handleSearch, 500) : null

  const searchInputRef = useRef(null)

  const handleClearField = () => {
    handleClearSearch()
    searchInputRef.current.value = ''
  }

  return (
    <>
      {hasSearch && (
        <div className="table-actions search">
          <Input
            className="form-control"
            name="name"
            type="text"
            onChange={(e) => search(e.target.value)}
            placeholder="Buscar"
            innerRef={searchInputRef}
          />
          <span onClick={handleClearField}>Limpar</span>
        </div>
      )}
      {!hiddenAddButton ? (
        <div className="table-actions wrapper">
          <Button
            key="add"
            color="primary"
            onClick={() => history.push(linkToCreate)}
          >
            <Plus size={20} />
            Adicionar
          </Button>
        </div>
      ) : (
        <br />
      )}
    </>
  )
}

export default memo(TableActions)
