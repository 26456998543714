import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios'

import { Row, Col, Label, Input, Form, Button } from 'reactstrap'
import { useForm } from 'react-hook-form'
import Select from 'react-select'

import { formatToSelect } from 'utils'

const CreateBankAccountForm = ({ handleCreateBankAccount }) => {
  const [optionsBank, setOptionsBank] = useState([])
  const [showPixInput, setShowPixInput] = useState(false)
  const [hiddeBankImputs, setHiddeBankImputs] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm()

  const { ref: pixKeyRef, ...pixKey } = register('pixKey')

  const { ref: agencyRef, ...agency } = register('agency', {
    required: true
  })

  const { ref: accountRef, ...account } = register('account', {
    required: true
  })

  const { ref: cpfCNPJRef, ...cpfCNPJ } = register('cpfCNPJ', {
    required: true
  })

  const getBanks = useCallback(async () => {
    const { data } = await axios.get(
      'https://raw.githubusercontent.com/guibranco/BancosBrasileiros/master/data/bancos.json'
    )
    let options = []
    options.push({
      value: "Quanta Bank",
      label: "Quanta Bank"
    })
    for (let i in data) {
      const nameFormatted = `${data[i].COMPE} - ${data[i].ShortName}`
      options.push({
        value: nameFormatted,
        label: nameFormatted
      })
    }
    setOptionsBank(options)
  }, [])

  useEffect(() => {
    getBanks()
  }, [getBanks])

  const BANK_TYPE_ACCOUNT = [
    {
      value: 'Conta Corrente',
      label: 'Conta Corrente'
    },
    {
      value: 'Conta Poupança',
      label: 'Conta Poupança'
    },
    {
      value: 'PIX',
      label: 'PIX'
    }
  ]

  return (
    <Form
      className="needs-validation"
      noValidate=""
      onSubmit={handleSubmit(handleCreateBankAccount)}
    >
      <Row style={{ marginBottom: '15px' }}>
        <Col>
          <Label htmlFor="validationCustom01">Banco</Label>
          <Select
            options={optionsBank}
            onChange={(e) => {
              setHiddeBankImputs(e.value === 'Quanta Bank')
              setValue('type', e.value)
            }}
          />
        </Col>
      </Row>
      {!hiddeBankImputs && (<Row>
        <Col lg="6">
          <Label htmlFor="validationCustom01">Agência</Label>
          <Input
            className="form-control"
            name="agency"
            type="text"
            placeholder="Digite aqui sua agência"
            innerRef={agencyRef}
            {...agency}
          />
          <small style={{ color: 'red' }}>
            {errors.agency && 'Agência é obrigatório'}
          </small>
        </Col>
        <Col lg="6">
          <Label htmlFor="validationCustom02">Conta</Label>
          <Input
            className="form-control"
            name="agency"
            type="text"
            placeholder="Digite aqui sua conta"
            innerRef={accountRef}
            {...account}
          />
          <small style={{ color: 'red' }}>
            {errors.account && 'Conta é obrigatório'}
          </small>
        </Col>
      </Row>)}
      < Row style={{ marginTop: '15px' }}>
        {!hiddeBankImputs && (<Col lg="6">
          <Label htmlFor="validationCustom01">Tipo</Label>
          <Select
            options={BANK_TYPE_ACCOUNT}
            onChange={(e) => {
              e.value === 'PIX' ? setShowPixInput(true) : setShowPixInput(false)
              setValue('type', e.value)
            }}
          />
        </Col>)}
        < Col lg="6">
          <Label htmlFor="validationCustom02">CPF / CNPJ</Label>
          <Input
            className="form-control"
            name="cpfCNPJ"
            type="text"
            placeholder="Digite aqui seu CPF / CNPJ"
            innerRef={cpfCNPJRef}
            {...cpfCNPJ}
          />
          <small style={{ color: 'red' }}>
            {errors.cpfCNPJ && 'CPF ou CNPJ é obrigatório'}
          </small>
        </Col>
      </Row >
      {showPixInput && (
        <Row style={{ marginTop: '15px' }}>
          <Col lg="12">
            <Label htmlFor="validationCustom01">Chave Pix</Label>
            <Input
              className="form-control"
              name="agency"
              type="text"
              placeholder="Digite sua chave pix"
              innerRef={pixKeyRef}
              {...pixKey}
            />
          </Col>
        </Row>
      )}
      <Row style={{ padding: '25px 0' }}>
        <Col lg="12" style={{ display: 'flex', justifyContent: 'center' }}>
          <Button type="submit" color="primary">
            Enviar
          </Button>
        </Col>
      </Row>
    </Form >
  )
}

export default CreateBankAccountForm
