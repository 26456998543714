import React from "react";
import { Form, Label, Input, Col, Button } from "reactstrap";
import { useForm } from "react-hook-form";


const CreateLevelForm = ({
  submitForm,
  levelsList,
  cashBackList,
  bonificationList,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { ref: nameRef, ...name } = register("name", { required: true });

  const { ref: descriptionRef, ...description } = register("description", {
    required: true,
  });

  const { ref: cashbackRef, ...cashback } = register("cashback");

  const { ref: bonificationRef, ...bonification } = register("bonification");

  const { ref: maxLevelQuantityRef, ...maxLevelQuantity } = register(
    "maxLevelQuantity",
    {
      required: true,
    }
  );
  const { ref: levelNumberRef, ...levelNumber } = register(
    "levelNumber",
    {
      required: true,
    }
  );

  return (
    <Form
      className="needs-validation"
      noValidate=""
      onSubmit={handleSubmit(submitForm)}
    >
      <div className="form-row">
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom01">Nome</Label>
          <Input
            className="form-control"
            name="name"
            type="text"
            placeholder="Nome"
            innerRef={nameRef}
            {...name}
          />
          <span>{errors.name && "Nome é obrigatório"}</span>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom02">Descrição</Label>
          <Input
            className="form-control"
            name="description"
            type="text"
            placeholder="Descrição"
            innerRef={descriptionRef}
            {...description}
          />

          <span>{errors.description && "Descrição é obrigatório"}</span>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom02">Quantidade máxima nível</Label>
          <Input
            className="form-control"
            name="description"
            type="number"
            placeholder="Quantidade máxima nível"
            innerRef={maxLevelQuantityRef}
            {...maxLevelQuantity}
          />

          <span>
            {errors.maxLevelQuantity && "Quantidade máxima nível é obrigatório"}
          </span>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom02">Posição Nível</Label>
          <Input
            className="form-control"
            name="description"
            type="number"
            placeholder="Posição Nível"
            innerRef={levelNumberRef}
            {...levelNumber}
          />

          <span>
            {errors.levelNumber && "Posição Nível é obrigatório"}
          </span>
        </Col>
      </div>
      <div className="form-row row-gap">
        <Col sm="12">
          <h5>Cashbacks</h5>
        </Col>
        <Col>
          {cashBackList?.map((item) => {
            const cashbackOwner = levelsList.find(
              (levelItem) => levelItem.cashback?.id === item.id
            );

            return (
              <Label className="d-block" for={item.id}>
                <Input
                  className="radio_animated"
                  id={item.id}
                  type="radio"
                  name="cashback"
                  innerRef={cashbackRef}
                  value={item.id}
                  {...cashback}
                  disabled={!!cashbackOwner}
                />
                {item.name} -{" "}
                {!!cashbackOwner ? (
                  <span style={{ color: "red" }}>
                    Não é possível atribuir este cashback, pois o mesmo pertence
                    à <strong>{cashbackOwner.name}</strong>
                  </span>
                ) : (
                  <span style={{ color: "green" }}>Disponível</span>
                )}
              </Label>
            );
          })}
        </Col>

        <Col sm="12">
          <h5>Bonificações</h5>
        </Col>
        <Col>
          {bonificationList?.map((item) => {
            const bonificationOwner = levelsList.find(
              (levelItem) => levelItem.bonification?.id === item.id
            );
            return (
              <Label className="d-block" for={item.id}>
                <Input
                  className="radio_animated"
                  id={item.id}
                  type="radio"
                  name="bonification"
                  innerRef={bonificationRef}
                  value={item.id}
                  disabled={!!bonificationOwner}
                  {...bonification}
                />
                {item.name}-{" "}
                {!!bonificationOwner ? (
                  <span style={{ color: "red" }}>
                    Não é possível atribuir esta bonificação, pois o mesmo
                    pertence à <strong>{bonificationOwner.name}</strong>
                  </span>
                ) : (
                  <span style={{ color: "green" }}>Disponível</span>
                )}
              </Label>
            );
          })}
        </Col>
      </div>
      <div className="form-row row-gap">
        <Button color="primary">Enviar</Button>
      </div>
    </Form>
  );
};

export default CreateLevelForm;
