export const Types = {
  GET_USERS_REQUEST: 'users/GET_USERS_REQUEST',
  GET_USERS_SUCCESS: 'users/GET_USERS_SUCCESS',
  GET_USERS_FAILURE: 'users/GET_USERS_FAILURE',

  GET_USERS_ACCREDITED_REQUEST: 'users/GET_USERS_ACCREDITED_REQUEST',
  GET_USERS_ACCREDITED_SUCCESS: 'users/GET_USERS_ACCREDITED_SUCCESS',
  GET_USERS_ACCREDITED_FAILURE: 'users/GET_USERS_ACCREDITED_FAILURE',

  GET_USER_BY_NAME_REQUEST: 'users/GET_USERS_BY_NAME_REQUEST',
  GET_USER_BY_NAME_SUCCESS: 'users/GET_USER_BY_NAME_SUCCESS',
  GET_USER_BY_NAME_FAILURE: 'users/GET_USER_BY_NAME_FAILURE',

  GET_USERS_DEADBEAT_REQUEST: 'users/GET_USERS_DEADBEAT_REQUEST',
  GET_USERS_DEADBEAT_SUCCESS: 'users/GET_USERS_DEADBEAT_SUCCESS',
  GET_USERS_DEADBEAT_FAILURE: 'users/GET_USERS_DEADBEAT_FAILURE',

  GET_USER_REQUEST: 'users/GET_USER_REQUEST',
  GET_USER_SUCCESS: 'users/GET_USER_SUCCESS',
  GET_USER_FAILURE: 'users/GET_USER_FAILURE',

  GET_USER_BY_EMAIL_REQUEST: 'users/GET_USER_BY_EMAIL_REQUEST',
  GET_USER_BY_EMAIL_SUCCESS: 'users/GET_USER_BY_EMAIL_SUCCESS',
  GET_USER_BY_EMAIL_FAILURE: 'users/GET_USER_BY_EMAIL_FAILURE',

  CREATE_USER_REQUEST: 'users/CREATE_USER_REQUEST',
  CREATE_USER_SUCCESS: 'users/CREATE_USER_SUCCESS',
  CREATE_USER_FAILURE: 'users/CREATE_USER_FAILURE',

  CREATE_USER_INVITE_REQUEST: 'users/CREATE_USERS_INVITE_REQUEST',
  CREATE_USER_INVITE_SUCCESS: 'users/CREATE_USER_INVITE_SUCCESS',
  CREATE_USER_INVITE_FAILURE: 'users/CREATE_USER_INVITE_FAILURE',

  UPDATE_USER_REQUEST: 'users/UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS: 'users/UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: 'users/UPDATE_USER_FAILURE',

  DELETE_USER_REQUEST: 'users/DELETE_USER_REQUEST',
  DELETE_USER_SUCCESS: 'users/DELETE_USER_SUCCESS',
  DELETE_USER_FAILURE: 'users/DELETE_USER_FAILURE',

  SET_PROFILE_TO_USER_REQUEST: 'users/SET_PROFILE_TO_USER_REQUEST',
  SET_PROFILE_TO_USER_SUCCESS: 'users/SET_PROFILE_TO_USER_SUCCESS',
  SET_PROFILE_TO_USER_FAILURE: 'users/SET_PROFILE_TO_USER_FAILURE'
}

const INITIAL_STATE = {
  list: [],
  user: {},
  accredited: [],
  usersByName: [],
  userByEmail: {},
  loading: false,
  error: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_USER_BY_NAME_REQUEST:
      return { ...state, loading: true, error: null }

    case Types.GET_USER_BY_NAME_SUCCESS:
      return {
        ...state,
        usersByName: action.payload,
        loading: false,
        error: null
      }

    case Types.GET_USER_BY_NAME_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case Types.GET_USERS_REQUEST:
      return { ...state, loading: true, error: null }

    case Types.GET_USERS_SUCCESS:
      return { ...state, list: action.payload, loading: false, error: null }

    case Types.GET_USERS_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case Types.GET_USERS_ACCREDITED_REQUEST:
      return { ...state, loading: true, error: null }

    case Types.GET_USERS_ACCREDITED_SUCCESS:
      return {
        ...state,
        accredited: action.payload,
        loading: false,
        error: null
      }

    case Types.GET_USERS_ACCREDITED_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case Types.GET_USERS_DEADBEAT_REQUEST:
      return { ...state, loading: true, error: null }

    case Types.GET_USERS_DEADBEAT_SUCCESS:
      return { ...state, list: action.payload, loading: false, error: null }

    case Types.GET_USERS_DEADBEAT_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case Types.GET_USER_REQUEST:
      return { ...state, loading: true, user: {}, error: null }

    case Types.GET_USER_SUCCESS:
      return { ...state, user: action.payload, loading: false, error: null }

    case Types.GET_USER_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case Types.CREATE_USER_REQUEST:
      return { ...state, loading: true, error: null }

    case Types.CREATE_USER_SUCCESS:
      return { ...state, user: action.payload, loading: false, error: null }

    case Types.CREATE_USER_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case Types.CREATE_USER_INVITE_REQUEST:
      return {
        ...state,
        userinvite: action.payload,
        loading: true,
        error: null
      }

    case Types.CREATE_USER_INVITE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      }

    case Types.CREATE_USER_INVITE_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case Types.UPDATE_USER_REQUEST:
      return { ...state, loading: true, error: null }

    case Types.UPDATE_USER_SUCCESS:
      return { ...state, user: {}, loading: false, error: null }

    case Types.UPDATE_USER_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case Types.DELETE_USER_REQUEST:
      return { ...state, loading: true, error: null }

    case Types.DELETE_USER_SUCCESS:
      return { ...state, loading: false, error: null }

    case Types.DELETE_USER_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case Types.SET_PROFILE_TO_USER_REQUEST:
      return { ...state, loading: true, error: null }

    case Types.SET_PROFILE_TO_USER_SUCCESS:
      return { ...state, user: action.payload, loading: false, error: null }

    case Types.SET_PROFILE_TO_USER_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case Types.GET_USER_BY_EMAIL_REQUEST:
      return { ...state, loading: true, error: null }

    case Types.GET_USER_BY_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        userByEmail: action.payload
      }

    case Types.GET_USER_BY_EMAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        userByEmail: {}
      }

    default:
      return state
  }
}

export const Creators = {
  getUsersByNameSuccess: (payload) => ({
    type: Types.GET_USER_BY_NAME_SUCCESS,
    payload
  }),

  getUsersByNameFailure: (payload) => ({
    type: Types.GET_USER_BY_NAME_FAILURE,
    payload
  }),
  getUsersByNameRequest: (payload) => ({
    type: Types.GET_USER_BY_NAME_REQUEST,
    payload
  }),

  getUsersDeadbeatSuccess: (payload) => ({
    type: Types.GET_USERS_DEADBEAT_SUCCESS,
    payload
  }),

  getUsersDeadbeatFailure: (payload) => ({
    type: Types.GET_USERS_DEADBEAT_FAILURE,
    payload
  }),
  getUsersDeadbeatRequest: (payload) => ({
    type: Types.GET_USERS_DEADBEAT_REQUEST,
    payload
  }),
  getUsersRequest: (payload) => ({
    type: Types.GET_USERS_REQUEST,
    payload
  }),

  getUsersSuccess: (payload) => ({
    type: Types.GET_USERS_SUCCESS,
    payload
  }),

  getUsersFailure: (payload) => ({
    type: Types.GET_USERS_ACCREDITED_FAILURE,
    payload
  }),
  getUsersAccreditedRequest: (payload) => ({
    type: Types.GET_USERS_ACCREDITED_REQUEST,
    payload
  }),

  getUsersAccreditedSuccess: (payload) => ({
    type: Types.GET_USERS_ACCREDITED_SUCCESS,
    payload
  }),

  getUsersAccreditedFailure: (payload) => ({
    type: Types.GET_USERS_FAILURE,
    payload
  }),
  getUserRequest: (payload) => ({
    type: Types.GET_USER_REQUEST,
    payload
  }),

  getUserSuccess: (payload) => ({
    type: Types.GET_USER_SUCCESS,
    payload
  }),

  getUserFailure: (payload) => ({
    type: Types.GET_USER_FAILURE,
    payload
  }),

  createUserInviteRequest: (payload) => ({
    type: Types.CREATE_USER_INVITE_REQUEST,
    payload
  }),

  createUserInviteSuccess: (payload) => ({
    type: Types.CREATE_USER_INVITE_SUCCESS,
    payload
  }),

  createUserInviteFailure: (payload) => ({
    type: Types.CREATE_USER_INVITE_FAILURE,
    payload
  }),

  createUserRequest: (payload) => ({
    type: Types.CREATE_USER_REQUEST,
    payload
  }),

  createUserSuccess: (payload) => ({
    type: Types.CREATE_USER_SUCCESS,
    payload
  }),

  createUserFailure: (payload) => ({
    type: Types.CREATE_USER_FAILURE,
    payload
  }),

  updateUserRequest: (payload) => ({
    type: Types.UPDATE_USER_REQUEST,
    payload
  }),

  updateUserSuccess: (payload) => ({
    type: Types.UPDATE_USER_SUCCESS,
    payload
  }),

  updateUserFailure: (payload) => ({
    type: Types.UPDATE_USER_FAILURE,
    payload
  }),

  deleteUserRequest: (payload) => ({
    type: Types.DELETE_USER_REQUEST,
    payload
  }),

  deleteUserSuccess: (payload) => ({
    type: Types.DELETE_USER_SUCCESS,
    payload
  }),

  deleteUserFailure: (payload) => ({
    type: Types.DELETE_USER_FAILURE,
    payload
  }),

  setProfileUserRequest: (payload) => ({
    type: Types.SET_PROFILE_TO_USER_REQUEST,
    payload
  }),

  setProfileUserSuccess: (payload) => ({
    type: Types.SET_PROFILE_TO_USER_SUCCESS,
    payload
  }),

  setProfileUserFailure: (payload) => ({
    type: Types.SET_PROFILE_TO_USER_FAILURE,
    payload
  }),

  getUserByEmailRequest: (payload) => ({
    type: Types.GET_USER_BY_EMAIL_REQUEST,
    payload
  }),

  getUserByEmailSuccess: (payload) => ({
    type: Types.GET_USER_BY_EMAIL_SUCCESS,
    payload
  }),

  getUserByEmailFailure: (payload) => ({
    type: Types.GET_USER_BY_EMAIL_FAILURE,
    payload
  })
}
