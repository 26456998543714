import { put, call } from 'redux-saga/effects'
import { api } from 'services/api'
import { push } from 'connected-react-router'
import { toast } from 'react-toastify'

import { Creators as PaymentMethodsActions } from 'store/ducks/paymentMethods'

export function* getPaymentMethods() {
  try {
    const { data } = yield call(api.get, '/payment/methods')
    yield put(PaymentMethodsActions.getPaymentMethodsSuccess(data))
  } catch (err) {
    yield put(PaymentMethodsActions.getPaymentMethodsFailure(err.message))
  }
}

export function* getPaymentMethodByID(action) {
  try {
    const { data } = yield call(api.get, `/payment/methods/${action.payload}`)
    yield put(PaymentMethodsActions.getPaymentMethodSuccess(data))
  } catch (err) {
    yield put(PaymentMethodsActions.getPaymentMethodFailure(err))
  }
}

export function* createPaymentMethod(action) {
  try {
    const { data } = yield call(api.post, `/payment/methods`, action.payload)
    yield put(PaymentMethodsActions.createPaymentMethodSuccess(data))
    yield put(push('/pagamentos/metodos'))
    toast.success('Método de pagamento criado com sucesso!')
  } catch (err) {
    toast.error('Erro ao criar método de pagamento')
    yield put(PaymentMethodsActions.createPaymentMethodFailure(err))
  }
}

export function* updatePaymentMethod(action) {
  try {
    const { data } = yield call(
      api.put,
      `/payment/methods/${action.payload.id}`,
      action.payload
    )
    yield put(PaymentMethodsActions.updatePaymentMethodSuccess(data))
    yield put(push('/pagamentos/metodos'))
    toast.success('Método de pagamento editado com sucesso!')
  } catch (err) {
    toast.error('Erro ao editar método de pagamento')
    yield put(PaymentMethodsActions.updatePaymentMethodFailure(err))
  }
}

export function* deletePaymentMethod(action) {
  try {
    const { data } = yield call(
      api.delete,
      `/payment/methods/${action.payload.id}`
    )
    yield put(PaymentMethodsActions.deletePaymentMethodSuccess(data))
    yield put(PaymentMethodsActions.getPaymentMethodsRequest())
    toast.success('Método de pagamento removido com sucesso')
  } catch (err) {
    toast.error('Erro ao remover método de pagamento')
    yield put(PaymentMethodsActions.deletePaymentMethodFailure(err))
  }
}
