import Show from 'pages/Materials/Show'
import List from 'pages/Materials/List'
import Create from 'pages/Materials/Create'
import Edit from 'pages/Materials/Edit'

import { BASE_URL } from 'shared/constants/config'

export default [
  { path: `${BASE_URL}/materiais-apoio`, component: Show },
  { path: `${BASE_URL}/materiais-apoio/itens`, component: List },
  { path: `${BASE_URL}/materiais-apoio/criar`, component: Create },
  { path: `${BASE_URL}/materiais-apoio/editar/:id`, component: Edit }
]
