import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Col, Card, CardBody } from 'reactstrap'

import CountUp from 'react-countup'
import { DollarSign, AlertCircle, CheckCircle, RefreshCw } from 'react-feather'

import { Creators as MoneyActions } from 'store/ducks/money'

const GeneralResume = () => {
  const {
    auth: { auth },
    network: { networkByOwner },

    money: { moneyByUser }
  } = useSelector((state) => state)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(MoneyActions.getMoneyByUserRequest(`${auth.id}?page=0&limit=10`))
  }, [auth.id, dispatch])

  return (
    <>
      <Col sm="6" xl="3" lg="6">
        <Card className="o-hidden">
          <CardBody
            className="b-r-4 card-body"
            style={{ background: '#3498db', color: '#fff' }}
          >
            <div className="media static-top-widget">
              <div className="align-self-center text-center">
                <DollarSign />
              </div>
              <div className="media-body">
                <span className="m-0" style={{ fontSize: '12.5px' }}>
                  Meu consumo
                </span>
                <h4 className="mb-0 counter">
                  R${' '}
                  <CountUp
                    decimal=","
                    decimals={2}
                    end={
                      moneyByUser?.cashbackStats?.totalCashbackLast30daysCount
                        ? moneyByUser?.cashbackStats?.totalCashbackLast30daysValue
                        : 0
                    }
                  />
                </h4>
                <DollarSign className="icon-bg" />
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col sm="6" xl="3" lg="6">
        <Card className="o-hidden">
          <div
            className="b-r-4 card-body"
            style={{ background: '#f39c12', color: '#fff' }}
          >
            <div className="media static-top-widget">
              <div className="align-self-center text-center">
                <RefreshCw />
              </div>
              <div className="media-body">
                <span className="m-0" style={{ fontSize: '12.5px' }}>
                  Cashbacks da rede
                </span>
                <h4 className="mb-0 counter">
                  R${' '}
                  <CountUp
                    decimal=","
                    decimals={2}
                    end={
                      moneyByUser?.cashbackStats?.totalNetworkCashbackLast30daysCount
                        ? moneyByUser?.cashbackStats?.totalNetworkCashbackLast30daysValue
                        : 0
                    }
                  />
                </h4>
                <RefreshCw className="icon-bg" />
              </div>
            </div>
          </div>
        </Card>
      </Col>
      <Col sm="6" xl="3" lg="6">
        <Card className="o-hidden">
          <CardBody
            className="b-r-4"
            style={{ background: '#27ae60', color: '#fff' }}
          >
            <div className="media static-top-widget">
              <div className="align-self-center text-center">
                <CheckCircle />
              </div>
              <div className="media-body">
                <span className="m-0" style={{ fontSize: '12.5px' }}>
                 Novas Habilitações
                </span>
                <h4 className="mb-0 counter">
               
                  <CountUp
       
                    end={
                      networkByOwner?.planActivationLast30days
                        ? networkByOwner?.planActivationLast30days
                        : 0
                    }
                  />
                </h4>
                <CheckCircle className="icon-bg" />
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col sm="6" xl="3" lg="6">
        <Card className="o-hidden">
          <CardBody
            className="b-r-4"
            style={{ background: '#e74c3c', color: '#fff' }}
          >
            <div className="media static-top-widget">
              <div className="align-self-center text-center">
                <AlertCircle />
              </div>
              <div className="media-body">
                <span className="m-0" style={{ fontSize: '12.5px' }}>
                  Meta
                </span>
                <h4 className="mb-0 counter">
             
                  <CountUp
                        decimal=","
                        decimals={2}
                    end={
                      moneyByUser?.cashbackStats?.goal
                        ? moneyByUser?.cashbackStats?.goal
                        : 0
                    }
                  />%
                </h4>
                <AlertCircle className="icon-bg" />
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  )
}

export default GeneralResume
