import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'

import DefaultPage from 'components/DefaultPage'
import Form from './Form'

import { Creators as TutorialsActions } from 'store/ducks/tutorials'

import { checkIsActive } from 'utils'

const EditTutorial = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const { tutorial } = useSelector((state) => state.tutorials)

  const [isActive, setIsActive] = useState(false)

  const handleActive = useCallback((value) => setIsActive(value), [])

  const handleUpdateTutorial = useCallback(
    (item) => {
      const body = {
        ...item,
        isActive: checkIsActive(isActive, tutorial && tutorial.isActive)
      }

      dispatch(TutorialsActions.updateTutorialRequest({ ...body, id }))
    },
    [dispatch, id, isActive, tutorial]
  )

  useEffect(() => {
    dispatch(TutorialsActions.getTutorialRequest(id))
  }, [dispatch, id])

  return (
    <DefaultPage
      pageTitle="Tutoriais"
      pageDescription="Editar tutorial"
      pageSubDescription="Editar tutorial para o sistema"
    >
      {!isEmpty(tutorial) && (
        <Form
          submitForm={handleUpdateTutorial}
          handleActive={handleActive}
          currentValue={tutorial}
        />
      )}
    </DefaultPage>
  )
}

export default EditTutorial
