import Levels from "pages/Levels";
import CreateLevel from "pages/Levels/Create";
import EditLevel from "pages/Levels/Edit";

import { BASE_URL } from "shared/constants/config";

export default [
  { path: `${BASE_URL}/niveis`, component: Levels },
  { path: `${BASE_URL}/niveis/criar`, component: CreateLevel },
  { path: `${BASE_URL}/niveis/editar/:id`, component: EditLevel },
];
