import React, { memo } from 'react'
import { Card, ListGroup, ListGroupItem } from 'reactstrap'
import { formatToCurrency } from 'utils'

const Movements = ({ data }) => (
  <div className="movements">
    <Card className="card">
      <strong>Construção de Ativo</strong>
      <div className="list">
        <ListGroup style={{ width: '100%' }}>
          <ListGroupItem
            className="list-group-item-action"
            tag="a"
            href="#javascript"
          >
            <div className="content">
              <div className="name">
                <span>Bônus Cashback</span>
              </div>
              <div className="amount">
                <span>
                  {formatToCurrency(
                    data && data.totalTypeCashback ? data.totalTypeCashback : 0
                  )}
                </span>
              </div>
            </div>
          </ListGroupItem>
          <ListGroupItem
            className="list-group-item-action"
            tag="a"
            href="#javascript"
          >
            <div className="content">
              <div className="name">
                <span>Bônus Cashback Residual</span>
              </div>
              <div className="amount">
                <span>
                  {formatToCurrency(
                    data && data.totalTypeCashbackResidual
                      ? data.totalTypeCashbackResidual
                      : 0
                  )}
                </span>
              </div>
            </div>
          </ListGroupItem>
          <ListGroupItem
            className="list-group-item-action"
            tag="a"
            href="#javascript"
          >
            <div className="content">
              <div className="name">
                <span>Bônus Habilitação</span>
              </div>
              <div className="amount">
                <span>
                  {formatToCurrency(
                    data && data.totalTypeBonusAccession
                      ? data.totalTypeBonusAccession
                      : 0
                  )}
                </span>
              </div>
            </div>
          </ListGroupItem>
          <ListGroupItem
            className="list-group-item-action"
            tag="a"
            href="#javascript"
          >
            <div className="content">
              <div className="name">
                <span>Bônus Habilitação Residual</span>
              </div>
              <div className="amount">
                <span>
                  {formatToCurrency(
                    data && data.totalTypeBonusAccessionResidual
                      ? data.totalTypeBonusAccessionResidual
                      : 0
                  )}
                </span>
              </div>
            </div>
          </ListGroupItem>
          <ListGroupItem
            className="list-group-item-action"
            tag="a"
            href="#javascript"
          >
            <div className="content">
              <div className="name">
                <span>Bônus Empresas</span>
              </div>
              <div className="amount">
                <span>
                  {formatToCurrency(
                    data && data.totalTypeBonusAccredited
                      ? data.totalTypeBonusAccredited
                      : 0
                  )}
                </span>
              </div>
            </div>
          </ListGroupItem>
          <ListGroupItem
            className="list-group-item-action"
            tag="a"
            href="#javascript"
          >
            <div className="content">
              <div className="name">
                <span>Bônus Empresas Residual</span>
              </div>
              <div className="amount">
                <span>
                  {formatToCurrency(
                    data && data.totalTypeBonusAccreditedResidual
                      ? data.totalTypeBonusAccreditedResidual
                      : 0
                  )}
                </span>
              </div>
            </div>
          </ListGroupItem>
          {/* <ListGroupItem
            className="list-group-item-action"
            tag="a"
            href="#javascript"
          >
            <div className="content">
              <div className="name">
                <span>Bônus Quanta Bank</span>
              </div>
              <div className="amount">
                <span>
                  {formatToCurrency(
                    data && data.totalTypeBonusQuantaBank
                      ? data.totalTypeBonusQuantaBank
                      : 0
                  )}
                </span>
              </div>
            </div>
          </ListGroupItem>

          <ListGroupItem
            className="list-group-item-action"
            tag="a"
            href="#javascript"
          >
            <div className="content">
              <div className="name">
                <span>Bônus Quanta Bank Residual</span>
              </div>
              <div className="amount">
                <span>
                  {formatToCurrency(
                    data && data.totalTypeBonusQuantaBankResidual
                      ? data.totalTypeBonusQuantaBankResidual
                      : 0
                  )}
                </span>
              </div>
            </div>
          </ListGroupItem>
          <ListGroupItem
            className="list-group-item-action"
            tag="a"
            href="#javascript"
          >
            <div className="content">
              <div className="name">
                <span>Bônus Personal Banker</span>
              </div>
              <div className="amount">
                <span>
                  {formatToCurrency(
                    data && data.totalTypeBonusPersonalBanker
                      ? data.totalTypeBonusPersonalBanker
                      : 0
                  )}
                </span>
              </div>
            </div>
          </ListGroupItem>
          <ListGroupItem
            className="list-group-item-action"
            tag="a"
            href="#javascript"
          >
            <div className="content">
              <div className="name">
                <span>Bônus Downline Qualificado</span>
              </div>
              <div className="amount">
                <span>
                  {formatToCurrency(
                    data && data.totalTypeBonusDownlineQualificado
                      ? data.totalTypeBonusDownlineQualificado
                      : 0
                  )}
                </span>
              </div>
            </div>
          </ListGroupItem>


          <ListGroupItem
            className="list-group-item-action"
            tag="a"
            href="#javascript"
          >
            <div className="content">
              <div className="name">
                <span>Bônus Presidencial</span>
              </div>
              <div className="amount">
                <span>
                  {formatToCurrency(
                    data && data.totalTypeBonusPresidential
                      ? data.totalTypeBonusPresidential
                      : 0
                  )}
                </span>
              </div>
            </div>
          </ListGroupItem>

          
          <ListGroupItem
            className="list-group-item-action"
            tag="a"
            href="#javascript"
          >
            <div className="content">
              <div className="name">
                <span>Bônus Plano de Carreira e Prêmios</span>
              </div>
              <div className="amount">
                <span>
                  {formatToCurrency(
                    data && data.totalTypeBonusCareerAwards
                      ? data.totalTypeBonusCareerAwards
                      : 0
                  )}
                </span>
              </div>
            </div>
          </ListGroupItem> */}

        </ListGroup>
      </div>
    </Card>
  </div>
)

export default memo(Movements)
