export const AUTH_KEYS = {
  TOKEN: "_qnt_tk",
  STATE: "_qnt_stt",
};

export const isAuthenticated = () =>
  localStorage.getItem(AUTH_KEYS.TOKEN) !== null;

export const getToken = () => localStorage.getItem(AUTH_KEYS.TOKEN);

export const token = (token) =>
  localStorage.setItem(AUTH_KEYS.TOKEN, `${token}`);

export const logout = () => localStorage.clear();
