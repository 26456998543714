export const Types = {
  GET_PROFILES_REQUEST: "profile/GET_PROFILES_REQUEST",
  GET_PROFILES_SUCCESS: "profile/GET_PROFILES_SUCCESS",
  GET_PROFILES_FAILURE: "profile/GET_PROFILES_FAILURE",

  GET_PROFILE_REQUEST: "profile/GET_PROFILE_REQUEST",
  GET_PROFILE_SUCCESS: "profile/GET_PROFILE_SUCCESS",
  GET_PROFILE_FAILURE: "profile/GET_PROFILE_FAILURE",

  CREATE_PROFILE_REQUEST: "profile/CREATE_PROFILE_REQUEST",
  CREATE_PROFILE_SUCCESS: "profile/CREATE_PROFILE_SUCCESS",
  CREATE_PROFILE_FAILURE: "profile/CREATE_PROFILE_FAILURE",

  UPDATE_PROFILE_REQUEST: "profile/UPDATE_PROFILE_REQUEST",
  UPDATE_PROFILE_SUCCESS: "profile/UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_FAILURE: "profile/UPDATE_PROFILE_FAILURE",

  DELETE_PROFILE_REQUEST: "profile/DELETE_PROFILE_REQUEST",
  DELETE_PROFILE_SUCCESS: "profile/DELETE_PROFILE_SUCCESS",
  DELETE_PROFILE_FAILURE: "profile/DELETE_PROFILE_FAILURE",
};

const INITIAL_STATE = {
  list: [],
  profile: {},
  loading: false,
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_PROFILES_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.GET_PROFILES_SUCCESS:
      return { ...state, loading: false, list: action.payload, error: null };

    case Types.GET_PROFILES_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.GET_PROFILE_REQUEST:
      return { ...state, loading: true, profile: {}, error: null };

    case Types.GET_PROFILE_SUCCESS:
      return { ...state, loading: false, profile: action.payload, error: null };

    case Types.GET_PROFILE_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.CREATE_PROFILE_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.CREATE_PROFILE_SUCCESS:
      return { ...state, loading: false, error: null };

    case Types.CREATE_PROFILE_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.UPDATE_PROFILE_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.UPDATE_PROFILE_SUCCESS:
      return { ...state, loading: false, profile: {}, error: null };

    case Types.UPDATE_PROFILE_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.DELETE_PROFILE_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.DELETE_PROFILE_SUCCESS:
      return { ...state, loading: false, error: null };

    case Types.DELETE_PROFILE_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export const Creators = {
  getProfilesRequest: (payload) => ({
    type: Types.GET_PROFILES_REQUEST,
    payload,
  }),

  getProfilesSuccess: (payload) => ({
    type: Types.GET_PROFILES_SUCCESS,
    payload,
  }),

  getProfilesFailure: (payload) => ({
    type: Types.GET_PROFILES_FAILURE,
    payload,
  }),

  getProfileRequest: (payload) => ({
    type: Types.GET_PROFILE_REQUEST,
    payload,
  }),

  getProfileSuccess: (payload) => ({
    type: Types.GET_PROFILE_SUCCESS,
    payload,
  }),

  getProfileFailure: (payload) => ({
    type: Types.GET_PROFILE_FAILURE,
    payload,
  }),

  createProfileRequest: (payload) => ({
    type: Types.CREATE_PROFILE_REQUEST,
    payload,
  }),

  createProfileSuccess: (payload) => ({
    type: Types.CREATE_PROFILE_SUCCESS,
    payload,
  }),

  createProfileFailure: (payload) => ({
    type: Types.CREATE_PROFILE_FAILURE,
    payload,
  }),

  updateProfileRequest: (payload) => ({
    type: Types.UPDATE_PROFILE_REQUEST,
    payload,
  }),

  updateProfileSuccess: (payload) => ({
    type: Types.UPDATE_PROFILE_SUCCESS,
    payload,
  }),

  updateProfileFailure: (payload) => ({
    type: Types.UPDATE_PROFILE_FAILURE,
    payload,
  }),

  deleteProfileRequest: (payload) => ({
    type: Types.DELETE_PROFILE_REQUEST,
    payload,
  }),

  deleteProfileSuccess: (payload) => ({
    type: Types.DELETE_PROFILE_SUCCESS,
    payload,
  }),

  deleteProfileFailure: (payload) => ({
    type: Types.DELETE_PROFILE_FAILURE,
    payload,
  }),
};
