import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import DefaultPage from "components/DefaultPage";

import { Creators as PositionsActions } from "store/ducks/positions";

import CreatePositionForm from "components/Positions/CreatePositionForm";

const CreatePosition = () => {
  const dispatch = useDispatch();
  const { list } = useSelector((state) => state.positions);

  const handleCreatePosition = useCallback(
    (item) => {
      dispatch(PositionsActions.createPositionRequest(item));
    },
    [dispatch]
  );

  return (
    <DefaultPage
      pageTitle="Posições"
      pageDescription="Criar posição"
      pageSubDescription="Criar uma nova posição"
    >
      <CreatePositionForm
        submitForm={handleCreatePosition}
        positionsList={list}
      />
    </DefaultPage>
  );
};

export default CreatePosition;
