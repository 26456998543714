import React from 'react'
import { Form, Label, Input, Row, Col, Button } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

const CreateForm = ({ submitForm, handleActive }) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()

  const { ref: nameRef, ...name } = register('name', { required: true })

  const { ref: descriptionRef, ...description } = register('description', {
    required: true
  })

  const { ref: fileRef, ...file } = register('file', {
    required: true
  })

  return (
    <Form
      className="needs-validation"
      noValidate=""
      onSubmit={handleSubmit(submitForm)}
    >
      <div className="form-row" style={{ marginTop: '-30px' }}>
        <Col
          md="6"
          style={{ display: 'flex', flexDirection: 'column', gap: '1rem 0' }}
        >
          <Row style={{ marginTop: '10px' }}>
            <Col>
              <Label htmlFor="validationCustom01">Nome</Label>
              <Input
                className="form-control"
                name="name"
                type="text"
                placeholder="Nome"
                innerRef={nameRef}
                {...name}
              />

              <small style={{ color: 'red' }}>
                {errors.question && 'Nome é obrigatório'}
              </small>
            </Col>
          </Row>
          <Row>
            <Col>
              <Label htmlFor="validationCustom01">Status</Label>
              <Input
                type="select"
                name="installments"
                onChange={(e) => handleActive(e.target.value)}
              >
                <option value="false">Inativo</option>
                <option value="true">Ativo</option>
              </Input>
              <small style={{ color: 'red' }}>
                {errors.status && 'Selecione um status'}
              </small>
            </Col>
          </Row>
        </Col>
        <Col
          md="6"
          style={{ display: 'flex', flexDirection: 'column', gap: '1rem 0' }}
        >
          <Row style={{ marginTop: '10px' }}>
            <Col>
              <Label htmlFor="validationCustom02">Descrição</Label>
              <Input
                className="form-control"
                name="name"
                type="text"
                placeholder="Descrição"
                innerRef={descriptionRef}
                {...description}
              />
              <small style={{ color: 'red' }}>
                {errors.answer && 'Descrição é obrigatório'}
              </small>
            </Col>
          </Row>
          <Row>
            <Col>
              <Label htmlFor="validationCustom02">Arquivo</Label>
              <Input
                className="form-control"
                name="name"
                type="file"
                placeholder="Arquivo"
                innerRef={fileRef}
                {...file}
              />
              <small style={{ color: 'red' }}>
                {errors.answer && 'Arquivo é obrigatório'}
              </small>
            </Col>
          </Row>
        </Col>
      </div>
      <div style={{ display: 'flex', gap: '0 1rem', marginTop: '20px' }}>
        <Button color="primary" type="submit">
          Salvar
        </Button>
        <Link to="/materiais-apoio/itens">
          <Button color="default" style={{ border: 'none' }}>
            Cancelar
          </Button>
        </Link>
      </div>
    </Form>
  )
}

export default CreateForm
