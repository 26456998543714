import React, { useState, useCallback } from 'react'
import { Form, Label, Col, Input, Row } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { debounce } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'

import FormFooterActions from 'components/FormFooterActions'
import { Creators as UserActions } from 'store/ducks/users'
import { useEffect } from 'react'

const CreateForm = ({ submitForm }) => {
  const dispatch = useDispatch()
  const { userByEmail } = useSelector((state) => state.users)
  const [typeNotification, setTypeNotification] = useState(null)
  const [typedEmail, setTypedEmail] = useState('')
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm()

  const { ref: typeRef, ...type } = register('type', {
    required: true
  })

  const { ref: emailReceiverRef, ...emailReceiver } = register('emailReceiver')

  const notificationTypes = [
    { value: 'alert_home', description: 'Alerta Home' },
    { value: 'email', description: 'Enviar por e-mail' },
    { value: 'notification_bar', description: 'Barra de notificação' }
  ]

  const handleFindUserByEmail = useCallback(
    (email) => {
      setTypedEmail(email)
      dispatch(UserActions.getUserByEmailRequest(email))
    },
    [dispatch]
  )

  const loadUsers = debounce(handleFindUserByEmail, 500)

  useEffect(() => {
    if (Object.keys(userByEmail).length === 0 && typedEmail !== '') {
      setError('emailReceiver', { message: 'Usuário não encontrado' })
    } else {
      clearErrors('emailReceiver')
    }
  }, [clearErrors, setError, typedEmail, userByEmail])

  return (
    <Form className="needs-validation" onSubmit={handleSubmit(submitForm)}>
      <div className="form-row">
        <Col sm="12" md="6" style={{ marginTop: '1rem' }}>
          <Row>
            <Col>
              <Label htmlFor="validationCustom01">Tipo</Label>
              <Input
                type="select"
                name="typeNotification"
                innerRef={typeRef}
                {...type}
                onChange={(e) => setTypeNotification(e.target.value)}
              >
                <option value="">Selecione o tipo de notificação</option>
                {notificationTypes.map((notification, index) => (
                  <option value={notification.value} key={index}>
                    {notification.description}
                  </option>
                ))}
              </Input>
              <small style={{ color: 'red' }}>
                {errors.typeNotification && 'Selecione um tipo'}
              </small>
            </Col>
          </Row>

          {typeNotification === 'email' && (
            <Row style={{ marginTop: '1rem' }}>
              <Col>
                <Label htmlFor="validationCustom01">E-mail</Label>
                <Input
                  className="form-control"
                  name="name"
                  type="text"
                  placeholder="E-mail"
                  innerRef={emailReceiverRef}
                  {...emailReceiver}
                  onChange={(e) => loadUsers(e.target.value)}
                />
                <small style={{ color: 'red' }}>
                  {errors.emailReceiver && errors.emailReceiver.message}
                </small>
              </Col>
            </Row>
          )}
        </Col>

        <Col sm="12" md="6" style={{ marginTop: '1rem' }}>
          <Label htmlFor="validationCustom02">Mensagem</Label>
          <textarea
            {...register('description', {
              required: true
            })}
            className="form-control"
            style={{ resize: 'none' }}
            rows={6}
          />

          <small style={{ color: 'red' }}>
            {errors.description && 'Descrição é obrigatória'}
          </small>
        </Col>
      </div>
      <FormFooterActions backPath="/notificacoes" />
    </Form>
  )
}

export default CreateForm
