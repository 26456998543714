import { toast } from 'react-toastify'
import { put, call } from 'redux-saga/effects'
import { api } from 'services/api'
import { push } from 'connected-react-router'
import { Creators as MoneyActions } from 'store/ducks/money'

export function* createMoney(action) {
  const messages = {
    success: action.payload.successMessage,
    error: action.payload.errorMessage
  }

  delete action.payload.successMessage
  delete action.payload.errorMessage

  const showToastr =
    action.payload.showToastr === false ? action.payload.showToastr : true

  delete action.payload.showToastr

  try {
    const { data } = yield call(api.post, '/moneys', action.payload)
    yield put(MoneyActions.createMoneySuccess(data))
    showToastr && toast.success(`${messages.success}`)
    if (action.payload.redirectPath)
      yield put(push(action.payload.redirectPath))

  } catch (err) {
    showToastr && toast.error(`${messages.errorMessage}`)
    yield put(MoneyActions.getMoneysFailure(err.message))
  }
}

export function* updateMoney(action) {
  try {
    const { data } = yield call(
      api.put,
      `/moneys/${action.payload.id}`,
      action.payload
    )
    yield put(MoneyActions.updateMoneySuccess(data))
    toast.success('Saque editado com sucesso!')
  } catch (err) {
    toast.error('Erro ao editar saque')
    yield put(MoneyActions.updateMoneyFailure(err))
  }
}
export function* getMoneys(action) {
  const search = action.payload.search ? `&search=${action.payload.search}` : ''
  console.log(action)
  try {

    const { data } = yield call(api.get, `/moneys/?page=${action.payload.page}&limit=${action.payload.limit}${search}`)
    yield put(MoneyActions.getMoneysSuccess(data))
  } catch (err) {
    yield put(MoneyActions.getMoneysFailure(err.message))
  }
}

export function* getMoneyByID(action) {
  try {
    const { data } = yield call(api.get, `/moneys/${action.payload}`)
    yield put(MoneyActions.getMoneySuccess(data))
  } catch (err) {
    yield put(MoneyActions.getMoneyFailure(err))
  }
}

export function* getMoneyByUser(action) {
  try {
    const { data } = yield call(api.get, `/moneys/user/${action.payload}`)
    yield put(MoneyActions.getMoneyByUserSuccess(data))
  } catch (err) {
    yield put(MoneyActions.getMoneyByUserFailure(err))
  }
}

export function* getMoneyByUserCashback(action) {
  try {
    const { data } = yield call(
      api.get,
      `/moneys/user/cashback/${action.payload}`
    )
    yield put(MoneyActions.getMoneyByUserCashbackSuccess(data))
  } catch (err) {
    yield put(MoneyActions.getMoneyByUserCashbackFailure(err))
  }
}

export function* getMoneyByUserBonusAccredited(action) {
  try {
    const { data } = yield call(
      api.get,
      `/moneys/user/bonusaccredited/${action.payload}`
    )
    yield put(MoneyActions.getMoneyByUserBonusAccreditedSuccess(data))
  } catch (err) {
    yield put(MoneyActions.getMoneyByUserBonusAccreditedFailure(err))
  }
}
export function* getMoneyByUserBonusAccession(action) {
  try {
    const { data } = yield call(
      api.get,
      `/moneys/user/bonusaccession/${action.payload}`
    )
    yield put(MoneyActions.getMoneyByUserBonusAccessionSuccess(data))
  } catch (err) {
    yield put(MoneyActions.getMoneyByUserBonusAccessionFailure(err))
  }
}


export function* getMoneyByUserPlanInstallments(action) {
  try {
    const { data } = yield call(
      api.get,
      `/moneys/type/plan/${action.payload}`
    )
    console.log(data)
    yield put(MoneyActions.getMoneyByUserPlanInstallmentsSuccess(data))
  } catch (err) {
    yield put(MoneyActions.getMoneyByUserPlanInstallmentsFailure(err))
  }
}
export function* getMoneyAdminDash() {
  try {
    const { data } = yield call(api.get, `/moneys/admin/one`)
    yield put(MoneyActions.getMoneyAdminDashSuccess(data))
  } catch (err) {
    yield put(MoneyActions.getMoneyAdminDashFailure(err))
  }
}

export function* getMoneyAdminDashDetail() {
  try {
    const { data } = yield call(api.get, `/moneys/admin/all`)
    yield put(MoneyActions.getMoneyAdminDashDetailSuccess(data))
  } catch (err) {
    yield put(MoneyActions.getMoneyAdminDashDetailFailure(err))
  }
}

export function* getMoneyUserByTypeSummary(action) {
  try {
    const { data } = yield call(api.get, `/moneys/types/${action.payload}`)
    yield put(MoneyActions.getMoneyUserByTypeSummarySuccess(data))
  } catch (err) {
    yield put(MoneyActions.getMoneyUserByTypeSummaryFailure(err))
  }
}
export function* getMoneySalesByAccredited(action) {
  try {
    const { data } = yield call(
      api.get,
      `/moneys/sales/accredited/${action.payload}`
    )
    yield put(MoneyActions.getMoneySalesByAccreditedSuccess(data))
  } catch (err) {
    yield put(MoneyActions.getMoneySalesByAccreditedFailure(err))
  }
}
