export const Types = {
  GET_GRADUATIONS_REQUEST: "graduations/GET_GRADUATIONS_REQUEST",
  GET_GRADUATIONS_SUCCESS: "graduations/GET_GRADUATIONS_SUCCESS",
  GET_GRADUATIONS_FAILURE: "graduations/GET_GRADUATIONS_FAILURE",

  GET_GRADUATION_REQUEST: "graduations/GET_GRADUATION_REQUEST",
  GET_GRADUATION_SUCCESS: "graduations/GET_GRADUATION_SUCCESS",
  GET_GRADUATION_FAILURE: "graduations/GET_GRADUATION_FAILURE",

  CREATE_GRADUATION_REQUEST: "graduations/CREATE_GRADUATION_REQUEST",
  CREATE_GRADUATION_SUCCESS: "graduations/CREATE_GRADUATION_SUCCESS",
  CREATE_GRADUATION_FAILURE: "graduations/CREATE_GRADUATION_FAILURE",

  UPDATE_GRADUATION_REQUEST: "graduations/UPDATE_GRADUATION_REQUEST",
  UPDATE_GRADUATION_SUCCESS: "graduations/UPDATE_GRADUATION_SUCCESS",
  UPDATE_GRADUATION_FAILURE: "graduations/UPDATE_GRADUATION_FAILURE",

  DELETE_GRADUATION_REQUEST: "graduations/DELETE_GRADUATION_REQUEST",
  DELETE_GRADUATION_SUCCESS: "graduations/DELETE_GRADUATION_SUCCESS",
  DELETE_GRADUATION_FAILURE: "graduations/DELETE_GRADUATION_FAILURE",
};

const INITIAL_STATE = {
  list: [],
  graduation: {},
  loading: false,
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_GRADUATIONS_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.GET_GRADUATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
        error: null,
      };

    case Types.GET_GRADUATIONS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.GET_GRADUATION_REQUEST:
      return { ...state, loading: true, graduation: {}, error: null };

    case Types.GET_GRADUATION_SUCCESS:
      return {
        ...state,
        loading: false,
        graduation: action.payload,
        error: null,
      };

    case Types.GET_GRADUATION_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.CREATE_GRADUATION_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.CREATE_GRADUATION_SUCCESS:
      return { ...state, loading: false, error: null };

    case Types.CREATE_GRADUATION_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.UPDATE_GRADUATION_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.UPDATE_GRADUATION_SUCCESS:
      return { ...state, loading: false, level: {}, error: null };

    case Types.UPDATE_GRADUATION_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.DELETE_GRADUATION_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.DELETE_GRADUATION_SUCCESS:
      return { ...state, loading: false, error: null };

    case Types.DELETE_GRADUATION_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export const Creators = {
  getGraduationsRequest: (payload) => ({
    type: Types.GET_GRADUATIONS_REQUEST,
    payload,
  }),

  getGraduationsSuccess: (payload) => ({
    type: Types.GET_GRADUATIONS_SUCCESS,
    payload,
  }),

  getGraduationsFailure: (payload) => ({
    type: Types.GET_GRADUATIONS_FAILURE,
    payload,
  }),

  getGraduationRequest: (payload) => ({
    type: Types.GET_GRADUATION_REQUEST,
    payload,
  }),

  getGraduationSuccess: (payload) => ({
    type: Types.GET_GRADUATION_SUCCESS,
    payload,
  }),

  getGraduationFailure: (payload) => ({
    type: Types.GET_GRADUATION_FAILURE,
    payload,
  }),

  createGraduationRequest: (payload) => ({
    type: Types.CREATE_GRADUATION_REQUEST,
    payload,
  }),

  createGraduationSuccess: (payload) => ({
    type: Types.CREATE_GRADUATION_SUCCESS,
    payload,
  }),

  createGraduationFailure: (payload) => ({
    type: Types.CREATE_GRADUATION_FAILURE,
    payload,
  }),

  updateGraduationRequest: (payload) => ({
    type: Types.UPDATE_GRADUATION_REQUEST,
    payload,
  }),

  updateGraduationSuccess: (payload) => ({
    type: Types.UPDATE_GRADUATION_SUCCESS,
    payload,
  }),

  updateGraduationFailure: (payload) => ({
    type: Types.UPDATE_GRADUATION_FAILURE,
    payload,
  }),

  deleteGraduationRequest: (payload) => ({
    type: Types.DELETE_GRADUATION_REQUEST,
    payload,
  }),

  deleteGraduationSuccess: (payload) => ({
    type: Types.DELETE_GRADUATION_SUCCESS,
    payload,
  }),

  deleteGraduationFailure: (payload) => ({
    type: Types.DELETE_GRADUATION_FAILURE,
    payload,
  }),
};
