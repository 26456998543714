import { put, call } from 'redux-saga/effects'
import { api } from 'services/api'

import { toast } from 'react-toastify'

import { Creators as CheckoutActions } from 'store/ducks/checkout'
import { Creators as MoneyActions } from 'store/ducks/money'

export function* createTransaction(action) {
  try {
    const { transaction, money } = action.payload
    transaction.order_id = money.orderId
    if (transaction.payment_method)
      money.paymentMethod = transaction.payment_method
    const { data } = yield call(api.post, '/transactions', transaction)
    yield put(CheckoutActions.generateTransactionSuccess(data))
    yield put(
      MoneyActions.createMoneyRequest({
        ...money,
        status: data.status,
        nsu: data.nsu,

        showToastr: false
      })
    )
  } catch (err) {
    toast.error('Erro ao criar transação')
    yield put(CheckoutActions.generateTransactionFailure(err.message))
  }
}
