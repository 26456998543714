import { Form, Label, Input, Col, Button } from "reactstrap";
import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { Download } from 'react-feather'
import { saveAs } from 'file-saver'

import Select from "react-select";

const EditForm = ({ submitForm, currentValue }) => {

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,

  } = useForm({
    defaultValues: {
      description: currentValue.description,
      status: currentValue.status
    },
  });


  const { ref: descriptionRef, ...description } = register("description", {
    required: true,
  });
  const { ref: comentarioRef, ...comentario } = register("comentario");

  const [optionsSelectStatus, setOptionsSelectStatus] = useState([]);
  useEffect(() => {
    let options = [];
    options.push({
      value: 'processing',
      label: 'Em Tratamento',
    });
    options.push({
      value: 'created',
      label: 'Criado',
    });
    options.push({
      value: 'done',
      label: 'Concluído',
    });
    options.push({
      value: 'waitingreturn',
      label: 'Aguardando Retorno',
    });
    setOptionsSelectStatus(options);
  }, []);
  const defaultValueSelectStatus =
    optionsSelectStatus[
    optionsSelectStatus.findIndex(
      (oss) => oss.value === currentValue?.status
    )
    ];
  let comentarios = [];

  for (const key in currentValue.comments) {
    let commentario = currentValue.comments[key]
    comentarios.push
      (
        <>

          <br /><br />
          <div className="form-row">
            <Col md="4 mb-6">
              Comentário relalizado por: {commentario?.user?.name} <br />
              Em {new Date(commentario?.createdAt).toLocaleDateString()}
              <br />
            </Col>
            <Col md="6 mb-6">
              <Label htmlFor="validationCustom02">Descrição</Label>
              <Input
                className="form-control"
                name="description"
                type="textarea"
                placeholder="Descrição"

                defaultValue={commentario.description}
                size='255'
              />
              <span>{errors.description && "Descrição é obrigatório"}</span>
            </Col>
          </div>
        </>
      )
  }


  return (
    <Form
      className="needs-validation"
      noValidate=""
      onSubmit={handleSubmit(submitForm)}
    >


      {currentValue.fileUrl && (
        <Col md="6 mb-6">

          <Label htmlFor="validationCustom02">Anexo:</Label>
          <br />
          {currentValue.fileUrl}
          <Download
            style={{ cursor: 'pointer' }}
            onClick={() => saveAs(currentValue.fileUrl)}
          />
        </Col>


      )}
      <br />
      <div className="form-row">

        <Col md="6 mb-6">
          <Label htmlFor="validationCustom02">Descrição</Label>
          <Input
            className="form-control"
            name="description"
            type="textarea"
            placeholder="Descrição"
            innerRef={descriptionRef}
            {...description}
            defaultValue={currentValue.description}
            size='255'
          />
          <span>{errors.description && "Descrição é obrigatório"}</span>
        </Col>
        {!!defaultValueSelectStatus && (
          <Col md="4 mb-3">
            <h5>Status</h5>
            <Select
              options={optionsSelectStatus}
              onChange={(e) => {
                !!e && setValue("status", e.value);
              }}
              defaultValue={defaultValueSelectStatus}

            />
          </Col>
        )}
        <Col md="6 mb-6">
          <Label htmlFor="validationCustom02">Adicionar Comentário</Label>
          <Input
            className="form-control"
            name="description"
            type="textarea"
            placeholder="Descrição"
            innerRef={comentarioRef}
            {...comentario}
            //defaultValue={currentValue.comments}
            size='255'
          />
          <span>{errors.description && "Descrição é obrigatório"}</span>
        </Col>
      </div>


      {comentarios}
      <div className="form-row row-gap">
        <Button color="primary">Enviar</Button>
      </div>
    </Form>
  );
};

export default EditForm;
