import React from 'react'

import { Card, CardHeader, CardBody, Table } from 'reactstrap'
import { isEmpty } from 'lodash'

import { formatToCurrency } from 'utils'

const MyNetwork = ({ title, list }) => {
  return (
    <Card>
      <CardHeader>
        <h5>{title}</h5>
      </CardHeader>
      <CardBody>
        {!isEmpty(list) ? (
          <div className="user-status table-responsive">
            <Table borderless>
              <thead>
                <tr>
                  <th scope="col">Nome</th>
                  <th scope="col">Cashback Gerado</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                {list.map((item) => (
                  <tr key={item.id}>
                    <td className="bd-t-none u-s-tb">
                      <div className="align-middle image-sm-size">
                        <img
                          className="img-radius align-top m-r-15 rounded-circle"
                          src={
                            item.icon && item.icon.indexOf("https://onneroyal.blob.core.windows.net/imagens/user-default.svg") === -1
                            ? item.icon
                              : require('assets/images/user/user.png')
                          }
                          alt=""
                        />
                        <div className="d-inline-block">
                          <h6>
                            {item.name}{' '}
                            {/* <span className="text-muted digits">
                            ({item.isActive})
                          </span> */}
                          </h6>
                        </div>
                      </div>
                    </td>
                    <td>{formatToCurrency(item.generatedCashbackAmount)}</td>
                    <td>
                      <>
                        <i className="fa fa-circle font-success f-12" />{' '}
                        <small style={{ marginLeft: '5px' }}>Ativo</small>
                      </>
                    </td>
                    {/* <td className="digits">{item.exp}</td> */}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ) : (
          'Você não possui usuários na rede'
        )}
      </CardBody>
    </Card>
  )
}

export default MyNetwork
