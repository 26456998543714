export const Types = {
  GET_BANK_ACCOUNT_REQUEST: 'bankAccounts/GET_BANK_ACCOUNT_REQUEST',
  GET_BANK_ACCOUNT_SUCCESS: 'bankAccounts/GET_BANK_ACCOUNT_SUCCESS',
  GET_BANK_ACCOUNT_FAILURE: 'bankAccounts/GET_BANK_ACCOUNT_FAILURE',

  CREATE_BANK_ACCOUNT_REQUEST: 'bankAccounts/CREATE_BANK_ACCOUNT_REQUEST',
  CREATE_BANK_ACCOUNT_SUCCESS: 'bankAccounts/CREATE_BANK_ACCOUNT_SUCCESS',
  CREATE_BANK_ACCOUNT_FAILURE: 'bankAccounts/CREATE_BANK_ACCOUNT_FAILURE',

  UPDATE_BANK_ACCOUNT_REQUEST: 'bankAccounts/UPDATE_BANK_ACCOUNT_REQUEST',
  UPDATE_BANK_ACCOUNT_SUCCESS: 'bankAccounts/UPDATE_BANK_ACCOUNT_SUCCESS',
  UPDATE_BANK_ACCOUNT_FAILURE: 'bankAccounts/UPDATE_BANK_ACCOUNT_FAILURE',

  DELETE_BANK_ACCOUNT_REQUEST: 'bankAccounts/DELETE_BANK_ACCOUNT_REQUEST',
  DELETE_BANK_ACCOUNT_SUCCESS: 'bankAccounts/DELETE_BANK_ACCOUNT_SUCCESS',
  DELETE_BANK_ACCOUNT_FAILURE: 'bankAccounts/DELETE_BANK_ACCOUNT_FAILURE'
}

const INITIAL_STATE = {
  list: [],
  bankAccount: {},
  loading: false,
  error: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_BANK_ACCOUNT_REQUEST:
      return { ...state, loading: true, list: [], error: null }

    case Types.GET_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
        error: null
      }

    case Types.GET_BANK_ACCOUNT_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case Types.CREATE_BANK_ACCOUNT_REQUEST:
      return { ...state, loading: true, error: null }

    case Types.CREATE_BANK_ACCOUNT_SUCCESS:
      return { ...state, loading: false, error: null }

    case Types.CREATE_BANK_ACCOUNT_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case Types.UPDATE_BANK_ACCOUNT_REQUEST:
      return { ...state, loading: true, error: null }

    case Types.UPDATE_BANK_ACCOUNT_SUCCESS:
      return { ...state, loading: false, bankAccount: {}, error: null }

    case Types.UPDATE_BANK_ACCOUNT_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case Types.DELETE_BANK_ACCOUNT_REQUEST:
      return { ...state, loading: true, error: null }

    case Types.DELETE_BANK_ACCOUNT_SUCCESS:
      return { ...state, loading: false, error: null }

    case Types.DELETE_BANK_ACCOUNT_FAILURE:
      return { ...state, loading: false, error: action.payload }

    default:
      return state
  }
}

export const Creators = {
  getBankAccountRequest: (payload) => ({
    type: Types.GET_BANK_ACCOUNT_REQUEST,
    payload
  }),

  getBankAccountSuccess: (payload) => ({
    type: Types.GET_BANK_ACCOUNT_SUCCESS,
    payload
  }),

  getBankAccountFailure: (payload) => ({
    type: Types.GET_BANK_ACCOUNT_FAILURE,
    payload
  }),

  createBankAccountRequest: (payload) => ({
    type: Types.CREATE_BANK_ACCOUNT_REQUEST,
    payload
  }),

  createBankAccountSuccess: (payload) => ({
    type: Types.CREATE_BANK_ACCOUNT_SUCCESS,
    payload
  }),

  createBankAccountFailure: (payload) => ({
    type: Types.CREATE_BANK_ACCOUNT_FAILURE,
    payload
  }),

  updateBankAccountRequest: (payload) => ({
    type: Types.UPDATE_BANK_ACCOUNT_REQUEST,
    payload
  }),

  updateBankAccountSuccess: (payload) => ({
    type: Types.UPDATE_BANK_ACCOUNT_SUCCESS,
    payload
  }),

  updateBankAccountFailure: (payload) => ({
    type: Types.UPDATE_BANK_ACCOUNT_FAILURE,
    payload
  }),

  deleteBankAccountRequest: (payload) => ({
    type: Types.DELETE_BANK_ACCOUNT_REQUEST,
    payload
  }),

  deleteBankAccountSuccess: (payload) => ({
    type: Types.DELETE_BANK_ACCOUNT_SUCCESS,
    payload
  }),

  deleteBankAccountFailure: (payload) => ({
    type: Types.DELETE_BANK_ACCOUNT_FAILURE,
    payload
  })
}
