import { useDispatch, useSelector } from 'react-redux'

import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signOut
} from 'firebase/auth'

import { Creators as AuthActions } from 'store/ducks/auth'
import { toast } from 'react-toastify'

export default () => {
  const facebook = new FacebookAuthProvider()
  const google = new GoogleAuthProvider()

  const dispatch = useDispatch()
  const authentication = useSelector((state) => state.auth)

  const auth = getAuth()

  const getProvider = (type) => {
    const Providers = {
      facebook: facebook,
      google: google,
      default: null
    }

    return Providers[type] || Providers.default
  }

  const handleSocialLogin = async (provider) => {
    try {
      const {
        _tokenResponse: { email, photoUrl }
      } = await signInWithPopup(auth, getProvider(provider))

      dispatch(
        AuthActions.loginRequest({
          email,
          social: true,
          icon: photoUrl
        })
      )
    } catch (error) {
      toast.error('Erro ao tentar efetuar o login')
    }
  }

  const handleSocialLogout = async () => await signOut(auth)

  return {
    handleSocialLogin,
    handleSocialLogout,
    loading: authentication?.loading ? authentication?.loading : null
  }
}
