import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DataTables from "components/Datatable";
import TableActions from "components/TableActions";
import ModalDelete from "components/ModalDelete";

import invoice, { Creators as InvoiceActions } from "store/ducks/invoice";
import { invoiceDataTableColumns } from "shared/constants/datatable";

import DefaultListPage from "components/DefaultPage";

const Invoices = () => {
  const dispatch = useDispatch()
  const {
    auth: { auth },
    invoice: { invoiceByUser, loading }
  } = useSelector((state) => state)
  const { list } = useSelector((state) => state.invoice);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [currentPage, setCurrentPage] = useState(0);


  const handleDeleteItem = useCallback(
    (item) => {
      dispatch(InvoiceActions.deleteInvoiceRequest(item));
      setModalOpen(false);
    },
    [dispatch]
  );

  const handleOpenModal = (value) => {
    setModalOpen(true);
    setModalData({
      title: "Excluir fatura",
      content: `Você tem certeza em excluir o fatura "${value.name}"?`,
      item: value,
    });
  };

  const handleChangeRowsPerPage = useCallback(
    (currentPage, size) => {
      dispatch(InvoiceActions.getInvoiceByUserRequest({ id: auth.id, page: currentPage, limit: size }))
    },
    [auth.id, dispatch]
  )

  const handleChangePage = useCallback(
    (page) => {
      setCurrentPage(page)
      dispatch(InvoiceActions.getInvoiceByUserRequest({ id: auth.id, page: page, limit: 10 }))
    },
    [auth.id, dispatch]
  )
  useEffect(() => {
    dispatch(InvoiceActions.getInvoiceByUserRequest({ id: auth.id, page: 0, limit: 10 }));
  }, [auth.id, dispatch]);

  return (
    <DefaultListPage
      pageTitle="Faturas"
      pageDescription="Gerenciar faturas"
      pageSubDescription="Adicione, remove, edite alguns dos itens abaixo"
    >
      <TableActions linkToCreate={"/invoice/create"} />

      <DataTables
        list={invoiceByUser[0]}
        count={invoiceByUser[1]}
        editURL="/invoice/update"
        customDataTableColumns={invoiceDataTableColumns}
        handleOpenModal={handleOpenModal}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        hasPagination
        loading={loading}
        currentPage={currentPage}
      />
      <ModalDelete
        isOpen={modalOpen}
        modalToggle={() => setModalOpen(false)}
        title={modalData.title}
        content={modalData.content}
        deleteItem={handleDeleteItem}
        item={modalData.item}
      />
    </DefaultListPage>
  );
};

export default Invoices;
