import PaymentMethods from "pages/PaymentMethods";
import CreatePaymentMethod from "pages/PaymentMethods/Create";
import EditPaymentMethod from "pages/PaymentMethods/Edit";

import { BASE_URL } from "shared/constants/config";

export default [
  { path: `${BASE_URL}/pagamentos/metodos`, component: PaymentMethods },
  {
    path: `${BASE_URL}/pagamentos/metodos/criar`,
    component: CreatePaymentMethod,
  },
  {
    path: `${BASE_URL}/pagamentos/metodos/editar/:id`,
    component: EditPaymentMethod,
  },
];
