import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import DataTables from 'components/Datatable'
import TableActions from 'components/TableActions'
import ModalDelete from 'components/ModalDelete'

import { Creators as UserActions } from 'store/ducks/users'

import { usersDataTableColumns } from 'shared/constants/datatable'

import DefaultListPage from 'components/DefaultPage'

const Users = () => {
  const dispatch = useDispatch()
  const { list, usersByName, loading } = useSelector((state) => state.users)

  const [modalOpen, setModalOpen] = useState(false)
  const [modalData, setModalData] = useState({})
  const [currentPage, setCurrentPage] = useState(0)

  const handleDeleteItem = useCallback(
    (item) => {
      dispatch(UserActions.deleteUserRequest(item))
      setModalOpen(false)
    },
    [dispatch]
  )

  const handleOpenModal = (value) => {
    setModalOpen(true)
    setModalData({
      title: 'Excluir usuário',
      content: `Você tem certeza em excluir o usuário "${value.name}"?`,
      item: value
    })
  }

  const handleChangeRowsPerPage = useCallback(
    (currentPage, size) => {
      dispatch(UserActions.getUsersRequest({ page: currentPage, limit: size }))
    },
    [dispatch]
  )

  const handleChangePage = useCallback(
    (page) => {
      setCurrentPage(page)
      dispatch(UserActions.getUsersRequest({ page: page, limit: 10 }))
    },
    [dispatch]
  )

  const handleSearch = useCallback(
    (value) => {
      if (value !== '') {
        dispatch(UserActions.getUsersRequest({ search: value, page: 0, limit: 10 }))
      } else if (list) {
        dispatch(UserActions.getUsersRequest({ page: 0, limit: 10 }))
      }
    },
    [dispatch, list]
  )

  const handleClearSearch = useCallback(() => {
    dispatch(UserActions.getUsersRequest({ page: 0, limit: 10 }))
  }, [dispatch])

  //Populate default list
  useEffect(() => {
    dispatch(UserActions.getUsersRequest({ page: 0, limit: 10 }))
  }, [dispatch])

  //Reset store state
  useEffect(() => {
    dispatch(UserActions.getUsersRequest({ page: 0, limit: 10 }))
  }, [dispatch])

  return (
    <DefaultListPage
      pageTitle="Usuários"
      pageDescription="Gerenciar usuários"
      pageSubDescription="Adicione, remove, edite alguns dos itens abaixo"
    >
      <TableActions
        linkToCreate={'/usuarios/criar'}
        hasSearch
        handleSearch={handleSearch}
        handleClearSearch={handleClearSearch}
      />

      <DataTables
        list={list.data}
        count={list.count}
        editURL="/usuarios/editar"
        loading={loading}
        customDataTableColumns={usersDataTableColumns}
        handleOpenModal={handleOpenModal}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        hasPagination
        currentPage={currentPage}
      />
      <ModalDelete
        isOpen={modalOpen}
        modalToggle={() => setModalOpen(false)}
        title={modalData.title}
        content={modalData.content}
        deleteItem={handleDeleteItem}
        item={modalData.item}
      />
    </DefaultListPage>
  )
}

export default Users
