import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { isEmpty } from "lodash";

import DefaultPage from "components/DefaultPage";

import EditLevelForm from "components/Levels/EditLevelForm";

import { Creators as LevelsActions } from "store/ducks/levels";
import { Creators as CashbackActions } from "store/ducks/cashbacks";
import { Creators as BonificationActions } from "store/ducks/bonifications";

const EditLevel = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { level, list } = useSelector((state) => state.levels);
  const cashBackList = useSelector((state) => state.cashbacks.list);
  const bonificationList = useSelector((state) => state.bonifications.list);

  const handleUpdateLevel = useCallback(
    (item) => {
      dispatch(LevelsActions.updateLevelRequest({ ...item, id }));
    },
    [dispatch, id]
  );

  useEffect(() => {
    dispatch(CashbackActions.getCashbacksRequest());
  }, [dispatch]);

  useEffect(() => {
    dispatch(BonificationActions.getBonificationsRequest());
  }, [dispatch]);

  useEffect(() => {
    dispatch(LevelsActions.getLevelRequest(id));
  }, [dispatch, id]);

  return (
    <DefaultPage
      pageTitle="Níveis"
      pageDescription="Editar nível"
      pageSubDescription="Editar o nível"
    >
      {!isEmpty(level) && (
        <EditLevelForm
          submitForm={handleUpdateLevel}
          currentValue={level}
          cashBackList={cashBackList}
          bonificationList={bonificationList}
          levelsList={list}
        />
      )}
    </DefaultPage>
  );
};

export default EditLevel;
