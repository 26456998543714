import React, { useCallback, useState } from "react";
import { Form, Label, Input, Col, Button, Row } from "reactstrap";
import Toggle from "react-toggle";
import ImageUploading, { ImageListType } from 'react-images-uploading'

import { useForm } from "react-hook-form";

const EditStoreForm = ({
  submitForm,
  currentValue,
}) => {

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({

    defaultValues: {
      name: currentValue.name,
      status: currentValue.status,
      position: currentValue.position,
      icon: currentValue.icon

    },
  });


  const [imageList, setImageList] = useState([])
  const { ref: fileRef, ...file } = register('file', {
    required: true
  })
  const [isActiveText, setIsActiveText] = useState(currentValue.status);

  const { ref: nameRef, ...name } = register("name", { required: true });
  const { ref: positionRef, ...position } = register("position", { required: true });


  const handleToggle = useCallback(
    (value) => {
      setIsActiveText(value);
      setValue("status", value);
    },
    [setValue]
  );

  return (
    <Form
      className="needs-validation"
      noValidate=""
      onSubmit={handleSubmit(submitForm)}
    >
      <div className="form-row">
        <Col md="3 mb-3">
          <Label htmlFor="validationCustom01">Nome</Label>
          <Input
            className="form-control"
            name="name"
            type="text"
            placeholder="Nome"
            innerRef={nameRef}
            {...name}
          />
          <span>{errors.name && "Nome é obrigatório"}</span>
        </Col>
        <Col md="3 mb-3">
          <Label htmlFor="validationCustom01">Posição na Home</Label>
          <Input
            className="form-control"
            name="position"
            type="text"
            placeholder="Posição"
            innerRef={positionRef}
            {...position}
          />
          <span>{errors.name && "Posiçào é obrigatório"}</span>
        </Col>
      </div>
      <div className="form-row">

        <Col md="3 mb-3">

          <div className="photo">
            <img src={
              currentValue.icon
            } alt="user-img" />


          </div>
          <div className="actions">
            <Col>
              <Label htmlFor="validationCustom02">Arquivo</Label>
              <Input
                className="form-control"
                name="name"
                type="file"
                placeholder="Arquivo"
                innerRef={fileRef}
                {...file}
              />
              <small style={{ color: 'red' }}>
                {errors.answer && 'Arquivo é obrigatório'}
              </small>
            </Col>
          </div>
        </Col>

        <Col md="2 mb-3" style={{ marginLeft: "20px" }}>
          <Label htmlFor="validationCustom02">Status</Label>
          <Row
            style={{
              marginLeft: "0px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Toggle
              id={currentValue.id}
              defaultChecked={currentValue.status}
              name="status"
              {...register("status")}
              onChange={(e) => handleToggle(e.target.checked)}
            />
            <span style={{ marginLeft: "15px" }}>
              {isActiveText ? "Ativo" : "Inativo"}
            </span>
            <span>{errors.isActive && "Status é obrigatório"}</span>
          </Row>
        </Col>

      </div>
      <div className="form-row row-gap">
        <Button color="primary">Enviar</Button>
      </div>
    </Form>
  );
};

export default EditStoreForm;
