export const Types = {
  GET_QUESTIONS_REQUEST: 'questions/GET_QUESTIONS_REQUEST',
  GET_QUESTIONS_SUCCESS: 'questions/GET_QUESTIONS_SUCCESS',
  GET_QUESTIONS_FAILURE: 'questions/GET_QUESTIONS_FAILURE',

  GET_QUESTION_REQUEST: 'questions/GET_QUESTION_REQUEST',
  GET_QUESTION_SUCCESS: 'questions/GET_QUESTION_SUCCESS',
  GET_QUESTION_FAILURE: 'questions/GET_QUESTION_FAILURE',

  CREATE_QUESTION_REQUEST: 'questions/CREATE_QUESTION_REQUEST',
  CREATE_QUESTION_SUCCESS: 'questions/CREATE_QUESTION_SUCCESS',
  CREATE_QUESTION_FAILURE: 'questions/CREATE_QUESTION_FAILURE',

  UPDATE_QUESTION_REQUEST: 'questions/UPDATE_QUESTION_REQUEST',
  UPDATE_QUESTION_SUCCESS: 'questions/UPDATE_QUESTION_SUCCESS',
  UPDATE_QUESTION_FAILURE: 'questions/UPDATE_QUESTION_FAILURE',

  DELETE_QUESTION_REQUEST: 'questions/DELETE_QUESTION_REQUEST',
  DELETE_QUESTION_SUCCESS: 'questions/DELETE_QUESTION_SUCCESS',
  DELETE_QUESTION_FAILURE: 'questions/DELETE_QUESTION_FAILURE'
}

const INITIAL_STATE = {
  list: [],
  question: {},
  loading: false,
  error: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_QUESTIONS_REQUEST:
      return { ...state, loading: true, error: null }

    case Types.GET_QUESTIONS_SUCCESS:
      return { ...state, loading: false, list: action.payload, error: null }

    case Types.GET_QUESTIONS_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case Types.GET_QUESTION_REQUEST:
      return { ...state, loading: true, question: {}, error: null }

    case Types.GET_QUESTION_SUCCESS:
      return { ...state, loading: false, question: action.payload, error: null }

    case Types.GET_QUESTION_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case Types.CREATE_QUESTION_REQUEST:
      return { ...state, loading: true, error: null }

    case Types.CREATE_QUESTION_SUCCESS:
      return { ...state, loading: false, error: null }

    case Types.CREATE_QUESTION_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case Types.UPDATE_QUESTION_REQUEST:
      return { ...state, loading: true, error: null }

    case Types.UPDATE_QUESTION_SUCCESS:
      return { ...state, loading: false, question: {}, error: null }

    case Types.UPDATE_QUESTION_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case Types.DELETE_QUESTION_REQUEST:
      return { ...state, loading: true, error: null }

    case Types.DELETE_QUESTION_SUCCESS:
      return { ...state, loading: false, error: null }

    case Types.DELETE_QUESTION_FAILURE:
      return { ...state, loading: false, error: action.payload }

    default:
      return state
  }
}

export const Creators = {
  getQuestionsRequest: (payload) => ({
    type: Types.GET_QUESTIONS_REQUEST,
    payload
  }),

  getQuestionsSuccess: (payload) => ({
    type: Types.GET_QUESTIONS_SUCCESS,
    payload
  }),

  getQuestionsFailure: (payload) => ({
    type: Types.GET_QUESTIONS_FAILURE,
    payload
  }),

  getQuestionRequest: (payload) => ({
    type: Types.GET_QUESTION_REQUEST,
    payload
  }),

  getQuestionSuccess: (payload) => ({
    type: Types.GET_QUESTION_SUCCESS,
    payload
  }),

  getQuestionFailure: (payload) => ({
    type: Types.GET_QUESTION_FAILURE,
    payload
  }),

  createQuestionRequest: (payload) => ({
    type: Types.CREATE_QUESTION_REQUEST,
    payload
  }),

  createQuestionSuccess: (payload) => ({
    type: Types.CREATE_QUESTION_SUCCESS,
    payload
  }),

  createQuestionFailure: (payload) => ({
    type: Types.CREATE_QUESTION_FAILURE,
    payload
  }),

  updateQuestionRequest: (payload) => ({
    type: Types.UPDATE_QUESTION_REQUEST,
    payload
  }),

  updateQuestionSuccess: (payload) => ({
    type: Types.UPDATE_QUESTION_SUCCESS,
    payload
  }),

  updateQuestionFailure: (payload) => ({
    type: Types.UPDATE_QUESTION_FAILURE,
    payload
  }),

  deleteQuestionRequest: (payload) => ({
    type: Types.DELETE_QUESTION_REQUEST,
    payload
  }),

  deleteQuestionSuccess: (payload) => ({
    type: Types.DELETE_QUESTION_SUCCESS,
    payload
  }),

  deleteQuestionFailure: (payload) => ({
    type: Types.DELETE_QUESTION_FAILURE,
    payload
  })
}
