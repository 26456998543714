import React, { memo, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { Creators as CheckoutActions } from 'store/ducks/checkout'

import Button from './Button'
import Boleto from './Boleto'
import CreditCard from './CreditCard'
import PIX from './PIX'

import useCheckout from 'shared/hooks/useCheckout'

const PaymentMethod = ({ product }) => {
  const {
    PAYMENT_METHODS,
    choosedMethod,
    user,
    handleBuyWithCC,
    handleSelectMethod,
    handleCreateTransaction
  } = useCheckout()

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(CheckoutActions.generateTransactionSuccess(null))
  }, [dispatch])

  return (
    <div className="new-checkout-payment-method">
      <div className="payment-method-items">
        {PAYMENT_METHODS.map((method, index) => (
          <Button
            key={index}
            title={method.label}
            value={method.value}
            icon={method.icon}
            selected={choosedMethod === method.value}
            handleSelectMethod={handleSelectMethod}
          />
        ))}
      </div>
      <div className="payment-method-form">
        {choosedMethod === 'CREDIT_CARD' && (
          <CreditCard
            product={product}
            handleBuyWithCC={handleBuyWithCC}
            handleCreateTransaction={handleCreateTransaction}
            user={user}
          />
        )}
        {choosedMethod === 'BOLETO' && (
          <Boleto
            product={product}
            handleCreateTransaction={handleCreateTransaction}
            user={user}
          />
        )}
        {choosedMethod === 'PIX' && (
          <PIX
            product={product}
            handleCreateTransaction={handleCreateTransaction}
            user={user}
          />
        )}
      </div>
    </div>
  )
}

export default memo(PaymentMethod)
