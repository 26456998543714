import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DataTables from "components/Datatable";
import TableActions from "components/TableActions";
import ModalDelete from "components/ModalDelete";

import { Creators as PaymentMethodsActions } from "store/ducks/paymentMethods";
import { paymentMethodsDatatableColumns } from "shared/constants/datatable";

import DefaultListPage from "components/DefaultPage";

const PaymentMethods = () => {
  const dispatch = useDispatch();
  const { list } = useSelector((state) => state.paymentMethods);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});

  useEffect(() => {
    dispatch(PaymentMethodsActions.getPaymentMethodsRequest());
  }, [dispatch]);

  const handleDeleteItem = useCallback(
    (item) => {
      dispatch(PaymentMethodsActions.deletePaymentMethodRequest(item));
      setModalOpen(false);
    },
    [dispatch]
  );

  const handleOpenModal = (value) => {
    setModalOpen(true);
    setModalData({
      title: "Excluir método de pagamento",
      content: `Você tem certeza em excluir o método de pagamento "${value.name}"?`,
      item: value,
    });
  };

  return (
    <DefaultListPage
      pageTitle="Métodos de pagamento"
      pageDescription="Gerenciar métodos de pagamento"
      pageSubDescription="Adicione, remove, edite alguns dos itens abaixo"
    >
      <TableActions linkToCreate={"/pagamentos/metodos/criar"} />
      <DataTables
        list={list}
        editURL="/pagamentos/metodos/editar"
        handleOpenModal={handleOpenModal}
        customDataTableColumns={paymentMethodsDatatableColumns}
      />
      <ModalDelete
        isOpen={modalOpen}
        modalToggle={() => setModalOpen(false)}
        title={modalData.title}
        content={modalData.content}
        deleteItem={handleDeleteItem}
        item={modalData.item}
      />
    </DefaultListPage>
  );
};

export default PaymentMethods;
