import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { isEmpty } from "lodash";

import DefaultPage from "components/DefaultPage";

import EditGraduationForm from "components/Graduations/EditPositionForm";

import { Creators as GraduationsActions } from "store/ducks/graduations";
import { Creators as PositionsActions } from "store/ducks/positions";
import { Creators as UsersActions } from "store/ducks/users";

const EditGraduation = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { graduation } = useSelector((state) => state.graduations);
  const positions = useSelector((state) => state.positions.list);
  const users = useSelector((state) => state.users.list);

  const handleUpdateGraduation = useCallback(
    (item) => {
      dispatch(GraduationsActions.updateGraduationRequest({ ...item, id }));
    },
    [dispatch, id]
  );

  useEffect(() => {
    dispatch(GraduationsActions.getGraduationRequest(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(PositionsActions.getPositionsRequest());
  }, [dispatch]);

  //HABILITAR DEPOIS DE PAGINAR USUÁRIOS
  // useEffect(() => {
  //   dispatch(UsersActions.getUsersRequest());
  // }, [dispatch]);

  return (
    <DefaultPage
      pageTitle="Graduações"
      pageDescription="Editar graduação"
      pageSubDescription="Editar o graduação"
    >
      {!isEmpty(graduation) && (
        <EditGraduationForm
          submitForm={handleUpdateGraduation}
          currentValue={graduation}
          positionsList={positions}
          usersList={users}
        />
      )}
    </DefaultPage>
  );
};

export default EditGraduation;
