export const Types = {
  GET_WITHDRAW_REQUESTS_REQUEST: 'withdraw/GET_WITHDRAW_REQUESTS_REQUEST',
  GET_WITHDRAW_REQUESTS_SUCCESS: 'withdraw/GET_WITHDRAW_REQUESTS_SUCCESS',
  GET_WITHDRAW_REQUESTS_FAILURE: 'withdraw/GET_WITHDRAW_REQUESTS_FAILURE'
}

const INITIAL_STATE = {
  list: [],
  withdrawRequest: {},
  loading: false,
  error: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_WITHDRAW_REQUESTS_REQUEST:
      return { ...state, loading: true, error: null }

    case Types.GET_WITHDRAW_REQUESTS_SUCCESS:
      return { ...state, loading: false, list: action.payload, error: null }

    case Types.GET_WITHDRAW_REQUESTS_FAILURE:
      return { ...state, loading: false, error: action.payload }

    default:
      return state
  }
}

export const Creators = {
  getWithdrawRequestsRequest: (payload) => ({
    type: Types.GET_WITHDRAW_REQUESTS_REQUEST,
    payload
  }),

  getWithdrawRequestsSuccess: (payload) => ({
    type: Types.GET_WITHDRAW_REQUESTS_SUCCESS,
    payload
  }),

  getWithdrawRequestsFailure: (payload) => ({
    type: Types.GET_WITHDRAW_REQUESTS_FAILURE,
    payload
  }),

  getWithdrawRequestRequest: (payload) => ({
    type: Types.GET_WITHDRAW_REQUEST_REQUEST,
    payload
  }),

  getWithdrawRequestSuccess: (payload) => ({
    type: Types.GET_WITHDRAW_REQUEST_SUCCESS,
    payload
  }),

  getWithdrawRequestFailure: (payload) => ({
    type: Types.GET_WITHDRAW_REQUEST_FAILURE,
    payload
  })
}
