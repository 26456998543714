import React from 'react'
import QRCodeGenerate from 'react-qr-code'

const QRCode = ({ value, size = 100 }) => (
  // value = pix, text, anything that can be a qrcode
  //size = size of qrcode
  <QRCodeGenerate value={value} size={size} />
)

export default QRCode
