import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'

const FormFooterActions = ({ backPath }) => {
  return (
    <div style={{ display: 'flex', gap: '0 1rem', marginTop: '20px' }}>
      <Button color="primary" type="submit">
        Salvar
      </Button>
      <Link to={backPath}>
        <Button color="default" style={{ border: 'none' }}>
          Cancelar
        </Button>
      </Link>
    </div>
  )
}

export default FormFooterActions
