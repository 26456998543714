import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import auth from './auth'
import users from './users'
import customizer from './customizer'
import actions from './actions'
import profiles from './profiles'
import plans from './plans'
import bonifications from './bonifications'
import cashbacks from './cashbacks'
import levels from './levels'
import network from './network'
import wallet from './wallet'
import money from './money'
import positions from './positions'
import graduations from './graduations'
import paymentMethods from './paymentMethods'
import services from './services'
import bankAccounts from './bankAccounts'
import withdraw from './withdraw'
import sales from './sales'
import stores from './stores'
import invoice from './invoice'
import support from './support'
import generic_search from './generic_search'
import checkout from './checkout'
import report from './report'
import faq from './faq'
import tutorials from './tutorials'
import materials from './materials'
import notifications from './notifications'

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    users,
    customizer,
    actions,
    profiles,
    plans,
    bonifications,
    cashbacks,
    levels,
    network,
    wallet,
    money,
    positions,
    graduations,
    paymentMethods,
    services,
    bankAccounts,
    withdraw,
    sales,
    invoice,
    support,
    generic_search,
    stores,
    checkout,
    report,
    faq,
    tutorials,
    materials,
    notifications
  })
