import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DataTables from 'components/Datatable'
import TableActions from 'components/TableActions'
import { Creators as MoneyActions } from "store/ducks/money";
import { transactionsDataTableColumns } from "shared/constants/datatable";


import DefaultListPage from 'components/DefaultPage'

const Transactions = () => {
  const dispatch = useDispatch()
  const {
    money: { list, loading },
  } = useSelector((state) => state);

  const [currentPage, setCurrentPage] = useState(0)

  const handleChangeRowsPerPage = useCallback(
    (currentPage, size) => {
      dispatch(MoneyActions.getMoneysRequest({ page: currentPage, limit: size }))
    },
    [dispatch]
  )

  const handleChangePage = useCallback(
    (page) => {
      setCurrentPage(page)
      dispatch(MoneyActions.getMoneysRequest({ page: page, limit: 10 }))
    },
    [dispatch]
  )

  const handleSearch = useCallback(
    (value) => {
      if (value !== '') {
        dispatch(MoneyActions.getMoneysRequest({ search: value, page: 0, limit: 10 }))
      } else if (list) {
        dispatch(MoneyActions.getMoneysRequest({ page: 0, limit: 10 }))
      }
    },
    [dispatch, list]
  )

  const handleClearSearch = useCallback(() => {
    dispatch(MoneyActions.getMoneysRequest({ page: 0, limit: 10 }))
  }, [dispatch])

  //Populate default list
  useEffect(() => {
    dispatch(MoneyActions.getMoneysRequest({ page: 0, limit: 10 }))
  }, [dispatch])

  //Reset store state
  useEffect(() => {
    dispatch(MoneyActions.getMoneysRequest({ page: 0, limit: 10 }))
  }, [dispatch])

  return (
    <DefaultListPage
      pageTitle="Transações"
      pageDescription="Transações"
      pageSubDescription="Visualize as suas últimas transações realizadas"
    >

      <TableActions
        hasSearch
        hiddenAddButton
        handleSearch={handleSearch}
        handleClearSearch={handleClearSearch}
      />



        <DataTables
          list={list[0]}
          count={list[1]}
          noActions={true}
          customDataTableColumns={transactionsDataTableColumns}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
          hasPagination
          loading={loading}
          currentPage={currentPage}
        />
    
    </DefaultListPage>
  );
};

export default Transactions;