export const Types = {
  GET_STORES_REQUEST: "stores/GET_STORES_REQUEST",
  GET_STORES_SUCCESS: "stores/GET_STORES_SUCCESS",
  GET_STORES_FAILURE: "stores/GET_STORES_FAILURE",

  GET_STORE_REQUEST: "stores/GET_STORE_REQUEST",
  GET_STORE_SUCCESS: "stores/GET_STORE_SUCCESS",
  GET_STORE_FAILURE: "stores/GET_STORE_FAILURE",

  CREATE_STORE_REQUEST: "stores/CREATE_STORE_REQUEST",
  CREATE_STORE_SUCCESS: "stores/CREATE_STORE_SUCCESS",
  CREATE_STORE_FAILURE: "stores/CREATE_STORE_FAILURE",

  UPDATE_STORE_REQUEST: "stores/UPDATE_STORE_REQUEST",
  UPDATE_STORE_SUCCESS: "stores/UPDATE_STORE_SUCCESS",
  UPDATE_STORE_FAILURE: "stores/UPDATE_STORE_FAILURE",

  DELETE_STORE_REQUEST: "stores/DELETE_STORE_REQUEST",
  DELETE_STORE_SUCCESS: "stores/DELETE_STORE_SUCCESS",
  DELETE_STORE_FAILURE: "stores/DELETE_STORE_FAILURE",
};

const INITIAL_STATE = {
  list: [],
  store: {},
  loading: false,
  error: null,
};

export default (state = INITIAL_STATE, store) => {
  switch (store.type) {
    case Types.GET_STORES_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.GET_STORES_SUCCESS:
      return { ...state, loading: false, list: store.payload, error: null };

    case Types.GET_STORES_FAILURE:
      return { ...state, loading: false, error: store.payload };

    case Types.GET_STORE_REQUEST:
      return { ...state, loading: true, store: {}, error: null };

    case Types.GET_STORE_SUCCESS:
      return { ...state, loading: false, store: store.payload, error: null };

    case Types.GET_STORE_FAILURE:
      return { ...state, loading: false, error: store.payload };

    case Types.CREATE_STORE_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.CREATE_STORE_SUCCESS:
      return { ...state, loading: false, error: null };

    case Types.CREATE_STORE_FAILURE:
      return { ...state, loading: false, error: store.payload };

    case Types.UPDATE_STORE_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.UPDATE_STORE_SUCCESS:
      return { ...state, loading: false, store: {}, error: null };

    case Types.UPDATE_STORE_FAILURE:
      return { ...state, loading: false, error: store.payload };

    case Types.DELETE_STORE_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.DELETE_STORE_SUCCESS:
      return { ...state, loading: false, error: null };

    case Types.DELETE_STORE_FAILURE:
      return { ...state, loading: false, error: store.payload };

    default:
      return state;
  }
};

export const Creators = {
  getStoresRequest: (payload) => ({
    type: Types.GET_STORES_REQUEST,
    payload,
  }),

  getStoresSuccess: (payload) => ({
    type: Types.GET_STORES_SUCCESS,
    payload,
  }),

  getStoresFailure: (payload) => ({
    type: Types.GET_STORES_FAILURE,
    payload,
  }),

  getStoreRequest: (payload) => ({
    type: Types.GET_STORE_REQUEST,
    payload,
  }),

  getStoreSuccess: (payload) => ({
    type: Types.GET_STORE_SUCCESS,
    payload,
  }),

  getStoreFailure: (payload) => ({
    type: Types.GET_STORE_FAILURE,
    payload,
  }),

  createStoreRequest: (payload) => ({
    type: Types.CREATE_STORE_REQUEST,
    payload,
  }),

  createStoreSuccess: (payload) => ({
    type: Types.CREATE_STORE_SUCCESS,
    payload,
  }),

  createStoreFailure: (payload) => ({
    type: Types.CREATE_STORE_FAILURE,
    payload,
  }),

  updateStoreRequest: (payload) => ({
    type: Types.UPDATE_STORE_REQUEST,
    payload,
  }),

  updateStoreSuccess: (payload) => ({
    type: Types.UPDATE_STORE_SUCCESS,
    payload,
  }),

  updateStoreFailure: (payload) => ({
    type: Types.UPDATE_STORE_FAILURE,
    payload,
  }),

  deleteStoreRequest: (payload) => ({
    type: Types.DELETE_STORE_REQUEST,
    payload,
  }),

  deleteStoreSuccess: (payload) => ({
    type: Types.DELETE_STORE_SUCCESS,
    payload,
  }),

  deleteStoreFailure: (payload) => ({
    type: Types.DELETE_STORE_FAILURE,
    payload,
  }),
};
