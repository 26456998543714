import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import DefaultPage from "components/DefaultPage";

import CreateCashbackForm from "components/Cashbacks/CreateCashbackForm";

import { Creators as CashbackActions } from "store/ducks/cashbacks";

const CreateCashback = () => {
  const dispatch = useDispatch();

  const handleCreateCashback = useCallback(
    (item) => {
      dispatch(CashbackActions.createCashbackRequest(item));
    },
    [dispatch]
  );

  return (
    <DefaultPage
      pageTitle="Cashbacks"
      pageDescription="Criar cashback"
      pageSubDescription="Criar um novo cashback"
    >
      <CreateCashbackForm submitForm={handleCreateCashback} />
    </DefaultPage>
  );
};

export default CreateCashback;
