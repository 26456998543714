import React, { useCallback,useEffect } from "react";

import { useDispatch, useSelector } from 'react-redux'

import DefaultPage from "components/DefaultPage";

import CreateInvoiceForm from "components/Invoice/CreateInvoiceForm";

import { Creators as InvoiceActions } from "store/ducks/invoice";


const CreateInvoice = () => {
  const dispatch = useDispatch()
  const {
    auth: { auth },
    invoice:{invoiceEstimetedByUser},
  } = useSelector((state) => state)

  useEffect(() => {
    dispatch(InvoiceActions.getInvoiceEstimetedByUserRequest(`${auth.id}?page=0&limit=10`))
  }, [dispatch, auth.id]);

  const handleCreateInvoice = useCallback(
    (value) => {
      dispatch(
        InvoiceActions.createInvoiceRequest({
          ...value,
          user: 
            {
              id: auth.id
            }
          ,
          successMessage: 'Solicitação enviada com sucesso!',
          errorMessage: 'Erro ao enviar soliticação'
        })
      )
    },
    [auth.id, dispatch]
  )
  
  return (
    <DefaultPage
      pageTitle="Faturas"
      pageDescription="Criar fatura"
      pageSubDescription="Criar uma nova fatura"
    >
      <CreateInvoiceForm
        invoiceEstimeted = {invoiceEstimetedByUser}
        submitForm={handleCreateInvoice}
      />
    </DefaultPage>
  );
};

export default CreateInvoice;
