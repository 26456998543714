import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { Grid, Menu } from 'react-feather'

import Sidemenu from './Sidemenu'
import Notification from './Notification'
import FullNotification from './FullNotification'

import { useWindowWide } from 'shared/hooks/useWindowWide'
import useFunctionRole from 'shared/hooks/useFunctionRole'
import useSocialAuth from 'shared/hooks/useSocialAuth'

import { logout } from 'services/auth'
import { Creators as AuthActions } from 'store/ducks/auth'
import { Creators as NotificationsActions } from 'store/ducks/notifications'

import { useSelector } from 'react-redux'
import { useMemo } from 'react'
import { useCallback } from 'react'

const NewMenu = ({ isCheckout }) => {
  const isMobile = useWindowWide() < 578
  const [collapse, setCollapse] = useState(true)
  const [toggled, setToggled] = useState(false)

  const handleToggleSidebar = (value) => setToggled(value)
  const handleCollapse = (value) => setCollapse(value)

  const dispatch = useDispatch()
  const history = useHistory()

  const { auth } = useSelector((state) => state.auth)
  const { userNotifications } = useSelector((state) => state.notifications)

  const { menuByRole, userInfoToHeader } = useFunctionRole()
  const { handleSocialLogout } = useSocialAuth()

  const handleLogout = () => {
    history.push('/login')
    logout()
    handleSocialLogout()
    dispatch(AuthActions.logout())
  }

  useEffect(() => {
    dispatch(
      NotificationsActions.getNotificationsByUserRequest(auth && auth.id)
    )
  }, [auth, dispatch])

  const alertHomeNotifications = useMemo(() => {
    return (
      userNotifications &&
      userNotifications.filter((item) => item.type === 'alert_home')
    )
  }, [userNotifications])

  const menuNotifications = useMemo(() => {
    return (
      userNotifications &&
      userNotifications.filter((item) => item.type === 'notification_bar')
    )
  }, [userNotifications])

  const handleRemoveNotification = useCallback(
    (id) => {
      const body = { auth: auth && auth.id, id }
      dispatch(NotificationsActions.setAsReadedNotificationByUserRequest(body))
    },
    [dispatch, auth]
  )

  return (
    <>
      {!isCheckout && (
        <Sidemenu
          collapse={collapse}
          toggled={toggled}
          handleToggleSidebar={handleToggleSidebar}
          handleCollapse={handleCollapse}
          menuItems={menuByRole}
        />
      )}
      <header className="new-menu">
        {isMobile && (
          <Menu
            onClick={() => {
              handleCollapse(false)
              handleToggleSidebar(true)
            }}
            style={{ marginLeft: '20px' }}
          />
        )}

        <div className="brand">
          <img
            src={require('assets/images/logo/quanta-new.png')}
            alt="brand-logo"
          />
        </div>
        <div className="menu-content">
          <div className="menu">
            <FullNotification
              isOpen={
                alertHomeNotifications && alertHomeNotifications.length > 0
              }
              content={alertHomeNotifications}
              handleRemoveNotification={handleRemoveNotification}
            />
            <Notification
              list={menuNotifications}
              handleRemoveNotification={handleRemoveNotification}
            />
            <div className="menu-item">
              <Grid />
              <Link to="/dashboard">Dashboard</Link>
            </div>
          </div>
          <div className="user-info">
            <img
              src={userInfoToHeader.img}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null // prevents looping
                currentTarget.src = require('assets/images/user/user.png')
              }}
              alt="user-profile"
            />
            <div className="role">
              <strong>{userInfoToHeader?.username}</strong>
              <small>{userInfoToHeader?.userProfile}</small>
            </div>
            <div className="actions">
              <span>
                <Link to="/perfil" style={{ color: 'inherit' }}>
                  Perfil
                </Link>
              </span>
              <span onClick={handleLogout}>Logout</span>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default NewMenu
