import { put, call } from 'redux-saga/effects'
import { api } from 'services/api'
import { push } from 'connected-react-router'
import { toast } from 'react-toastify'

import { Creators as BonificationActions } from 'store/ducks/bonifications'

export function* getBonifications() {
  try {
    const { data } = yield call(api.get, '/bonifications')
    yield put(BonificationActions.getBonificationsSuccess(data))
  } catch (err) {
    yield put(BonificationActions.getBonificationsFailure(err.message))
  }
}

export function* getBonificationByID(action) {
  try {
    const { data } = yield call(api.get, `/bonifications/${action.payload}`)
    yield put(BonificationActions.getBonificationSuccess(data))
  } catch (err) {
    yield put(BonificationActions.getBonificationFailure(err))
  }
}

export function* createBonification(action) {
  try {
    const { data } = yield call(api.post, `/bonifications`, action.payload)
    yield put(BonificationActions.createBonificationSuccess(data))
    yield put(push('/bonificacoes'))
    toast.success('Bonificação criado com sucesso!')
  } catch (err) {
    toast.error('Erro ao criar bonificação')
    yield put(BonificationActions.createBonificationFailure(err))
  }
}

export function* updateBonification(action) {
  try {
    const { data } = yield call(
      api.put,
      `/bonifications/${action.payload.id}`,
      action.payload
    )
    yield put(BonificationActions.updateBonificationSuccess(data))
    yield put(push('/bonificacoes'))
    toast.success('Bonificação editado com sucesso!')
  } catch (err) {
    toast.error('Erro ao editar bonificação')
    yield put(BonificationActions.updateBonificationFailure(err))
  }
}

export function* deleteBonification(action) {
  try {
    const { data } = yield call(
      api.delete,
      `/bonifications/${action.payload.id}`
    )
    yield put(BonificationActions.deleteBonificationSuccess(data))
    yield put(BonificationActions.getBonificationsRequest())
    toast.success('Bonificação removido com sucesso')
  } catch (err) {
    toast.error('Erro ao remover bonificação')
    yield put(BonificationActions.deleteBonificationFailure(err))
  }
}
