import React, { useState, useEffect } from "react";
import { Form, Label, Input, Col, Button } from "reactstrap";
import { useForm } from "react-hook-form";

const EditPlanForm = ({ submitForm, listLevels, currentValue }) => {
  const [currentLevels, setCurrentLevels] = useState([]);
  const [canShowLevels, setCanShowLevels] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: currentValue.name,
      description: currentValue.description,
      minAmout: currentValue?.withdraw?.minAmout
    },
  });

  const { ref: nameRef, ...name } = register("name", { required: true });

  const { ref: descriptionRef, ...description } = register("description", {
    required: true,
  });
  const { ref: priceRef, ...price } = register("price", {
    required: true,
  });
  const { ref: pointsRef, ...points } = register("points", {
    required: true,
  });

  const { ref: feature01Ref, ...feature01 } = register("feature01", {
    required: true,
  });
  const { ref: feature02Ref, ...feature02 } = register("feature02", {
    required: true,
  });
  const { ref: feature03Ref, ...feature03 } = register("feature03", {
    required: true,
  });
  const { ref: feature04Ref, ...feature04 } = register("feature04", {
    required: true,
  });
  const { ref: minAmoutRef, ...minAmout } = register("minAmout", {
    required: true,
  });
  const { ref: levelsRef, ...levels } = register("levels");

  useEffect(() => {
    if (!!currentValue && currentValue.levels) {
      let levels = [];
      currentValue.levels.map((item) => levels.push(item.id));
      setCurrentLevels(levels);
      setCanShowLevels(true);
    }
  }, [currentValue]);

  return (
    <Form
      className="needs-validation"
      noValidate=""
      onSubmit={handleSubmit(submitForm)}
    >
      <div className="form-row">
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom01">Nome</Label>
          <Input
            className="form-control"
            name="name"
            type="text"
            placeholder="Nome"
            innerRef={nameRef}
            {...name}
            defaultValue={currentValue.name}
          />
          <span>{errors.name && "Nome é obrigatório"}</span>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom02">Descrição</Label>
          <Input
            className="form-control"
            name="description"
            type="text"
            placeholder="Descrição"
            innerRef={descriptionRef}
            {...description}
            defaultValue={currentValue.description}
          />
          <span>{errors.description && "Descrição é obrigatório"}</span>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom02">Valor Mínimo de Saque</Label>
          <Input
            className="form-control"
            name="withdraw"
            type="text"
            placeholder="Saque"
            innerRef={minAmoutRef}
            {...minAmout}
            defaultValue={currentValue?.withdraw?.minAmout}
          />
          <span>{errors.description && "Descrição é obrigatório"}</span>
        </Col>
      </div>



      <div className="form-row">
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom02">Preço</Label>
          <Input
            className="form-control"
            name="description"
            type="text"
            placeholder="Preço"
            innerRef={priceRef}
            {...price}
            defaultValue={currentValue?.price}

          />

          <span>{errors.description && "Preço é obrigatório"}</span>
        </Col>

        <Col md="4 mb-3">
          <Label htmlFor="validationCustom01">pontos</Label>
          <Input
            className="form-control"
            name="name"
            type="text"
            placeholder="Pontos"
            innerRef={pointsRef}
            {...points}
            defaultValue={currentValue?.points}

          />
          <span>{errors.name && "Pontos é obrigatório"}</span>
        </Col>
      </div>
      <div className="form-row">
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom02">Descritivo de venda 01</Label>
          <Input
            className="form-control"
            name="description"
            type="text"
            placeholder="Descritivo de venda"
            innerRef={feature01Ref}
            {...feature01}
            defaultValue={currentValue?.feature01}

          />

          <span>{errors.description && "Descritivo de venda é obrigatório"}</span>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom02">Descritivo de venda 02</Label>
          <Input
            className="form-control"
            name="description"
            type="text"
            placeholder="Descritivo de venda"
            innerRef={feature02Ref}
            {...feature02}
            defaultValue={currentValue?.feature02}

          />

          <span>{errors.description && "Descritivo de venda é obrigatório"}</span>
        </Col>
      </div>
      <div className="form-row">
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom02">Descritivo de venda 03</Label>
          <Input
            className="form-control"
            name="description"
            type="text"
            placeholder="Descritivo de venda"
            innerRef={feature03Ref}
            {...feature03}
            defaultValue={currentValue?.feature03}

          />

          <span>{errors.description && "Descritivo de venda é obrigatório"}</span>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom02">Descritivo de venda 04</Label>
          <Input
            className="form-control"
            name="description"
            type="text"
            placeholder="Descritivo de venda"
            innerRef={feature04Ref}
            {...feature04}
            defaultValue={currentValue?.feature04}

          />

          <span>{errors.description && "Descritivo de venda é obrigatório"}</span>
        </Col>
      </div>





      <div className="form-row row-gap">
        <Col sm="12">
          <h5>Níveis</h5>
        </Col>
        <Col>
          {canShowLevels &&
            listLevels?.map((item) => (
              <Label className="d-block" for={item.id} key={item.id}>
                <Input
                  className="checkbox_animated"
                  id={item.id}
                  type="checkbox"
                  innerRef={levelsRef}
                  value={item.id}
                  {...levels}
                  defaultChecked={currentLevels.includes(item.id)}
                />
                {item.name}
              </Label>
            ))}
        </Col>
      </div>
      <div className="form-row row-gap">
        <Button color="primary">Enviar</Button>
      </div>
    </Form>
  );
};

export default EditPlanForm;
