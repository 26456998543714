import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Button, Modal, ModalHeader, ModalBody } from 'reactstrap'

import CreateBankAccountForm from './CreateBankAccountForm'

import { Creators as BankAccountActions } from 'store/ducks/bankAccounts'

const BankAccounts = ({ accounts }) => {
  const dispatch = useDispatch()
  const { auth } = useSelector((state) => state.auth)
  const [modalOpen, setModalOpen] = useState(false)
  const handleModalToggle = (value) => setModalOpen(value)

  const handleCreateBankAccount = useCallback(
    (value) => {
      const body = { ...value, user: { id: auth.id } }
      dispatch(BankAccountActions.createBankAccountRequest(body))
      handleModalToggle(false)
    },
    [auth.id, dispatch]
  )

  return (
    <>
      <Card className="card">
        <div className="bank-accounts">
          <strong>Minhas contas bancárias</strong>
          {accounts.length > 0 ? (
            accounts.map((item) => (
              <div className="account" key={item.id}>
                {/* <div className="icon">
                <span>icone</span>
              </div> */}
                <div className="description">
                  <strong>{item.bank}</strong>
                  <small>
                    Agencia: {item.agency} - Conta: {item.account} - Tipo:{' '}
                    {item.type}
                  </small>
                </div>
              </div>
            ))
          ) : (
            <div className="empty">
              <span>Você não possui contas bancárias cadastradas</span>
            </div>
          )}

          <div className="new-account">
            <Button color="primary" onClick={() => handleModalToggle(true)}>
              Adicionar nova conta
            </Button>
          </div>
        </div>
      </Card>
      <Modal
        isOpen={modalOpen}
        toggle={() => handleModalToggle(false)}
        centered
      >
        <ModalHeader toggle={() => handleModalToggle(false)}>
          Adicionar uma nova conta bancária
        </ModalHeader>
        <ModalBody>
          <CreateBankAccountForm
            handleCreateBankAccount={handleCreateBankAccount}
          />
        </ModalBody>
      </Modal>
    </>
  )
}

export default BankAccounts
