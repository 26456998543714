import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import DefaultPage from "components/DefaultPage";

import CreatePaymentMethodForm from "components/PaymentMethods/CreatePaymentMethodForm";

import { Creators as PaymentMethodsActions } from "store/ducks/paymentMethods";

const CreatePaymentMethod = () => {
  const dispatch = useDispatch();

  const handleCreatePaymentMethod = useCallback(
    (item) => {
      dispatch(PaymentMethodsActions.createPaymentMethodRequest(item));
    },
    [dispatch]
  );

  return (
    <DefaultPage
      pageTitle="Métodos de pagamento"
      pageDescription="Criar método de pagamento"
      pageSubDescription="Criar um novo método de pagamento"
    >
      <CreatePaymentMethodForm submitForm={handleCreatePaymentMethod} />
    </DefaultPage>
  );
};

export default CreatePaymentMethod;
