import Invoices from 'pages/Invoice'
import InvoicesUser from 'pages/Invoice/Create'
import EditInvoices from 'pages/Invoice/Edit'

import { BASE_URL } from 'shared/constants/config'

export default [
  { path: `${BASE_URL}/invoice`, component: Invoices },
  { path: `${BASE_URL}/invoice/create`, component: InvoicesUser },
  { path: `${BASE_URL}/invoice/update/:id`, component: EditInvoices }
]
