import React, { useState, useCallback, useEffect } from 'react'

import { Input, Label } from 'reactstrap'
import InputMask from 'react-input-mask'

import Cards from 'react-credit-cards'
import 'react-credit-cards/es/styles-compiled.css'

import { useForm } from 'react-hook-form'

import axios from 'axios'

import { formatCheckoutInstallments, formatCurrencyToTransaction } from 'utils'

const CreditCard = ({ product, handleCreateTransaction, user }) => {
  const {
    register,
    watch,
    getValues,
    setValue,
    formState: { errors, isValid }
  } = useForm({ mode: 'all' })

  //CREDIT CARD
  const { ref: cardNumberRef, ...cardNumber } = register('number', {
    required: true
  })

  const { ref: cardCVVRef, ...cardCVV } = register('cvc', {
    required: true,
    pattern: { value: '[0-9]+' }
  })

  const { ref: cardExpirationRef, ...cardExpiration } = register('expiry', {
    required: true
  })

  const {
    ref: cardHolderNameRef,
    onChange,
    ...cardHolderName
  } = register('name', {
    required: true
  })

  const watchFields = watch(['number', 'cvc', 'expiry', 'name'])

  //ADDRESS
  const {
    ref: zipcodeRef,
    onBlur,
    ...zipcode
  } = register('zipcode', {
    required: true
  })

  const { ref: streetRef, ...street } = register('street', {
    required: true
  })

  const { ref: cityRef, ...city } = register('city', {
    required: true
  })

  const { ref: neighborhoodRef, ...neighborhood } = register('neighborhood', {
    required: true
  })

  const { ref: streetNumberRef, ...streetNumber } = register('street_number', {
    required: true
  })

  const { ref: stateRef, ...state } = register('state', {
    required: true
  })

  const [address, setAddress] = useState({})
  const [installments, setInstallments] = useState([])
  const [focusField, setFocusField] = useState('')
  const [choosedInstallment, setChoosedInstallment] = useState({})

  const handleInputFocus = useCallback((e) => {
    setFocusField(e.target.name)
  }, [])

  const handleSearchByCEP = useCallback(
    async (value) => {
      const { data } = await axios.get(
        `https://viacep.com.br/ws/${value}/json/`
      )
      setValue('state', data.uf)
      setValue('city', data.localidade)
      setValue('neighborhood', data.bairro)
      setValue('street', data.logradouro)
      setAddress(data)
    },
    [setValue]
  )

  const handleBuyWithCC = useCallback(() => {
    const formattedCardNumber = getValues('number')
    const formattedExpiry = getValues('expiry')
    const formattedZipCode = getValues('zipcode')

    handleCreateTransaction({
      amount:
        choosedInstallment &&
        Number(formatCurrencyToTransaction(choosedInstallment.value)),
      amountUnformated: choosedInstallment && Number(choosedInstallment.value),
      installments: choosedInstallment && choosedInstallment.quantity,
      card_number: formattedCardNumber.replaceAll(' ', ''),
      card_cvv: getValues('cvc'),
      card_expiration_date: formattedExpiry.replace('/', ''),
      card_holder_name: getValues('name'),
      customer: {
        type: 'individual',
        country: 'br',
        name: user && user.name,
        external_id: user && user.cpf ? user.cpf.toString() : new Date().getTime().toString(),
        email: user && user.email,
        phone_numbers: user && [`+55${user.phone?.replace(/[^0-9\.]+/g, '')}`],
        documents: [
          {
            type: 'cpf',
            number: user && user.cpf
          }
        ]
      },

      billing: {
        name: user && user.name,
        address: {
          country: 'br',
          state: getValues('state'),
          city: getValues('city'),
          neighborhood: getValues('neighborhood'),
          street: getValues('street'),
          street_number: getValues('street_number'),
          zipcode: formattedZipCode.replace('-', '')
        }
      },

      items: [
        {
          id: '1',
          title: `${product.label} ${product.price}`,
          unit_price:
            choosedInstallment &&
            formatCurrencyToTransaction(choosedInstallment.value),
          quantity: 1,
          tangible: false
        }
      ],

      description: `Compra ${product && product.label}`
    })
  }, [choosedInstallment, getValues, handleCreateTransaction, product, user])

  useEffect(() => {
    if (product && product.price) {
      const formattedInstallments = formatCheckoutInstallments(product.price)
      setInstallments(formattedInstallments)
    }
  }, [product])

  return (
    <div className="credit-card">
      <div className="card-info">
        <div>
          <Label for="name">Titular do Cartão</Label>
          <Input
            className="form-control"
            name="name"
            type="text"
            onChange={onChange}
            onFocus={(e) => handleInputFocus(e)}
            innerRef={cardHolderNameRef}
            style={{ textTransform: 'uppercase' }}
            {...cardHolderName}
          />
          <small>{errors.name && 'Titular do cartão é obrigatório'}</small>
        </div>
        <div className="card-info-extras">
          <div className="number">
            <Label for="cardNumber">Número do cartão</Label>
            <Input
              className="form-control"
              name="number"
              type="text"
              onChange={onChange}
              pattern="[0-9]{13,16}"
              tag={InputMask}
              mask="9999 9999 9999 9999"
              innerRef={cardNumberRef}
              {...cardNumber}
            />
            <small>{errors.number && 'Número do cartão é obrigatório'}</small>
          </div>
          <div className="expiry">
            <Label for="cardNumber">Validade</Label>
            <Input
              className="form-control"
              name="expiry"
              type="text"
              onChange={onChange}
              tag={InputMask}
              mask="99/99"
              innerRef={cardExpirationRef}
              {...cardExpiration}
            />
            <small>{errors.expiry && 'Validade é obrigatório'}</small>
          </div>
          <div className="cvv">
            <Label for="cardNumber">CVV</Label>
            <Input
              className="form-control"
              name="cvc"
              type="text"
              onInput={(e) =>
                (e.target.value = e.target.value.replace(/[^0-9.]/g, ''))
              }
              onChange={onChange}
              onFocus={(e) => handleInputFocus(e)}
              innerRef={cardCVVRef}
              maxLength={3}
              {...cardCVV}
            />
            <small>{errors.cvc && 'CVV é obrigatório'}</small>
          </div>
        </div>
        <div>
          <Label for="installments">Parcelas</Label>
          <Input
            type="select"
            name="installments"
            onFocus={(e) => handleInputFocus(e)}
            onChange={(e) =>
              setChoosedInstallment({
                quantity: Number(
                  e.target.selectedOptions[0].innerText.split('x')[0]
                ),
                value: Number(e.target.value).toFixed(2).toString()
              })
            }
          >
            <option value="">Selecione</option>
            {installments.map((installment, index) => (
              <option key={index} value={installment.value}>
                {installment.label}
              </option>
            ))}
          </Input>
        </div>
        <div className="user-address">
          <div className="street">
            <div>
              <Label for="street">CEP</Label>
              <Input
                className="form-control"
                type="text"
                onBlur={(e) =>
                  e.target.value !== '' && handleSearchByCEP(e.target.value)
                }
                innerRef={zipcodeRef}
                tag={InputMask}
                mask="99999-999"
                {...zipcode}
              />
              <small>{errors.zipcode && 'CEP é obrigatório'}</small>
            </div>
            <div>
              <Label for="street">Endereço</Label>
              <Input
                className="form-control"
                type="text"
                defaultValue={address.logradouro}
                innerRef={streetRef}
                {...street}
              />
              <small>{errors.street && 'Endereço é obrigatório'}</small>
            </div>
            <div>
              <Label for="street">Cidade</Label>
              <Input
                className="form-control"
                type="text"
                defaultValue={address.localidade}
                innerRef={cityRef}
                {...city}
              />
              <small>{errors.city && 'Cidade é obrigatório'}</small>
            </div>
          </div>
          <div className="location">
            <div>
              <Label for="street">Bairro</Label>
              <Input
                className="form-control"
                type="text"
                name="neighborhood"
                defaultValue={address.bairro}
                innerRef={neighborhoodRef}
                {...neighborhood}
              />
              <small>{errors.neighborhood && 'Bairro é obrigatório'}</small>
            </div>
            <div className="number-plus">
              <div>
                <Label for="street">Número</Label>
                <Input
                  className="form-control"
                  type="text"
                  innerRef={streetNumberRef}
                  {...streetNumber}
                />
                <small>{errors.street_number && 'Número é obrigatório'}</small>
              </div>
              <div>
                <Label for="street">Complemento</Label>
                <Input className="form-control" type="text" />
              </div>
            </div>
            <div>
              <Label for="street">Estado</Label>
              <Input
                className="form-control"
                type="text"
                defaultValue={address.uf}
                maxLength={2}
                innerRef={stateRef}
                style={{ textTransform: 'uppercase' }}
                {...state}
              />
              <small>{errors.state && 'Estado é obrigatório'}</small>
            </div>
          </div>
        </div>

        <button
          className="action-button"
          onClick={handleBuyWithCC}
          disabled={!isValid}
        >
          COMPRAR AGORA
        </button>
      </div>
      <div className="card-show">
        <Cards
          cvc={watchFields[1] ? watchFields[1] : ''}
          expiry={watchFields[2] ? watchFields[2] : ''}
          focused={focusField}
          name={watchFields[3] ? watchFields[3] : ''}
          number={watchFields[0] ? watchFields[0] : ''}
          placeholders={{ name: 'TITULAR DO CARTÃO' }}
          locale={{ valid: 'Valido até' }}
        />
      </div>
    </div>
  )
}

export default CreditCard
