import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Button, Form } from 'reactstrap'
import Select from 'react-select'
import { useForm } from 'react-hook-form'

import { v4 as uuidv4 } from 'uuid'

import { Creators as MoneyActions } from 'store/ducks/money'
import { Creators as BankAccountActions } from 'store/ducks/bankAccounts'

import { formatValueFromCurrencyInputs } from 'utils'

import CurrentInput from 'components/CurrencyInput'

const Withdraw = ({ currentBalance }) => {
  const [accountOptions, setAccountOptions] = useState([])
  const dispatch = useDispatch()
  const {
    auth: { auth },
    bankAccounts: { list }

  } = useSelector((state) => state)

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    reset
  } = useForm()

  const {
    ref: amountRef,
    onChange,
    ...amount
  } = register('amount', { required: 'Valor é obrigatório' })

  const handleCreateWithdrawOrder = useCallback(
    (value) => {
      value.amount = formatValueFromCurrencyInputs(value.amount)

      dispatch(
        MoneyActions.createMoneyRequest({
          ...value,
          orderId: uuidv4(),
          user: {
            id: auth && auth.id
          },
          status: 'pending',
          type: 'withdraw',
          successMessage: 'Solicitação enviada com sucesso!',
          errorMessage: 'Erro ao enviar soliticação'
        })
      )
      reset()
    },
    [auth, dispatch, reset]
  )

  useEffect(() => {
    dispatch(BankAccountActions.getBankAccountRequest(auth && auth.id))
  }, [auth, dispatch, reset])

  useEffect(() => {
    let options = []
    for (let i in list) {
      options.push({
        value: list[i].id,
        label: list[i].bank
      })
    }
    setAccountOptions(options)
  }, [list])
  const minamount = (auth?.plan[0]?.withdraw?.minAmout ? auth.plan[0].withdraw.minAmout : 100.0)
  const canWithdraw = useMemo(
    () => Number(currentBalance) >= minamount,
    [currentBalance, minamount]
  )

  const handleValidationMinimumAmount = (value) => {
    if (
      Number(formatValueFromCurrencyInputs(value)) >
      Number(formatValueFromCurrencyInputs(currentBalance))
    ) {
      setError('amount', {
        message: 'O valor inserido é maior que seu saldo atual'
      })
    } else if (Number(formatValueFromCurrencyInputs(value)) < minamount) {
      setError('amount', {
        message: 'Insira um valor maior do que o mínimo permitido'
      })
    } else {
      clearErrors('amount')
    }
  }

  return (
    <Card className="card">
      <Form onSubmit={handleSubmit(handleCreateWithdrawOrder)}>
        <div className="withdraw">
          <div className="process">
            <strong>Solicitar saque</strong>
            {canWithdraw ? (
              <>
                <div className="amount">
                  <span>Valor</span>
                  <CurrentInput
                    innerRef={amountRef}
                    validation={handleValidationMinimumAmount}
                    {...amount}
                  />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginTop: '10px'
                    }}
                  >
                    <small style={{ color: errors.amount ? 'red' : 'inherit' }}>
                      {errors.amount?.message}
                      {console.log(errors)}
                    </small>
                    <small>Valor mínimo: R$ {minamount}</small>
                  </div>
                </div>
                <div className="bank">
                  <span>Conta</span>
                  <Select
                    options={accountOptions}
                    onChange={(e) => setValue('description', e.label)}
                  />
                </div>
              </>
            ) : (
              <div className="disclaimer">
                <span>Você não possui saldo suficiente.</span>
                <span>Valor mínimo para saque R$ {minamount}</span>
              </div>
            )}
          </div>
          {canWithdraw && (
            <div className="do-withdraw">
              <Button color="primary" type="submit">
                Solicitar saque
              </Button>
            </div>
          )}
        </div>
      </Form>
    </Card>
  )
}

export default Withdraw
