import { put, call } from 'redux-saga/effects'
import { api } from 'services/api'
import { push } from 'connected-react-router'
import { toast } from 'react-toastify'

import { Creators as MaterialsActions } from 'store/ducks/materials'

export function* getMaterials() {
  try {
    const { data } = yield call(api.get, '/materials')
    yield put(MaterialsActions.getMaterialsSuccess(data))
  } catch (err) {
    yield put(MaterialsActions.getMaterialsFailure(err.message))
  }
}

export function* getMaterialByID(action) {
  try {
    const { data } = yield call(api.get, `/materials/${action.payload}`)
    yield put(MaterialsActions.getMaterialSuccess(data))
  } catch (err) {
    yield put(MaterialsActions.getMaterialFailure(err))
  }
}

export function* createMaterial(action) {
  const currentFile = new FormData()
  currentFile.append('file', action.payload.file[0])

  try {
    const response = yield call(api.post, `/materials/file`, currentFile, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    delete action.payload.file
    const body = { ...action.payload, fileUrl: response.data.fileUrl }
    const { data } = yield call(api.post, `/materials`, body)

    yield put(MaterialsActions.createMaterialSuccess(data))
    yield put(push('/materiais-apoio/itens'))
    toast.success('Material criado com sucesso!')
  } catch (err) {
    toast.error('Erro ao criar material')
    yield put(MaterialsActions.createMaterialFailure(err))
  }
}

export function* updateMaterial(action) {
  try {
    let response = action.payload.file
    let body = action.payload
    if (typeof action.payload.file !== 'string') {
      const currentFile = new FormData()
      currentFile.append('file', action.payload.file[0])
      response = yield call(api.post, `/materials/file`, currentFile, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      delete action.payload.file
      body = { ...action.payload, fileUrl: response.data.fileUrl }
    }
    const { data } = yield call(
      api.put,
      `/materials/${action.payload.id}`,
      body
    )
    yield put(MaterialsActions.updateMaterialSuccess(data))
    yield put(push('/materiais-apoio/itens'))
    toast.success('Material editado com sucesso!')
  } catch (err) {
    toast.error('Erro ao editar material')
    yield put(MaterialsActions.updateMaterialFailure(err))
  }
}

export function* deleteMaterial(action) {
  try {
    const { data } = yield call(api.delete, `/materials/${action.payload.id}`)
    yield put(MaterialsActions.deleteMaterialSuccess(data))
    yield put(MaterialsActions.getMaterialsRequest())
    toast.success('Material removido com sucesso')
  } catch (err) {
    toast.error('Erro ao remover material')
    yield put(MaterialsActions.deleteMaterialFailure(err))
  }
}
