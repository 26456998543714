export const Types = {
  GET_MATERIALS_REQUEST: 'materials/GET_MATERIALS_REQUEST',
  GET_MATERIALS_SUCCESS: 'materials/GET_MATERIALS_SUCCESS',
  GET_MATERIALS_FAILURE: 'materials/GET_MATERIALS_FAILURE',

  GET_MATERIAL_REQUEST: 'materials/GET_MATERIAL_REQUEST',
  GET_MATERIAL_SUCCESS: 'materials/GET_MATERIAL_SUCCESS',
  GET_MATERIAL_FAILURE: 'materials/GET_MATERIAL_FAILURE',

  CREATE_MATERIAL_REQUEST: 'materials/CREATE_MATERIAL_REQUEST',
  CREATE_MATERIAL_SUCCESS: 'materials/CREATE_MATERIAL_SUCCESS',
  CREATE_MATERIAL_FAILURE: 'materials/CREATE_MATERIAL_FAILURE',

  UPDATE_MATERIAL_REQUEST: 'materials/UPDATE_MATERIAL_REQUEST',
  UPDATE_MATERIAL_SUCCESS: 'materials/UPDATE_MATERIAL_SUCCESS',
  UPDATE_MATERIAL_FAILURE: 'materials/UPDATE_MATERIAL_FAILURE',

  DELETE_MATERIAL_REQUEST: 'materials/DELETE_MATERIAL_REQUEST',
  DELETE_MATERIAL_SUCCESS: 'materials/DELETE_MATERIAL_SUCCESS',
  DELETE_MATERIAL_FAILURE: 'materials/DELETE_MATERIAL_FAILURE',

  UPDATE_MATERIAL_FILE_REQUEST: 'materials/UPDATE_MATERIAL_FILE_REQUEST',
  UPDATE_MATERIAL_FILE_SUCCESS: 'materials/UPDATE_MATERIAL_FILE_SUCCESS',
  UPDATE_MATERIAL_FILE_FAILURE: 'materials/UPDATE_MATERIAL_FILE_FAILURE'
}

const INITIAL_STATE = {
  list: [],
  material: {},
  loading: false,
  error: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_MATERIALS_REQUEST:
      return { ...state, loading: true, error: null }

    case Types.GET_MATERIALS_SUCCESS:
      return { ...state, loading: false, list: action.payload, error: null }

    case Types.GET_MATERIALS_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case Types.GET_MATERIAL_REQUEST:
      return { ...state, loading: true, material: {}, error: null }

    case Types.GET_MATERIAL_SUCCESS:
      return { ...state, loading: false, material: action.payload, error: null }

    case Types.GET_MATERIAL_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case Types.CREATE_MATERIAL_REQUEST:
      return { ...state, loading: true, error: null }

    case Types.CREATE_MATERIAL_SUCCESS:
      return { ...state, loading: false, error: null }

    case Types.CREATE_MATERIAL_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case Types.UPDATE_MATERIAL_REQUEST:
      return { ...state, loading: true, error: null }

    case Types.UPDATE_MATERIAL_SUCCESS:
      return { ...state, loading: false, material: {}, error: null }

    case Types.UPDATE_MATERIAL_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case Types.DELETE_MATERIAL_REQUEST:
      return { ...state, loading: true, error: null }

    case Types.DELETE_MATERIAL_SUCCESS:
      return { ...state, loading: false, error: null }

    case Types.DELETE_MATERIAL_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case Types.UPDATE_MATERIAL_FILE_REQUEST:
      return { ...state, loading: true, error: null }

    case Types.UPDATE_MATERIAL_FILE_SUCCESS:
      return { ...state, loading: false, error: null }

    case Types.UPDATE_MATERIAL_FILE_FAILURE:
      return { ...state, loading: false, error: action.payload }

    default:
      return state
  }
}

export const Creators = {
  getMaterialsRequest: (payload) => ({
    type: Types.GET_MATERIALS_REQUEST,
    payload
  }),

  getMaterialsSuccess: (payload) => ({
    type: Types.GET_MATERIALS_SUCCESS,
    payload
  }),

  getMaterialsFailure: (payload) => ({
    type: Types.GET_MATERIALS_FAILURE,
    payload
  }),

  getMaterialRequest: (payload) => ({
    type: Types.GET_MATERIAL_REQUEST,
    payload
  }),

  getMaterialSuccess: (payload) => ({
    type: Types.GET_MATERIAL_SUCCESS,
    payload
  }),

  getMaterialFailure: (payload) => ({
    type: Types.GET_MATERIAL_FAILURE,
    payload
  }),

  createMaterialRequest: (payload) => ({
    type: Types.CREATE_MATERIAL_REQUEST,
    payload
  }),

  createMaterialSuccess: (payload) => ({
    type: Types.CREATE_MATERIAL_SUCCESS,
    payload
  }),

  createMaterialFailure: (payload) => ({
    type: Types.CREATE_MATERIAL_FAILURE,
    payload
  }),

  updateMaterialRequest: (payload) => ({
    type: Types.UPDATE_MATERIAL_REQUEST,
    payload
  }),

  updateMaterialSuccess: (payload) => ({
    type: Types.UPDATE_MATERIAL_SUCCESS,
    payload
  }),

  updateMaterialFailure: (payload) => ({
    type: Types.UPDATE_MATERIAL_FAILURE,
    payload
  }),

  deleteMaterialRequest: (payload) => ({
    type: Types.DELETE_MATERIAL_REQUEST,
    payload
  }),

  deleteMaterialSuccess: (payload) => ({
    type: Types.DELETE_MATERIAL_SUCCESS,
    payload
  }),

  deleteMaterialFailure: (payload) => ({
    type: Types.DELETE_MATERIAL_FAILURE,
    payload
  }),

  uploadMaterialFileRequest: (payload) => ({
    type: Types.UPDATE_MATERIAL_FILE_REQUEST,
    payload
  }),

  uploadMaterialFileSuccess: (payload) => ({
    type: Types.UPDATE_MATERIAL_FILE_SUCCESS,
    payload
  }),

  uploadMaterialFileFailure: (payload) => ({
    type: Types.UPDATE_MATERIAL_FILE_FAILURE,
    payload
  })
}
