import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DataTables from "components/Datatable";
import TableActions from "components/TableActions";

import ModalDelete from "components/ModalDelete";

import DefaultPage from "components/DefaultPage";

import { Creators as ProfileActions } from "store/ducks/profiles";
import { profilesDataTableColumns } from "shared/constants/datatable";

const Profiles = () => {
  const dispatch = useDispatch();
  const { list, loading } = useSelector((state) => state.profiles);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});

  useEffect(() => {
    dispatch(ProfileActions.getProfilesRequest());
  }, [dispatch]);

  const handleDeleteItem = useCallback(
    (item) => {
      dispatch(ProfileActions.deleteProfileRequest(item));
      setModalOpen(false);
    },
    [dispatch]
  );

  const handleOpenModal = (value) => {
    setModalOpen(true);
    setModalData({
      title: "Excluir perfil",
      content: `Você tem certeza em excluir o perfil "${value.name}"?`,
      item: value,
    });
  };

  return (
    <DefaultPage
      pageTitle="Perfis"
      pageDescription="Gerenciar Perfis"
      pageSubDescription="Adicione, remove, edite alguns dos itens abaixo"
    >
      <TableActions linkToCreate={"/perfis/criar"} />
      <DataTables
        list={list}
        editURL="/perfis/editar"
        handleOpenModal={handleOpenModal}
        customDataTableColumns={profilesDataTableColumns}
        loading={loading}
      />
      <ModalDelete
        isOpen={modalOpen}
        modalToggle={() => setModalOpen(false)}
        title={modalData.title}
        content={modalData.content}
        deleteItem={handleDeleteItem}
        item={modalData.item}
      />
    </DefaultPage>
  );
};

export default Profiles;
