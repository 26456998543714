import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'

import DefaultPage from 'components/DefaultPage'

import Form from './Form'

import { Creators as FAQActions } from 'store/ducks/faq'

const CreateQuestion = () => {
  const [isActive, setIsActive] = useState(false)
  const dispatch = useDispatch()

  const handleCreateQuestion = useCallback(
    (item) => {
      const body = {
        ...item,
        isActive: isActive === 'true' ? true : false
      }
      dispatch(FAQActions.createQuestionRequest(body))
    },
    [dispatch, isActive]
  )

  const handleActive = useCallback((value) => setIsActive(value), [])

  return (
    <DefaultPage
      pageTitle="Perguntas"
      pageDescription="Criar perguntas"
      pageSubDescription="Criar perguntas e respostas para o FAQ"
    >
      <Form submitForm={handleCreateQuestion} handleActive={handleActive} />
    </DefaultPage>
  )
}

export default CreateQuestion
