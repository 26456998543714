import React from 'react'

import { Card, Table } from 'reactstrap'
import { formatToCurrency } from 'utils'
import { TransactionsCheck } from 'shared/constants/datatable'

import { Link } from 'react-router-dom'

import { formatDate } from 'utils'

const History = ({ list }) => {
  return (
    <div className="history">
      <Card className="card">
        <strong>Construção de Ativo Recentes</strong>
        <Table borderless responsive>
          <thead>
            <tr>
              <th scope="col">Descrição</th>
              <th scope="col">Valor</th>
              <th scope="col">Tipo</th>
              <th scope="col">Status</th>
              <th scope="col">Data</th>
            </tr>
          </thead>
          <tbody>
            {list?.length > 0 &&
              list.map((item) => (
                <tr key={item.id}>
                  <td>{item.description}</td>
                  <td>{formatToCurrency(item.amount)}</td>
                  <td>{item.type}</td>
                  <td>{TransactionsCheck(item.status)}</td>
                  <td>{formatDate(item.updatedAt)}</td>
                </tr>
              ))}
          </tbody>
        </Table>
        <div className="full-history">
          <Link to="/transacoes/minhascompras">Ver histórico completo</Link>
        </div>
      </Card>
    </div>
  )
}

export default History
