import Stores from 'pages/Stores'
import EditStores from 'pages/Stores/Edit'


import { BASE_URL } from 'shared/constants/config'

export default [
  { path: `${BASE_URL}/stores`, component: Stores },
  { path: `${BASE_URL}/stores/edit/:id`, component: EditStores },

]
