import React from "react";
import { Form, Label, Input, Col, Button } from "reactstrap";
import { useForm } from "react-hook-form";

const CreateCashbackForm = ({ submitForm }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { ref: nameRef, ...name } = register("name", { required: true });

  const { ref: percentSystemRef, ...percentSystem } = register(
    "percentSystem",
    {
      required: true,
    }
  );

  const { ref: percentDirectRef, ...percentDirect } = register(
    "percentDirect",
    {
      required: true,
    }
  );

  const { ref: percentLevelRef, ...percentLevel } = register("percentLevel", {
    required: true,
  });

  return (
    <Form
      className="needs-validation"
      noValidate=""
      onSubmit={handleSubmit(submitForm)}
    >
      <div className="form-row">
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom01">Nome</Label>
          <Input
            className="form-control"
            name="name"
            type="text"
            placeholder="Nome"
            innerRef={nameRef}
            {...name}
          />
          <span>{errors.name && "Nome é obrigatório"}</span>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom02">Percentual Sistema</Label>
          <Input
            className="form-control"
            name="percentSystem"
            type="number"
            placeholder="Percentual sistema"
            innerRef={percentSystemRef}
            {...percentSystem}
          />
          <span>
            {errors.percentSystem && "Percentual Sistema é obrigatório"}
          </span>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom02">Percentual Direto</Label>
          <Input
            className="form-control"
            name="percentDirect"
            type="number"
            placeholder="Percentual direto"
            innerRef={percentDirectRef}
            {...percentDirect}
          />
          <span>
            {errors.percentDirect && "Percentual Direto é obrigatório"}
          </span>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom02">Percentual Nível</Label>
          <Input
            className="form-control"
            name="percentLevel"
            type="number"
            placeholder="Percentual nível"
            innerRef={percentLevelRef}
            {...percentLevel}
          />
          <span>{errors.percentLevel && "Percentual Nível é obrigatório"}</span>
        </Col>
      </div>

      <Button color="primary">Enviar</Button>
    </Form>
  );
};

export default CreateCashbackForm;
