import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { isEmpty } from "lodash";

import DefaultPage from "components/DefaultPage";

import EditPositionForm from "components/Positions/EditPositionForm";

import { Creators as PositionsActions } from "store/ducks/positions";

const EditPosition = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { list, position } = useSelector((state) => state.positions);

  const handleUpdatePosition = useCallback(
    (item) => {
      dispatch(PositionsActions.updatePositionRequest({ ...item, id }));
    },
    [dispatch, id]
  );

  useEffect(() => {
    dispatch(PositionsActions.getPositionRequest(id));
  }, [dispatch, id]);

  return (
    <DefaultPage
      pageTitle="Posições"
      pageDescription="Editar posição"
      pageSubDescription="Editar o posição"
    >
      {!isEmpty(position) && (
        <EditPositionForm
          submitForm={handleUpdatePosition}
          currentValue={position}
          positionsList={list}
        />
      )}
    </DefaultPage>
  );
};

export default EditPosition;
