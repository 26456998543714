export const Types = {
  GET_NETWORKS_REQUEST: "network/GET_NETWORKS_REQUEST",
  GET_NETWORKS_SUCCESS: "network/GET_NETWORKS_SUCCESS",
  GET_NETWORKS_FAILURE: "network/GET_NETWORKS_FAILURE",

  GET_NETWORK_REQUEST: "network/GET_NETWORK_REQUEST",
  GET_NETWORK_SUCCESS: "network/GET_NETWORK_SUCCESS",
  GET_NETWORK_FAILURE: "network/GET_NETWORK_FAILURE",

  GET_NETWORK_BY_OWNER_REQUEST: "network/GET_NETWORK_BY_OWNER_REQUEST",
  GET_NETWORK_BY_OWNER_SUCCESS: "network/GET_NETWORK_BY_OWNER_SUCCESS",
  GET_NETWORK_BY_OWNER_FAILURE: "network/GET_NETWORK_BY_OWNER_FAILURE",

  GET_NETWORK_PRODUCTIVE_SUMMARY_REQUEST:
    "network/GET_NETWORK_PRODUCTIVE_SUMMARY_REQUEST",
  GET_NETWORK_PRODUCTIVE_SUMMARY_SUCCESS:
    "network/GET_NETWORK_PRODUCTIVE_SUMMARY_REQUEST",
  GET_NETWORK_PRODUCTIVE_SUMMARY_FAILURE:
    "network/GET_NETWORK_PRODUCTIVE_SUMMARY_REQUEST",
};

const INITIAL_STATE = {
  list: [],
  network: {},
  loading: false,
  error: null,
  networkByOwner: {},
  networkProductiveSummary: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_NETWORKS_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.GET_NETWORKS_SUCCESS:
      return { ...state, loading: false, list: action.payload, error: null };

    case Types.GET_NETWORKS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.GET_NETWORK_REQUEST:
      return { ...state, loading: true, network: {}, error: null };

    case Types.GET_NETWORK_SUCCESS:
      return { ...state, loading: false, network: action.payload, error: null };

    case Types.GET_NETWORK_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.GET_NETWORK_BY_OWNER_REQUEST:
      return { ...state, loading: true, networkByOwner: {}, error: null };

    case Types.GET_NETWORK_BY_OWNER_SUCCESS:
      return {
        ...state,
        loading: false,
        networkByOwner: action.payload,
        error: null,
      };

    case Types.GET_NETWORK_BY_OWNER_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.GET_NETWORK_PRODUCTIVE_SUMMARY_REQUEST:
      return {
        ...state,
        loading: true,
        networkProductiveSummary: {},
        error: null,
      };

    case Types.GET_NETWORK_PRODUCTIVE_SUMMARY_SUCCESS:
      return {
        ...state,
        loading: false,
        networkProductiveSummary: action.payload,
        error: null,
      };

    case Types.GET_NETWORK_PRODUCTIVE_SUMMARY_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export const Creators = {
  getNetworksRequest: (payload) => ({
    type: Types.GET_NETWORKS_REQUEST,
    payload,
  }),

  getNetworksSuccess: (payload) => ({
    type: Types.GET_NETWORKS_SUCCESS,
    payload,
  }),

  getNetworksFailure: (payload) => ({
    type: Types.GET_NETWORKS_FAILURE,
    payload,
  }),

  getNetworkRequest: (payload) => ({
    type: Types.GET_NETWORK_REQUEST,
    payload,
  }),

  getNetworkSuccess: (payload) => ({
    type: Types.GET_NETWORK_SUCCESS,
    payload,
  }),

  getNetworkFailure: (payload) => ({
    type: Types.GET_NETWORK_FAILURE,
    payload,
  }),
  getNetworkByOwnerRequest: (payload) => ({
    type: Types.GET_NETWORK_BY_OWNER_REQUEST,
    payload,
  }),

  getNetworkByOwnerSuccess: (payload) => ({
    type: Types.GET_NETWORK_BY_OWNER_SUCCESS,
    payload,
  }),

  getNetworkByOwnerFailure: (payload) => ({
    type: Types.GET_NETWORK_BY_OWNER_FAILURE,
    payload,
  }),

  getNetworkProductiveSummaryRequest: (payload) => ({
    type: Types.GET_NETWORK_PRODUCTIVE_SUMMARY_REQUEST,
    payload,
  }),

  getNetworkProductiveSummarySuccess: (payload) => ({
    type: Types.GET_NETWORK_PRODUCTIVE_SUMMARY_SUCCESS,
    payload,
  }),

  getNetworkProductiveSummaryFailure: (payload) => ({
    type: Types.GET_NETWORK_PRODUCTIVE_SUMMARY_FAILURE,
    payload,
  }),
};
