import Plans from 'pages/Plans'
import CreatePlan from 'pages/Plans/Create'
import EditPlan from 'pages/Plans/Edit'
import ListPlan from 'pages/Plans/List'

import { BASE_URL } from 'shared/constants/config'

export default [
  { path: `${BASE_URL}/planos`, component: ListPlan },
  { path: `${BASE_URL}/planos/buy`, component: Plans },
  { path: `${BASE_URL}/planos/criar`, component: CreatePlan },
  { path: `${BASE_URL}/planos/editar/:id`, component: EditPlan }
]
