import { put, call, delay } from 'redux-saga/effects'
import { api } from 'services/api'

import { Creators as WalletActions } from 'store/ducks/wallet'
import { Creators as MoneyActions } from 'store/ducks/money'
import { Creators as WithdrawActions } from 'store/ducks/withdraw'

export function* getWallets() {
  try {
    const { data } = yield call(api.get, '/wallets')
    yield put(WalletActions.getWalletsSuccess(data))
  } catch (err) {
    yield put(WalletActions.getWalletsFailure(err.message))
  }
}

export function* getWalletsByID(action) {
  try {
    const { data } = yield call(api.get, `/wallets/${action.payload}`)
    yield put(WalletActions.getWalletSuccess(data))
  } catch (err) {
    yield put(WalletActions.getWalletFailure(err))
  }
}

export function* getWalletByUser(action) {
  try {
    const { data } = yield call(api.get, `/wallets/user/${action.payload}`)
    yield put(WalletActions.getWalletByUserSuccess(data))
  } catch (err) {
    yield put(WalletActions.getWalletByUserFailure(err))
  }
}

export function* updateWallet(action) {
  try {
    const { data } = yield call(api.put, `/wallets/${action.payload.wallet}`, {
      amount: action.payload.amount
    })
    yield put(WalletActions.updateWalletSuccess(data))
    yield put(MoneyActions.updateMoneyRequest(action.payload.money))
    yield delay(3000)
    yield put(
      WithdrawActions.getWithdrawRequestsRequest({ page: 0, limit: 10 })
    )
  } catch (err) {
    yield put(WalletActions.updateWalletFailure(err))
  }
}
