import React from "react";

import { Container, Row, Col, Card, CardHeader, CardBody } from "reactstrap";

import Breadcrumb from "components/Layout/breadcrumb";
import Layout from "components/Layout";

const DefaultPage = ({
  pageTitle,
  pageDescription,
  pageSubDescription,
  children,
}) => {
  return (
    <Layout>
      <Breadcrumb parent="" title={pageTitle} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>{pageDescription}</h5>
                <span>{pageSubDescription}</span>
              </CardHeader>
              <CardBody>{children}</CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default DefaultPage;
