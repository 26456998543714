import React from "react";
import { Form, Label, Input, Col, Button } from "reactstrap";
import { useForm } from "react-hook-form";

const CreateForm = ({ submitForm }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { ref: descriptionRef, ...description } = register("description", {
    required: true,
  });
  const { ref: fileRef, ...file } = register('file', {
    required: true
  })



  return (
    <Form
      className="needs-validation"
      noValidate=""
      onSubmit={handleSubmit(submitForm)}
    >
      <div className="form-row">

        <Col md="4 mb-3">
          <Label htmlFor="validationCustom02">Descrição</Label>
          <Input
            className="form-control"
            name="description"
            type="textarea"

            placeholder="Descrição"
            innerRef={descriptionRef}
            {...description}
          />

          <span>{errors.description && "Descrição é obrigatório"}</span>
        </Col>
        <Col>
          <Label htmlFor="validationCustom02">Arquivo</Label>
          <Input
            className="form-control"
            name="name"
            type="file"
            placeholder="Arquivo"
            innerRef={fileRef}
            {...file}
          />
          <small style={{ color: 'red' }}>
            {errors.answer && 'Arquivo é obrigatório'}
          </small>
        </Col>
      </div>

      <div className="form-row row-gap">
        <Button color="primary">Enviar</Button>
      </div>
    </Form>
  );
};

export default CreateForm;
