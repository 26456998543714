export const Types = {
  GET_LEVELS_REQUEST: "levels/GET_LEVELS_REQUEST",
  GET_LEVELS_SUCCESS: "levels/GET_LEVELS_SUCCESS",
  GET_LEVELS_FAILURE: "levels/GET_LEVELS_FAILURE",

  GET_LEVEL_REQUEST: "levels/GET_LEVEL_REQUEST",
  GET_LEVEL_SUCCESS: "levels/GET_LEVEL_SUCCESS",
  GET_LEVEL_FAILURE: "levels/GET_LEVEL_FAILURE",

  CREATE_LEVEL_REQUEST: "levels/CREATE_LEVEL_REQUEST",
  CREATE_LEVEL_SUCCESS: "levels/CREATE_LEVEL_SUCCESS",
  CREATE_LEVEL_FAILURE: "levels/CREATE_LEVEL_FAILURE",

  UPDATE_LEVEL_REQUEST: "levels/UPDATE_LEVEL_REQUEST",
  UPDATE_LEVEL_SUCCESS: "levels/UPDATE_LEVEL_SUCCESS",
  UPDATE_LEVEL_FAILURE: "levels/UPDATE_LEVEL_FAILURE",

  DELETE_LEVEL_REQUEST: "levels/DELETE_LEVEL_REQUEST",
  DELETE_LEVEL_SUCCESS: "levels/DELETE_LEVEL_SUCCESS",
  DELETE_LEVEL_FAILURE: "levels/DELETE_LEVEL_FAILURE",
};

const INITIAL_STATE = {
  list: [],
  level: {},
  loading: false,
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_LEVELS_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.GET_LEVELS_SUCCESS:
      return { ...state, loading: false, list: action.payload, error: null };

    case Types.GET_LEVELS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.GET_LEVEL_REQUEST:
      return { ...state, loading: true, level: {}, error: null };

    case Types.GET_LEVEL_SUCCESS:
      return {
        ...state,
        loading: false,
        level: action.payload,
        error: null,
      };

    case Types.GET_LEVEL_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.CREATE_LEVEL_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.CREATE_LEVEL_SUCCESS:
      return { ...state, loading: false, error: null };

    case Types.CREATE_LEVEL_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.UPDATE_LEVEL_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.UPDATE_LEVEL_SUCCESS:
      return { ...state, loading: false, level: {}, error: null };

    case Types.UPDATE_LEVEL_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.DELETE_LEVEL_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.DELETE_LEVEL_SUCCESS:
      return { ...state, loading: false, error: null };

    case Types.DELETE_LEVEL_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export const Creators = {
  getLevelsRequest: (payload) => ({
    type: Types.GET_LEVELS_REQUEST,
    payload,
  }),

  getLevelsSuccess: (payload) => ({
    type: Types.GET_LEVELS_SUCCESS,
    payload,
  }),

  getLevelsFailure: (payload) => ({
    type: Types.GET_LEVELS_FAILURE,
    payload,
  }),

  getLevelRequest: (payload) => ({
    type: Types.GET_LEVEL_REQUEST,
    payload,
  }),

  getLevelSuccess: (payload) => ({
    type: Types.GET_LEVEL_SUCCESS,
    payload,
  }),

  getLevelFailure: (payload) => ({
    type: Types.GET_LEVEL_FAILURE,
    payload,
  }),

  createLevelRequest: (payload) => ({
    type: Types.CREATE_LEVEL_REQUEST,
    payload,
  }),

  createLevelSuccess: (payload) => ({
    type: Types.CREATE_LEVEL_SUCCESS,
    payload,
  }),

  createLevelFailure: (payload) => ({
    type: Types.CREATE_LEVEL_FAILURE,
    payload,
  }),

  updateLevelRequest: (payload) => ({
    type: Types.UPDATE_LEVEL_REQUEST,
    payload,
  }),

  updateLevelSuccess: (payload) => ({
    type: Types.UPDATE_LEVEL_SUCCESS,
    payload,
  }),

  updateLevelFailure: (payload) => ({
    type: Types.UPDATE_LEVEL_FAILURE,
    payload,
  }),

  deleteLevelRequest: (payload) => ({
    type: Types.DELETE_LEVEL_REQUEST,
    payload,
  }),

  deleteLevelSuccess: (payload) => ({
    type: Types.DELETE_LEVEL_SUCCESS,
    payload,
  }),

  deleteLevelFailure: (payload) => ({
    type: Types.DELETE_LEVEL_FAILURE,
    payload,
  }),
};
