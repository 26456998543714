export const Types = {
  GET_WALLETS_REQUEST: 'wallet/GET_WALLETS_REQUEST',
  GET_WALLETS_SUCCESS: 'wallet/GET_WALLETS_SUCCESS',
  GET_WALLETS_FAILURE: 'wallet/GET_WALLETS_FAILURE',

  GET_WALLET_REQUEST: 'wallet/GET_WALLET_REQUEST',
  GET_WALLET_SUCCESS: 'wallet/GET_WALLET_SUCCESS',
  GET_WALLET_FAILURE: 'wallet/GET_WALLET_FAILURE',

  GET_WALLET_BY_USER_REQUEST: 'wallet/GET_WALLET_BY_USER_REQUEST',
  GET_WALLET_BY_USER_SUCCESS: 'wallet/GET_WALLET_BY_USER_SUCCESS',
  GET_WALLET_BY_USER_FAILURE: 'wallet/GET_WALLET_BY_USER_FAILURE',

  UPDATE_WALLET_REQUEST: 'wallet/UPDATE_WALLET_REQUEST',
  UPDATE_WALLET_SUCCESS: 'wallet/UPDATE_WALLET_SUCCESS',
  UPDATE_WALLET_FAILURE: 'wallet/UPDATE_WALLET_FAILURE'
}

const INITIAL_STATE = {
  list: [],
  wallet: {},
  loading: false,
  error: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_WALLETS_REQUEST:
      return { ...state, loading: true, error: null }

    case Types.GET_WALLETS_SUCCESS:
      return { ...state, loading: false, list: action.payload, error: null }

    case Types.GET_WALLETS_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case Types.GET_WALLET_REQUEST:
      return { ...state, loading: true, wallet: {}, error: null }

    case Types.GET_WALLET_SUCCESS:
      return { ...state, loading: false, wallet: action.payload, error: null }

    case Types.GET_WALLET_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case Types.GET_WALLET_BY_USER_REQUEST:
      return { ...state, loading: true, wallet: {}, error: null }

    case Types.GET_WALLET_BY_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        wallet: action.payload,
        error: null
      }

    case Types.GET_WALLET_BY_USER_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case Types.UPDATE_WALLET_REQUEST:
      return { ...state, loading: true, error: null, wallet: {} }

    case Types.UPDATE_WALLET_SUCCESS:
      return { ...state, loading: false, error: null, wallet: {} }

    case Types.UPDATE_WALLET_FAILURE:
      return { ...state, loading: false, error: null }

    default:
      return state
  }
}

export const Creators = {
  getWalletsRequest: (payload) => ({
    type: Types.GET_WALLETS_REQUEST,
    payload
  }),

  getWalletsSuccess: (payload) => ({
    type: Types.GET_WALLETS_SUCCESS,
    payload
  }),

  getWalletsFailure: (payload) => ({
    type: Types.GET_WALLETS_FAILURE,
    payload
  }),

  getWalletRequest: (payload) => ({
    type: Types.GET_WALLET_REQUEST,
    payload
  }),

  getWalletSuccess: (payload) => ({
    type: Types.GET_WALLET_SUCCESS,
    payload
  }),

  getWalletFailure: (payload) => ({
    type: Types.GET_WALLET_FAILURE,
    payload
  }),
  getWalletByUserRequest: (payload) => ({
    type: Types.GET_WALLET_BY_USER_REQUEST,
    payload
  }),

  getWalletByUserSuccess: (payload) => ({
    type: Types.GET_WALLET_BY_USER_SUCCESS,
    payload
  }),

  getWalletByUserFailure: (payload) => ({
    type: Types.GET_WALLET_BY_USER_FAILURE,
    payload
  }),

  updateWalletRequest: (payload) => ({
    type: Types.UPDATE_WALLET_REQUEST,
    payload
  }),

  updateWalletSuccess: (payload) => ({
    type: Types.UPDATE_WALLET_SUCCESS,
    payload
  }),

  updateWalletFailure: (payload) => ({
    type: Types.UPDATE_WALLET_FAILURE,
    payload
  })
}
