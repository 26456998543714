import React from 'react'
import {
  Activity,
  DollarSign,
  Grid,
  Settings,
  ShoppingCart,
  Target,
  User,
  Users,
  LogOut,
  HelpCircle,
  Database
} from 'react-feather'

export const menuItems = [
  {
    name: 'Dashboard',
    icon: <Grid size={16} />,
    path: `/dashboard`
  },
  {
    name: 'Administração',
    icon: <Settings size={16} />,
    subItems: [

      {
        name: 'Usuários em atraso',
        path: '/deadbeat',
        icon: <Users size={16} />
      },

      {
        name: 'Solicitações de saque',
        path: '/saques/solicitacoes',
        icon: <Users size={16} />
      },
      {
        name: 'Relatórios',
        path: '/relatorios',
        icon: <Database size={16} />
      },
      {
        name: 'Ações',
        path: '/acoes',
        icon: <Users size={16} />
      },
      {
        name: 'Perfis',
        path: '/perfis',
        icon: <Users size={16} />
      },
      {
        name: 'Bonificações',
        path: '/bonificacoes'
      },
      {
        name: 'Cashbacks',
        path: '/cashbacks'
      },

      {
        name: 'Níveis',
        path: '/niveis'
      },

      {
        name: 'Habilitações',
        path: '/planos'
      },

      {
        name: 'Posições',
        path: '/posicoes'
      },
      {
        name: 'Métodos de Pagamento',
        path: '/pagamentos/metodos'
      },

      {
        name: 'Serviços',
        path: '/servicos'
      },

      {
        name: 'Lojas',
        path: '/stores'
      },
      {
        name: 'Tutoriais',
        path: '/tutoriais/itens'
      },
      {
        name: 'Faq',
        path: '/faq/itens'
      },
      {
        name: 'Material de Apoio',
        path: '/materiais-apoio/itens'
      },
    ]
  },
  {
    name: 'Suporte',
    icon: <Settings size={16} />,
    subItems: [
      {
        name: 'Usuários',
        path: '/usuarios',
        icon: <Users size={16} />
      },
      {
        name: 'Empresas',
        path: '/credenciados',
        icon: <Users size={16} />
      },
      {
        name: 'Formulário de Suporte',
        icon: <Activity size={16} />,
        path: `/suporte`
      },
      {
        name: 'Transações',
        path: '/transacoes',
        icon: <Users size={16} />
      },
      {
        name: 'Notificações',
        icon: <DollarSign size={16} />,
        path: `/notificacoes`
      },
    ]
  },
  {
    name: 'Empresas',
    icon: <Settings size={16} />,
    subItems: [
      {
        name: 'Faturas',
        path: '/invoice',
        icon: <Users size={16} />
      },
      {
        name: 'Vendas',
        path: '/sales',
        icon: <Users size={16} />
      },
      // {
      //   name: 'Meus Vendedores',
      //   path: '/user/sellers',
      //   icon: <Users size={16} />
      // }
    ]
  },
  {
    name: 'Financeiro',
    icon: <Settings size={16} />,
    subItems: [
      {
        name: 'Empresas',
        icon: <Activity size={16} />,
        path: `/transacoes/empresas`
      },
      {
        name: 'Minhas Compras',
        icon: <Activity size={16} />,
        path: `/transacoes/minhascompras`
      },
      {
        name: 'Habilitação',
        icon: <Activity size={16} />,
        path: `/transacoes/bonusindicacao`
      },
      {
        name: 'Construção de Ativo',
        icon: <DollarSign size={16} />,
        path: `/financeiro`
      },
    ]
  },

  {
    name: 'Habilitações',
    icon: <Target size={16} />,
    path: `/planos/buy`
  },
  {
    name: 'Rede',
    icon: <Users size={16} />,
    path: `/rede`
  },



  {
    name: 'Perfil',
    icon: <User size={16} />,
    path: `/perfil`
  },
  {
    name: 'Formulário de Suporte',
    icon: <HelpCircle size={16} />,
    path: `/suporte`
  },
  {
    name: 'Lojas',
    icon: <ShoppingCart size={16} />,
    path: `https://shop.quantabank.com.br/`,//replicado tbm src/shared/hooks/useFunctionRole.js
    external: true

  },
  {
    icon: <HelpCircle size={16} />,

    name: 'Tutoriais',
    path: '/tutoriais'
  },
  {
    icon: <HelpCircle size={16} />,

    name: 'Material de Apoio',
    path: '/materiais-apoio'
  },
  {
    icon: <HelpCircle size={16} />,

    name: 'Faq',
    path: '/faq'
  },
  {
    name: 'Sair',
    icon: <LogOut size={16} />,
    path: `/logout`

  }
]
