import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap'
import { modalContentStyle, modalActionsStyle } from './style'

const ModalAprovement = ({ modalOpen, modalToggle, handleWithdraw }) => (
  <Modal isOpen={modalOpen} toggle={modalToggle} centered>
    <ModalBody>
      <div>
        <div style={modalContentStyle}>
          <strong style={{ fontSize: '16px' }}>Aprovar solicitação</strong>
          <span>Deseja realmente aprovar a solicitação?</span>
        </div>
        <div style={modalActionsStyle}>
          <Button color="primary" onClick={() => modalToggle(false)}>
            Cancelar
          </Button>
          <Button color="success" onClick={handleWithdraw}>
            Aprovar
          </Button>
        </div>
      </div>
    </ModalBody>
  </Modal>
)

export default ModalAprovement
