import React, { useEffect, useState } from "react";
import { Form, Label, Input, Col, Button } from "reactstrap";
import { useForm } from "react-hook-form";
import Select from "react-select";

const CreatePositionForm = ({ submitForm, positionsList }) => {
  const [optionsSelect, setOptionsSelect] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const { ref: nameRef, ...name } = register("name", { required: true });

  const { ref: minScoreRef, ...minScore } = register("minScore", {
    required: true,
  });
  const { ref: minActiveUserRef, ...minActiveUser } = register("minActiveUser", {
    required: true,
  });
  const { ref: descriptionRef, ...description } = register("description", {
    required: true,
  });
  const { ref: nextPositionRef, ...nextPosition } = register("nextPosition");

  useEffect(() => {
    let options = [];
    for (let i in positionsList) {
      options.push({
        value: positionsList[i].id,
        label: positionsList[i].name,
      });
    }
    setOptionsSelect(options);
  }, [positionsList]);

  return (
    <Form
      className="needs-validation"
      noValidate=""
      onSubmit={handleSubmit(submitForm)}
    >
      <div className="form-row">
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom01">Nome</Label>
          <Input
            className="form-control"
            name="name"
            type="text"
            placeholder="Nome"
            innerRef={nameRef}
            {...name}
          />
          <span>{errors.name && "Nome é obrigatório"}</span>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom02">Descrição</Label>
          <Input
            className="form-control"
            name="description"
            type="text"
            placeholder="Descrição"
            innerRef={descriptionRef}
            {...description}
          />

          <span>{errors.description && "Descrição é obrigatório"}</span>
        </Col>
      </div>
      <div className="form-row">
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">Mínimo de Pontos</Label>
          <Input
            className="form-control"
            name="minScore"
            type="text"
            placeholder="Mínimo de Pontos"
            innerRef={minScoreRef}
            {...minScore}
          />
          <span>{errors.name && "minScore é obrigatório"}</span>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom04">Mínimo de Pessoas na Rede Ativos</Label>
          <Input
            className="form-control"
            name="minActiveUser"
            type="text"
            placeholder="Mínimo de Rede"
            innerRef={minActiveUserRef}
            {...minActiveUser}
          />

          <span>{errors.minActiveUser && "minActiveUser é obrigatório"}</span>
        </Col>
      </div>
      <div className="form-row row-gap">
        <Col sm="12">
          <h5>Próxima Posição (Upgrade)</h5>
        </Col>
        <Col xl="4" lg="4">
          <Select
            options={optionsSelect}
            onChange={(e) => setValue("nextPosition", [{ id: e.value }])}
          />
        </Col>
      </div>
      <div className="form-row row-gap">
        <Button color="primary">Enviar</Button>
      </div>
    </Form>
  );
};

export default CreatePositionForm;
