import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { isEmpty } from "lodash";

import DefaultPage from "components/DefaultPage";
import EditPlanForm from "components/Plans/EditPlanForm";

import { Creators as PlanActions } from "store/ducks/plans";
import { Creators as LevelActions } from "store/ducks/levels";

const EditPlan = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { plan } = useSelector((state) => state.plans);
  const { list } = useSelector((state) => state.levels);

  const handleUpdatePlan = useCallback(
    (item) => {
      let bodyIDs = [];
      item.levels.map((item) => bodyIDs.push({ id: item }));
      const saque = { 'minAmout': item.minAmout, 'name': item.name };
      delete item.levels;
      let body = { ...item, levels: bodyIDs, withdraw: saque };

      console.log(body)


      dispatch(PlanActions.updatePlanRequest({ ...body, id }));
    },
    [dispatch, id]
  );

  useEffect(() => {
    dispatch(LevelActions.getLevelsRequest());
  }, [dispatch]);

  useEffect(() => {
    dispatch(PlanActions.getPlanRequest(id));
  }, [dispatch, id]);

  return (
    <DefaultPage
      pageTitle="Habilitações"
      pageDescription="Editar Habilitação"
      pageSubDescription="Editar o Habilitação"
    >
      {!isEmpty(plan) && (
        <EditPlanForm
          submitForm={handleUpdatePlan}
          currentValue={plan}
          listLevels={list}
        />
      )}
    </DefaultPage>
  );
};

export default EditPlan;
