import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import DataTables from "components/Datatable";
import TableActions from "components/TableActions";
import ModalDelete from "components/ModalDelete";

import { Creators as SupportsActions } from "store/ducks/support";
import { supportDataTableColumns } from "shared/constants/datatable";

import DefaultListPage from "components/DefaultPage";

const Supports = () => {
  const dispatch = useDispatch();
  const { list, loading } = useSelector((state) => state.support);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});

  const {
    auth: { auth },
  } = useSelector((state) => state)

  const adminProfile = useMemo(() => {
    return auth.profile?.find((item) => item.name === 'Perfil Tudo')?.id
  }, [auth.profile])

  const supportProfile = useMemo(() => {
    return auth.profile?.find((item) => item.name === 'Suporte')?.id
  }, [auth.profile])

  useEffect(() => {
    if (adminProfile || supportProfile)
      dispatch(SupportsActions.getSupportsRequest());
    else
      dispatch(SupportsActions.getSupportsByUserRequest(auth.id));

  }, [adminProfile, auth.id, dispatch, supportProfile]);

  const handleDeleteItem = useCallback(
    (item) => {
      dispatch(SupportsActions.deleteSupportRequest(item));
      setModalOpen(false);
    },
    [dispatch]
  );

  const handleOpenModal = (value) => {
    setModalOpen(true);
    setModalData({
      title: "Excluir suporte",
      content: `Você tem certeza em excluir a suporte "${value.name}"?`,
      item: value,
    });
  };

  return (
    <DefaultListPage
      pageTitle="Suporte"
      pageDescription="Tickets de Suporte"
      pageSubDescription="Adicione, remove, edite alguns dos itens abaixo"
    >
      <TableActions linkToCreate={"/suporte/criar"} />
      <DataTables
        list={list}
        editURL="/suporte/editar"
        handleOpenModal={handleOpenModal}
        customDataTableColumns={supportDataTableColumns}
        loading={loading}
      />
      <ModalDelete
        isOpen={modalOpen}
        modalToggle={() => setModalOpen(false)}
        title={modalData.title}
        content={modalData.content}
        deleteItem={handleDeleteItem}
        item={modalData.item}
      />
    </DefaultListPage>
  );
};

export default Supports;
