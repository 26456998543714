export const Types = {
  CREATE_MONEY_REQUEST: 'money/CREATE_MONEY_REQUEST',
  CREATE_MONEY_SUCCESS: 'money/CREATE_MONEY_SUCCESS',
  CREATE_MONEY_FAILURE: 'money/CREATE_MONEY_FAILURE',

  GET_MONEYS_REQUEST: 'money/GET_MONEYS_REQUEST',
  GET_MONEYS_SUCCESS: 'money/GET_MONEYS_SUCCESS',
  GET_MONEYS_FAILURE: 'money/GET_MONEYS_FAILURE',

  GET_MONEY_REQUEST: 'money/GET_MONEY_REQUEST',
  GET_MONEY_SUCCESS: 'money/GET_MONEY_SUCCESS',
  GET_MONEY_FAILURE: 'money/GET_MONEY_FAILURE',

  GET_MONEY_BY_USER_REQUEST: 'money/GET_MONEY_BY_USER_REQUEST',
  GET_MONEY_BY_USER_SUCCESS: 'money/GET_MONEY_BY_USER_SUCCESS',
  GET_MONEY_BY_USER_FAILURE: 'money/GET_MONEY_BY_USER_FAILURE',


  GET_MONEY_BY_USER_CASHBACK_REQUEST: 'money/GET_MONEY_BY_USER_CASHBACK_REQUEST',
  GET_MONEY_BY_USER_CASHBACK_SUCCESS: 'money/GET_MONEY_BY_USER_CASHBACK_SUCCESS',
  GET_MONEY_BY_USER_CASHBACK_FAILURE: 'money/GET_MONEY_BY_USER_CASHBACK_FAILURE',


  GET_MONEY_BY_USER_ACCREDITED_REQUEST: 'money/GET_MONEY_BY_USER_ACCREDITED_REQUEST',
  GET_MONEY_BY_USER_ACCREDITED_SUCCESS: 'money/GET_MONEY_BY_USER_ACCREDITED_SUCCESS',
  GET_MONEY_BY_USER_ACCREDITED_FAILURE: 'money/GET_MONEY_BY_USER_ACCREDITED_FAILURE',

  GET_MONEY_BY_USER_BONUS_ACCREDITED_REQUEST: 'money/GET_MONEY_BY_USER_BONUS_ACCREDITED_REQUEST',
  GET_MONEY_BY_USER_BONUS_ACCREDITED_SUCCESS: 'money/GET_MONEY_BY_USER_BONUS_ACCREDITED_SUCCESS',
  GET_MONEY_BY_USER_BONUS_ACCREDITED_FAILURE: 'money/GET_MONEY_BY_USER_BONUS_ACCREDITED_FAILURE',

  GET_MONEY_BY_USER_BONUS_ACCESSION_REQUEST: 'money/GET_MONEY_BY_USER_BONUS_ACCESSION_REQUEST',
  GET_MONEY_BY_USER_BONUS_ACCESSION_SUCCESS: 'money/GET_MONEY_BY_USER_BONUS_ACCESSION_SUCCESS',
  GET_MONEY_BY_USER_BONUS_ACCESSION_FAILURE: 'money/GET_MONEY_BY_USER_BONUS_ACCESSION_FAILURE',


  GET_MONEY_ADMIN_DASH_REQUEST: 'money/GET_MONEY_ADMIN_DASH_REQUEST',
  GET_MONEY_ADMIN_DASH_SUCCESS: 'money/GET_MONEY_ADMIN_DASH_SUCCESS',
  GET_MONEY_ADMIN_DASH_FAILURE: 'money/GET_MONEY_ADMIN_DASH_FAILURE',

  GET_MONEY_ADMIN_DASH_DETAIL_REQUEST:
    'money/GET_MONEY_ADMIN_DASH_DETAIL_REQUEST',
  GET_MONEY_ADMIN_DASH_DETAIL_SUCCESS:
    'money/GET_MONEY_ADMIN_DASH_DETAIL_SUCCESS',
  GET_MONEY_ADMIN_DASH_DETAIL_FAILURE:
    'money/GET_MONEY_ADMIN_DASH_DETAIL_FAILURE',

  GET_MONEY_USER_BY_TYPE_SUMMARY_REQUEST:
    'money/GET_MONEY_USER_BY_TYPE_SUMMARY_REQUEST',
  GET_MONEY_USER_BY_TYPE_SUMMARY_SUCCESS:
    'money/GET_MONEY_USER_BY_TYPE_SUMMARY_SUCCESS',
  GET_MONEY_USER_BY_TYPE_SUMMARY_FAILURE:
    'money/GET_MONEY_USER_BY_TYPE_SUMMARY_FAILURE',

  GET_MONEY_SALES_REQUEST: 'money/GET_MONEY_SALES_REQUEST',
  GET_MONEY_SALES_SUCCESS: 'money/GET_MONEY_SALES_SUCCESS',
  GET_MONEY_SALES_FAILURE: 'money/GET_MONEY_SALES_FAILURE',

  UPDATE_MONEY_REQUEST: "money/UPDATE_MONEY_REQUEST",
  UPDATE_MONEY_SUCCESS: "money/UPDATE_MONEY_SUCCESS",
  UPDATE_MONEY_FAILURE: "money/UPDATE_MONEY_FAILURE",

  GET_MONEY_BY_USER_PLAN_INSTALLMENTS_REQUEST: 'money/GET_MONEY_BY_USER_PLAN_INSTALLMENTS_REQUEST',
  GET_MONEY_BY_USER_PLAN_INSTALLMENTS_SUCCESS: 'money/GET_MONEY_BY_USER_PLAN_INSTALLMENTS_SUCCESS',
  GET_MONEY_BY_USER_PLAN_INSTALLMENTS_FAILURE: 'money/GET_MONEY_BY_USER_PLAN_INSTALLMENTS_FAILURE',
}


const INITIAL_STATE = {
  list: [],
  money: {},
  loading: false,
  error: null,
  moneyByUser: {},
  moneyByUserCashback: {},
  moneyByUserBonusAccredited: {},
  moneyAdminDash: {},
  moneyAdminDashDetail: {},
  moneyUserByTypeSummary: {},
  moneySalesAccredited: {},
  moneyByUserBonusAccession: {},
  moneyByUserPlanInstallments: {},

}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.CREATE_MONEY_REQUEST:
      return { ...state, loading: true, error: null }

    case Types.CREATE_MONEY_SUCCESS:
      return { ...state, loading: false, error: null }

    case Types.CREATE_MONEY_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case Types.GET_MONEYS_REQUEST:
      return { ...state, loading: true, error: null }

    case Types.GET_MONEYS_SUCCESS:
      for (let index = 0; index < action.payload[0].length; index++) {
        action.payload[0][index].type = getMoneyTypeFriendlyName(action.payload[0][index].type);
      }
      return { ...state, loading: false, list: action.payload, error: null }

    case Types.GET_MONEYS_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case Types.GET_MONEY_REQUEST:
      return { ...state, loading: true, money: {}, error: null }

    case Types.GET_MONEY_SUCCESS:
      return { ...state, loading: false, money: action.payload, error: null }

    case Types.GET_MONEY_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case Types.GET_MONEY_BY_USER_REQUEST:
      return { ...state, loading: true, moneyByUser: {}, error: null }

    case Types.GET_MONEY_BY_USER_SUCCESS:

      for (let index = 0; index < action.payload.list.length; index++) {
        action.payload.list[index].type = getMoneyTypeFriendlyName(action.payload.list[index].type);
      }
      return {
        ...state,
        loading: false,
        moneyByUser: action.payload,
        error: null
      }

    case Types.GET_MONEY_BY_USER_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case Types.GET_MONEY_BY_USER_CASHBACK_REQUEST:
      return { ...state, loading: true, moneyByUserCashback: {}, error: null }

    case Types.GET_MONEY_BY_USER_CASHBACK_SUCCESS:
      return {
        ...state,
        loading: false,
        moneyByUserCashback: action.payload,
        error: null
      }

    case Types.GET_MONEY_BY_USER_CASHBACK_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case Types.GET_MONEY_BY_USER_BONUS_ACCREDITED_REQUEST:
      return { ...state, loading: true, moneyByUserBonusAccredited: {}, error: null }

    case Types.GET_MONEY_BY_USER_BONUS_ACCREDITED_SUCCESS:
      return {
        ...state,
        loading: false,
        moneyByUserBonusAccredited: action.payload,
        error: null
      }

    case Types.GET_MONEY_BY_USER_BONUS_ACCREDITED_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case Types.GET_MONEY_BY_USER_BONUS_ACCESSION_REQUEST:
      return { ...state, loading: true, moneyByUserBonusAccession: {}, error: null }

    case Types.GET_MONEY_BY_USER_BONUS_ACCESSION_SUCCESS:
      return {
        ...state,
        loading: false,
        moneyByUserBonusAccession: action.payload,
        error: null
      }

    case Types.GET_MONEY_BY_USER_BONUS_ACCESSION_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case Types.GET_MONEY_BY_USER_ACCREDITED_REQUEST:
      return { ...state, loading: true, moneyByUserAccredited: {}, error: null }

    case Types.GET_MONEY_BY_USER_ACCREDITED_SUCCESS:
      return {
        ...state,
        loading: false,
        moneyByUserAccredited: action.payload,
        error: null
      }

    case Types.GET_MONEY_BY_USER_ACCREDITED_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case Types.GET_MONEY_ADMIN_DASH_REQUEST:
      return { ...state, loading: true, moneyAdminDash: {}, error: null }

    case Types.GET_MONEY_ADMIN_DASH_SUCCESS:
      return {
        ...state,
        loading: false,
        moneyAdminDash: action.payload,
        error: null
      }

    case Types.GET_MONEY_ADMIN_DASH_FAILURE:
      return { ...state, loading: false, error: action.payload }


    case Types.GET_MONEY_ADMIN_DASH_DETAIL_REQUEST:
      return { ...state, loading: true, moneyAdminDashDetail: {}, error: null }

    case Types.GET_MONEY_ADMIN_DASH_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        moneyAdminDashDetail: action.payload,
        error: null
      }

    case Types.GET_MONEY_ADMIN_DASH_DETAIL_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case Types.GET_MONEY_USER_BY_TYPE_SUMMARY_REQUEST:
      return { ...state, loading: true, error: null }

    case Types.GET_MONEY_USER_BY_TYPE_SUMMARY_SUCCESS:
      return {
        ...state,
        loading: false,
        moneyUserByTypeSummary: action.payload
      }

    case Types.GET_MONEY_USER_BY_TYPE_SUMMARY_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case Types.GET_MONEY_SALES_REQUEST:
      return { ...state, loading: true, moneySalesAccredited: {}, error: null }

    case Types.GET_MONEY_SALES_SUCCESS:
      return { ...state, loading: false, moneySalesAccredited: action.payload, error: null }

    case Types.GET_MONEY_SALES_FAILURE:
      return { ...state, loading: false, moneySalesAccredited: action.payload }
    case Types.UPDATE_MONEY_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.UPDATE_MONEY_SUCCESS:
      return { ...state, loading: false, money: {}, error: null };

    case Types.UPDATE_MONEY_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case Types.GET_MONEY_BY_USER_PLAN_INSTALLMENTS_REQUEST:
      return { ...state, loading: true, moneyByUserPlanInstallments: {}, error: null }

    case Types.GET_MONEY_BY_USER_PLAN_INSTALLMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        moneyByUserPlanInstallments: action.payload,
        error: null
      }

    case Types.GET_MONEY_BY_USER_PLAN_INSTALLMENTS_FAILURE:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }

  function getMoneyTypeFriendlyName(type) {
    let retorno = "";
    switch (type) {
      case 'bonus_accession':
        retorno = "Bonus adesão"

        break

      case 'bonus_accession_residual':
        retorno = "Bonus Residual adesão"

        break

      case 'bonus_accredited':
        retorno = "Bonus credenciado"

        break

      case 'bonus_accredited_residual':
        retorno = "Bonus Residual credenciado"

        break

      case 'cashback':
        retorno = "Cashback"

        break

      case 'cashback_residual':
        retorno = "Residual Cashback"

        break

      case 'cashback_accredited':
        retorno = "Cashback"

        break

      case 'cashback_accredited_residual':
        retorno = "Cashback"

        break

      case 'sales_cashback_accredited':
        retorno = "Venda Loja Física"

        break

      case 'deposit':
        retorno = "Deposito"

        break

      case 'plan':
        retorno = "Plano"

        break

      case 'transfer':
        retorno = "Transferencia"

        break

      case 'withdraw':
        retorno = "Saque"

        break

      case 'refund':
        retorno = "Estorno"

        break

      case 'manual':
        retorno = "Manual"

        break

      default:
        break
    }
    return retorno;
  }
}

export const Creators = {
  createMoneyRequest: (payload) => ({
    type: Types.CREATE_MONEY_REQUEST,
    payload
  }),

  createMoneySuccess: (payload) => ({
    type: Types.CREATE_MONEY_SUCCESS,
    payload
  }),

  createMoneyFailure: (payload) => ({
    type: Types.CREATE_MONEY_FAILURE,
    payload
  }),


  getMoneyByUserPlanInstallmentsRequest: (payload) => ({
    type: Types.GET_MONEY_BY_USER_PLAN_INSTALLMENTS_REQUEST,
    payload
  }),
  getMoneyByUserPlanInstallmentsSuccess: (payload) => ({
    type: Types.GET_MONEY_BY_USER_PLAN_INSTALLMENTS_SUCCESS,
    payload
  }),
  getMoneyByUserPlanInstallmentsFailure: (payload) => ({
    type: Types.GET_MONEY_BY_USER_PLAN_INSTALLMENTS_FAILURE,
    payload
  }),
  getMoneysRequest: (payload) => ({
    type: Types.GET_MONEYS_REQUEST,
    payload
  }),

  getMoneysSuccess: (payload) => ({
    type: Types.GET_MONEYS_SUCCESS,
    payload
  }),

  getMoneysFailure: (payload) => ({
    type: Types.GET_MONEYS_FAILURE,
    payload
  }),

  getMoneyRequest: (payload) => ({
    type: Types.GET_MONEY_REQUEST,
    payload
  }),

  getMoneySuccess: (payload) => ({
    type: Types.GET_MONEY_SUCCESS,
    payload
  }),

  getMoneyFailure: (payload) => ({
    type: Types.GET_MONEY_FAILURE,
    payload
  }),
  getMoneyByUserRequest: (payload) => ({
    type: Types.GET_MONEY_BY_USER_REQUEST,
    payload
  }),

  getMoneyByUserSuccess: (payload) => ({
    type: Types.GET_MONEY_BY_USER_SUCCESS,
    payload
  }),

  getMoneyByUserFailure: (payload) => ({
    type: Types.GET_MONEY_BY_USER_FAILURE,
    payload
  }),

  getMoneyByUserCashbackRequest: (payload) => ({
    type: Types.GET_MONEY_BY_USER_CASHBACK_REQUEST,
    payload
  }),

  getMoneyByUserCashbackSuccess: (payload) => ({
    type: Types.GET_MONEY_BY_USER_CASHBACK_SUCCESS,
    payload
  }),

  getMoneyByUserCashbackFailure: (payload) => ({
    type: Types.GET_MONEY_BY_USER_CASHBACK_FAILURE,
    payload
  }),


  getMoneyByUserBonusAccreditedRequest: (payload) => ({
    type: Types.GET_MONEY_BY_USER_BONUS_ACCREDITED_REQUEST,
    payload
  }),

  getMoneyByUserBonusAccessionSuccess: (payload) => ({
    type: Types.GET_MONEY_BY_USER_BONUS_ACCESSION_SUCCESS,
    payload
  }),

  getMoneyByUserBonusAccessionFailure: (payload) => ({
    type: Types.GET_MONEY_BY_USER_BONUS_ACCESSION_FAILURE,
    payload
  }),

  getMoneyByUserBonusAccessionRequest: (payload) => ({
    type: Types.GET_MONEY_BY_USER_BONUS_ACCESSION_REQUEST,
    payload
  }),

  getMoneyByUserBonusAccreditedSuccess: (payload) => ({
    type: Types.GET_MONEY_BY_USER_BONUS_ACCREDITED_SUCCESS,
    payload
  }),

  getMoneyByUserBonusAccreditedFailure: (payload) => ({
    type: Types.GET_MONEY_BY_USER_BONUS_ACCREDITED_FAILURE,
    payload
  }),

  getMoneyAdminDashRequest: (payload) => ({
    type: Types.GET_MONEY_ADMIN_DASH_REQUEST,
    payload
  }),

  getMoneyAdminDashSuccess: (payload) => ({
    type: Types.GET_MONEY_ADMIN_DASH_SUCCESS,
    payload
  }),

  getMoneyAdminDashFailure: (payload) => ({
    type: Types.GET_MONEY_ADMIN_DASH_FAILURE,
    payload
  }),

  getMoneyAdminDashDetailRequest: (payload) => ({
    type: Types.GET_MONEY_ADMIN_DASH_DETAIL_REQUEST,
    payload
  }),

  getMoneyAdminDashDetailSuccess: (payload) => ({
    type: Types.GET_MONEY_ADMIN_DASH_DETAIL_SUCCESS,
    payload
  }),

  getMoneyAdminDashDetailFailure: (payload) => ({
    type: Types.GET_MONEY_ADMIN_DASH_DETAIL_FAILURE,
    payload
  }),

  getMoneyUserByTypeSummaryRequest: (payload) => ({
    type: Types.GET_MONEY_USER_BY_TYPE_SUMMARY_REQUEST,
    payload
  }),

  getMoneyUserByTypeSummarySuccess: (payload) => ({
    type: Types.GET_MONEY_USER_BY_TYPE_SUMMARY_SUCCESS,
    payload
  }),

  getMoneyUserByTypeSummaryFailure: (payload) => ({
    type: Types.GET_MONEY_USER_BY_TYPE_SUMMARY_FAILURE,
    payload
  }),

  getMoneySalesByAccreditedRequest: (payload) => ({
    type: Types.GET_MONEY_SALES_REQUEST,
    payload
  }),

  getMoneySalesByAccreditedSuccess: (payload) => ({
    type: Types.GET_MONEY_SALES_SUCCESS,
    payload
  }),

  getMoneySalesByAccreditedFailure: (payload) => ({
    type: Types.GET_MONEY_SALES_FAILURE,
    payload
  }),

  getMoneyByUserAccreditedRequest: (payload) => ({
    type: Types.GET_MONEY_BY_USER_ACCREDITED_REQUEST,
    payload
  }),
  getMoneyByUserAccreditedSuccess: (payload) => ({
    type: Types.GET_MONEY_BY_USER_ACCREDITED_SUCCESS,
    payload
  }),
  getMoneyByUserAccreditedFailure: (payload) => ({
    type: Types.GET_MONEY_BY_USER_ACCREDITED_FAILURE,
    payload
  }),

  updateMoneyRequest: (payload) => ({
    type: Types.UPDATE_MONEY_REQUEST,
    payload,
  }),

  updateMoneySuccess: (payload) => ({
    type: Types.UPDATE_MONEY_SUCCESS,
    payload,
  }),

  updateMoneyFailure: (payload) => ({
    type: Types.UPDATE_MONEY_FAILURE,
    payload,
  }),

}
