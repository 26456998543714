import React from 'react'
import { formatDate } from 'utils'
const StatusCheck = (type) => {
  const Status = {
    processing: (
      <>
        <small style={{ marginLeft: '5px' }}>Em Tratamento</small>
      </>
    ),
    done: (
      <>
        <small style={{ marginLeft: '5px' }}>Concluído</small>
      </>
    ),
    created: (
      <>
        <small style={{ marginLeft: '5px' }}>Criado</small>
      </>
    ),
    waitingreturn: (
      <>
        <small style={{ marginLeft: '5px' }}>Aguardando Retorno</small>
      </>
    )
  }

  return Status[type] || Status.processing
}
export const TransactionsCheck = (type) => {
  const Status = {
    processing: (
      <>
        <i className="fa fa-circle font-warning f-12" />{' '}
        <small style={{ marginLeft: '5px' }}>Processando</small>
      </>
    ),
    finished: (
      <>
        <i className="fa fa-circle font-success f-12" />{' '}
        <small style={{ marginLeft: '5px' }}>Finalizado</small>
      </>
    ),
    cancelled: (
      <>
        <i className="fa fa-circle font-danger f-12" />{' '}
        <small style={{ marginLeft: '5px' }}>Cancelado</small>
      </>
    )
  }

  return Status[type] || Status.processing
}

export const ActiveStatusCheck = (status) => {
  const Status = {
    true: (
      <>
        <i className="fa fa-circle font-success f-12" />{' '}
        <small style={{ marginLeft: '5px' }}>Ativo</small>
      </>
    ),
    false: (
      <>
        <i className="fa fa-circle font-danger f-12" />{' '}
        <small style={{ marginLeft: '5px' }}>Inativo</small>
      </>
    )
  }
  return Status[status] || Status.false
}

const notificationStatus = {
  waiting_for_sync: 'Aguardando sincronização',
  synchronizing: 'Sincronizando',
  synced: 'Enviado',
  error: 'Erro'
}

const notificationType = {
  alert_home: 'Alerta Home',
  email: 'Enviar por e-mail',
  notification_bar: 'Barra de notificação'
}

const truncateString = (text, num) =>
  text.length <= num ? text : `${text.slice(0, num)}...`

export const actionsDataTableColumns = [
  {
    name: 'Nome',
    selector: (item) => item.name,
    sortable: true,
    center: true
  },
  {
    name: 'Descrição',
    selector: (item) => item.description,
    sortable: true,
    center: true
  }
]

export const profilesDataTableColumns = [
  {
    name: 'Nome',
    selector: (item) => item.name,
    sortable: true,
    center: true
  },
  {
    name: 'Descrição',
    selector: (item) => item.description,
    sortable: true,
    center: true
  }
]

export const bonificationsDataTableColumns = [
  {
    name: 'Nome',
    selector: (item) => item.name,
    sortable: true,
    center: true
  },
  {
    name: 'Percentual Sistena',
    selector: (item) => `${item.percentSystem}%`,
    sortable: true,
    center: true
  },

  {
    name: 'Percentual Direto',
    sortable: true,
    center: true,
    selector: (item) => `${item.percentDirect}%`
  },

  {
    name: 'Percentual Nível',
    sortable: true,
    center: true,
    selector: (item) => `${item.percentLevel}%`
  }
]

export const cashbacksDataTableColumns = [
  {
    name: 'Nome',
    selector: (item) => item.name,
    sortable: true,
    center: true
  },
  {
    name: 'Percentual Sistena',
    selector: (item) => `${item.percentSystem}%`,
    sortable: true,
    center: true
  },

  {
    name: 'Percentual Direto',
    sortable: true,
    center: true,
    selector: (item) => `${item.percentDirect}%`
  },

  {
    name: 'Percentual Nível',
    sortable: true,
    center: true,
    selector: (item) => `${item.percentLevel}%`
  }
]

export const levelsDataTableColumns = [
  {
    name: 'Nome',
    selector: (item) => item.name,
    sortable: true,
    center: true
  },

  {
    name: 'Descrição',
    selector: (item) => item.description,
    sortable: true,
    center: true
  },
  {
    name: 'Cashback',
    sortable: true,
    center: true,
    selector: (item) => item.cashback?.name
  },

  {
    name: 'Bonificação',
    sortable: true,
    center: true,
    selector: (item) => item.bonification?.name
  },

  {
    name: 'Quantidade máxima no nível',
    selector: (item) => item.maxLevelQuantity,
    sortable: true,
    center: true
  },

  {
    name: 'Habilitação',
    selector: (item) => item?.plan?.name,
    sortable: true,
    center: true
  }
]

export const usersDataTableColumns = [
  {
    name: 'Nome',
    selector: (item) => item.name,
    sortable: true,
    center: true
  },

  {
    name: 'Perfil',
    selector: (item) => item.profile?.[0]?.name,
    sortable: true,
    center: true
  },

  {
    name: 'Patrocinador',
    selector: (item) => item.sponsor?.[0]?.name,
    sortable: true,
    center: true
  },

  {
    name: 'Habilitação',
    selector: (item) => item.plan?.[0]?.name,
    sortable: true,
    center: true
  },

  {
    name: 'Status',
    selector: (item) =>
      item.isActive ? (
        <>
          <i className="fa fa-circle font-success f-12" />{' '}
          <small style={{ marginLeft: '5px' }}>Ativo</small>
        </>
      ) : (
        <>
          <i className="fa fa-circle font-danger f-12" />
          <small style={{ marginLeft: '5px' }}> Inativo</small>
        </>
      ),
    sortable: true,
    center: true
  }
]

export const usersAccreditedDataTableColumns = [
  {
    name: 'Nome',
    selector: (item) => item.establishment,
    sortable: true,
    center: true
  },
  {
    name: 'Cidade',
    selector: (item) => item.city,
    sortable: true,
    center: true
  },
  {
    name: 'Perfil',
    selector: (item) => item.profile?.[0]?.name,
    sortable: true,
    center: true
  },

  {
    name: 'Patrocinador',
    selector: (item) => item.sponsor?.[0]?.name,
    sortable: true,
    center: true
  },

  {
    name: 'Status',
    selector: (item) =>
      item.isActive ? (
        <>
          <i className="fa fa-circle font-success f-12" />{' '}
          <small style={{ marginLeft: '5px' }}>Ativo</small>
        </>
      ) : (
        <>
          <i className="fa fa-circle font-danger f-12" />
          <small style={{ marginLeft: '5px' }}> Inativo</small>
        </>
      ),
    sortable: true,
    center: true
  }
]

export const storesDataTableColumns = [
  {
    name: 'Nome',
    selector: (item) => item.name,
    sortable: true,
    center: true
  },

  {
    name: 'Status',
    selector: (item) =>
      item.status ? (
        <>
          <i className="fa fa-circle font-success f-12" />{' '}
          <small style={{ marginLeft: '5px' }}>Ativo</small>
        </>
      ) : (
        <>
          <i className="fa fa-circle font-danger f-12" />
          <small style={{ marginLeft: '5px' }}> Inativo</small>
        </>
      ),
    sortable: true,
    center: true
  }
]

export const invoiceDataTableColumns = [
  {
    name: 'Copia e Cola PIX',
    selector: (item) => item.pixqrcode,
    sortable: true,
    center: true
  },

  {
    name: 'Data Vencimento',
    selector: (item) => item.expirationDate,
    sortable: true,
    center: true
  },
  {
    name: 'Status',
    selector: (item) => TransactionsCheck(item.status),

    sortable: true,
    center: true,
    wrap: true
  }
]

export const installmentDataTableColumns = [
  {
    name: 'Parcela',
    selector: (item) => item.installmentNumber,
    center: true,
    wrap: true
  },
  {
    name: 'Descrição',
    selector: (item) => item.description,
    center: true,
    wrap: true
  },
  {
    name: 'Valor',
    selector: (item) =>
      `R$ ${item.amount
        .toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL'
        })
        .replace('.', ',')}`,
    center: true,
    wrap: true
  },
  {
    name: 'Boleto',
    selector: (item) => item.url,
    center: true,
    wrap: true
  },

  {
    name: 'Status',
    selector: (item) => TransactionsCheck(item.status),

    sortable: true,
    center: true,
    wrap: true
  }
]
export const transactionsDataTableColumns = [
  {
    name: 'Usuário',
    selector: (item) => item.user.name,
    center: true,
    wrap: true
  },
  {
    name: 'Descrição',
    selector: (item) => item.description,
    center: true,
    wrap: true
  },
  {
    name: 'Valor',
    selector: (item) =>
      `R$ ${item.amount
        .toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL'
        })
        .replace('.', ',')}`,
    center: true,
    wrap: true
  },
  {
    name: 'Valor Pedido',
    selector: (item) =>
      item?.externalOrderAmount
        ? `R$ ${item?.externalOrderAmount
          .toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL'
          })
          .replace('.', ',')}`
        : '',
    center: true,
    wrap: true
  },

  {
    name: 'Tipo',
    selector: (item) => item.type,
    center: true,
    wrap: true
  },
  {
    name: 'Status',
    selector: (item) => TransactionsCheck(item.status),

    sortable: true,
    center: true,
    wrap: true
  }
]

export const transactionsDtDataTableColumns = [
  {
    name: 'Descrição',
    selector: (item) => item.description,
    center: true,
    wrap: true
  },

  {
    name: 'Valor',
    selector: (item) =>
      `R$ ${item.amount
        .toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL'
        })
        .replace('.', ',')}`,
    center: true,
    wrap: true
  },
  {
    name: 'Status',
    selector: (item) => TransactionsCheck(item.status),

    sortable: true,
    center: true,
    wrap: true
  }
]

export const positionsDataTableColumns = [
  {
    name: 'Nome',
    selector: (item) => item.name,
    center: true
  },

  {
    name: 'Descrição',
    selector: (item) => item.description,
    center: true
  },

  {
    name: 'Proxima posição (upgrade)',
    selector: (item) => item.nextPosition?.[0]?.name,
    center: true
  }
]

export const supportDataTableColumns = [
  {
    name: 'Usuário',
    selector: (item) => item?.user?.name,
    center: true
  },

  {
    name: 'Descrição',
    selector: (item) => item?.description,
    center: true
  },
  {
    name: 'Status',
    selector: (item) => StatusCheck(item.status),

    sortable: true,
    center: true,
    wrap: true
  }
]

export const notificationDataTableColumns = [
  {
    name: 'Autor',
    selector: (item) => item?.author?.name,
    center: true
  },

  {
    name: 'Mensagem',
    selector: (item) => item?.description,
    center: true
  },
  {
    name: 'Tipo',
    selector: (item) => notificationType[item.type],
    center: true
  },

  {
    name: 'Destinatário',
    selector: (item) => (item.emailReceiver ? item.emailReceiver.email : 'N/A'),
    center: true
  },

  {
    name: 'Status',
    selector: (item) => notificationStatus[item.status],
    center: true
  },
  {
    name: 'Data de entrega',
    selector: (item) =>
      item.delivereDate ? formatDate(item?.delivereDate) : 'Aguardando envio',
    center: true
  }
]

export const graduationsDatatableColumns = [
  {
    name: 'Usuário',
    selector: (item) => item.user?.[0]?.name,
    center: true
  },

  {
    name: 'Posição',
    selector: (item) => item.position?.[0]?.name,
    center: true
  },

  {
    name: 'Pontuação',
    selector: (item) => item.score,
    center: true
  }
]

export const paymentMethodsDatatableColumns = [
  {
    name: 'Nome',
    selector: (item) => item.name,
    center: true
  },
  {
    name: 'Descrição',
    selector: (item) => item.description,
    center: true
  }
]

export const servicesDatatableColumns = [
  {
    name: 'Nome',
    selector: (item) => item.name,
    center: true
  },
  {
    name: 'Status',
    selector: (item) =>
      item.isActive ? (
        <>
          <i className="fa fa-circle font-success f-12" />{' '}
          <small style={{ marginLeft: '5px' }}>Ativo</small>
        </>
      ) : (
        <>
          <i className="fa fa-circle font-danger f-12" />
          <small style={{ marginLeft: '5px' }}> Inativo</small>
        </>
      ),
    center: true
  }
]

export const withdrawRequestsDataTableColumns = [
  {
    name: 'Usuário',
    selector: (item) => item.user?.name,
    sortable: true,
    center: true
  },
  {
    name: 'Descrição',
    selector: (item) => item.description,
    sortable: true,
    center: true
  },
  {
    name: 'Valor',
    selector: (item) =>
      `R$ ${item.amount
        .toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL'
        })
        .replace('.', ',')}`,
    center: true,
    wrap: true
  },
  {
    name: 'Status',
    selector: (item) => TransactionsCheck(item.status),

    sortable: true,
    center: true,
    wrap: true
  }
]

export const faqDataTableColumns = [
  {
    name: 'Pergunta',
    selector: (item) => item.question,
    sortable: true,
    center: true
  },
  {
    name: 'Resposta',
    selector: (item) => truncateString(item.answer, 100),
    sortable: true,
    center: true
  },

  {
    name: 'Status',
    selector: (item) => ActiveStatusCheck(item.isActive),
    sortable: true,
    center: true,
    wrap: true
  }
]

export const tutorialsDataTableColumns = [
  {
    name: 'Nome',
    selector: (item) => item.name,
    sortable: true,
    center: true
  },
  {
    name: 'Descrição',
    selector: (item) => item.description,
    sortable: true,
    center: true
  },
  {
    name: 'Link do video',
    selector: (item) => item.video,
    sortable: true,
    center: true
  },
  {
    name: 'Status',
    selector: (item) => ActiveStatusCheck(item.isActive),
    sortable: true,
    center: true,
    wrap: true
  }
]

export const materialsDataTableColumns = [
  {
    name: 'Nome',
    selector: (item) => item.name,
    sortable: true,
    center: true
  },
  {
    name: 'Descrição',
    selector: (item) => item.description,
    sortable: true,
    center: true
  },
  {
    name: 'Arquivo',
    selector: (item) => item.fileUrl.split('material-apoio/')[1],
    sortable: true,
    center: true
  },
  {
    name: 'Status',
    selector: (item) => ActiveStatusCheck(item.isActive),
    sortable: true,
    center: true,
    wrap: true
  }
]
