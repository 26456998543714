import React, { useEffect } from 'react'

import { Col, Card, CardBody } from 'reactstrap'
import CountUp from 'react-countup'
import { Activity, DollarSign, Star, Users } from 'react-feather'

import { Creators as WalletActions } from 'store/ducks/wallet'
import { Creators as NetworkActions } from 'store/ducks/network'
import { Creators as MoneyActions } from 'store/ducks/money'

import { useDispatch, useSelector } from 'react-redux'

const Indicators = () => {
  const dispatch = useDispatch()
  const {
    auth: { auth },
    wallet: { wallet },
    network: { networkByOwner },
    money: { moneyByUser,moneyByUserCashback }
    
  } = useSelector((state) => state)

  useEffect(() => {
    dispatch(WalletActions.getWalletByUserRequest(auth.id))
  }, [dispatch, auth.id])

  useEffect(() => {
    dispatch(NetworkActions.getNetworkByOwnerRequest(auth.id))
  }, [auth.id, dispatch])

  useEffect(() => {
    dispatch(MoneyActions.getMoneyByUserRequest(`${auth.id}?page=0&limit=10`))
  }, [dispatch, auth.id])

  return (
    <>

      <Col sm="6" xl="3" lg="6">
        <Card className="o-hidden">
          <div className="bg-secondary b-r-4 card-body">
            <div className="media static-top-widget">
              <div className="align-self-center text-center">
                <DollarSign />
              </div>
              <div className="media-body">
                <span className="m-0">Saldo</span>
                <h4 className="mb-0 counter">
                  R${' '}
                  <CountUp
                    decimal=","
                    decimals={2}
                    end={wallet.amount ? wallet.amount : 0}
                  />
                </h4>
                <DollarSign className="icon-bg" />
              </div>
            </div>
          </div>
        </Card>
      </Col>
      <Col sm="6" xl="3" lg="6">
        <Card className="o-hidden">
          <CardBody className="bg-primary b-r-4">
            <div className="media static-top-widget">
              <div className="align-self-center text-center">
                <Users />
              </div>
              <div className="media-body">
                <span className="m-0">Usuários na rede</span>
                <h4 className="mb-0 counter">
                  <CountUp
                    end={
                      networkByOwner.users?.length > 0
                        ? networkByOwner.totalNetwork
                        : 0
                    }
                  />
                </h4>
                <Users className="icon-bg" />
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col sm="6" xl="3" lg="6">
        <Card className="o-hidden">
          <CardBody className="bg-primary b-r-4">
            <div className="media static-top-widget">
              <div className="align-self-center text-center">
                <Star />
              </div>
              <div className="media-body">
                <span className="m-0">Pontuação/ Graduação</span>
                <h4 className="mb-0 counter">
                  <CountUp
                    end={auth.graduation?.score ? auth.graduation.score : 0}
                  />
                  <span className="m-0 f-18">
                    / {auth.graduation?.position ? auth.graduation.position.name : ''}
                  </span>

                </h4>
                <Star className="icon-bg" />
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col sm="6" xl="3" lg="6">
        <Card className="o-hidden">
          <CardBody className="bg-primary b-r-4 card-body">
            <div className="media static-top-widget">
              <div className="align-self-center text-center">
                <Activity />
              </div>
              <div className="media-body">
                <span className="m-0">Meu Cashback</span>


                
                <h4 className="mb-0 counter">
                  R${' '}
                  <CountUp
                    decimal=","
                    decimals={2}
                    end={
                      moneyByUser?.cashbackStats?.totalCashbackCount
                        ? moneyByUser?.cashbackStats?.totalCashbackValue
                        : 0
                    }
                  />
                </h4>
                <DollarSign className="icon-bg" />
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
 
    
    </>
  )
}

export default Indicators
