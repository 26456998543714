import { put, call } from 'redux-saga/effects'
import { api } from 'services/api'
import { toast } from 'react-toastify'

import { Creators as BankAccounts } from 'store/ducks/bankAccounts'

export function* getBankAccountByUser(action) {
  try {
    const { data } = yield call(api.get, `/bank-accounts/${action.payload}`)
    yield put(BankAccounts.getBankAccountSuccess(data))
  } catch (err) {
    yield put(BankAccounts.getBankAccountFailure(err))
  }
}

export function* createBankAccount(action) {
  try {
    const idUser = action.payload.user.id

    const { data } = yield call(api.post, `/bank-accounts`, action.payload)
    yield put(BankAccounts.createBankAccountSuccess(data))
    yield put(BankAccounts.getBankAccountRequest(idUser))
    toast.success('Conta bancária criada com sucesso!')
  } catch (err) {
    toast.error('Erro ao criar conta bancária')
    yield put(BankAccounts.createBankAccountFailure(err))
  }
}

export function* updateBankAccount(action) {
  try {
    const { data } = yield call(
      api.put,
      `/bank-accounts/${action.payload.id}`,
      action.payload
    )
    yield put(BankAccounts.updateBankAccountSuccess(data))
    // yield put(push("/acoes"));
    toast.success('Conta bancária editada com sucesso!')
  } catch (err) {
    toast.error('Erro ao editar conta bancária')
    yield put(BankAccounts.updateBankAccountFailure(err))
  }
}

export function* deleteBankAccount(action) {
  try {
    const { data } = yield call(
      api.delete,
      `/bank-accounts/${action.payload.id}`
    )
    yield put(BankAccounts.deleteBankAccountSuccess(data))
    yield put(BankAccounts.getBankAccountsRequest())
    toast.success('Ação removida com sucesso')
  } catch (err) {
    toast.error('Erro ao remover ação')
    yield put(BankAccounts.deleteBankAccountFailure(err))
  }
}
