import { put, call } from 'redux-saga/effects'
import { api } from 'services/api'
import { push } from 'connected-react-router'
import { toast } from 'react-toastify'

import { Creators as ProfileActions } from 'store/ducks/profiles'

export function* getProfiles() {
  try {
    const { data } = yield call(api.get, '/profiles')
    yield put(ProfileActions.getProfilesSuccess(data))
  } catch (err) {
    yield put(ProfileActions.getProfilesFailure(err.message))
  }
}

export function* getProfileByID(action) {
  try {
    const { data } = yield call(api.get, `/profiles/${action.payload}`)
    yield put(ProfileActions.getProfileSuccess(data))
  } catch (err) {
    yield put(ProfileActions.getProfileFailure(err))
  }
}

export function* createProfile(action) {
  try {
    const { data } = yield call(api.post, `/profiles`, action.payload)
    yield put(ProfileActions.createProfileSuccess(data))
    yield put(push('/perfis'))
    toast.success('Perfil criado com sucesso!')
  } catch (err) {
    toast.error('Erro ao criar perfil')
    yield put(ProfileActions.createProfileFailure(err))
  }
}

export function* updateProfile(action) {
  try {
    const { data } = yield call(
      api.put,
      `/profiles/${action.payload.id}`,
      action.payload
    )
    yield put(ProfileActions.updateProfileSuccess(data))
    yield put(push('/perfis'))
    toast.success('Perfil editado com sucesso!')
  } catch (err) {
    toast.error('Erro ao editar perfil')
    yield put(ProfileActions.updateProfileFailure(err))
  }
}

export function* deleteProfile(action) {
  try {
    const { data } = yield call(api.delete, `/profiles/${action.payload.id}`)
    yield put(ProfileActions.deleteProfileSuccess(data))
    yield put(ProfileActions.getProfilesRequest())
    toast.success('Perfil removido com sucesso')
  } catch (err) {
    toast.error('Erro ao remover perfil')
    yield put(ProfileActions.deleteProfileFailure(err))
  }
}
