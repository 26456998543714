export const Types = {
  GENERIC_SEARCH_REQUEST: 'generic_search/GENERIC_SEARCH_REQUEST',
  GENERIC_SEARCH_SUCCESS: 'generic_search/GENERIC_SEARCH_SUCCESS',
  GENERIC_SEARCH_FAILURE: 'generic_search/GENERIC_SEARCH_FAILURE'
}

const INITIAL_STATE = {
  list: [],
  loading: false,
  source: null,
  error: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GENERIC_SEARCH_REQUEST:
      return {
        ...state,
        loading: true,
        list: [],
        error: null,
        source: action.payload.source
      }

    case Types.GENERIC_SEARCH_SUCCESS:
      return { ...state, loading: false, list: action.payload, error: null }

    case Types.GENERIC_SEARCH_FAILURE:
      return { ...state, loading: false, error: action.payload, list: [] }

    default:
      return state
  }
}

export const Creators = {
  getGenericSearchRequest: (payload) => ({
    type: Types.GENERIC_SEARCH_REQUEST,
    payload
  }),

  getGenericSearchSuccess: (payload) => ({
    type: Types.GENERIC_SEARCH_SUCCESS,
    payload
  }),

  getGenericSearchFailure: (payload) => ({
    type: Types.GENERIC_SEARCH_FAILURE,
    payload
  })
}
