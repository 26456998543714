import React from 'react'

import { Card } from 'reactstrap'
import SweetAlert from 'react-bootstrap-sweetalert'

import Layout from 'components/Layout'

import PaymentMethod from 'components/Checkout/PaymentMethod'
import { formatToCurrency } from 'utils'

import useCheckout from 'shared/hooks/useCheckout'

const Checkout = () => {
  const {
    showConfirmModal,
    product,
    handleSelectMethod,
    handleConfirmBuyModal
  } = useCheckout()

  return (
    <Layout isCheckout>
      <div className="new-checkout">
        <Card className="card payment">
          <div className="payment-method">
            <h2>Selecione o método de pagamento</h2>
            <div className="payment-method-items">
              <PaymentMethod
                product={product}
                handleSelectMethod={handleSelectMethod}
              />
            </div>
          </div>
        </Card>
        <Card className="card purchase">
          <div className="purchase-resume">
            <h2>Resumo da Compra</h2>
            <div className="purchase-items">
              {/* <strong>{label}</strong> */}
              <span>
                {product?.label} - {formatToCurrency(product?.price)}
              </span>
            </div>
          </div>
        </Card>
        {showConfirmModal && (
          <SweetAlert
            success
            title="Compra efetuada!"
            onConfirm={handleConfirmBuyModal}
          >
            Seu pedido está sendo processado, e seu plano será atualizado em
            breve.
          </SweetAlert>
        )}
      </div>
    </Layout>
  )
}

export default Checkout
