import React from 'react'
import MainAccordion from 'react-bootstrap/Accordion'
import 'bootstrap/dist/css/bootstrap.min.css'

const Accordion = ({ items }) => {
  return (
    <MainAccordion>
      {items.map((item, index) => (
        <MainAccordion.Item eventKey={index} key={index}>
          <MainAccordion.Header>{item.header}</MainAccordion.Header>
          <MainAccordion.Body>{item.content}</MainAccordion.Body>
        </MainAccordion.Item>
      ))}
    </MainAccordion>
  )
}

export default Accordion
