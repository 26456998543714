import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button
} from 'reactstrap'

import { GoogleLoginButton } from 'react-social-login-buttons'

import LOGIN from 'shared/constants/login'
import useSocialAuth from 'shared/hooks/useSocialAuth'

import { Creators as AuthActions } from 'store/ducks/auth'

import Loader from 'react-spinners/BeatLoader'
import { Link } from 'react-router-dom'

const Login = () => {
  const dispatch = useDispatch()
  const { register, handleSubmit } = useForm()

  //OAuth
  const { handleSocialLogin, loading } = useSocialAuth()

  const [togglePassword, setTogglePassword] = useState(false)

  const HideShowPassword = (tPassword) => setTogglePassword(!tPassword)

  const handleLogin = (values) => dispatch(AuthActions.loginRequest(values))

  const { ref: emailRef, ...email } = register('email')
  const { ref: passwordRef, ...password } = register('password')

  const LoginLoader = () => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '0 1rem'
      }}
    >
      <span>Carregando</span>
      <Loader color="#fff" size={8} />
    </div>
  )

  return (
    <Container fluid={true} className="p-0">
      <Row className="m-0">
        <Col xs="12" className="p-0">
          <div className="login-card">
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '0 0 25px 0'
                }}
              >
                {/* <a className="logo" href="#javascript"> */}
                <img
                  className="img-fluid for-light"
                  src={require('../../assets/images/logo/quanta-default.png')}
                  alt="quanta-logo"
                  style={{ width: '180px' }}
                />
                <img
                  className="img-fluid for-dark"
                  src={require('../../assets/images/logo/quanta-dark.png')}
                  alt="quanta-logo-white"
                  style={{ width: '230px' }}
                />
                {/* </a> */}
              </div>
              <div className="login-main login-tab">
                <Form
                  className="theme-form"
                  onSubmit={handleSubmit(handleLogin)}
                >
                  <h4>Login</h4>
                  <p>{LOGIN.INSERT_EMAIL_PASS}</p>
                  <FormGroup>
                    <Label className="col-form-label">{LOGIN.EMAIL}</Label>
                    <Input
                      className="form-control"
                      type="email"
                      placeholder={LOGIN.EMAIL_PLACEHOLDER}
                      innerRef={emailRef}
                      {...email}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">{LOGIN.PASSWORD}</Label>
                    <Input
                      className="form-control"
                      type={togglePassword ? 'text' : 'password'}
                      name="password"
                      placeholder="*********"
                      innerRef={passwordRef}
                      {...password}
                    />
                    <div
                      className="show-hide"
                      onClick={() => HideShowPassword(togglePassword)}
                    >
                      <span className={togglePassword ? '' : `show`}></span>
                    </div>
                  </FormGroup>
                  <div className="form-group mb-0">
                    <div className="checkbox ml-3">
                      <Input id="checkbox1" type="checkbox" />
                      <Label className="text-muted" for="checkbox1">
                        {LOGIN.REMEMBER_PASS}
                      </Label>
                    </div>
                    <a className="link" href="#javascript">
                      {LOGIN.FORGOT_PASS}
                    </a>
                    <Button
                      color="primary"
                      className="btn-block"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? <LoginLoader /> : LOGIN.LOGIN_BTN}
                    </Button>
                  </div>
                  <h6 className="text-muted mt-4 or">{LOGIN.OR_SIGNIN}</h6>
                  <div className="social mt-4">
                    <div className="btn-showcase">
                      <GoogleLoginButton
                        onClick={() => handleSocialLogin('google')}
                        align="center"
                        iconSize="20px"
                      >
                        <span>Google</span>
                      </GoogleLoginButton>
                    </div>
                  </div>
                  <p className="mt-4 mb-0">
                    {'Não tem uma conta?'}
                    <Link className="ml-2" to="/register">
                      Criar
                    </Link>
                  </p>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Login
