import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import DefaultPage from "components/DefaultPage";

import CreateLevelForm from "components/Levels/CreateLevelForm";

import { Creators as LevelsActions } from "store/ducks/levels";
import { Creators as CashbackActions } from "store/ducks/cashbacks";
import { Creators as BonificationActions } from "store/ducks/bonifications";

const CreateLevel = () => {
  const dispatch = useDispatch();
  const cashBackList = useSelector((state) => state.cashbacks.list);
  const bonificationList = useSelector((state) => state.bonifications.list);
  const { list } = useSelector((state) => state.levels);

  const handleCreateLevel = useCallback(
    (item) => {
      let body = {
        ...item,
        bonification: { id: item.bonification },
        cashback: { id: item.cashback },
      };

      dispatch(LevelsActions.createLevelRequest(body));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(CashbackActions.getCashbacksRequest());
  }, [dispatch]);

  useEffect(() => {
    dispatch(BonificationActions.getBonificationsRequest());
  }, [dispatch]);

  return (
    <DefaultPage
      pageTitle="Níveis"
      pageDescription="Criar nível"
      pageSubDescription="Criar um novo nível"
    >
      <CreateLevelForm
        submitForm={handleCreateLevel}
        cashBackList={cashBackList}
        bonificationList={bonificationList}
        levelsList={list}
      />
    </DefaultPage>
  );
};

export default CreateLevel;
