export const Types = {
  GET_BONIFICATIONS_REQUEST: "bonifications/GET_BONIFICATIONS_REQUEST",
  GET_BONIFICATIONS_SUCCESS: "bonifications/GET_BONIFICATIONS_SUCCESS",
  GET_BONIFICATIONS_FAILURE: "bonifications/GET_BONIFICATIONS_FAILURE",

  GET_BONIFICATION_REQUEST: "bonifications/GET_BONIFICATION_REQUEST",
  GET_BONIFICATION_SUCCESS: "bonifications/GET_BONIFICATION_SUCCESS",
  GET_BONIFICATION_FAILURE: "bonifications/GET_BONIFICATION_FAILURE",

  CREATE_BONIFICATION_REQUEST: "bonifications/CREATE_BONIFICATION_REQUEST",
  CREATE_BONIFICATION_SUCCESS: "bonifications/CREATE_BONIFICATION_SUCCESS",
  CREATE_BONIFICATION_FAILURE: "bonifications/CREATE_BONIFICATION_FAILURE",

  UPDATE_BONIFICATION_REQUEST: "bonifications/UPDATE_BONIFICATION_REQUEST",
  UPDATE_BONIFICATION_SUCCESS: "bonifications/UPDATE_BONIFICATION_SUCCESS",
  UPDATE_BONIFICATION_FAILURE: "bonifications/UPDATE_BONIFICATION_FAILURE",

  DELETE_BONIFICATION_REQUEST: "bonifications/DELETE_BONIFICATION_REQUEST",
  DELETE_BONIFICATION_SUCCESS: "bonifications/DELETE_BONIFICATION_SUCCESS",
  DELETE_BONIFICATION_FAILURE: "bonifications/DELETE_BONIFICATION_FAILURE",
};

const INITIAL_STATE = {
  list: [],
  bonification: {},
  loading: false,
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_BONIFICATIONS_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.GET_BONIFICATIONS_SUCCESS:
      return { ...state, loading: false, list: action.payload, error: null };

    case Types.GET_BONIFICATIONS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.GET_BONIFICATION_REQUEST:
      return { ...state, loading: true, bonification: {}, error: null };

    case Types.GET_BONIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        bonification: action.payload,
        error: null,
      };

    case Types.GET_BONIFICATION_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.CREATE_BONIFICATION_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.CREATE_BONIFICATION_SUCCESS:
      return { ...state, loading: false, error: null };

    case Types.CREATE_BONIFICATION_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.UPDATE_BONIFICATION_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.UPDATE_BONIFICATION_SUCCESS:
      return { ...state, loading: false, bonification: {}, error: null };

    case Types.UPDATE_BONIFICATION_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.DELETE_BONIFICATION_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.DELETE_BONIFICATION_SUCCESS:
      return { ...state, loading: false, error: null };

    case Types.DELETE_BONIFICATION_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export const Creators = {
  getBonificationsRequest: (payload) => ({
    type: Types.GET_BONIFICATIONS_REQUEST,
    payload,
  }),

  getBonificationsSuccess: (payload) => ({
    type: Types.GET_BONIFICATIONS_SUCCESS,
    payload,
  }),

  getBonificationsFailure: (payload) => ({
    type: Types.GET_BONIFICATIONS_FAILURE,
    payload,
  }),

  getBonificationRequest: (payload) => ({
    type: Types.GET_BONIFICATION_REQUEST,
    payload,
  }),

  getBonificationSuccess: (payload) => ({
    type: Types.GET_BONIFICATION_SUCCESS,
    payload,
  }),

  getBonificationFailure: (payload) => ({
    type: Types.GET_BONIFICATION_FAILURE,
    payload,
  }),

  createBonificationRequest: (payload) => ({
    type: Types.CREATE_BONIFICATION_REQUEST,
    payload,
  }),

  createBonificationSuccess: (payload) => ({
    type: Types.CREATE_BONIFICATION_SUCCESS,
    payload,
  }),

  createBonificationFailure: (payload) => ({
    type: Types.CREATE_BONIFICATION_FAILURE,
    payload,
  }),

  updateBonificationRequest: (payload) => ({
    type: Types.UPDATE_BONIFICATION_REQUEST,
    payload,
  }),

  updateBonificationSuccess: (payload) => ({
    type: Types.UPDATE_BONIFICATION_SUCCESS,
    payload,
  }),

  updateBonificationFailure: (payload) => ({
    type: Types.UPDATE_BONIFICATION_FAILURE,
    payload,
  }),

  deleteBonificationRequest: (payload) => ({
    type: Types.DELETE_BONIFICATION_REQUEST,
    payload,
  }),

  deleteBonificationSuccess: (payload) => ({
    type: Types.DELETE_BONIFICATION_SUCCESS,
    payload,
  }),

  deleteBonificationFailure: (payload) => ({
    type: Types.DELETE_BONIFICATION_FAILURE,
    payload,
  }),
};
