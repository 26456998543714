import React, { useRef, useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Label } from 'reactstrap'
import Select from 'react-select'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'

import {
  formatCheckoutInstallments,
  generateDueDate,
  formatCurrencyToTransaction
} from 'utils'

const Boleto = ({ product, handleCreateTransaction, user }) => {
  const { transaction } = useSelector((state) => state.checkout)
  const [isCopiedCode, setIsCopiedCode] = useState(false)
  const barCodeRef = useRef(null)
  const handleSelectAllText = () => barCodeRef.current.select()

  const copyTextToClipboard = async (text) => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text)
    } else {
      return document.execCommand('copy', true, text)
    }
  }

  const handleCopyCode = async () => {
    await copyTextToClipboard(transaction?.boleto_barcode)
    setIsCopiedCode(true)
    setTimeout(() => {
      setIsCopiedCode(false)
    }, 1500)
  }

  const [installments, setInstallments] = useState([])

  const handleGenerateBoleto = useCallback(
    (data) => {
      !!data &&
        handleCreateTransaction({
          amount: formatCurrencyToTransaction(data.value),
          amountUnformated: data.value.toFixed(2),
          dueDate: generateDueDate(3),
          installments: data.quantity,
          payment_method: 'boleto',
          boleto_expiration_date: generateDueDate(3),
          customer: {
            type: 'individual',
            country: 'br',
            name: user && user.name,
            documents: [
              {
                type: 'cpf',
                number: user && user.cpf
              }
            ]
          },

          description: `Compra ${product && product.label}`
        })
    },
    [handleCreateTransaction, product, user]
  )

  const handleOpenBoletoPage = () => window.open(transaction?.boleto_url)

  useEffect(() => {
    if (product && product.price) {
      const formattedInstallments = formatCheckoutInstallments(product.price)
      setInstallments(formattedInstallments)
    }
  }, [product])

  return (
    <div className="boleto">
      <div className="installments">
        <Label for="installments">Parcelas</Label>
        <Select
          options={installments}
          onChange={(e) =>
            handleGenerateBoleto({
              quantity: e.label.split('x')[0],
              value: e.value
            })
          }
          defaultInputValue={installments[0]}
        />
      </div>
      {transaction && (
        <div className="boleto-wrapper">
          <div className="boleto-code">
            <textarea
              onClick={handleSelectAllText}
              ref={barCodeRef}
              className="form-control"
              value={transaction?.boleto_barcode}
              readOnly
            />
            <button onClick={handleCopyCode} className="action-button">
              {isCopiedCode ? 'Copiado!' : 'Copiar código de barras '}
            </button>
          </div>

          <div className="actions">
            <button className="print" onClick={handleOpenBoletoPage}>
              <FontAwesomeIcon icon={faUpRightFromSquare} />
              <span>Visualizar Boleto</span>
            </button>
            {/* <button className="download">
              <FontAwesomeIcon icon={faDownload} />
              <span>Baixar Boleto</span>
            </button> */}
          </div>
        </div>
      )}
    </div>
  )
}

export default Boleto
