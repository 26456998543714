import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DataTables from "components/Datatable";
import ModalDelete from "components/ModalDelete";

import { Creators as UserActions } from "store/ducks/users";
import { usersDataTableColumns } from "shared/constants/datatable";

import DefaultListPage from "components/DefaultPage";
const UsersDeadbeat = () => {
  const dispatch = useDispatch();
  const { list, loading } = useSelector((state) => state.users);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [currentPage, setCurrentPage] = useState(0);

  const handleDeleteItem = useCallback(
    (item) => {
      dispatch(UserActions.deleteUserRequest(item));
      setModalOpen(false);
    },
    [dispatch]
  );

  const handleOpenModal = (value) => {
    setModalOpen(true);
    setModalData({
      title: "Excluir usuário",
      content: `Você tem certeza em excluir o usuário "${value.name}"?`,
      item: value,
    });
  };

  const handleChangeRowsPerPage = useCallback(
    (currentPage, size) => {
      dispatch(
        UserActions.getUsersDeadbeatRequest({ page: currentPage, limit: size })
      );
    },
    [dispatch]
  );

  const handleChangePage = useCallback(
    (page) => {
      setCurrentPage(page);
      dispatch(UserActions.getUsersDeadbeatRequest({ page: page, limit: 10 }));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(UserActions.getUsersDeadbeatRequest({ page: 0, limit: 10 }));
  }, [dispatch]);

  return (
    <DefaultListPage
      pageTitle="Usuários em Atraso"
      pageDescription="Gerenciar usuários"
      pageSubDescription="Adicione, remove, edite alguns dos itens abaixo"
    >
      <DataTables
        list={list.data}
        count={list.count}
        editURL="/deadbeat/editar"
        customDataTableColumns={usersDataTableColumns}
        handleOpenModal={handleOpenModal}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        hasPagination
        currentPage={currentPage}
        loading={loading}
        onlyEditable
      />
      <ModalDelete
        isOpen={modalOpen}
        modalToggle={() => setModalOpen(false)}
        title={modalData.title}
        content={modalData.content}
        deleteItem={handleDeleteItem}
        item={modalData.item}
      />
    </DefaultListPage>
  );
};

export default UsersDeadbeat;
