import React, { } from "react";
import { Form, Label, Input, Col, Button } from "reactstrap";
import { useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import QRCode from 'components/QRCode'


const EditForm = ({
  submitForm,
  currentValue
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      amount: currentValue?.amount,
      date: currentValue?.expirationDate.split("T")[0],
      qrcode: currentValue?.pixqrcode
    },
  });


  const { ref: qrcodeRef, ...qrcode } = register("qrcode");
  const { ref: amountRef, ...amount } = register("amount", { required: true });
  const { ref: dateRef, ...date } = register("date", { required: true });


  return (
    <Form
      className="needs-validation"
      noValidate=""
      onSubmit={handleSubmit(submitForm)}
    >
      <div className="form-row">
        <Col md="3 mb-3">
          <QRCode value={qrcode} size={250} />

        </Col>
        <Col md="3 mb-3">
          <Label htmlFor="validationCustom02">PIX Copia e Cola</Label>
          <Input
            className="form-control"
            name="description"
            type="textarea"

            placeholder="Descrição"
            innerRef={qrcodeRef}
            {...qrcode}
          />

          <span>{errors.amount && "Amount é obrigatório"}</span>
        </Col>
        <Col md="3 mb-3">
          <Label htmlFor="validationCustom02">Valor</Label>
          <Input
            className="form-control"
            name="amount"
            type="number"
            placeholder="Valor"
            innerRef={amountRef}
            min="0.00"
            step="0.001"
            max="999999999999.00"
            presicion={2}   //very important
            onChange={() => {
              console.log('page rate changed');
            }}
            {...amount}
          />

          <span>{errors.amount && "Amount é obrigatório"}</span>
        </Col>
        <Col md="3 mb-3">
          <Label htmlFor="validationCustom02">Data de Vencimento</Label>
          <Input
            className="form-control"
            name="date"
            type="text"
            placeholder="Data de Vencimento"
            innerRef={dateRef}
            {...date}
          />

          <span>{errors.birthDate && "Data de Vencimento é obrigatório"}</span>
        </Col>

      </div>

    </Form>
  );
};

export default EditForm;
