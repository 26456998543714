import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DataTables from "components/Datatable";
import TableActions from 'components/TableActions'

import { Creators as UserActions } from "store/ducks/users";
import { usersAccreditedDataTableColumns } from "shared/constants/datatable";

import DefaultListPage from "components/DefaultPage";

const Users = () => {
  const dispatch = useDispatch();
  const { accredited ,loading} = useSelector((state) => state.users);

  const [currentPage, setCurrentPage] = useState(0);


  const handleChangeRowsPerPage = useCallback(
    (currentPage, size) => {
      dispatch(UserActions.getUsersAccreditedRequest({ page: currentPage, limit: size }));
    },
    [dispatch]
  );

  const handleChangePage = useCallback(
    (page) => {
      setCurrentPage(page);
      dispatch(UserActions.getUsersAccreditedRequest({ page: page, limit: 10 }));
    },
    [dispatch]
  );


  const handleSearch = useCallback(
    (value) => {
      if (value !== '') {
        dispatch(UserActions.getUsersAccreditedRequest({ search: value, page: 0, limit: 10 }))
      } else if (accredited.data) {
        dispatch(UserActions.getUsersAccreditedRequest({ page: 0, limit: 10 }))
      }
    },
    [accredited.data, dispatch]
  )

  const handleClearSearch = useCallback(() => {
    dispatch(UserActions.getUsersAccreditedRequest({ page: 0, limit: 10 }))
  }, [dispatch])


  useEffect(() => {
    dispatch(UserActions.getUsersAccreditedRequest({ page: 0, limit: 10 }));
  }, [dispatch]);

  return (
    <DefaultListPage
      pageTitle="Credenciados"
      pageDescription="Aprovação de Credenciado"
      pageSubDescription="Adicione, remove, edite alguns dos itens abaixo"
    >
      <TableActions
        hiddenAddButton={true}
        hasSearch
        handleSearch={handleSearch}
        handleClearSearch={handleClearSearch}
      />


      <DataTables
        list={accredited.data}
        count={accredited.count}
        editURL="/credenciados/editar"
        onlyEditable={true}
        loading={loading}
        customDataTableColumns={usersAccreditedDataTableColumns}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        hasPagination
        currentPage={currentPage}
      />

    </DefaultListPage>
  );
};

export default Users;
