import Actions from "pages/Actions";
import CreateAction from "pages/Actions/Create";
import EditAction from "pages/Actions/Edit";

import { BASE_URL } from "shared/constants/config";

export default [
  { path: `${BASE_URL}/acoes`, component: Actions },
  { path: `${BASE_URL}/acoes/criar`, component: CreateAction },
  { path: `${BASE_URL}/acoes/editar/:id`, component: EditAction },
];
