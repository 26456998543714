import React from 'react'
import { Form, Label, Input, Row, Col, Button } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

const EditForm = ({ submitForm, handleActive, currentValue }) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      question: currentValue.question,
      answer: currentValue.answer
    }
  })

  return (
    <Form
      className="needs-validation"
      noValidate=""
      onSubmit={handleSubmit(submitForm)}
    >
      <div className="form-row">
        <Col
          md="6"
          style={{ display: 'flex', flexDirection: 'column', gap: '1rem 0' }}
        >
          <Row>
            <Col>
              <Label htmlFor="validationCustom01">Pergunta</Label>
              <textarea
                {...register('question', {
                  required: true
                })}
                className="form-control"
                style={{ resize: 'none' }}
                defaultValue={currentValue.question}
              />

              <small style={{ color: 'red' }}>
                {errors.question && 'Pergunta é obrigatório'}
              </small>
            </Col>
          </Row>
          <Row>
            <Col>
              <Label htmlFor="validationCustom01">Status</Label>
              <Input
                type="select"
                name="installments"
                onChange={(e) => handleActive(e.target.value)}
                defaultValue={currentValue.isActive}
              >
                <option value="false">Inativo</option>
                <option value="true">Ativo</option>
              </Input>
              <small style={{ color: 'red' }}>
                {errors.status && 'Selecione um status'}
              </small>
            </Col>
          </Row>
        </Col>
        <Col md="6">
          <Label htmlFor="validationCustom02">Descrição</Label>
          <textarea
            {...register('answer', {
              required: true
            })}
            className="form-control"
            style={{ resize: 'none' }}
            rows={6}
            defaultValue={currentValue.answer}
          />

          <small style={{ color: 'red' }}>
            {errors.answer && 'Resposta é obrigatório'}
          </small>
        </Col>
      </div>
      <div style={{ display: 'flex', gap: '0 1rem', marginTop: '20px' }}>
        <Button color="primary" type="submit">
          Salvar
        </Button>
        <Link to="/faq">
          <Button color="default" style={{ border: 'none' }}>
            Cancelar
          </Button>
        </Link>
      </div>
    </Form>
  )
}

export default EditForm
