import { put, call } from 'redux-saga/effects'
import { api } from 'services/api'
import { push } from 'connected-react-router'
import { toast } from 'react-toastify'

import { Creators as LevelActions } from 'store/ducks/levels'

export function* getLevels() {
  try {
    const { data } = yield call(api.get, '/levels')
    yield put(LevelActions.getLevelsSuccess(data))
  } catch (err) {
    yield put(LevelActions.getLevelsFailure(err.message))
  }
}

export function* getLevelByID(action) {
  try {
    const { data } = yield call(api.get, `/levels/${action.payload}`)
    yield put(LevelActions.getLevelSuccess(data))
  } catch (err) {
    yield put(LevelActions.getLevelFailure(err))
  }
}

export function* createLevel(action) {
  try {
    const { data } = yield call(api.post, `/levels`, action.payload)
    yield put(LevelActions.createLevelSuccess(data))
    yield put(push('/niveis'))
    toast.success('Nívelcriado com sucesso!')
  } catch (err) {
    toast.error('Erro ao criar level')
    yield put(LevelActions.createLevelFailure(err))
  }
}

export function* updateLevel(action) {
  try {
    const { data } = yield call(
      api.put,
      `/levels/${action.payload.id}`,
      action.payload
    )
    yield put(LevelActions.updateLevelSuccess(data))
    yield put(push('/niveis'))
    toast.success('Nível editado com sucesso!')
  } catch (err) {
    toast.error('Erro ao editar level')
    yield put(LevelActions.updateLevelFailure(err))
  }
}

export function* deleteLevel(action) {
  try {
    const { data } = yield call(api.delete, `/levels/${action.payload.id}`)
    yield put(LevelActions.deleteLevelSuccess(data))
    yield put(LevelActions.getLevelsRequest())
    toast.success('Nível removido com sucesso')
  } catch (err) {
    toast.error('Erro ao remover level')
    yield put(LevelActions.deleteLevelFailure(err))
  }
}
