import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { isEmpty } from "lodash";

import DefaultPage from "components/DefaultPage";

import EditForm from "components/Support/EditSupportForm";

import { Creators as SupportActions } from "store/ducks/support";

const Edit = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const {
    auth: { auth }
  } = useSelector((state) => state)
  const { list, support } = useSelector((state) => state.support);

  const handleUpdate = useCallback(
    (item) => {

      if (item.comentario) {
        const comment = {
          description: item.comentario,
          user: {
            id: auth.id
          },
          support: { id: id }

        };
        dispatch(SupportActions.createSupportCommentRequest(comment));
      }
      dispatch(SupportActions.updateSupportRequest({ ...item, id }));
    },
    [auth.id, dispatch, id]
  );

  useEffect(() => {
    dispatch(SupportActions.getSupportRequest(id));
  }, [dispatch, id]);

  return (
    <DefaultPage
      pageTitle="Suporte"
      pageDescription="Atualizar Ticket"
      pageSubDescription="Adicionar Comentário"
    >
      {!isEmpty(support) && (
        <EditForm
          submitForm={handleUpdate}
          currentValue={support}
          positionsList={list}
        />
      )}
    </DefaultPage>
  );
};

export default Edit;
