import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import DefaultPage from "components/DefaultPage";

import CreateUserForm from "components/Users/CreateUserForm";

import { Creators as UserActions } from "store/ducks/users";
import { Creators as ProfileActions } from "store/ducks/profiles";
import { Creators as PlanActions } from "store/ducks/plans";

const CreateUser = () => {
  const dispatch = useDispatch();
  const profileList = useSelector((state) => state.profiles.list);
  const planList = useSelector((state) => state.plans.list);
  const userList = useSelector((state) => state.users.list);

  const handleCreateUser = useCallback(
    (item) => {
      dispatch(UserActions.createUserRequest(item));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(ProfileActions.getProfilesRequest());
  }, [dispatch]);

  useEffect(() => {
    dispatch(PlanActions.getPlansRequest());
  }, [dispatch]);

  return (
    <DefaultPage
      pageTitle="Usuários"
      pageDescription="Criar usuário"
      pageSubDescription="Criar um novo usuário"
    >
      <CreateUserForm
        submitForm={handleCreateUser}
        profileList={profileList}
        planList={planList}
        userList={userList}
      />
    </DefaultPage>
  );
};

export default CreateUser;
