import { put, call } from 'redux-saga/effects'
import { api } from 'services/api'
import { push } from 'connected-react-router'
import { toast } from 'react-toastify'

import { Creators as ServicesActions } from 'store/ducks/services'

export function* getServices() {
  try {
    const { data } = yield call(api.get, '/services')
    yield put(ServicesActions.getServicesSuccess(data))
  } catch (err) {
    yield put(ServicesActions.getServicesFailure(err.message))
  }
}

export function* getServiceByID(action) {
  try {
    const { data } = yield call(api.get, `/services/${action.payload}`)
    yield put(ServicesActions.getServiceSuccess(data))
  } catch (err) {
    yield put(ServicesActions.getServiceFailure(err))
  }
}

export function* createService(action) {
  try {
    const { data } = yield call(api.post, `/services`, action.payload)
    yield put(ServicesActions.createServiceSuccess(data))
    yield put(push('/servicos'))
    toast.success('Serviço criado com sucesso!')
  } catch (err) {
    toast.error('Erro ao criar serviço')
    yield put(ServicesActions.createServiceFailure(err))
  }
}

export function* updateService(action) {
  try {
    const { data } = yield call(
      api.put,
      `/services/${action.payload.id}`,
      action.payload
    )
    yield put(ServicesActions.updateServiceSuccess(data))
    yield put(push('/servicos'))
    toast.success('Serviço editado com sucesso!')
  } catch (err) {
    toast.error('Erro ao editar serviço')
    yield put(ServicesActions.updateServiceFailure(err))
  }
}

export function* deleteService(action) {
  try {
    const { data } = yield call(api.delete, `/services/${action.payload.id}`)
    yield put(ServicesActions.deleteServiceSuccess(data))
    yield put(ServicesActions.getServicesRequest())
    toast.success('Serviço removido com sucesso')
  } catch (err) {
    toast.error('Erro ao remover serviço')
    yield put(ServicesActions.deleteServiceFailure(err))
  }
}
