import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { checkIsActive } from 'utils'

import DefaultPage from 'components/DefaultPage'

import { Creators as MaterialsActions } from 'store/ducks/materials'

import Form from './Form'

const EditMaterial = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const { material } = useSelector((state) => state.materials)

  const [isActive, setIsActive] = useState(false)

  const handleActive = useCallback((value) => setIsActive(value), [])

  const handleUpdateMaterial = useCallback(
    (item) => {
      const body = {
        ...item,
        isActive: checkIsActive(isActive, material && material.isActive),
        file: item.file
      }

      dispatch(MaterialsActions.updateMaterialRequest({ ...body, id }))
    },
    [dispatch, id, isActive, material]
  )

  useEffect(() => {
    dispatch(MaterialsActions.getMaterialRequest(id))
  }, [dispatch, id])

  return (
    <DefaultPage
      pageTitle="Materiais de apoio"
      pageDescription="Editar material"
      pageSubDescription="Editar material de apoio para o sistema"
    >
      {!isEmpty(material) && (
        <Form
          submitForm={handleUpdateMaterial}
          handleActive={handleActive}
          currentValue={material}
        />
      )}
    </DefaultPage>
  )
}

export default EditMaterial
