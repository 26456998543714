import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import DefaultPage from "components/DefaultPage";

import { Creators as GraduationsActions } from "store/ducks/graduations";
import { Creators as PositionsActions } from "store/ducks/positions";
import { Creators as UsersActions } from "store/ducks/users";

import CreateGraduationForm from "components/Graduations/CreateGraduationForm";

const CreateGraduation = () => {
  const dispatch = useDispatch();
  const positions = useSelector((state) => state.positions.list);
  const users = useSelector((state) => state.users.list);

  const handleCreatePosition = useCallback(
    (item) => {
      dispatch(GraduationsActions.createGraduationRequest(item));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(PositionsActions.getPositionsRequest());
  }, [dispatch]);

  //HABILITAR DEPOIS DE PAGINAR USUÁRIOS
  // useEffect(() => {
  //   dispatch(UsersActions.getUsersRequest());
  // }, [dispatch]);

  return (
    <DefaultPage
      pageTitle="Graduações"
      pageDescription="Criar graduação"
      pageSubDescription="Criar uma nova graduação"
    >
      <CreateGraduationForm
        submitForm={handleCreatePosition}
        positionsList={positions}
        usersList={users}
      />
    </DefaultPage>
  );
};

export default CreateGraduation;
