export const cashbackChart = {
  series: [
    {
      name: 'Title',
      data: []
    },
    {
      name: 'Title',
      data: []
    }
  ],
  options: {
    chart: {
      height: 240,
      type: 'area',
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      type: 'category',
      low: 0,
      offsetX: 0,
      offsetY: 0,
      show: false,
      categories: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
      ],
      labels: {
        low: 0,
        offsetX: 0,
        show: false
      },
      axisBorder: {
        low: 0,
        offsetX: 0,
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    markers: {
      strokeWidth: 3,
      colors: '#ffffff',
      strokeColors: ['#2ecc71', '#000'],
      hover: {
        size: 6
      }
    },
    yaxis: {
      low: 0,
      offsetX: 0,
      offsetY: 0,
      show: false,
      labels: {
        low: 0,
        offsetX: 0,
        show: false
      },
      axisBorder: {
        low: 0,
        offsetX: 0,
        show: false
      }
    },
    grid: {
      show: false,
      padding: {
        left: 0,
        right: 0,
        bottom: -15,
        top: -40
      }
    },
    colors: ['#2ecc71', '#000'],
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.5,
        stops: [0, 80, 100]
      }
    },
    legend: {
      show: false
    },
    tooltip: {
      x: {
        format: 'MM'
      }
    }
  }
}
