export const requestActionsMainStyle = {
  display: 'flex',
  gap: '0 0.5rem',
  fontSize: '5px'
}

export const requestActionsButtonStyle = {
  width: 'auto',
  height: '30px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px'
}

export const buttonIconStyle = { width: '16px', height: '16px' }

export const modalContentStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem 0'
}

export const modalActionsStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '1rem 0',
  gap: '0 1rem'
}

export const modalTextAreaStyle = {
  resize: 'none',
  minHeight: '100px'
}
