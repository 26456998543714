import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { isEmpty } from "lodash";

import DefaultPage from "components/DefaultPage";
import EditProfileForm from "components/Profiles/EditProfileForm";

import { Creators as ProfileActions } from "store/ducks/profiles";
import { Creators as UserActions } from "store/ducks/actions";

const EditProfile = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { profile } = useSelector((state) => state.profiles);
  const { list } = useSelector((state) => state.actions);

  const handleUpdateProfile = useCallback(
    (item) => {
      let bodyIDs = [];
      item.actions.map((item) => bodyIDs.push({ id: item }));
      delete item.actions;
      let body = { ...item, actions: bodyIDs };

      dispatch(ProfileActions.updateProfileRequest({ ...body, id }));
    },
    [dispatch, id]
  );

  useEffect(() => {
    dispatch(UserActions.getActionsRequest());
  }, [dispatch]);

  useEffect(() => {
    dispatch(ProfileActions.getProfileRequest(id));
  }, [dispatch, id]);

  return (
    <DefaultPage
      pageTitle="Perfis"
      pageDescription="Criar perfil"
      pageSubDescription="Criar um novo perfil"
    >
      {!isEmpty(profile) && (
        <EditProfileForm
          submitForm={handleUpdateProfile}
          currentValue={profile}
          listActions={list}
        />
      )}
    </DefaultPage>
  );
};

export default EditProfile;
