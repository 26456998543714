import React, { Fragment } from 'react'
import { ToastContainer } from 'react-toastify'

import useFunctionRole from 'shared/hooks/useFunctionRole'

import NewMenu from './NewMenu/index'
import NewFooter from './NewFooter'

const Layout = ({ children, isCheckout }) => {
  const { isUserAdmin, menuByRole } = useFunctionRole()

  return (
    <Fragment>
      <NewMenu isCheckout={isCheckout} />
      <div className={isCheckout ? 'new-layout checkout' : 'new-layout'}>
        {children}
      </div>
      <NewFooter />
      <ToastContainer />
    </Fragment>
  )
}

export default Layout
