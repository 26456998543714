import React, { useState } from 'react'
import NotificationList from './List'

import { Bell } from 'react-feather'

const Notification = ({ list, handleRemoveNotification }) => {
  const [toggleNotificationList, setToggleNotificationList] = useState(false)

  return (
    <div className="notifications">
      <div
        className="icon"
        onClick={() => setToggleNotificationList(!toggleNotificationList)}
      >
        <Bell className="bell" />
        {list.length > 0 && <span className="badge">{list.length}</span>}
      </div>
      {toggleNotificationList && (
        <NotificationList
          list={list}
          onClickOutside={() => setToggleNotificationList(false)}
          handleRemoveNotification={handleRemoveNotification}
        />
      )}
    </div>
  )
}

export default Notification
