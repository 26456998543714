import Bonifications from "pages/Bonifications";
import CreateBonification from "pages/Bonifications/Create";
import EditBonification from "pages/Bonifications/Edit";

import { BASE_URL } from "shared/constants/config";

export default [
  { path: `${BASE_URL}/bonificacoes`, component: Bonifications },
  { path: `${BASE_URL}/bonificacoes/criar`, component: CreateBonification },
  { path: `${BASE_URL}/bonificacoes/editar/:id`, component: EditBonification },
];
