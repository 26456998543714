import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Label } from 'reactstrap'
import Select from 'react-select'

import QRCode from 'components/QRCode'

import {
  formatCurrencyToTransaction,
  generateDueDate,
  formatCheckoutInstallments
} from 'utils'

const PIX = ({ product, handleCreateTransaction, user }) => {
  const { transaction } = useSelector((state) => state.checkout)

  const [isCopiedCode, setIsCopiedCode] = useState(false)
  const [installments, setInstallments] = useState([])

  const pixCodeRef = useRef(null)
  const handleSelectAllText = () => pixCodeRef.current.select()

  const copyTextToClipboard = async (text) => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text)
    } else {
      return document.execCommand('copy', true, text)
    }
  }

  const handleCopyCode = async () => {
    await copyTextToClipboard(transaction?.pix_qr_code)
    setIsCopiedCode(true)
    setTimeout(() => {
      setIsCopiedCode(false)
    }, 1500)
  }

  const handleGenerateQRCode = useCallback(
    (data) => {
      !!data &&
        handleCreateTransaction({
          amount: formatCurrencyToTransaction(data.value),
          amountUnformated: data.value.toFixed(2),
          dueDate: generateDueDate(3),
          installments: data.quantity,
          payment_method: 'pix',
          pix_expiration_date: generateDueDate(3),
          pix_additional_fields: [
            {
              name: 'Product',
              value: `${product.label} ${product.price}`
            },

            {
              name: 'User',
              value: user && `Name: ${user.name} - ID: ${user.id}`
            }
          ],

          description: `Compra ${product.label}`
        })
    },
    [handleCreateTransaction, product, user]
  )

  useEffect(() => {
    if (product && product.price) {
      const formattedInstallments = formatCheckoutInstallments(product.price)
      setInstallments(formattedInstallments)
    }
  }, [product])

  return (
    <div className="pix">
      <div className="installments">
        <Label for="installments">Parcelas</Label>
        <Select
          options={installments}
          onChange={(e) =>
            handleGenerateQRCode({
              quantity: e.label.split('x')[0],
              value: e.value
            })
          }
          defaultInputValue={installments[0]}
        />
      </div>

      {transaction && (
        <div className="pix-wrapper">
          <div className="pix-code">
            <textarea
              onClick={handleSelectAllText}
              ref={pixCodeRef}
              className="form-control"
              value={transaction?.pix_qr_code}
              readOnly
            />
            <button onClick={handleCopyCode} className="action-button">
              {isCopiedCode ? 'Copiado!' : 'Copiar código PIX '}
            </button>
          </div>
          <div className="qrcode">
            <QRCode value={transaction?.pix_qr_code} size={175} />
          </div>{' '}
        </div>
      )}
    </div>
  )
}

export default PIX
