import React from "react";
import { Form, Label, Input, Col, Button } from "reactstrap";
import { useForm } from "react-hook-form";

const CreateProfileForm = ({ submitForm, listActions }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { ref: nameRef, ...name } = register("name", { required: true });

  const { ref: descriptionRef, ...description } = register("description", {
    required: true,
  });

  const { ref: actionsRef, ...actions } = register("actions");

  return (
    <Form
      className="needs-validation"
      noValidate=""
      onSubmit={handleSubmit(submitForm)}
    >
      <div className="form-row">
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom01">Nome</Label>
          <Input
            className="form-control"
            name="name"
            type="text"
            placeholder="Nome"
            innerRef={nameRef}
            {...name}
          />
          <span>{errors.name && "Nome é obrigatório"}</span>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom02">Descrição</Label>
          <Input
            className="form-control"
            name="description"
            type="text"
            placeholder="Descrição"
            innerRef={descriptionRef}
            {...description}
          />

          <span>{errors.description && "Descrição é obrigatório"}</span>
        </Col>
      </div>
      <div className="form-row row-gap">
        <Col sm="12">
          <h5>Ações disponíveis</h5>
        </Col>
        <Col>
          {listActions?.map((item) => (
            <Label className="d-block" for={item.id}>
              <Input
                className="checkbox_animated"
                id={item.id}
                type="checkbox"
                innerRef={actionsRef}
                value={item.id}
                {...actions}
              />
              {item.name}
            </Label>
          ))}
        </Col>
      </div>
      <div className="form-row row-gap">
        <Button color="primary">Enviar</Button>
      </div>
    </Form>
  );
};

export default CreateProfileForm;
