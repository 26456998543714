import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { isEmpty } from "lodash";

import DefaultPage from "components/DefaultPage";

import EditActionForm from "components/Actions/EditActionForm";

import { Creators as UserActions } from "store/ducks/actions";

const EditAction = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { action } = useSelector((state) => state.actions);

  const handleUpdateAction = useCallback(
    (item) => {
      dispatch(UserActions.updateActionRequest({ ...item, id }));
    },
    [dispatch, id]
  );

  useEffect(() => {
    dispatch(UserActions.getActionRequest(id));
  }, [dispatch, id]);

  return (
    <DefaultPage
      pageTitle="Ações"
      pageDescription="Editar ação"
      pageSubDescription="Editar a ação"
    >
      {!isEmpty(action) && (
        <EditActionForm submitForm={handleUpdateAction} currentValue={action} />
      )}
    </DefaultPage>
  );
};

export default EditAction;
