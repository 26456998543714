import React from "react";
import { Form, Label, Input, Col, Button } from "reactstrap";
import { useForm } from "react-hook-form";

const CreatePlanForm = ({ submitForm, listLevels }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { ref: nameRef, ...name } = register("name", { required: true });
  const { ref: descriptionRef, ...description } = register("description", {
    required: true,
  });
  const { ref: priceRef, ...price } = register("price", {
    required: true,
  });
  const { ref: pointsRef, ...points } = register("points", {
    required: true,
  }); 
  
  const { ref: feature01Ref, ...feature01 } = register("feature01", {
    required: true,
  });
  const { ref: feature02Ref, ...feature02 } = register("feature02", {
    required: true,
  });
  const { ref: feature03Ref, ...feature03 } = register("feature03", {
    required: true,
  });
  const { ref: feature04Ref, ...feature04 } = register("feature04", {
    required: true,
  });
  const { ref: minAmoutRef, ...minAmout } = register("minAmout", {
    required: true,
  });
  const { ref: levelsRef, ...levels } = register("levels");

  return (
    <Form
      className="needs-validation"
      noValidate=""
      onSubmit={handleSubmit(submitForm)}
    >
      <div className="form-row">
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom01">Nome</Label>
          <Input
            className="form-control"
            name="name"
            type="text"
            placeholder="Nome"
            innerRef={nameRef}
            {...name}
          />
          <span>{errors.name && "Nome é obrigatório"}</span>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom02">Descrição</Label>
          <Input
            className="form-control"
            name="description"
            type="text"
            placeholder="Descrição"
            innerRef={descriptionRef}
            {...description}
          />

          <span>{errors.description && "Descrição é obrigatório"}</span>
        </Col>
        </div>
      <div className="form-row">
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom02">Preço</Label>
          <Input
            className="form-control"
            name="description"
            type="text"
            placeholder="Preço"
            innerRef={priceRef}
            {...price}
          />

          <span>{errors.description && "Preço é obrigatório"}</span>
        </Col>

        <Col md="4 mb-3">
          <Label htmlFor="validationCustom01">pontos</Label>
          <Input
            className="form-control"
            name="name"
            type="text"
            placeholder="Pontos"
            innerRef={pointsRef}
            {...points}
          />
          <span>{errors.name && "Pontos é obrigatório"}</span>
        </Col>
        </div>
      <div className="form-row">
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom02">Descritivo de venda 01</Label>
          <Input
            className="form-control"
            name="description"
            type="text"
            placeholder="Descritivo de venda"
            innerRef={feature01Ref}
            {...feature01}
          />

          <span>{errors.description && "Descritivo de venda é obrigatório"}</span>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom02">Descritivo de venda 02</Label>
          <Input
            className="form-control"
            name="description"
            type="text"
            placeholder="Descritivo de venda"
            innerRef={feature02Ref}
            {...feature02}
          />

          <span>{errors.description && "Descritivo de venda é obrigatório"}</span>
        </Col>
        </div>
      <div className="form-row">
      <Col md="4 mb-3">
          <Label htmlFor="validationCustom02">Descritivo de venda 03</Label>
          <Input
            className="form-control"
            name="description"
            type="text"
            placeholder="Descritivo de venda"
            innerRef={feature03Ref}
            {...feature03}
          />

          <span>{errors.description && "Descritivo de venda é obrigatório"}</span>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom02">Descritivo de venda 04</Label>
          <Input
            className="form-control"
            name="description"
            type="text"
            placeholder="Descritivo de venda"
            innerRef={feature04Ref}
            {...feature04}
          />

          <span>{errors.description && "Descritivo de venda é obrigatório"}</span>
        </Col>
      </div>
      <div className="form-row row-gap">
        <Col sm="12">
          <h5>Níveis</h5>
        </Col>
        <Col>
          {listLevels?.map((item) => (
            <Label className="d-block" for={item.id}>
              <Input
                className="checkbox_animated"
                id={item.id}
                type="checkbox"
                innerRef={levelsRef}
                value={item.id}
                {...levels}
              />
              {item.name}
            </Label>
          ))}
        </Col>
      </div>
      <div className="form-row row-gap">
        <Button color="primary">Enviar</Button>
      </div>
    </Form>
  );
};

export default CreatePlanForm;
