import UsersDeadbeat from "pages/Deadbeat";
import CreateUser from "pages/Deadbeat/Create";
import EditUser from "pages/Deadbeat/Edit";

import { BASE_URL } from "shared/constants/config";
export default [
  { path: `${BASE_URL}/deadbeat`, component: UsersDeadbeat },
  { path: `${BASE_URL}/deadbeat/criar`, component: CreateUser },
  { path: `${BASE_URL}/deadbeat/editar/:id`, component: EditUser },
];
