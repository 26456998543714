export const Types = {
  GET_SUPPORTS_REQUEST: "support/GET_SUPPORTS_REQUEST",
  GET_SUPPORTS_SUCCESS: "support/GET_SUPPORTS_SUCCESS",
  GET_SUPPORTS_FAILURE: "support/GET_SUPPORTS_FAILURE",

  GET_SUPPORTS_BY_ID_REQUEST: "support/GET_SUPPORTS_BY_ID_REQUEST",
  GET_SUPPORTS_BY_ID_SUCCESS: "support/GET_SUPPORTS_BY_ID_SUCCESS",
  GET_SUPPORTS_BY_ID_FAILURE: "support/GET_SUPPORTS_BY_ID_FAILURE",
  GET_SUPPORTS_BY_USER_REQUEST: "support/GET_SUPPORTS_BY_USER_REQUEST",
  GET_SUPPORTS_BY_USER_SUCCESS: "support/GET_SUPPORTS_BY_USER_SUCCESS",
  GET_SUPPORTS_BY_USER_FAILURE: "support/GET_SUPPORTS_BY_USER_FAILURE",
  GET_SUPPORT_REQUEST: "support/GET_SUPPORT_REQUEST",
  GET_SUPPORT_SUCCESS: "support/GET_SUPPORT_SUCCESS",
  GET_SUPPORT_FAILURE: "support/GET_SUPPORT_FAILURE",

  CREATE_SUPPORT_REQUEST: "support/CREATE_SUPPORT_REQUEST",
  CREATE_SUPPORT_SUCCESS: "support/CREATE_SUPPORT_SUCCESS",
  CREATE_SUPPORT_FAILURE: "support/CREATE_SUPPORT_FAILURE",
  CREATE_SUPPORT_COMMENT_REQUEST: "support/CREATE_SUPPORT_COMMENT_REQUEST",
  CREATE_SUPPORT_COMMENT_SUCCESS: "support/CREATE_SUPPORT_COMMENT_SUCCESS",
  CREATE_SUPPORT_COMMENT_FAILURE: "support/CREATE_SUPPORT_COMMENT_FAILURE",

  UPDATE_SUPPORT_REQUEST: "support/UPDATE_SUPPORT_REQUEST",
  UPDATE_SUPPORT_SUCCESS: "support/UPDATE_SUPPORT_SUCCESS",
  UPDATE_SUPPORT_FAILURE: "support/UPDATE_SUPPORT_FAILURE",

  DELETE_SUPPORT_REQUEST: "support/DELETE_SUPPORT_REQUEST",
  DELETE_SUPPORT_SUCCESS: "support/DELETE_SUPPORT_SUCCESS",
  DELETE_SUPPORT_FAILURE: "support/DELETE_SUPPORT_FAILURE",
};

const INITIAL_STATE = {
  list: [],
  support: {},
  loading: false,
  error: null,
  supportComment: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_SUPPORTS_REQUEST:
      return { ...state, loading: true, list: [], error: null };

    case Types.GET_SUPPORTS_SUCCESS:
      return { ...state, loading: false, list: action.payload, error: null };

    case Types.GET_SUPPORTS_FAILURE:
      return { ...state, loading: false, list: [], error: action.payload };
    case Types.GET_SUPPORTS_BY_USER_REQUEST:
      return { ...state, loading: true, list: [], error: null };

    case Types.GET_SUPPORTS_BY_USER_SUCCESS:
      return { ...state, loading: false, list: action.payload, error: null };

    case Types.GET_SUPPORTS_BY_USER_FAILURE:
      return { ...state, loading: false, list: [], error: action.payload };
    case Types.GET_SUPPORT_REQUEST:
      return { ...state, loading: true, support: {}, error: null };
    case Types.GET_SUPPORT_COMMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        supportComment: action.payload,
        error: null,
      };

    case Types.GET_SUPPORT_COMMENT_FAILURE:
      return { ...state, loading: false, supportComment: {}, error: action.payload };

    case Types.CREATE_SUPPORT_COMMENT_REQUEST:
      return { ...state, loading: true, supportComment: {}, error: null };
    case Types.GET_SUPPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        support: action.payload,
        error: null,
      };

    case Types.GET_SUPPORT_FAILURE:
      return { ...state, loading: false, support: {}, error: action.payload };

    case Types.CREATE_SUPPORT_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.CREATE_SUPPORT_SUCCESS:
      return { ...state, loading: false, error: null };

    case Types.CREATE_SUPPORT_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.UPDATE_SUPPORT_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.UPDATE_SUPPORT_SUCCESS:
      return { ...state, loading: false, support: {}, error: null };

    case Types.UPDATE_SUPPORT_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.DELETE_SUPPORT_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.DELETE_SUPPORT_SUCCESS:
      return { ...state, loading: false, error: null };

    case Types.DELETE_SUPPORT_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export const Creators = {
  getSupportsRequest: (payload) => ({
    type: Types.GET_SUPPORTS_REQUEST,
    payload,
  }),

  getSupportsSuccess: (payload) => ({
    type: Types.GET_SUPPORTS_SUCCESS,
    payload,
  }),

  getSupportsFailure: (payload) => ({
    type: Types.GET_SUPPORTS_FAILURE,
    payload,
  }),
  getSupportsByUserRequest: (payload) => ({
    type: Types.GET_SUPPORTS_BY_USER_REQUEST,
    payload,
  }),

  getSupportsByUserSuccess: (payload) => ({
    type: Types.GET_SUPPORTS_BY_USER_SUCCESS,
    payload,
  }),

  getSupportsByUserFailure: (payload) => ({
    type: Types.GET_SUPPORTS_BY_USER_FAILURE,
    payload,
  }),
  getSupportRequest: (payload) => ({
    type: Types.GET_SUPPORT_REQUEST,
    payload,
  }),

  getSupportSuccess: (payload) => ({
    type: Types.GET_SUPPORT_SUCCESS,
    payload,
  }),

  getSupportFailure: (payload) => ({
    type: Types.GET_SUPPORT_FAILURE,
    payload,
  }),

  createSupportRequest: (payload) => ({
    type: Types.CREATE_SUPPORT_REQUEST,
    payload,
  }),

  createSupportSuccess: (payload) => ({
    type: Types.CREATE_SUPPORT_SUCCESS,
    payload,
  }),

  createSupportFailure: (payload) => ({
    type: Types.CREATE_SUPPORT_FAILURE,
    payload,
  }),
  createSupportCommentRequest: (payload) => ({
    type: Types.CREATE_SUPPORT_COMMENT_REQUEST,
    payload,
  }),

  createSupportCommentSuccess: (payload) => ({
    type: Types.CREATE_SUPPORT_COMMENT_SUCCESS,
    payload,
  }),

  createSupportCommentFailure: (payload) => ({
    type: Types.CREATE_SUPPORT_COMMENT_FAILURE,
    payload,
  }),

  updateSupportRequest: (payload) => ({
    type: Types.UPDATE_SUPPORT_REQUEST,
    payload,
  }),

  updateSupportSuccess: (payload) => ({
    type: Types.UPDATE_SUPPORT_SUCCESS,
    payload,
  }),

  updateSupportFailure: (payload) => ({
    type: Types.UPDATE_SUPPORT_FAILURE,
    payload,
  }),

  deleteSupportRequest: (payload) => ({
    type: Types.DELETE_SUPPORT_REQUEST,
    payload,
  }),

  deleteSupportSuccess: (payload) => ({
    type: Types.DELETE_SUPPORT_SUCCESS,
    payload,
  }),

  deleteSupportFailure: (payload) => ({
    type: Types.DELETE_SUPPORT_FAILURE,
    payload,
  }),
};
