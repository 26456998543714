import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Creators as FAQActions } from 'store/ducks/faq'
import ContentPage from 'components/ContentPage'

const Show = () => {
  const dispatch = useDispatch()
  const { list } = useSelector((state) => state.faq)

  useEffect(() => {
    dispatch(FAQActions.getQuestionsRequest())
  }, [dispatch])

  const items = useMemo(() => {
    const formatted = []
    const onlyActiveItems = list.filter((item) => item.isActive !== false)
    onlyActiveItems.map((item) =>
      formatted.push({ header: item.question, content: item.answer })
    )
    return formatted
  }, [list])

  return (
    <ContentPage hasAccordion pageTitle="FAQ - Perguntas mais frequentes" items={items} />
  )
}

export default Show
