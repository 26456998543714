import React, { Fragment, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'


import useSocialAuth from 'shared/hooks/useSocialAuth'

import { Creators as AuthActions } from 'store/ducks/auth'
import { logout } from 'services/auth'

const Logout = () => {
  const dispatch = useDispatch()

  const { handleSocialLogout } = useSocialAuth()

  const history = useHistory()

  useEffect(() => {
    history.push('/login')
    handleSocialLogout()
    logout()
    dispatch(AuthActions.logout())
  }, [dispatch, handleSocialLogout, history])
  
  history.push('/login')
    handleSocialLogout()
    logout()
    dispatch(AuthActions.logout())
  
  return (
    <Fragment>
      <div></div>
       
    </Fragment>
  )
}
export default Logout
