import React, { useEffect, useState } from "react";
import { Form, Label, Input, Col, Button } from "reactstrap";
import { debounce } from "lodash";
import { Link } from 'react-router-dom'

import { useForm } from "react-hook-form";
import Select from "react-select";

import InputMask from "react-input-mask";

const CreateUserForm = ({
  submitForm,
  profileList,
  planList,
  sponsorsList,
  handleUserList,
  sponsorsLoading,
}) => {
  const [optionsSelectProfile, setOptionsSelectProfile] = useState([]);
  const [optionsSelectPlan, setOptionsSelectPlan] = useState([]);
  const [optionsSelectSponsor, setOptionsSelectSponsor] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const { ref: cpfRef, ...cpf } = register("cpf");
  const { ref: emailRef, ...email } = register("email", { required: true });
  const { ref: nameRef, ...name } = register("name", { required: true });
  const { ref: passwordRef, ...password } = register("password", {
    required: true,
  });

  const { ref: phoneRef, ...phone } = register("phone", { required: true });
  const { ref: whatsappRef, ...whatsapp } = register("whatsapp");
  const { ref: birthDateRef, ...birthDate } = register("birthDate");

  const { ref: companyNameRef, ...companyName } = register("companyName");
  const { ref: cnpjRef, ...cnpj } = register("cnpj");
  const { ref: establishmentRef, ...establishment } = register(
    "establishment",
    { required: true }
  );

  const { ref: geolocationRef, ...geolocation } = register("geolocation");
  const { ref: iconRef, ...icon } = register("icon");

  useEffect(() => {
    let options = [];
    for (let i in profileList) {
      options.push({
        value: profileList[i].id,
        label: profileList[i].name,
      });
    }
    setOptionsSelectProfile(options);
  }, [profileList]);

  useEffect(() => {
    let options = [];
    for (let i in planList) {
      options.push({
        value: planList[i].id,
        label: planList[i].name,
      });
    }
    setOptionsSelectPlan(options);
  }, [planList]);

  useEffect(() => {
    let options = [];
    for (let i in sponsorsList) {
      options.push({
        value: sponsorsList[i].id,
        label: sponsorsList[i].name,
      });
    }
    setOptionsSelectSponsor(options);
  }, [sponsorsList]);

  const loadSuggestions = debounce(handleUserList, 500);

  return (
    <Form
      className="needs-validation"
      noValidate=""
      onSubmit={handleSubmit(submitForm)}
    >
      <div className="form-row">
        <Col md="3 mb-3">
          <Label htmlFor="validationCustom01">Nome</Label>
          <Input
            className="form-control"
            name="name"
            type="text"
            placeholder="Nome"
            innerRef={nameRef}
            {...name}
          />
          <span>{errors.name && "Nome é obrigatório"}</span>
        </Col>
        <Col md="3 mb-3">
          <Label htmlFor="validationCustom02">E-mail</Label>
          <Input
            className="form-control"
            name="description"
            type="text"
            placeholder="E-mail"
            innerRef={emailRef}
            {...email}
          />

          <span>{errors.email && "E-mail é obrigatório"}</span>
        </Col>
        <Col md="3 mb-3">
          <Label htmlFor="validationCustom02">Data de Nascimento</Label>
          <Input
            className="form-control"
            name="birthDate"
            type="text"
            placeholder="Data de Nascimento"
            innerRef={birthDateRef}
            {...birthDate}
            tag={InputMask}
            mask="99/99/9999"
          />

          <span>{errors.birthDate && "Data de nascimento é obrigatório"}</span>
        </Col>
        <Col md="3 mb-3">
          <Label htmlFor="validationCustom02">CPF</Label>
          <Input
            className="form-control"
            name="description"
            type="text"
            placeholder="CPF"
            innerRef={cpfRef}
            {...cpf}
            tag={InputMask}
            mask="999.999.999-99"
          />

          {/* <span>{errors.cpf && "CPF é obrigatório"}</span> */}
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom02">CNPJ</Label>
          <Input
            className="form-control"
            name="description"
            type="text"
            placeholder="CNPJ"
            innerRef={cnpjRef}
            {...cnpj}
            tag={InputMask}
            mask="99.999.999/9999-99"
          />

          {/* <span>{errors.cpf && "CPF é obrigatório"}</span> */}
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom02">Nome da Empresa</Label>
          <Input
            className="form-control"
            name="description"
            type="text"
            placeholder="Nome da Empresa"
            innerRef={companyNameRef}
            {...companyName}
          />

          {/* <span>{errors.cpf && "CPF é obrigatório"}</span> */}
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom02">Tipo do Estabelecimento</Label>
          <Input
            className="form-control"
            name="establishment"
            type="text"
            placeholder="Tipo do Estabelecimento"
            innerRef={establishmentRef}
            {...establishment}
          />

          {/* <span>{errors.cpf && "CPF é obrigatório"}</span> */}
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom02">Telefone</Label>
          <Input
            className="form-control"
            name="phone"
            type="text"
            placeholder="Telefone"
            innerRef={phoneRef}
            {...phone}
            tag={InputMask}
            mask="(99) 9999-9999"
          />

          <span>{errors.phone && "Telefone é obrigatório"}</span>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom02">Whatsapp</Label>
          <Input
            className="form-control"
            name="whatsapp"
            type="text"
            placeholder="Whatsapp"
            innerRef={whatsappRef}
            {...whatsapp}
            tag={InputMask}
            mask="(99) 99999-9999"
          />

          {/* <span>{errors.phone && "Telefone é obrigatório"}</span> */}
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom02">Geolocalização</Label>
          <Input
            className="form-control"
            name="geolocation"
            type="text"
            placeholder="Geolocalização"
            innerRef={geolocationRef}
            {...geolocation}
          />

          {/* <span>{errors.phone && "Telefone é obrigatório"}</span> */}
        </Col>
        <Col md="2 mb-3">
          <Label htmlFor="validationCustom02">Senha</Label>
          <Input
            className="form-control"
            name="password"
            type="text"
            placeholder="Senha"
            innerRef={passwordRef}
            {...password}
          />

          <span>{errors.password && "Senha é obrigatório"}</span>
        </Col>
      </div>
      <div className="form-row row-gap">
        <Col>
          <h5>Patrocinador</h5>
          <Select
            options={optionsSelectSponsor}
            onChange={(e) => {
              !!e && setValue("sponsor", [{ id: e.value }]);
            }}
            onInputChange={(e) => loadSuggestions(e)}
            onMenuOpen={() => setOptionsSelectSponsor([])}
            isLoading={sponsorsLoading}
            isClearable
          />
        </Col>
        <Col>
          <h5>Perfil</h5>
          <Select
            options={optionsSelectProfile}
            onChange={(e) => setValue("profile", [{ id: e.value }])}
          />
        </Col>

        <Col>
          <h5>Habilitação</h5>
          <Select
            options={optionsSelectPlan}
            onChange={(e) => setValue("plan", [{ id: e.value }])}
          />
        </Col>
      </div>
      <div className="form-row row-gap">
        <Button color="primary">Enviar</Button>
        <Link to="/usuarios">
          <Button color="default" style={{ border: 'none' }}>
            Cancelar
          </Button>
        </Link>
      </div>
    </Form>
  );
};

export default CreateUserForm;
