export const Types = {
  GET_TUTORIALS_REQUEST: 'tutorials/GET_TUTORIALS_REQUEST',
  GET_TUTORIALS_SUCCESS: 'tutorials/GET_TUTORIALS_SUCCESS',
  GET_TUTORIALS_FAILURE: 'tutorials/GET_TUTORIALS_FAILURE',

  GET_TUTORIAL_REQUEST: 'tutorials/GET_TUTORIAL_REQUEST',
  GET_TUTORIAL_SUCCESS: 'tutorials/GET_TUTORIAL_SUCCESS',
  GET_TUTORIAL_FAILURE: 'tutorials/GET_TUTORIAL_FAILURE',

  CREATE_TUTORIAL_REQUEST: 'tutorials/CREATE_TUTORIAL_REQUEST',
  CREATE_TUTORIAL_SUCCESS: 'tutorials/CREATE_TUTORIAL_SUCCESS',
  CREATE_TUTORIAL_FAILURE: 'tutorials/CREATE_TUTORIAL_FAILURE',

  UPDATE_TUTORIAL_REQUEST: 'tutorials/UPDATE_TUTORIAL_REQUEST',
  UPDATE_TUTORIAL_SUCCESS: 'tutorials/UPDATE_TUTORIAL_SUCCESS',
  UPDATE_TUTORIAL_FAILURE: 'tutorials/UPDATE_TUTORIAL_FAILURE',

  DELETE_TUTORIAL_REQUEST: 'tutorials/DELETE_TUTORIAL_REQUEST',
  DELETE_TUTORIAL_SUCCESS: 'tutorials/DELETE_TUTORIAL_SUCCESS',
  DELETE_TUTORIAL_FAILURE: 'tutorials/DELETE_TUTORIAL_FAILURE'
}

const INITIAL_STATE = {
  list: [],
  tutorial: {},
  loading: false,
  error: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_TUTORIALS_REQUEST:
      return { ...state, loading: true, error: null }

    case Types.GET_TUTORIALS_SUCCESS:
      return { ...state, loading: false, list: action.payload, error: null }

    case Types.GET_TUTORIALS_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case Types.GET_TUTORIAL_REQUEST:
      return { ...state, loading: true, tutorial: {}, error: null }

    case Types.GET_TUTORIAL_SUCCESS:
      return { ...state, loading: false, tutorial: action.payload, error: null }

    case Types.GET_TUTORIAL_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case Types.CREATE_TUTORIAL_REQUEST:
      return { ...state, loading: true, error: null }

    case Types.CREATE_TUTORIAL_SUCCESS:
      return { ...state, loading: false, error: null }

    case Types.CREATE_TUTORIAL_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case Types.UPDATE_TUTORIAL_REQUEST:
      return { ...state, loading: true, error: null }

    case Types.UPDATE_TUTORIAL_SUCCESS:
      return { ...state, loading: false, tutorial: {}, error: null }

    case Types.UPDATE_TUTORIAL_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case Types.DELETE_TUTORIAL_REQUEST:
      return { ...state, loading: true, error: null }

    case Types.DELETE_TUTORIAL_SUCCESS:
      return { ...state, loading: false, error: null }

    case Types.DELETE_TUTORIAL_FAILURE:
      return { ...state, loading: false, error: action.payload }

    default:
      return state
  }
}

export const Creators = {
  getTutorialsRequest: (payload) => ({
    type: Types.GET_TUTORIALS_REQUEST,
    payload
  }),

  getTutorialsSuccess: (payload) => ({
    type: Types.GET_TUTORIALS_SUCCESS,
    payload
  }),

  getTutorialsFailure: (payload) => ({
    type: Types.GET_TUTORIALS_FAILURE,
    payload
  }),

  getTutorialRequest: (payload) => ({
    type: Types.GET_TUTORIAL_REQUEST,
    payload
  }),

  getTutorialSuccess: (payload) => ({
    type: Types.GET_TUTORIAL_SUCCESS,
    payload
  }),

  getTutorialFailure: (payload) => ({
    type: Types.GET_TUTORIAL_FAILURE,
    payload
  }),

  createTutorialRequest: (payload) => ({
    type: Types.CREATE_TUTORIAL_REQUEST,
    payload
  }),

  createTutorialSuccess: (payload) => ({
    type: Types.CREATE_TUTORIAL_SUCCESS,
    payload
  }),

  createTutorialFailure: (payload) => ({
    type: Types.CREATE_TUTORIAL_FAILURE,
    payload
  }),

  updateTutorialRequest: (payload) => ({
    type: Types.UPDATE_TUTORIAL_REQUEST,
    payload
  }),

  updateTutorialSuccess: (payload) => ({
    type: Types.UPDATE_TUTORIAL_SUCCESS,
    payload
  }),

  updateTutorialFailure: (payload) => ({
    type: Types.UPDATE_TUTORIAL_FAILURE,
    payload
  }),

  deleteTutorialRequest: (payload) => ({
    type: Types.DELETE_TUTORIAL_REQUEST,
    payload
  }),

  deleteTutorialSuccess: (payload) => ({
    type: Types.DELETE_TUTORIAL_SUCCESS,
    payload
  }),

  deleteTutorialFailure: (payload) => ({
    type: Types.DELETE_TUTORIAL_FAILURE,
    payload
  })
}
