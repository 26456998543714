import React, { memo } from 'react'

import Deposit from './Deposit'
import BankAccounts from './BankAccounts'
import Withdraw from './Withdraw'
import ValueCard from '../Values/Card'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBrazilianRealSign } from '@fortawesome/free-solid-svg-icons'
import { formatToCurrency } from 'utils'
const Actions = ({ data, dataValues }) => {
  return (
    <div className="actions">
      <div className="values">
        <ValueCard
          isMain
          icon={
            <FontAwesomeIcon
              icon={faBrazilianRealSign}
              color="rgb(34, 95, 107)"
              fontSize="20px"
            />
          }
          title="Saldo atual"
          value={dataValues?.balance && formatToCurrency(dataValues?.balance)}
        />
      </div>
      {/* <Deposit /> */}
      <Withdraw currentBalance={dataValues?.balance} />
      <BankAccounts accounts={data.bankAccounts} />
    </div>
  )
}

export default memo(Actions)
