import { put, call } from 'redux-saga/effects'
import { api } from 'services/api'
import { push } from 'connected-react-router'
import { toast } from 'react-toastify'

import { Creators as NotificationsActions } from 'store/ducks/notifications'

export function* getNotifications() {
  try {
    const { data } = yield call(api.get, '/notifications')
    yield put(NotificationsActions.getNotificationsSuccess(data))
  } catch (err) {
    toast.error('Erro ao carregar as notificações do sistema!')
    yield put(NotificationsActions.getNotificationsFailure(err.message))
  }
}

export function* createNotification(action) {
  try {
    let body = action.payload
    if (action.payload.type === 'email') {
      const userReceiver = yield call(
        api.get,
        `/user?email=${action.payload.emailReceiver}`
      )
      body.emailReceiver = userReceiver.data
    }

    const { data } = yield call(api.post, `/notifications`, body)
    yield put(NotificationsActions.createNotificationSuccess(data))
    yield put(push('/notificacoes'))
    toast.success('Notificação criada com sucesso!')
  } catch (err) {
    console.log(err)
    toast.error('Erro ao criar notificação!')
    yield put(NotificationsActions.createNotificationFailure(err))
  }
}

export function* getNotificationsByuser(action) {
  try {
    const { data } = yield call(
      api.get,
      `/notifications/user?id=${action.payload}`
    )
    yield put(NotificationsActions.getNotificationsByUserSuccess(data))
  } catch (err) {
    toast.error('Erro ao carregar as notificações do usuário!')
    yield put(NotificationsActions.getNotificationsByUserFailure(err.message))
  }
}

export function* setAsReadedNotificationByUser(action) {
  try {
    yield call(api.patch, `/notifications/${action.payload.id}`, {
      isReaded: true
    })
    yield put(NotificationsActions.setAsReadedNotificationByUserSuccess())
    yield put(
      NotificationsActions.getNotificationsByUserRequest(action.payload.auth)
    )
  } catch (err) {
    toast.error('Erro ao excluir notificação')
    yield put(
      NotificationsActions.setAsReadedNotificationByUserFailure(err.message)
    )
  }
}
