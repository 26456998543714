import Positions from "pages/Graduations";
import CreateGraduation from "pages/Graduations/Create";
import EditGraduation from "pages/Graduations/Edit";

import { BASE_URL } from "shared/constants/config";

export default [
  { path: `${BASE_URL}/graduacoes`, component: Positions },
  { path: `${BASE_URL}/graduacoes/criar`, component: CreateGraduation },
  { path: `${BASE_URL}/graduacoes/editar/:id`, component: EditGraduation },
];
