import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Creators as MoneyActions } from 'store/ducks/money'

import { formatToCurrency } from 'utils'

export default () => {
  const {
    auth: { auth },
    money: { moneyAdminDash, moneyAdminDashDetail }
  } = useSelector((state) => state)

  const dispatch = useDispatch()

  const greetingsData = useMemo(() => {
    const icon = auth && auth.icon
    const plan = auth && auth.plan?.[0]
    const name = auth && auth.name

    return { icon, plan, name }
  }, [auth])

  const summaryDataChart = useMemo(() => {

    const cashbackAmount = parseFloat(moneyAdminDash?.list?.find(
      (item) => item.type === 'cashback_accredited'
    )?.sum) + parseFloat(moneyAdminDash?.list?.find(
      (item) => item.type === 'cashback'
    )?.sum)

    const soldPlansAmount =
      moneyAdminDash &&
      moneyAdminDash.list?.find(
        (item) => item.type === 'bonus_accession_residual'
      )?.sum

    const physicalStoresAmount =
      moneyAdminDash &&
      moneyAdminDash.list?.find(
        (item) => item.type === 'cashback_accredited_residual'
      )?.sum

    const spreadBoughtPlanBonus =
      moneyAdminDash &&
      moneyAdminDash.list?.find(
        (item) => item.type === 'bonus_accession'
      )?.sum

    const reversal =
      moneyAdminDash &&
      moneyAdminDash.list?.find((item) => item.type === 'refund')?.sum

    const cashbackChartData =
      moneyAdminDashDetail && moneyAdminDashDetail.list
        ? moneyAdminDashDetail.list.map((item) => parseFloat(item.sum))
        : []

    cashbackChartData.length = 12

    return {
      cashbackAmount,
      soldPlansAmount,
      physicalStoresAmount,
      spreadBoughtPlanBonus,
      reversal,
      cashbackChartData
    }
  }, [moneyAdminDash, moneyAdminDashDetail])

  useEffect(() => {
    dispatch(MoneyActions.getMoneyAdminDashRequest())
  }, [dispatch])

  useEffect(() => {
    dispatch(MoneyActions.getMoneyAdminDashDetailRequest())
  }, [dispatch])

  return {
    summaryDataChart,
    greetingsData
  }
}
