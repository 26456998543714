import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { isEmpty } from "lodash";

import DefaultPage from "components/DefaultPage";

import EditBonificationForm from "components/Bonifications/EditBonificationForm";

import { Creators as BonificationActions } from "store/ducks/bonifications";

const EditBonification = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { bonification } = useSelector((state) => state.bonifications);

  const handleUpdateBonification = useCallback(
    (item) => {
      dispatch(BonificationActions.updateBonificationRequest({ ...item, id }));
    },
    [dispatch, id]
  );

  useEffect(() => {
    dispatch(BonificationActions.getBonificationRequest(id));
  }, [dispatch, id]);

  return (
    <DefaultPage
      pageTitle="Bonificações"
      pageDescription="Editar bonificação"
      pageSubDescription="Editar a bonificação"
    >
      {!isEmpty(bonification) && (
        <EditBonificationForm
          submitForm={handleUpdateBonification}
          currentValue={bonification}
        />
      )}
    </DefaultPage>
  );
};

export default EditBonification;
