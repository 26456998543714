import React from 'react'
import Moment from 'react-moment'
import 'moment/locale/pt-br'

import { Button, Modal, ModalBody } from 'reactstrap'

const FullNotification = ({
  isOpen,
  content,
  modalToggle,
  handleRemoveNotification
}) => {
  const contentWrapperStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0.5rem 0',
    gap: '0.5rem 0'
  }

  const titleStyle = { fontSize: '2rem', textTransform: 'uppercase' }
  const buttonStyle = { marginTop: '1rem' }

  const contentStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
  return (
    <Modal isOpen={isOpen} centered>
      <ModalBody>
        <div style={contentWrapperStyle}>
          <strong style={titleStyle}>Aviso</strong>
          {content.map((item) => (
            <div style={contentStyle} key={item.id}>
              <span style={{ fontSize: '1.25rem' }}>{item.description}</span>
              <Moment fromNow>{item.delivereDate}</Moment>
            </div>
          ))}
          <Button
            color="primary"
            style={buttonStyle}
            onClick={() => handleRemoveNotification(content && content[0].id)}
          >
            Entendi
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default FullNotification
