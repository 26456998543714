import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import DefaultPage from 'components/DefaultPage'
import GenerateReportForm from 'components/Report/GenerateReportForm'

import { Creators as ReportActions } from 'store/ducks/report'

const Report = () => {
  const dispatch = useDispatch()
  const [selectedEntity, setSelectedEntity] = useState('')
  const [canDownload, setCanDownload] = useState(false)

  const { entities, columns, reportData, loadingEntities, loadingColumns } =
    useSelector((state) => state.report)

  const handleChooseEntity = useCallback(
    (value) => {
      let currentEntity = value
      if (value !== '') {
        const entityId = value.split('-')[0]
        currentEntity = value.split('-')[1]
        setCanDownload(false)
        dispatch(ReportActions.getEntityColumnsRequest(entityId))
      }
      setSelectedEntity(currentEntity)
    },
    [dispatch]
  )

  const handleGenerateReport = useCallback(
    (value) => {
      delete value.selectColumns
      const keys = Object.keys(value)
      const values = Object.values(value)

      let body = []

      keys.map((key, index) =>
        body.push({
          column: key,
          value: values[index]
        })
      )

      const filtered = body.filter((item) => item.value !== '')
      const finalBody = {
        source: selectedEntity,
        params: filtered
      }

      dispatch(ReportActions.getReportDataRequest(finalBody))
    },
    [selectedEntity, dispatch]
  )

  const handleResetAll = useCallback(() => {
    setSelectedEntity('')
    setCanDownload(false)
  }, [])

  useEffect(() => {
    dispatch(ReportActions.getEntitiesRequest())
  }, [dispatch])

  useEffect(() => {
    dispatch(ReportActions.getEntityColumnsSuccess([]))
  }, [dispatch])

  useEffect(() => {
    dispatch(ReportActions.getReportDataSuccess([]))
  }, [dispatch])

  useEffect(() => {
    if (reportData && reportData.length > 0) {
      setCanDownload(true)
    } else {
      setCanDownload(false)
    }
  }, [reportData])

  return (
    <DefaultPage
      pageTitle="Relatórios"
      pageDescription="Gerar relatório"
      pageSubDescription="Selecione os dados para geração do relatório"
    >
      <GenerateReportForm
        entities={entities}
        columns={columns?.columns ? columns?.columns : []}
        loadingEntities={loadingEntities}
        loadingColumns={loadingColumns}
        canDownload={canDownload}
        reportData={reportData}
        handleChooseEntity={handleChooseEntity}
        handleGenerateReport={handleGenerateReport}
        selectedEntity={selectedEntity}
        handleResetAll={handleResetAll}
      />
    </DefaultPage>
  )
}

export default Report
