import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { isEmpty } from "lodash";

import DefaultPage from "components/DefaultPage";

import EditInvoiceForm from "components/Invoice/EditInvoiceForm";

import { Creators as InvoiceActions } from "store/ducks/invoice";

const Edit = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { invoice } = useSelector((state) => state.invoice);
 
  const handleUpdate = useCallback(
    (item) => {

      dispatch(InvoiceActions.updateInvoiceRequest({ ...item, id }));
    },
    [dispatch, id]
  );

  useEffect(() => {
    dispatch(InvoiceActions.getInvoiceByIdRequest(id));
  }, [dispatch, id]);

  return (
    <DefaultPage
      pageTitle="Faturas"
      pageDescription="Editar Fatura"
      pageSubDescription="Editar Fatura"
    >
      {!isEmpty(invoice) && (
        <EditInvoiceForm
          submitForm={handleUpdate}
          currentValue={invoice}
      
        />
      )}
    </DefaultPage>
  );
};

export default Edit;
