import Sales from 'pages/Sales'
import CreateSales from 'pages/Sales/Create'


import { BASE_URL } from 'shared/constants/config'

export default [
  { path: `${BASE_URL}/sales`, component: Sales },

  { path: `${BASE_URL}/sales/create`, component: CreateSales },
]
