import Positions from "pages/Positions";
import CreatePosition from "pages/Positions/Create";
import EditPosition from "pages/Positions/Edit";

import { BASE_URL } from "shared/constants/config";

export default [
  { path: `${BASE_URL}/posicoes`, component: Positions },
  { path: `${BASE_URL}/posicoes/criar`, component: CreatePosition },
  { path: `${BASE_URL}/posicoes/editar/:id`, component: EditPosition },
];
