import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactPlayer from 'react-player'
import { Row, Col } from 'reactstrap'

import ContentPage from 'components/ContentPage'

import { Creators as TutorialsActions } from 'store/ducks/tutorials'

const Show = () => {
  const dispatch = useDispatch()
  const { list } = useSelector((state) => state.tutorials)

  useEffect(() => {
    dispatch(TutorialsActions.getTutorialsRequest())
  }, [dispatch])

  const Content = ({ link, description }) => (
    <Row className="tutorials">
      <Col md="5">
        <div className="tutorial-video">
          <ReactPlayer url={link} controls={true} width="100%" />
        </div>
      </Col>
      <Col> 
        <div className="tutorial-description">
          <strong>Descrição</strong>
          <span>{description}</span>
        </div>
      </Col>
    </Row>
  )

  const items = useMemo(() => {
    const formatted = []
    const onlyActiveItems = list.filter((item) => item.isActive !== false)
    
    onlyActiveItems.map((item) =>
      formatted.push({
        header: item.name,
        content: <Content link={item.video} description={item.description} />
      })
    )
    return formatted
  }, [list])

  return <ContentPage hasAccordion pageTitle="Tutoriais - Material de apoio" items={items} />
}

export default Show
