import { push } from 'connected-react-router'
import { toast } from 'react-toastify'
import { put, call } from 'redux-saga/effects'
// import { push, goBack } from "connected-react-router";
import { api } from 'services/api'
import { token } from 'services/auth'

import { Creators as AuthActions } from 'store/ducks/auth'

const INITIAL_PLAN = '6f53e6fd-77c6-4f4a-a139-da469b12fa1f' //Vision
const INITIAL_PROFILE = 'f3f673f8-c1d3-4c7f-8a1a-f872f0a84039' //Empreendedor
const ACCREDITED_PLAN = '36416af3-7c0e-4f31-b7fe-b544bccc95b4' //Credenciado
const DEFAULT_IMG =
  'https://bigcash.blob.core.windows.net/imagens-perfil/440bb1e4-2585-4605-b100-5db050b3b836_G.jpeg'

export function* login(action) {
  try {
    const { data } = yield call(api.post, `/auth`, action.payload)
    yield put(AuthActions.loginSuccess(data.user))
    yield token(data.token)
    yield put(push('/dashboard'))
  } catch (err) {
    toast.error('Usuário e/ou senha inválidos.')
    yield put(AuthActions.loginFailure(err))
  }
}

export function* updateAuthUser(action) {
  const showToastr = action.payload.isImageEdit ? false : true
  try {
    const { data } = yield call(
      api.put,
      `/users/${action.payload.id}`,
      action.payload
    )
    yield put(AuthActions.updateAuthUserSuccess(data))
    showToastr && toast.success('Perfil editado com sucesso!')
  } catch (err) {
    toast.error('Erro ao editar perfil')
    yield put(AuthActions.updateAuthUserFailure(err))
  }
}

export function* updateAuthUserPassword(action) {
  try {
    const { data } = yield call(
      api.patch,
      `/users/reset-pass/${action.payload.id}`,
      { password: action.payload.password }
    )
    yield put(AuthActions.updateAuthUserPasswordSuccess(data))
  } catch (err) {
    yield put(AuthActions.updateAuthUserPasswordFailure(err))
  }
}

export function* uploadUserProfileImage(action) {
  try {
    const { data } = yield call(api.post, `/users/${action.payload.id}/image`, {
      image: action.payload.image
    })
    yield put(
      AuthActions.updateAuthUserRequest({
        icon: data.icon,
        id: action.payload.id,
        isImageEdit: true
      })
    )
    toast.success('Imagem de perfil atualizada com sucesso.')
    yield put(AuthActions.uploadAuthUserProfileImageSuccess())
  } catch (err) {
    toast.error('Erro ao atualizar imagem de perfil')
    yield put(AuthActions.uploadAuthUserProfileImageFailure(err))
  }
}

export function* registerUser(action) {
  try {
    const sponsorUser = yield call(
      api.get,
      `/user?email=${action.payload.sponsor}`
    )

    action.payload.sponsor = [{ id: sponsorUser.data.id }]
    action.payload.plan = [{ id: INITIAL_PLAN }]
    action.payload.icon = DEFAULT_IMG

    if (!!action.payload.cnpj && !!action.payload.companyAddress) {
      action.payload.profile = [{ id: ACCREDITED_PLAN }]
      action.payload.beAccredited = true
    } else {
      action.payload.profile = [{ id: INITIAL_PROFILE }]
    }
    const { data } = yield call(api.post, `/users`, action.payload)
    yield put(AuthActions.registerUserSuccess(data))
    yield put(
      AuthActions.loginRequest({
        email: action.payload.email,
        password: action.payload.password
      })
    )
    toast.success('Usuário criado com sucesso!')
  } catch (err) {
    toast.error('Erro ao criar usuário!')
    yield put(AuthActions.registerUserFailure(err))
  }
}
