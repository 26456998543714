import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { isEmpty } from "lodash";

import DefaultPage from "components/DefaultPage";

import EditStoreForm from "components/Stores/EditStoreForm";

import { Creators as StoresActions } from "store/ducks/stores";

const EditStore = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { store } = useSelector((state) => state.stores);

  const handleUpdate = useCallback(
    (item) => {
      
     

      dispatch(StoresActions.updateStoreRequest({ ...item, id, icon: item.file }));
    },
    [dispatch, id]
  );

  useEffect(() => {

    dispatch(StoresActions.getStoreRequest(id));
  }, [dispatch, id]);

  return (
    <DefaultPage
      pageTitle="Lojas"
      pageDescription="Editar loja"
      pageSubDescription="Editar a loja"
    >
      {!isEmpty(store) && (
        <EditStoreForm
          submitForm={handleUpdate}
          currentValue={store}

        />
      )}
    </DefaultPage>
  );
};

export default EditStore;
