/* eslint-disable no-restricted-globals */
import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Provider as ReduxProvider } from 'react-redux'
import { ErrorBoundary } from 'react-error-boundary'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { firebaseConfig } from 'config/firebase'
import { initializeApp } from 'firebase/app'

import Routes from './routes'
import store from './store'

import './index.scss'

import { Creators as AuthActions } from 'store/ducks/auth'

import Fallback from 'components/Fallback'

export default function App() {
  initializeApp(firebaseConfig)
  const redirectLogin = () => {
    history.pushState({}, '', '/login')
    document.location.reload(true)
  }
  window.onstorage = () => redirectLogin()

  const handleError = () => {
    if (process.env.NODE_ENV === 'production') {
      store.dispatch(AuthActions.logout())
      setTimeout(() => redirectLogin(), 5000)
    }
  }

  return (
    <ErrorBoundary onError={handleError} FallbackComponent={Fallback}>
      <ReduxProvider store={store}>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
        <ToastContainer />
      </ReduxProvider>
    </ErrorBoundary>
  )
}
