import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import DefaultPage from 'components/DefaultPage'
import Form from './Form'

import { Creators as NotificationActions } from 'store/ducks/notifications'

const CreateNotification = () => {
  const dispatch = useDispatch()
  const { auth } = useSelector((state) => state.auth)

  const handleCreateNotification = useCallback(
    (value) => {
      !value.emailReceiver && delete value.emailReceiver
      value.author = auth
      dispatch(NotificationActions.createNotificationRequest(value))
    },
    [auth, dispatch]
  )

  return (
    <DefaultPage
      pageTitle="Notificações"
      pageDescription="Criar notificação"
      pageSubDescription="Crie notificações que serão disparadas no sistema"
    >
      <Form submitForm={handleCreateNotification} />
    </DefaultPage>
  )
}

export default CreateNotification
