import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import DefaultPage from "components/DefaultPage";

import CreateActionForm from "components/Actions/CreateActionForm";

import { Creators as UserActions } from "store/ducks/actions";

const CreateAction = () => {
  const dispatch = useDispatch();

  const handleCreateAction = useCallback(
    (item) => {
      dispatch(UserActions.createActionRequest(item));
    },
    [dispatch]
  );

  return (
    <DefaultPage
      pageTitle="Ações"
      pageDescription="Criar ação"
      pageSubDescription="Criar uma nova ação no sistema"
    >
      <CreateActionForm submitForm={handleCreateAction} />
    </DefaultPage>
  );
};

export default CreateAction;
