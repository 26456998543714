export const formatToSelect = (list) => {
  let options = []
  for (let i in list) {
    options.push({
      value: list[i].id,
      label: list[i].name
    })
  }
  return options
}

export const formatToCurrency = (value) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(value)
}

export const formatDate = (value) => {
  const date = new Date(value)
  return date.toLocaleDateString('pt-BR')
}

export const formatDateHour = (value) => {
  const date = new Date(value)
  return date.toLocaleDateString('pt-BR', { hour: 'numeric' })
}

export const formatCurrencyToTransaction = (value) => {
  const newValue = formatToCurrency(value)
  const formatted = newValue.replace(/\$|R|\.|,/g, '').trim()
  return formatted
}

export const generateDueDate = (value) => {
  const today = new Date()
  today.setDate(today.getDate() + value)
  return today.toLocaleDateString('sv')
}

export const formatCheckoutInstallments = (value) => {
  let options = []

  for (let i = 1; i < 13; i++) {
    let currentInstallmentValue = value / i
    options.push({
      value: currentInstallmentValue,
      label: `${i}x de ${formatToCurrency(currentInstallmentValue)}`
    })
  }

  return options
}

export const currencyMask = (e) => {
  let value = e.target.value
  value = value.replace(/\D/g, '')
  value = value.replace(/(\d)(\d{2})$/, '$1,$2')
  value = value.replace(/(?=(\d{3})+(\D))\B/g, '.')
  e.target.value = value
  return e
}

export const formatValueFromCurrencyInputs = (value) => {
  value = value.replace(/[^0-9]+/g, '')
  value = value.replace(/(\d{2})$/, '.$1')
  return value
}

export const checkIsActive = (value, currentValue) => {
  if (value) {
    if (value === 'true') {
      return true
    } else {
      return false
    }
  }
  return currentValue
}
