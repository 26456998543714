import { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'

import { Creators as CheckoutActions } from 'store/ducks/checkout'

import { faBarcode, faCreditCard } from '@fortawesome/free-solid-svg-icons'
import { faPix } from '@fortawesome/free-brands-svg-icons'

import { v4 as uuidv4 } from 'uuid'

export default () => {
  const PAYMENT_METHODS = [
    {
      label: 'Cartão de crédito',
      value: 'CREDIT_CARD',
      icon: faCreditCard
    },
    {
      label: 'Boleto',
      value: 'BOLETO',
      icon: faBarcode
    },
    {
      label: 'PIX',
      value: 'PIX',
      icon: faPix
    }
  ]

  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  const { price, label } = location.state

  const { auth } = useSelector((state) => state.auth)
  const { transaction } = useSelector((state) => state.checkout)

  const [choosedMethod, setChoosedMethod] = useState(PAYMENT_METHODS[0].value)

  const handleSelectMethod = useCallback(
    (value) => {
      dispatch(CheckoutActions.generateTransactionSuccess(null))
      setChoosedMethod(value)
    },
    [dispatch]
  )

  const handleConfirmBuyModal = useCallback(() => {
    dispatch(CheckoutActions.generateTransactionSuccess(null))
    history.push('/dashboard')
  }, [dispatch, history])

  const handleCreateTransaction = useCallback(
    (value) => {
      const priceForMoney = value.amountUnformated
      const description = value.description
      const dueDate = value.dueDate ? value.dueDate : ''
      const installments = Number(value.installments)

      delete value.amountUnformated
      delete value.installments
      delete value.installmentsNumber
      delete value.description
      delete value.dueDate

      dispatch(
        CheckoutActions.generateTransactionRequest({
          transaction: value,
          money: {
            user: { id: auth.id },
            orderId: uuidv4(),
            installments,
            installmentsNumber: 1,
            amount: priceForMoney,
            description,
            type: 'plan',
            dueDate,
            paymentMethod:choosedMethod
          }
        })
      )
    },
    [auth.id, choosedMethod, dispatch]
  )

  return {
    showConfirmModal: transaction && transaction.status === 'paid',
    choosedMethod,
    PAYMENT_METHODS,
    user: {
      id: auth.id,
      name: auth.name,
      cpf: auth.cpf,
      cnpj: auth.cnpj,
      email: auth.email,
      phone: auth.phone
    },
    product: {
      price,
      label
    },

    handleSelectMethod,
    handleConfirmBuyModal,
    handleCreateTransaction
  }
}
