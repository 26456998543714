import { put, call } from 'redux-saga/effects'
import { api } from 'services/api'
import { push } from 'connected-react-router'
import { toast } from 'react-toastify'

import { Creators as UserActions } from 'store/ducks/users'

export function* getUsers(action) {
  const search = action.payload.search ? `&search=${action.payload.search}` : ''

  try {
    const { data } = yield call(
      api.get,
      `/users?page=${action.payload.page}&limit=${action.payload.limit}${search}`
    )
    yield put(UserActions.getUsersSuccess({ data: data[0], count: data[1] }))
  } catch (err) {
    yield put(UserActions.getUsersFailure(err.message))
  }
}
export function* getUsersAccredited(action) {
  const search = action.payload.search ? `&search=${action.payload.search}` : ''

  try {
    const { data } = yield call(
      api.get,
      `/users-accredited?page=${action.payload.page}&limit=${action.payload.limit}${search}`
    )
    yield put(
      UserActions.getUsersAccreditedSuccess({ data: data[0], count: data[1] })
    )
  } catch (err) {
    yield put(UserActions.getUsersAccreditedFailure(err.message))
  }
}
export function* getUserByName(action) {
  try {
    const { data } = yield call(
      api.get,
      `/users/name/${action.payload}?limit=10`
    )
    yield put(
      UserActions.getUsersByNameSuccess({ data: data, count: data.length })
    )
  } catch (err) {
    yield put(UserActions.getUsersByNameFailure(err.message))
  }
}
export function* getUsersDeadbeat(action) {
  try {
    const { data } = yield call(
      api.get,
      `/users-deadbeat?page=${action.payload.page}&limit=${action.payload.limit}`
    )
    yield put(UserActions.getUsersSuccess({ data: data[0], count: data[1] }))
  } catch (err) {
    yield put(UserActions.getUsersFailure(err.message))
  }
}

export function* getUserByID(action) {
  try {
    const { data } = yield call(api.get, `/users/${action.payload}`)
    yield put(UserActions.getUserSuccess(data))
  } catch (err) {
    yield put(UserActions.getUserFailure(err))
  }
}

export function* createUser(action) {
  try {
    const { data } = yield call(api.post, `/users`, action.payload)
    yield put(UserActions.createUserSuccess(data))
    yield put(push('/usuarios'))
    toast.success('Usuário criado com sucesso!')
  } catch (err) {
    toast.error('Erro ao criar usuário!')
    yield put(UserActions.createUserFailure(err))
  }
}
export function* createUserInvite(action) {
  try {
    const { data } = yield call(api.post, `/sendmail/invite`, action.payload)
    yield put(UserActions.createUserInviteSuccess(data))
    toast.success('Convite criado com sucesso!')
  } catch (err) {
    toast.error('Erro ao enviar convite')
    yield put(UserActions.createUserInviteFailure(err))
  }
}
export function* updateUser(action) {
  try {
    const { data } = yield call(
      api.put,
      `/users/${action.payload.id}`,
      action.payload
    )
    yield put(UserActions.updateUserSuccess(data))
    yield put(push('/usuarios'))
    toast.success('Usuário editado com sucesso!')
  } catch (err) {
    toast.error('Erro ao editar usuário')
    yield put(UserActions.updateUserFailure(err))
  }
}

export function* deleteUser(action) {
  try {
    const { data } = yield call(api.delete, `/users/${action.payload.id}`)
    yield put(UserActions.deleteUserSuccess(data))
    yield put(UserActions.getUsersRequest())
    toast.success('Usuário removido com sucesso')
  } catch (err) {
    toast.error('Erro ao remover usuário')
    yield put(UserActions.deleteUserFailure(err))
  }
}

export function* setProfileUser(action) {
  try {
    const { data } = yield call(
      api.patch,
      `/users/${action.payload.id}`,
      action.payload
    )
    yield put(UserActions.setProfileUserSuccess(data))
  } catch (err) {
    yield put(UserActions.setProfileUserFailure(err))
  }
}

export function* getUserByEmail(action) {
  try {
    const { data } = yield call(api.get, `/user?email=${action.payload}`)
    yield put(UserActions.getUserByEmailSuccess(data))
  } catch (err) {
    if (err.status === 404) {
      yield put(UserActions.getUserByEmailSuccess({}))
    }
    yield put(UserActions.getUserByEmailFailure(err.message))
  }
}
