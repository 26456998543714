import { put, call } from 'redux-saga/effects'
import { api } from 'services/api'
import { push } from 'connected-react-router'
import { toast } from 'react-toastify'

import { Creators as PositionActions } from 'store/ducks/positions'

export function* getPositions() {
  try {
    const { data } = yield call(api.get, '/positions')
    yield put(PositionActions.getPositionsSuccess(data))
  } catch (err) {
    yield put(PositionActions.getPositionsFailure(err.message))
  }
}

export function* getPositionByID(action) {
  try {
    const { data } = yield call(api.get, `/positions/${action.payload}`)
    yield put(PositionActions.getPositionSuccess(data))
  } catch (err) {
    yield put(PositionActions.getPositionFailure(err))
  }
}

export function* createPosition(action) {
  try {
    const { data } = yield call(api.post, `/positions`, action.payload)
    yield put(PositionActions.createPositionSuccess(data))
    yield put(push('/posicoes'))
    toast.success('Posição criada com sucesso!')
  } catch (err) {
    toast.error('Erro ao criar posição')
    yield put(PositionActions.createPositionFailure(err))
  }
}

export function* updatePosition(action) {
  try {
    const { data } = yield call(
      api.put,
      `/positions/${action.payload.id}`,
      action.payload
    )
    yield put(PositionActions.updatePositionSuccess(data))
    yield put(push('/posicoes'))
    toast.success('Posição editado com sucesso!')
  } catch (err) {
    toast.error('Erro ao editar posição')
    yield put(PositionActions.updatePositionFailure(err))
  }
}

export function* deletePosition(action) {
  try {
    const { data } = yield call(api.delete, `/positions/${action.payload.id}`)
    yield put(PositionActions.deletePositionSuccess(data))
    yield put(PositionActions.getPositionsRequest())
    toast.success('Posição removida com sucesso')
  } catch (err) {
    toast.error('Erro ao remover posição')
    yield put(PositionActions.deletePositionFailure(err))
  }
}
