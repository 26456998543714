import React, { useMemo } from 'react'
import { Row, Col } from 'reactstrap'
import { useForm } from 'react-hook-form'

import SelectEntities from './SelectEntities'
import SelectEntityColumns from './SelectEntityColumns'
import GenerateReportButton from './GenerateReportButton'

function GenerateReportForm({
  handleChooseEntity,
  handleChooseEntityColumns,
  handleGenerateReport,
  entities,
  reportData,
  loadingEntities,
  columns,
  loadingColumns,
  canDownload,
  selectedEntity,
  handleResetAll
}) {
  const { register, watch, handleSubmit, reset, getValues } = useForm({
    mode: 'onChange'
  })
  const sortedEntities = useMemo(() => {
    return entities.sort((a, b) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    )
  }, [entities])

  const selectedColumns = watch('selectColumns')

  return (
    <div className="reports">
      <form onSubmit={handleSubmit(handleGenerateReport)}>
        <Row>
          <Col md="3" style={{ marginRight: '2rem' }}>
            <Row>
              <SelectEntities
                handleChooseEntity={handleChooseEntity}
                entities={sortedEntities}
                loadingEntities={loadingEntities}
                reset={reset}
                getValues={getValues}
              />
            </Row>
            <Row className="generate_desk">
              <GenerateReportButton
                handleGenerateReport={handleGenerateReport}
                canDownload={canDownload}
                selectedColumns={selectedColumns}
                reportData={reportData}
                handleResetAll={handleResetAll}
                reset={reset}
              />
            </Row>
          </Col>
          <Col md="6" className="entity_columns">
            {columns.length > 0 && selectedEntity !== '' && (
              <SelectEntityColumns
                columns={columns}
                loadingColumns={loadingColumns}
                register={register}
                selectedColumns={selectedColumns}
              />
            )}
          </Col>
        </Row>
        <Row className="generate_mobile">
          <GenerateReportButton
            handleGenerateReport={handleGenerateReport}
            canDownload={canDownload}
            selectedColumns={selectedColumns}
            reportData={reportData}
          />
        </Row>
      </form>
    </div>
  )
}

export default GenerateReportForm
