import { put, call } from 'redux-saga/effects'
import { api } from 'services/api'

import { Creators as Network } from 'store/ducks/network'

export function* getNetworks() {
  try {
    const { data } = yield call(api.get, '/networks')
    yield put(Network.getNetworksSuccess(data))
  } catch (err) {
    yield put(Network.getNetworksFailure(err.message))
  }
}

export function* getNetworkByID(action) {
  try {
    const { data } = yield call(api.get, `/networks/${action.payload}`)
    yield put(Network.getNetworkSuccess(data))
  } catch (err) {
    yield put(Network.getNetworkFailure(err))
  }
}

export function* getNetworkByOwner(action) {
  try {
    const { data } = yield call(api.get, `/networks/owner/${action.payload}`)

    yield put(Network.getNetworkByOwnerSuccess(data))
  } catch (err) {
    yield put(Network.getNetworkByOwnerFailure(err))
  }
}

export function* getNetworkProductiveSummary(action) {
  try {
    const { data } = yield call(
      api.get,
      `/networks/productive/${action.payload}`
    )
    yield put(Network.getNetworkProductiveSummarySuccess(data))
  } catch (err) {
    yield put(Network.getNetworkProductiveSummaryFailure(err))
  }
}
