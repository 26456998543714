import React from "react";
import { Card } from "reactstrap";

const ValueCard = ({ isMain, icon, title, value }) => {
  const colorToMain = "rgb(34, 95, 107)";

  const classToCard = isMain ? "main" : "card";
  return (
    <Card className={classToCard}>
      <div className="icon">{icon}</div>
      <div className="name">
        <span>{title}</span>
      </div>
      <div className="value">
        <span>{value}</span>
      </div>
    </Card>
  );
};

export default ValueCard;
