export const Types = {
  GET_ACTIONS_REQUEST: "actions/GET_ACTIONS_REQUEST",
  GET_ACTIONS_SUCCESS: "actions/GET_ACTIONS_SUCCESS",
  GET_ACTIONS_FAILURE: "actions/GET_ACTIONS_FAILURE",

  GET_ACTION_REQUEST: "actions/GET_ACTION_REQUEST",
  GET_ACTION_SUCCESS: "actions/GET_ACTION_SUCCESS",
  GET_ACTION_FAILURE: "actions/GET_ACTION_FAILURE",

  CREATE_ACTION_REQUEST: "actions/CREATE_ACTION_REQUEST",
  CREATE_ACTION_SUCCESS: "actions/CREATE_ACTION_SUCCESS",
  CREATE_ACTION_FAILURE: "actions/CREATE_ACTION_FAILURE",

  UPDATE_ACTION_REQUEST: "actions/UPDATE_ACTION_REQUEST",
  UPDATE_ACTION_SUCCESS: "actions/UPDATE_ACTION_SUCCESS",
  UPDATE_ACTION_FAILURE: "actions/UPDATE_ACTION_FAILURE",

  DELETE_ACTION_REQUEST: "actions/DELETE_ACTION_REQUEST",
  DELETE_ACTION_SUCCESS: "actions/DELETE_ACTION_SUCCESS",
  DELETE_ACTION_FAILURE: "actions/DELETE_ACTION_FAILURE",
};

const INITIAL_STATE = {
  list: [],
  action: {},
  loading: false,
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_ACTIONS_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.GET_ACTIONS_SUCCESS:
      return { ...state, loading: false, list: action.payload, error: null };

    case Types.GET_ACTIONS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.GET_ACTION_REQUEST:
      return { ...state, loading: true, action: {}, error: null };

    case Types.GET_ACTION_SUCCESS:
      return { ...state, loading: false, action: action.payload, error: null };

    case Types.GET_ACTION_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.CREATE_ACTION_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.CREATE_ACTION_SUCCESS:
      return { ...state, loading: false, error: null };

    case Types.CREATE_ACTION_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.UPDATE_ACTION_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.UPDATE_ACTION_SUCCESS:
      return { ...state, loading: false, action: {}, error: null };

    case Types.UPDATE_ACTION_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.DELETE_ACTION_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.DELETE_ACTION_SUCCESS:
      return { ...state, loading: false, error: null };

    case Types.DELETE_ACTION_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export const Creators = {
  getActionsRequest: (payload) => ({
    type: Types.GET_ACTIONS_REQUEST,
    payload,
  }),

  getActionsSuccess: (payload) => ({
    type: Types.GET_ACTIONS_SUCCESS,
    payload,
  }),

  getActionsFailure: (payload) => ({
    type: Types.GET_ACTIONS_FAILURE,
    payload,
  }),

  getActionRequest: (payload) => ({
    type: Types.GET_ACTION_REQUEST,
    payload,
  }),

  getActionSuccess: (payload) => ({
    type: Types.GET_ACTION_SUCCESS,
    payload,
  }),

  getActionFailure: (payload) => ({
    type: Types.GET_ACTION_FAILURE,
    payload,
  }),

  createActionRequest: (payload) => ({
    type: Types.CREATE_ACTION_REQUEST,
    payload,
  }),

  createActionSuccess: (payload) => ({
    type: Types.CREATE_ACTION_SUCCESS,
    payload,
  }),

  createActionFailure: (payload) => ({
    type: Types.CREATE_ACTION_FAILURE,
    payload,
  }),

  updateActionRequest: (payload) => ({
    type: Types.UPDATE_ACTION_REQUEST,
    payload,
  }),

  updateActionSuccess: (payload) => ({
    type: Types.UPDATE_ACTION_SUCCESS,
    payload,
  }),

  updateActionFailure: (payload) => ({
    type: Types.UPDATE_ACTION_FAILURE,
    payload,
  }),

  deleteActionRequest: (payload) => ({
    type: Types.DELETE_ACTION_REQUEST,
    payload,
  }),

  deleteActionSuccess: (payload) => ({
    type: Types.DELETE_ACTION_SUCCESS,
    payload,
  }),

  deleteActionFailure: (payload) => ({
    type: Types.DELETE_ACTION_FAILURE,
    payload,
  }),
};
