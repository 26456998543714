import React from "react";
import { Form, Label, Input, Col, Button } from "reactstrap";
import { useForm } from "react-hook-form";

const EditPaymentMethodForm = ({ submitForm, currentValue }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: currentValue.name,
      description: currentValue.description,
    },
  });

  const { ref: nameRef, ...name } = register("name", { required: true });

  const { ref: descriptionRef, ...description } = register("description", {
    required: true,
  });

  return (
    <Form
      className="needs-validation"
      noValidate=""
      onSubmit={handleSubmit(submitForm)}
    >
      <div className="form-row">
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom01">Nome</Label>
          <Input
            className="form-control"
            name="name"
            type="text"
            placeholder="Nome"
            innerRef={nameRef}
            {...name}
            defaultValue={currentValue.name}
          />
          <span>{errors.name && "Nome é obrigatório"}</span>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom02">Descrição</Label>
          <Input
            className="form-control"
            name="description"
            type="type"
            placeholder="Descrição"
            innerRef={descriptionRef}
            {...description}
            defaultValue={currentValue.description}
          />
          <span>{errors.description && "Descrição é obrigatório"}</span>
        </Col>
      </div>

      <Button color="primary">Enviar</Button>
    </Form>
  );
};

export default EditPaymentMethodForm;
