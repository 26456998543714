/* eslint-disable no-case-declarations */
import ConfigDB from 'data/customizer/config'

export const Types = {
  ADD_COSTOMIZER: 'customizer/ADD_COSTOMIZER',
  ADD_LAYOUT: 'customizer/ADD_LAYOUT',
  ADD_SIDEBAR_TYPES: 'customizer/ADD_SIDEBAR_TYPES',
  ADD_SIDEBAR_SETTINGS: 'ADD_SIDEBAR_SETTINGS',
  ADD_COLOR: 'customizer/ADD_COLOR',
  ADD_MIX_BACKGROUND_LAYOUT: 'customizer/ADD_MIX_BACKGROUND_LAYOUT',
  ROUTER_ANIMATION: 'customizer/ROUTER_ANIMATION'
}

const INITIAL_STATE = {
  customizer: ConfigDB.data,
  configData: {},
  layout: 'ltr',
  sidebar_types: {},
  settings: '',
  color: {},
  mix_background_layout: '',
  animation: ''
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.ADD_COSTOMIZER:
      return { ...state, loading: false, customizer: ConfigDB.data }

    case Types.ADD_LAYOUT:
      state.customizer.settings.layout_type = action.payload
      const layoutUpdate = state.customizer.settings.layout_type
      return { ...state, loading: true, layout: layoutUpdate }

    case Types.ADD_SIDEBAR_TYPES:
      state.customizer.settings.sidebar = action.payload
      const sidebarTypeUpdate = state.customizer.settings.sidebar

      return { ...state, loading: true, sidebar_types: sidebarTypeUpdate }

    case Types.ADD_SIDEBAR_SETTINGS:
      state.customizer.settings.sidebar_setting = action.payload
      const settingsUpdate = state.customizer.settings.sidebar_setting
      return { ...state, loading: true, settings: settingsUpdate }

    case Types.ADD_COLOR:
      const colors = action.payload
      state.customizer.color.primary_color = colors.primary_color
      state.customizer.color.secondary_color = colors.secondary_color
      return { ...state, color: colors, loading: true }

    case Types.ADD_MIX_BACKGROUND_LAYOUT:
      const mix_background_layout = action.payload
      state.customizer.color.mix_background_layout = mix_background_layout
      return {
        ...state,
        mix_background_layout: mix_background_layout,
        loading: true
      }

    case Types.ROUTER_ANIMATION:
      const anim = action.payload
      state.customizer.router_animation = anim
      return { ...state, animation: anim, loading: true }

    default:
      return { ...state }
  }
}

export const Creators = {
  addCustomizer: (payload) => ({
    type: Types.ADD_COSTOMIZER,
    payload
  }),

  addLayout: (payload) => ({
    type: Types.ADD_LAYOUT,
    payload
  }),

  addSidebarTypes: (payload) => ({
    type: Types.ADD_SIDEBAR_TYPES,
    payload
  }),

  addSidebarSettings: (payload) => ({
    type: Types.ADD_SIDEBAR_SETTINGS,
    payload
  }),

  addColor: (payload) => ({
    type: Types.ADD_COLOR,
    payload
  }),

  addMixBackgroundLayout: (payload) => ({
    type: Types.ADD_MIX_BACKGROUND_LAYOUT,
    payload
  }),

  routerAnimation: (payload) => ({
    type: Types.ROUTER_ANIMATION,
    payload
  })
}
