export const Types = {
  GET_INVOICE_REQUEST: "invoice/GET_INVOICE_REQUEST",
  GET_INVOICE_SUCCESS: "invoice/GET_INVOICE_SUCCESS",
  GET_INVOICE_FAILURE: "invoice/GET_INVOICE_FAILURE",

  GET_INVOICE_BY_ID_REQUEST: "invoice/GET_INVOICE_BY_ID_REQUEST",
  GET_INVOICE_BY_ID_SUCCESS: "invoice/GET_INVOICE_BY_ID_SUCCESS",
  GET_INVOICE_BY_ID_FAILURE: "invoice/GET_INVOICE_BY_ID_FAILURE",


  GET_INVOICE_BY_USER_REQUEST: "invoice/GET_INVOICE_BY_USER_REQUEST",
  GET_INVOICE_BY_USER_SUCCESS: "invoice/GET_INVOICE_BY_USER__SUCCESS",
  GET_INVOICE_BY_USER_FAILURE: "invoice/GET_INVOICE_BY_USER__FAILURE",


  GET_INVOICE_ESTIMETED_BY_USER_REQUEST: "invoice/GET_INVOICE_ESTIMETED_BY_USER_REQUEST",
  GET_INVOICE_ESTIMETED_BY_USER_SUCCESS: "invoice/GET_INVOICE_ESTIMETED_BY_USER_SUCCESS",
  GET_INVOICE_ESTIMETED_BY_USER_FAILURE: "invoice/GET_INVOICE_ESTIMETED_BY_USER_FAILURE",


  CREATE_INVOICE_REQUEST: "invoice/CREATE_INVOICE_REQUEST",
  CREATE_INVOICE_SUCCESS: "invoice/CREATE_INVOICE_SUCCESS",
  CREATE_INVOICE_FAILURE: "invoice/CREATE_INVOICE_FAILURE",

  UPDATE_INVOICE_REQUEST: "invoice/UPDATE_INVOICE_REQUEST",
  UPDATE_INVOICE_SUCCESS: "invoice/UPDATE_INVOICE_SUCCESS",
  UPDATE_INVOICE_FAILURE: "invoice/UPDATE_INVOICE_FAILURE",

  DELETE_INVOICE_REQUEST: "invoice/DELETE_INVOICE_REQUEST",
  DELETE_INVOICE_SUCCESS: "invoice/DELETE_INVOICE_SUCCESS",
  DELETE_INVOICE_FAILURE: "invoice/DELETE_INVOICE_FAILURE",
};

const INITIAL_STATE = {
  list: [],
  invoice: {},
  invoiceByUser: {},
  invoiceEstimetedByUser: {},
  loading: false,
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_INVOICE_REQUEST:
      return { ...state, loading: true, list: {}, error: null };

    case Types.GET_INVOICE_SUCCESS:
      return { ...state, loading: false, list: action.payload, error: null };

    case Types.GET_INVOICE_FAILURE:
      return { ...state, loading: false, list: {}, error: action.payload };

    case Types.GET_INVOICE_BY_ID_REQUEST:
      return { ...state, loading: true, invoice: {}, error: null };

    case Types.GET_INVOICE_BY_ID_SUCCESS:
      return { ...state, loading: false, invoice: action.payload, error: null };

    case Types.GET_INVOICE_BY_ID_FAILURE:
      return { ...state, loading: false, invoice: {}, error: action.payload };

    case Types.GET_INVOICE_BY_USER_REQUEST:
      return { ...state, loading: true, invoiceByUser: {}, error: null };

    case Types.GET_INVOICE_BY_USER_SUCCESS:
      return { ...state, loading: false, invoiceByUser: action.payload, error: null };

    case Types.GET_INVOICE_ESTIMETED_BY_USER_FAILURE:
      return { ...state, loading: false, invoiceEstimetedByUser: {}, error: action.payload };

    case Types.GET_INVOICE_ESTIMETED_BY_USER_REQUEST:
      return { ...state, loading: true, invoiceEstimetedByUser: {}, error: null };

    case Types.GET_INVOICE_ESTIMETED_BY_USER_SUCCESS:
      return { ...state, loading: false, invoiceEstimetedByUser: action.payload, error: null };

    case Types.GET_INVOICE_BY_USER_FAILURE:
      return { ...state, loading: false, invoiceByUser: {}, error: action.payload };

    case Types.CREATE_INVOICE_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.CREATE_INVOICE_SUCCESS:
      return { ...state, loading: false, error: null };

    case Types.CREATE_INVOICE_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.UPDATE_INVOICE_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.UPDATE_INVOICE_SUCCESS:
      return { ...state, loading: false, cashback: {}, error: null };

    case Types.UPDATE_INVOICE_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.DELETE_INVOICE_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.DELETE_INVOICE_SUCCESS:
      return { ...state, loading: false, error: null };

    case Types.DELETE_INVOICE_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export const Creators = {
  getInvoiceRequest: (payload) => ({
    type: Types.GET_INVOICE_REQUEST,
    payload,
  }),

  getInvoiceSuccess: (payload) => ({
    type: Types.GET_INVOICE_SUCCESS,
    payload,
  }),

  getInvoiceFailure: (payload) => ({
    type: Types.GET_INVOICE_FAILURE,
    payload,
  }),
  getInvoiceByIdRequest: (payload) => ({
    type: Types.GET_INVOICE_BY_ID_REQUEST,
    payload,
  }),

  getInvoiceByIdSuccess: (payload) => ({
    type: Types.GET_INVOICE_BY_ID_SUCCESS,
    payload,
  }),

  getInvoiceByIdFailure: (payload) => ({
    type: Types.GET_INVOICE_BY_ID_FAILURE,
    payload,
  }),
  getInvoiceByUserRequest: (payload) => ({
    type: Types.GET_INVOICE_BY_USER_REQUEST,
    payload,
  }),

  getInvoiceByUserSuccess: (payload) => ({
    type: Types.GET_INVOICE_BY_USER_SUCCESS,
    payload,
  }),

  getInvoiceByUserFailure: (payload) => ({
    type: Types.GET_INVOICE_BY_USER_FAILURE,
    payload,
  }),
  getInvoiceEstimetedByUserRequest: (payload) => ({
    type: Types.GET_INVOICE_ESTIMETED_BY_USER_REQUEST,
    payload,
  }),

  getInvoiceEstimetedByUserSuccess: (payload) => ({
    type: Types.GET_INVOICE_ESTIMETED_BY_USER_SUCCESS,
    payload,
  }),

  getInvoiceEstimetedByUserFailure: (payload) => ({
    type: Types.GET_INVOICE_ESTIMETED_BY_USER_FAILURE,
    payload,
  }),
  createInvoiceRequest: (payload) => ({
    type: Types.CREATE_INVOICE_REQUEST,
    payload,
  }),

  createInvoiceSuccess: (payload) => ({
    type: Types.CREATE_INVOICE_SUCCESS,
    payload,
  }),

  createInvoiceFailure: (payload) => ({
    type: Types.CREATE_INVOICE_FAILURE,
    payload,
  }),

  updateInvoiceRequest: (payload) => ({
    type: Types.UPDATE_INVOICE_REQUEST,
    payload,
  }),

  updateInvoiceSuccess: (payload) => ({
    type: Types.UPDATE_INVOICE_SUCCESS,
    payload,
  }),

  updateInvoiceFailure: (payload) => ({
    type: Types.UPDATE_INVOICE_FAILURE,
    payload,
  }),

  deleteInvoiceRequest: (payload) => ({
    type: Types.DELETE_INVOICE_REQUEST,
    payload,
  }),

  deleteInvoiceSuccess: (payload) => ({
    type: Types.DELETE_INVOICE_SUCCESS,
    payload,
  }),

  deleteInvoiceFailure: (payload) => ({
    type: Types.DELETE_INVOICE_FAILURE,
    payload,
  }),
};
