import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { isEmpty } from "lodash";

import DefaultPage from "components/DefaultPage";

import EditCashbackForm from "components/Cashbacks/EditCashbackForm";

import { Creators as CashbackActions } from "store/ducks/cashbacks";

const EditCashback = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { cashback } = useSelector((state) => state.cashbacks);

  const handleUpdateCashback = useCallback(
    (item) => {
      dispatch(CashbackActions.updateCashbackRequest({ ...item, id }));
    },
    [dispatch, id]
  );

  useEffect(() => {
    dispatch(CashbackActions.getCashbackRequest(id));
  }, [dispatch, id]);

  return (
    <DefaultPage
      pageTitle="Cashbacks"
      pageDescription="Editar cashback"
      pageSubDescription="Editar o cashback"
    >
      {!isEmpty(cashback) && (
        <EditCashbackForm
          submitForm={handleUpdateCashback}
          currentValue={cashback}
        />
      )}
    </DefaultPage>
  );
};

export default EditCashback;
