export default {
  LOGIN: "Login",
  INSERT_EMAIL_PASS: "Entre com seu e-mail e senha",
  EMAIL: "E-mail",
  EMAIL_PLACEHOLDER: "Seu email aqui",
  PASSWORD: "Senha",
  PASSWORD_PLACEHOLDER: "Sua senha aqui",
  SHOW: "Mostrar",
  REMEMBER_PASS: "Lembrar senha",
  FORGOT_PASS: "Esqueceu a senha?",
  LOGIN_BTN: "Login",
  OR_SIGNIN: "Ou entre com",
};
