import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { saveAs } from 'file-saver'

import ContentPage from 'components/ContentPage'
import DataTables from 'components/Datatable'
import { Download } from 'react-feather'

import { Creators as MaterialsActions } from 'store/ducks/materials'

import { materialsDataTableColumns } from 'shared/constants/datatable'

const Show = () => {
  const dispatch = useDispatch()
  const { list, loading } = useSelector((state) => state.materials)

  useEffect(() => {
    dispatch(MaterialsActions.getMaterialsRequest())
  }, [dispatch])

  const DownloadActions = (item) => (
    <Download
      style={{ cursor: 'pointer' }}
      onClick={() => saveAs(item.fileUrl)}
    />
  )

  const items = useMemo(() => {
    const onlyActiveItems = list.filter((item) => item.isActive !== false)
    return onlyActiveItems
  }, [list])

  const filterColumns = useMemo(() => {
    const withoutStatus = materialsDataTableColumns.filter(
      (item) => item.name !== 'Status'
    )
    return withoutStatus
  }, [])

  return (
    <ContentPage pageTitle="Materiais de apoio" hasAccordion={false}>
      <DataTables
        list={items}
        customDataTableColumns={filterColumns}
        customActions={DownloadActions}
        defaultActions={false}
        loading={loading}
      />
    </ContentPage>
  )
}

export default Show
