import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'

import DefaultPage from 'components/DefaultPage'

import Form from './Form'

import { Creators as MaterialsActions } from 'store/ducks/materials'

const CreateMaterial = () => {
  const [isActive, setIsActive] = useState(false)
  const dispatch = useDispatch()

  const handleCreateMaterial = useCallback(
    (item) => {
      const body = {
        ...item,
        isActive: isActive === 'true' ? true : false,
        file: item.file
      }
      dispatch(MaterialsActions.createMaterialRequest(body))
    },
    [dispatch, isActive]
  )

  const handleActive = useCallback((value) => setIsActive(value), [])

  return (
    <DefaultPage
      pageTitle="Materiais de apoio"
      pageDescription="Criar material de apoio"
      pageSubDescription="Criar material de apoio sobre o sistema"
    >
      <Form submitForm={handleCreateMaterial} handleActive={handleActive} />
    </DefaultPage>
  )
}

export default CreateMaterial
