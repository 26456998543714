import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import DataTables from "components/Datatable";

import { Creators as MoneyActions } from "store/ducks/money";
import { transactionsDtDataTableColumns } from "shared/constants/datatable";

import DefaultListPage from "components/DefaultPage";

import LoaderSpinner from "components/LoaderSpinner";

const Bonusindicacao = () => {
  const INTIAL_PAGE = 0;
  const INITIAL_LIMIT = 10;

  const [currentPage, setCurrentPage] = useState(INTIAL_PAGE);
  const dispatch = useDispatch();

  const {
    money: { moneyByUserBonusAccession, loading },
    auth: { auth },
  } = useSelector((state) => state);

  const handleChangeRowsPerPage = useCallback(
    (currentPage, size) => {
      dispatch(
        MoneyActions.getMoneyByUserBonusAccessionRequest(
          `${auth.id}?page=${currentPage}&limit=${size}`
        )
      );
    },
    [auth.id, dispatch]
  );

  const handleChangePage = useCallback(
    (page) => {
      setCurrentPage(page);
      dispatch(
        MoneyActions.getMoneyByUserBonusAccessionRequest(
          `${auth.id}?page=${page}&limit=${INITIAL_LIMIT}`
        )
      );
    },
    [auth.id, dispatch]
  );

  useEffect(() => {
    dispatch(
      MoneyActions.getMoneyByUserBonusAccessionRequest(
        `${auth.id}?page=${INTIAL_PAGE}&limit=${INITIAL_LIMIT}`
      )
    );
  }, [dispatch, auth.id]);

  return (
    <DefaultListPage
      pageTitle="Bônus de Habilitação e Residual de Habilitação"
      pageDescription="Bônus de Habilitação e Residual de Habilitação"
      pageSubDescription="Visualize as suas últimas transações de Bônus de Habilitação e Residual de Habilitação realizadas"
    >
      {loading ? (
        <LoaderSpinner />
      ) : (
        <DataTables
          list={moneyByUserBonusAccession?.list}
          customDataTableColumns={transactionsDtDataTableColumns}
          noActions={true}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
          hasPagination
          currentPage={currentPage}
          count={moneyByUserBonusAccession?.count}
        />
      )}
    </DefaultListPage>
  );
};

export default Bonusindicacao;
