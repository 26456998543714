
import { put, call } from 'redux-saga/effects'
import { api } from 'services/api'
import { push } from 'connected-react-router'
import { toast } from 'react-toastify'

import { Creators as SalesActions } from 'store/ducks/sales'

export function* getSales() {
  try {
    const { data } = yield call(api.get, '/sales')
    yield put(SalesActions.getSalesSuccess(data))
  } catch (err) {
    yield put(SalesActions.getSalesFailure(err.message))
  }
}

export function* getSalesByID(action) {
  try {
    const { data } = yield call(api.get, `/sales/${action.payload}`)
    yield put(SalesActions.getSalesSuccess(data))
  } catch (err) {
    yield put(SalesActions.getSalesFailure(err))
  }
}

export function* createSales(action) {
  try {
    const { data } = yield call(api.post, `/sales`, action.payload)
    yield put(SalesActions.createSalesSuccess(data))
    yield put(push('/sales'))
    toast.success('Sales criado com sucesso!')
  } catch (err) {
    toast.error('Erro ao criar venda')
    yield put(SalesActions.createSalesFailure(err))
  }
}

export function* updateSales(action) {
  try {
    const { data } = yield call(
      api.put,
      `/sales/${action.payload.id}`,
      action.payload
    )
    yield put(SalesActions.updateSalesSuccess(data))
    yield put(push('/sales'))
    toast.success('Sales editado com sucesso!')
  } catch (err) {
    toast.error('Erro ao editar venda')
    yield put(SalesActions.updateSalesFailure(err))
  }
}

export function* deleteSales(action) {
  try {
    const { data } = yield call(api.delete, `/sales/${action.payload.id}`)
    yield put(SalesActions.deleteSalesSuccess(data))
    yield put(SalesActions.getSalesRequest())
    toast.success('Sales removido com sucesso')
  } catch (err) {
    toast.error('Erro ao remover venda')
    yield put(SalesActions.deleteSalesFailure(err))
  }
}
