import React, { useEffect, useState } from "react";
import { Form, Label, Input, Col, Button } from "reactstrap";
import { useForm } from "react-hook-form";

const EditProfileForm = ({ submitForm, currentValue, listActions }) => {
  const [currentActions, setCurrentActions] = useState([]);
  const [canShowActions, setCanShowActions] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: currentValue.name,
      description: currentValue.description,
    },
  });

  const { ref: nameRef, ...name } = register("name", { required: true });

  const { ref: descriptionRef, ...description } = register("description", {
    required: true,
  });

  const { ref: actionsRef, ...actions } = register("actions");

  useEffect(() => {
    if (!!currentValue && currentValue.actions) {
      let actions = [];
      currentValue.actions.map((item) => actions.push(item.id));
      setCurrentActions(actions);
      setCanShowActions(true);
    }
  }, [currentValue]);

  return (
    <Form
      className="needs-validation"
      noValidate=""
      onSubmit={handleSubmit(submitForm)}
    >
      <div className="form-row">
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom01">Nome</Label>
          <Input
            className="form-control"
            name="name"
            type="text"
            placeholder="Nome"
            innerRef={nameRef}
            {...name}
            defaultValue={currentValue.name}
          />
          <span>{errors.name && "Nome é obrigatório"}</span>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom02">Descrição</Label>
          <Input
            className="form-control"
            name="description"
            type="text"
            placeholder="Descrição"
            innerRef={descriptionRef}
            {...description}
            defaultValue={currentValue.description}
          />
          <span>{errors.description && "Descrição é obrigatório"}</span>
        </Col>
      </div>

      <div className="form-row row-gap">
        <Col sm="12">
          <h5>Ações disponíveis</h5>
        </Col>
        <Col>
          {canShowActions &&
            listActions?.map((item) => (
              <Label className="d-block" for={item.id} key={item.id}>
                <Input
                  className="checkbox_animated"
                  id={item.id}
                  type="checkbox"
                  innerRef={actionsRef}
                  value={item.id}
                  {...actions}
                  defaultChecked={currentActions.includes(item.id)}
                />
                {item.name}
              </Label>
            ))}
        </Col>
      </div>
      <div className="form-row row-gap">
        <Button color="primary">Enviar</Button>
      </div>
    </Form>
  );
};

export default EditProfileForm;
