import Financial from 'pages/Financial'

import { BASE_URL } from 'shared/constants/config'
import Minhascompras from 'pages/Transactions/Minhascompras'
import Bonusindicacao from 'pages/Transactions/Bonusindicacao'
import Empresas from 'pages/Transactions/Empresas'

import Transactions from 'pages/Transactions/'

export default [
  { path: `${BASE_URL}/financeiro`, component: Financial },
  // { path: `${BASE_URL}/acoes/criar`, component: CreateAction },
  // { path: `${BASE_URL}/acoes/editar/:id`, component: EditAction },

  { path: `${BASE_URL}/transacoes`, component:Transactions},

  { path: `${BASE_URL}/transacoes/minhascompras`, component:Minhascompras},
  { path: `${BASE_URL}/transacoes/bonusindicacao`, component:Bonusindicacao},
  { path: `${BASE_URL}/transacoes/empresas`, component:Empresas}


]
