export const Types = {
  CREATE_TRANSACTION_REQUEST: 'checkout/CREATE_TRANSACTION_REQUEST',
  CREATE_TRANSACTION_SUCCESS: 'checkout/CREATE_TRANSACTION_SUCCESS',
  CREATE_TRANSACTION_FAILURE: 'checkout/CREATE_TRANSACTION_FAILURE'
}

const INITIAL_STATE = {
  pix: null,
  loading: false,
  error: null,
  transaction: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.CREATE_TRANSACTION_REQUEST:
      return { ...state, loading: true, error: null }

    case Types.CREATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        transaction: action.payload
      }

    case Types.CREATE_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        transaction: null
      }

    default:
      return state
  }
}

export const Creators = {
  generateTransactionRequest: (payload) => ({
    type: Types.CREATE_TRANSACTION_REQUEST,
    payload
  }),

  generateTransactionSuccess: (payload) => ({
    type: Types.CREATE_TRANSACTION_SUCCESS,
    payload
  }),

  generateTransactionFailure: (payload) => ({
    type: Types.CREATE_TRANSACTION_FAILURE,
    payload
  })
}
