import React from "react";
import { Form, Label, Input, Col, Button } from "reactstrap";
import { useForm } from "react-hook-form";

const EditLevelForm = ({
  submitForm,
  currentValue,
  cashBackList,
  bonificationList,
  levelsList,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: currentValue.name,
      description: currentValue.description,
      cashback: currentValue.cashback,
      bonification: currentValue.bonification,
      maxLevelQuantity: currentValue.maxLevelQuantity,
      levelPosition: currentValue.levelNumber
    },
  });

  const { ref: nameRef, ...name } = register("name", { required: true });

  const { ref: descriptionRef, ...description } = register("description", {
    required: true,
  });

  const { ref: cashbackRef, ...cashback } = register("cashback");

  const { ref: bonificationRef, ...bonification } = register("bonification");

  const { ref: maxLevelQuantityRef, ...maxLevelQuantity } = register(
    "maxLevelQuantity",
    {
      required: true,
    }
  );
  const { ref: levelNumberRef, ...levelNumber } = register(
    "levelNumber",
    {
      required: true,
    }
  );
  return (
    <Form
      className="needs-validation"
      noValidate=""
      onSubmit={handleSubmit(submitForm)}
    >
      <div className="form-row">
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom01">Nome</Label>
          <Input
            className="form-control"
            name="name"
            type="text"
            placeholder="Nome"
            innerRef={nameRef}
            {...name}
            defaultValue={currentValue.name}
          />
          <span>{errors.name && "Nome é obrigatório"}</span>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom02">Descrição</Label>
          <Input
            className="form-control"
            name="description"
            type="text"
            placeholder="Descrição"
            innerRef={descriptionRef}
            {...description}
            defaultValue={currentValue.description}
          />
          <span>{errors.description && "Descrição é obrigatório"}</span>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom02">Quantidade máxima nível</Label>
          <Input
            className="form-control"
            name="description"
            type="number"
            placeholder="Quantidade máxima nível"
            innerRef={maxLevelQuantityRef}
            defaultValue={currentValue.maxLevelQuantity}
            {...maxLevelQuantity}
          />

          <span>
            {errors.maxLevelQuantity && "Quantidade máxima nível é obrigatório"}
          </span>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom02">Posição Nível</Label>
          <Input
            className="form-control"
            name="description"
            type="number"
            placeholder="Posição Nível"
            innerRef={levelNumberRef}
            defaultValue={currentValue.levelNumber}

            {...levelNumber}
          />

          <span>
            {errors.levelPosition && "Posição Nível é obrigatório"}
          </span>
        </Col>
      </div>

      <div className="form-row row-gap">
        <Col sm="12">
          <h5>Cashbacks</h5>
        </Col>
        <Col>
          {cashBackList?.map((item) => {
            const cashbackOwner = levelsList?.find(
              (levelItem) => levelItem.cashback?.id === item.id
            );

            return (
              <Label className="d-block" for={item.id}>
                <input
                  className="radio_animated"
                  id={item.id}
                  type="radio"
                  name="cashback"
                  innerRef={cashbackRef}
                  value={item.id}
                  {...cashback}
                  disabled={!!cashbackOwner}
                  defaultChecked={item.id === item.cashback?.id}
                />
                {item.name} -{" "}
                {!!cashbackOwner ? (
                  <span style={{ color: "red" }}>
                    Não é possível atribuir este cashback, pois o mesmo pertence
                    à <strong>{cashbackOwner.name}</strong>
                  </span>
                ) : (
                  <span style={{ color: "green" }}>Disponível</span>
                )}
              </Label>
            );
          })}
        </Col>

        <Col sm="12">
          <h5>Bonificações</h5>
        </Col>
        <Col>
          {bonificationList?.map((item) => {
            const bonificationOwner = levelsList.find(
              (levelItem) => levelItem.bonification?.id === item.id
            );
            return (
              <Label className="d-block" for={item.id}>
                <input
                  className="radio_animated"
                  id={item.id}
                  type="radio"
                  name="bonification"
                  innerRef={bonificationRef}
                  value={item.id}
                  {...bonification}
                  disabled={!!bonificationOwner}
                  defaultChecked={item.id === item.bonification?.id}
                />
                {item.name} -{" "}
                {!!bonificationOwner ? (
                  <span style={{ color: "red" }}>
                    Não é possível atribuir este cashback, pois o mesmo pertence
                    à <strong>{bonificationOwner.name}</strong>
                  </span>
                ) : (
                  <span style={{ color: "green" }}>Disponível</span>
                )}
              </Label>
            );
          })}
        </Col>
      </div>
      <div className="form-row row-gap">
        <Button color="primary">Enviar</Button>
      </div>
    </Form>
  );
};

export default EditLevelForm;
