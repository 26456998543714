import React, { useMemo, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Card, Row, Col, Button } from 'reactstrap'

import ImageUploading from 'react-images-uploading'

import Layout from 'components/Layout'
import Breadcrumbs from 'components/Layout/breadcrumb'

import Personal from './Personal'
import FastLinks from './FastLinks'

import { Creators as AuthActions } from 'store/ducks/auth'

const UserProfile = () => {
  const dispatch = useDispatch()
  const { auth } = useSelector((state) => state.auth)

  const [personalButtonText, setPersonalButtonText] =
    useState('Editar informações')

  const [toggleAccredited, setToggleAccredited] = useState(
    auth && auth.beAccredited
  )

  const [canEditPersonal, setCanEditPersonal] = useState(false)

  const personalData = useMemo(() => {
    return {
      documentNumber: auth && auth.cpf,
      email: auth && auth.email,
      name: auth && auth.name,
      phone: auth && auth.phone,
      whatsapp: auth.whatsapp ? auth.whatsapp : 'N/A',
      photo: auth && auth.icon,
      companyAddress: auth && auth.companyAddress,
      cnpj: auth && auth.cnpj,
      beAccredited: auth && auth.beAccredited
    }
  }, [auth])

  const handleButtonText = (value) => setPersonalButtonText(value)
  const handleCanEdit = (value) => setCanEditPersonal(value)
  const handleToggleAccredited = (value) => setToggleAccredited(value)

  const handleUpdateUser = useCallback(
    (item) => {
      !!item.companyAddress && !!item.cnpj
        ? (item.beAccredited = true)
        : (item.beAccredited = false)

      !!item.password
        ? dispatch(
          AuthActions.updateAuthUserPasswordRequest({
            id: auth.id,
            password: item.password
          })
        )
        : delete item.password

      dispatch(
        AuthActions.updateAuthUserRequest({
          ...item,
          id: auth.id
        })
      )
      handleButtonText('Editar informações')
      handleCanEdit(false)
      item.beAccredited
        ? handleToggleAccredited(item.beAccredited)
        : handleToggleAccredited(false)
    },
    [auth.id, dispatch]
  )

  const handleUpdateProfileImage = useCallback(
    (image) => {
      dispatch(
        AuthActions.uploadAuthUserProfileImageRequest({
          image: image[0].dataURL,
          id: auth && auth.id
        })
      )
    },
    [auth, dispatch]
  )

  return (
    <Layout>
      <Breadcrumbs title="Perfil" />
      <div className="u-profile">
        <Row>
          <Col lg="4">
            <Row>
              <Col>
                <Card className="card">
                  <div className="info">
                    <div className="photo">
                      <img
                        src={personalData.photo}
                        alt="user-img"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null // prevents looping
                          currentTarget.src = require('assets/images/user/user.png')
                        }}
                      />
                    </div>
                    <div className="business">
                      <span className="plan">
                        Habilitação - {auth?.plan?.[0].name}
                      </span>
                      <span className="profile">
                        Perfil - {auth?.profile?.[0]?.name}
                      </span>
                    </div>
                  </div>
                  <div className="actions">
                    <ImageUploading
                      multiple
                      onChange={handleUpdateProfileImage}
                      maxNumber={1}
                    >
                      {({ onImageUpload, dragProps }) => (
                        <Button color="primary" onClick={onImageUpload}>
                          Atualizar imagem
                        </Button>
                      )}
                    </ImageUploading>
                  </div>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className="card">
                  <div className="fast-links">
                    <strong>Links rapidos</strong>
                    <FastLinks />
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>

          <Col lg="8">
            <Card className="card">
              <div className="personal">
                <strong>Dados pessoais</strong>
                <Personal
                  data={personalData && personalData}
                  currentValue={personalData}
                  personalButtonText={personalButtonText}
                  canEditPersonal={canEditPersonal}
                  toggleAccredited={toggleAccredited}
                  handleUpdateUser={handleUpdateUser}
                  handleButtonText={handleButtonText}
                  handleCanEdit={handleCanEdit}
                  handleToggleAccredited={handleToggleAccredited}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </Layout>
  )
}

export default UserProfile
