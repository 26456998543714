import React, { useEffect } from "react";

import {
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";

import { useForm } from "react-hook-form";

const Invite = ({
  submitForm
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { ref: emailRef, ...email } = register("email", { required: true });
  const { ref: nameRef, ...name } = register("name", { required: true });


  return (
    <Card className="height-equal">
      <CardHeader>
        <h5>Convidar</h5>
      </CardHeader>
      <CardBody className="contact-form">
        <Form
          noValidate=""
          onSubmit={handleSubmit(submitForm)}
          className="theme-form">
          <div className="form-icon">
            <i className="icofont icofont-envelope-open"></i>
          </div>
          <FormGroup>
            <Label for="exampleInputName">name</Label>
            <Input
              className="form-control"
              id="exampleInputName"
              type="text"
              placeholder="name"
              innerRef={nameRef}
              {...name}
            />
          </FormGroup>
          <FormGroup>
            <Label className="col-form-label" htmlFor="exampleInputEmail1">
              E-mail
            </Label>
            <Input
              className="form-control"
              id="exampleInputEmail1"
              type="email"
              placeholder="E-mail"
              innerRef={emailRef}
              {...email}
            />
          </FormGroup>

          <div className="text-sm-right">
            <Button className="btn btn-primary">Enviar convite</Button>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
};

export default Invite;
