/* eslint-disable no-console */
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isAuthenticated } from "services/auth";

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{ pathname: "/login", state: { from: props.location } }}
        />
      )
    }
  />
);

export const RegisterRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      props.location.pathname === "/register" && !isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

export const LoginRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      props.location.pathname === "/login" && isAuthenticated() ? (
        <Redirect
          to={{
            pathname:
              props.location.state !== undefined &&
              props.location.state.prevUrl,
            state: { from: props.location },
          }}
        />
      ) : (
        <Component {...props} />
      )
    }
  />
);
