import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { isEmpty } from "lodash";

import DefaultPage from "components/DefaultPage";

import EditUserForm from "components/Users/EditUserForm";

import { Creators as UserActions } from "store/ducks/users";

const EditUser = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { user } = useSelector((state) => state.users);
  const profileList = useSelector((state) => state.profiles.list);
  const planList = useSelector((state) => state.plans.list);
  const userList = useSelector((state) => state.users.list);

  const handleUpdateUser = useCallback(
    (item) => {
      dispatch(UserActions.updateUserRequest({ ...item, id }));
    },
    [dispatch, id]
  );

  useEffect(() => {
    dispatch(UserActions.getUserRequest(id));
  }, [dispatch, id]);

  return (
    <DefaultPage
      pageTitle="Ações"
      pageDescription="Editar ação"
      pageSubDescription="Editar a ação"
    >
      {!isEmpty(user) && (
        <EditUserForm
          submitForm={handleUpdateUser}
          currentValue={user}
          profileList={profileList}
          planList={planList}
          userList={userList}
        />
      )}
    </DefaultPage>
  );
};

export default EditUser;
