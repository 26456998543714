import React from 'react'
import { Activity, DollarSign, Target, Users, Paperclip } from 'react-feather'
import { Link } from 'react-router-dom'

const FastLinks = () => {
  return (
    <div className="items">
      <div className="item">
        <Link to="/financeiro">
          <div className="icon" style={{ background: '#2980b9' }}>
            <DollarSign size={35} />
          </div>
          <div className="description">Financeiro</div>
        </Link>
      </div>
      <div className="item">
        <Link to="/rede">
          <div className="icon" style={{ background: '#27ae60' }}>
            <Users size={35} />
          </div>
          <div className="description">Rede</div>
        </Link>
      </div>
      <div className="item">
        <Link to="/planos/buy">
          <div className="icon" style={{ background: '#e74c3c' }}>
            <Target size={35} />
          </div>
          <div className="description">Habilitações</div>
        </Link>
      </div>
      {/* <div className="item">
        <Link to="/financeiro">
          <div className="icon" style={{ background: '#f39c12' }}>
            <Activity size={35} />
          </div>
          <div className="description">Construção de Ativo</div>
        </Link>
      </div> */}
      <div className="item">

        <Link to="/QB_CONTRATO_DE_PARCERIA_PARA_MARKETING_DE_FIDELIZACAO_COM_CASHBACK_v2022.pdf" target={'_blank'}>
          <div className="icon" style={{ background: '#2980b9' }}>
            <Paperclip size={35} />
          </div>
          <div className="description">Contrato</div>
        </Link>
      </div>
    </div>
  )
}

export default FastLinks
