import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'

import DefaultPage from 'components/DefaultPage'

import Form from './Form'

import { Creators as TutorialsActions } from 'store/ducks/tutorials'

const CreateQuestion = () => {
  const [isActive, setIsActive] = useState(false)
  const dispatch = useDispatch()

  const handleCreateTutorial = useCallback(
    (item) => {
      const body = {
        ...item,
        isActive: isActive === 'true' ? true : false
      }
      dispatch(TutorialsActions.createTutorialRequest(body))
    },
    [dispatch, isActive]
  )

  const handleActive = useCallback((value) => setIsActive(value), [])

  return (
    <DefaultPage
      pageTitle="Tutoriais"
      pageDescription="Criar tutorial"
      pageSubDescription="Criar tutoriais sobre o sistema"
    >
      <Form submitForm={handleCreateTutorial} handleActive={handleActive} />
    </DefaultPage>
  )
}

export default CreateQuestion
