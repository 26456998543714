import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { isEmpty } from "lodash";

import DefaultPage from "components/DefaultPage";

import EditPaymentMethodForm from "components/PaymentMethods/EditPaymentMethodForm";

import { Creators as PaymentMethodActions } from "store/ducks/paymentMethods";

const EditPaymentMethod = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { method } = useSelector((state) => state.paymentMethods);

  const handleUpdatePaymentMethod = useCallback(
    (item) => {
      dispatch(
        PaymentMethodActions.updatePaymentMethodRequest({ ...item, id })
      );
    },
    [dispatch, id]
  );

  useEffect(() => {
    dispatch(PaymentMethodActions.getPaymentMethodRequest(id));
  }, [dispatch, id]);

  return (
    <DefaultPage
      pageTitle="Métodos de pagamento"
      pageDescription="Editar método de pagamento"
      pageSubDescription="Editar o método de pagamento"
    >
      {!isEmpty(method) && (
        <EditPaymentMethodForm
          submitForm={handleUpdatePaymentMethod}
          currentValue={method}
        />
      )}
    </DefaultPage>
  );
};

export default EditPaymentMethod;
