import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { isEmpty } from "lodash";

import DefaultPage from "components/DefaultPage";

import EditServiceForm from "components/Services/EditServiceForm";

import { Creators as ServicesActions } from "store/ducks/services";

const EditService = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { service } = useSelector((state) => state.services);

  const handleUpdateService = useCallback(
    (item) => {
      dispatch(ServicesActions.updateServiceRequest({ ...item, id }));
    },
    [dispatch, id]
  );

  useEffect(() => {
    dispatch(ServicesActions.getServiceRequest(id));
  }, [dispatch, id]);

  return (
    <DefaultPage
      pageTitle="Serviços"
      pageDescription="Editar serviço"
      pageSubDescription="Editar o serviço"
    >
      {!isEmpty(service) && (
        <EditServiceForm
          submitForm={handleUpdateService}
          currentValue={service}
        />
      )}
    </DefaultPage>
  );
};

export default EditService;
