import Cashbacks from "pages/Cashbacks";
import CreateCashback from "pages/Cashbacks/Create";
import EditCashback from "pages/Cashbacks/Edit";

import { BASE_URL } from "shared/constants/config";

export default [
  { path: `${BASE_URL}/cashbacks`, component: Cashbacks },
  { path: `${BASE_URL}/cashbacks/criar`, component: CreateCashback },
  { path: `${BASE_URL}/cashbacks/editar/:id`, component: EditCashback },
];
