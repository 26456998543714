import { all, takeLatest } from 'redux-saga/effects'

import { Types as AuthTypes } from 'store/ducks/auth'
import {
  login,
  registerUser,
  updateAuthUser,
  updateAuthUserPassword,
  uploadUserProfileImage
} from './auth'

import { Types as UserTypes } from 'store/ducks/users'
import {
  getUsers,
  getUsersAccredited,
  getUserByID,
  createUser,
  updateUser,
  deleteUser,
  setProfileUser,
  getUsersDeadbeat,
  getUserByName,
  createUserInvite,
  getUserByEmail
} from './user'
import { Types as SalesTypes } from 'store/ducks/sales'
import { createSales, getSalesByID, updateSales } from './sales'

import { Types as InvoiceTypes } from 'store/ducks/invoice'
import {
  createinvoice,
  deleteinvoice,
  getinvoice,
  getinvoiceByID,
  getInvoiceByUser,
  getInvoiceEstimetedByUser,
  updateinvoice
} from './invoice'

import { Types as ActionTypes } from 'store/ducks/actions'
import {
  getActions,
  getActionByID,
  createAction,
  updateAction,
  deleteAction
} from './actions'

import { Types as StoreTypes } from 'store/ducks/stores'
import {
  getStores,
  getStoreByID,
  createStore,
  updateStore,
  deleteStore
} from './stores'

import { Types as ProfileTypes } from 'store/ducks/profiles'
import {
  getProfiles,
  getProfileByID,
  createProfile,
  updateProfile,
  deleteProfile
} from './profile'

import { Types as PlansTypes } from 'store/ducks/plans'
import {
  getPlans,
  getPlanByID,
  createPlan,
  updatePlan,
  deletePlan
} from './plans'

import { Types as BonificationTypes } from 'store/ducks/bonifications'
import {
  getBonifications,
  getBonificationByID,
  createBonification,
  updateBonification,
  deleteBonification
} from './bonifications'

import { Types as CashbackTypes } from 'store/ducks/cashbacks'
import {
  getCashbacks,
  getCashbackByID,
  createCashback,
  updateCashback,
  deleteCashback
} from './cashbacks'

import { Types as LevelTypes } from 'store/ducks/levels'
import {
  getLevels,
  getLevelByID,
  createLevel,
  updateLevel,
  deleteLevel
} from './levels'

import { Types as NetworkTypes } from 'store/ducks/network'
import {
  getNetworks,
  getNetworkByID,
  getNetworkByOwner,
  getNetworkProductiveSummary
} from './network'

import { Types as WalletTypes } from 'store/ducks/wallet'
import {
  getWallets,
  getWalletsByID,
  getWalletByUser,
  updateWallet
} from './wallet'

import { Types as MoneyTypes } from 'store/ducks/money'
import {
  createMoney,
  getMoneys,
  getMoneyByID,
  getMoneyByUser,
  getMoneyByUserCashback,
  getMoneyAdminDash,
  getMoneyAdminDashDetail,
  getMoneyUserByTypeSummary,
  getMoneySalesByAccredited,
  getMoneyByUserBonusAccredited,
  getMoneyByUserBonusAccession,
  getMoneyByUserPlanInstallments,
  updateMoney
} from './money'

import { Types as PositionTypes } from 'store/ducks/positions'
import {
  getPositions,
  getPositionByID,
  createPosition,
  updatePosition,
  deletePosition
} from './positions'

import { Types as GraduationTypes } from 'store/ducks/graduations'
import {
  getGraduations,
  getGraduationByID,
  createGraduation,
  updateGraduation,
  deleteGraduation
} from './graduations'

import { Types as SupportTypes } from 'store/ducks/support'
import {
  getSupports,
  getSupportByID,
  createSupport,
  updateSupport,
  deleteSupport,
  createSupportComment,
  getSupportByUser
} from './support'

import { Types as PaymentMethodTypes } from 'store/ducks/paymentMethods'
import {
  getPaymentMethods,
  getPaymentMethodByID,
  createPaymentMethod,
  updatePaymentMethod,
  deletePaymentMethod
} from './paymentMethods'

import { Types as ServicesTypes } from 'store/ducks/services'
import {
  getServices,
  createService,
  deleteService,
  updateService,
  getServiceByID
} from './services'

import { Types as BankAccountsTypes } from 'store/ducks/bankAccounts'
import {
  getBankAccountByUser,
  createBankAccount,
  deleteBankAccount,
  updateBankAccount
} from './bankAccounts'

import { Types as WithdrawTypes } from 'store/ducks/withdraw'
import { getWithdrawRequests } from './withdraw'

import { Types as GenericSearchTypes } from 'store/ducks/generic_search'
import { getGenericSearch } from './generic_search'

import { Types as CheckoutTypes } from 'store/ducks/checkout'
import { createTransaction } from './checkout'

import { Types as ReportsType } from 'store/ducks/report'
import { getEntities, getEntityColumns, getReports } from './report'

import { Types as FAQTypes } from 'store/ducks/faq'
import {
  createQuestion,
  deleteQuestion,
  getQuestionByID,
  getQuestions,
  updateQuestion
} from './faq'

import { Types as TutorialTypes } from 'store/ducks/tutorials'
import {
  createTutorial,
  deleteTutorial,
  getTutorialByID,
  getTutorials,
  updateTutorial
} from './tutorials'

import { Types as MaterialTypes } from 'store/ducks/materials'
import {
  createMaterial,
  deleteMaterial,
  getMaterialByID,
  getMaterials,
  updateMaterial
} from './materials'

import { Types as NotificationTypes } from 'store/ducks/notifications'
import {
  createNotification,
  getNotifications,
  getNotificationsByuser,
  setAsReadedNotificationByUser
} from './notifications'

export default function* rootSaga() {
  yield all([
    //Auth
    takeLatest(AuthTypes.LOGIN_REQUEST, login),
    takeLatest(AuthTypes.UPDATE_AUTH_USER_REQUEST, updateAuthUser),
    takeLatest(
      AuthTypes.UPDATE_AUTH_USER_PASSWORD_REQUEST,
      updateAuthUserPassword
    ),
    takeLatest(
      AuthTypes.UPDATE_USER_PROFILE_IMAGE_REQUEST,
      uploadUserProfileImage
    ),
    takeLatest(AuthTypes.REGISTER_USER_REQUEST, registerUser),

    //Users
    takeLatest(UserTypes.GET_USERS_REQUEST, getUsers),
    takeLatest(UserTypes.GET_USERS_ACCREDITED_REQUEST, getUsersAccredited),
    takeLatest(UserTypes.GET_USERS_DEADBEAT_REQUEST, getUsersDeadbeat),
    takeLatest(UserTypes.GET_USER_BY_NAME_REQUEST, getUserByName),
    takeLatest(UserTypes.GET_USER_REQUEST, getUserByID),
    takeLatest(UserTypes.CREATE_USER_REQUEST, createUser),
    takeLatest(UserTypes.CREATE_USER_INVITE_REQUEST, createUserInvite),
    takeLatest(UserTypes.UPDATE_USER_REQUEST, updateUser),
    takeLatest(UserTypes.DELETE_USER_REQUEST, deleteUser),
    takeLatest(UserTypes.SET_PROFILE_TO_USER_REQUEST, setProfileUser),
    takeLatest(UserTypes.GET_USER_BY_EMAIL_REQUEST, getUserByEmail),

    //Actions
    takeLatest(ActionTypes.GET_ACTIONS_REQUEST, getActions),
    takeLatest(ActionTypes.GET_ACTION_REQUEST, getActionByID),
    takeLatest(ActionTypes.CREATE_ACTION_REQUEST, createAction),
    takeLatest(ActionTypes.UPDATE_ACTION_REQUEST, updateAction),
    takeLatest(ActionTypes.DELETE_ACTION_REQUEST, deleteAction),

    //Profiles
    takeLatest(ProfileTypes.GET_PROFILES_REQUEST, getProfiles),
    takeLatest(ProfileTypes.GET_PROFILE_REQUEST, getProfileByID),
    takeLatest(ProfileTypes.CREATE_PROFILE_REQUEST, createProfile),
    takeLatest(ProfileTypes.UPDATE_PROFILE_REQUEST, updateProfile),
    takeLatest(ProfileTypes.DELETE_PROFILE_REQUEST, deleteProfile),

    //Plans
    takeLatest(PlansTypes.GET_PLANS_REQUEST, getPlans),
    takeLatest(PlansTypes.GET_PLAN_REQUEST, getPlanByID),
    takeLatest(PlansTypes.CREATE_PLAN_REQUEST, createPlan),
    takeLatest(PlansTypes.UPDATE_PLAN_REQUEST, updatePlan),
    takeLatest(PlansTypes.DELETE_PLAN_REQUEST, deletePlan),

    //Bonifications
    takeLatest(BonificationTypes.GET_BONIFICATIONS_REQUEST, getBonifications),
    takeLatest(BonificationTypes.GET_BONIFICATION_REQUEST, getBonificationByID),
    takeLatest(
      BonificationTypes.CREATE_BONIFICATION_REQUEST,
      createBonification
    ),
    takeLatest(
      BonificationTypes.UPDATE_BONIFICATION_REQUEST,
      updateBonification
    ),
    takeLatest(
      BonificationTypes.DELETE_BONIFICATION_REQUEST,
      deleteBonification
    ),

    //Cashbacks
    takeLatest(CashbackTypes.GET_CASHBACKS_REQUEST, getCashbacks),
    takeLatest(CashbackTypes.GET_CASHBACK_REQUEST, getCashbackByID),
    takeLatest(CashbackTypes.CREATE_CASHBACK_REQUEST, createCashback),
    takeLatest(CashbackTypes.UPDATE_CASHBACK_REQUEST, updateCashback),
    takeLatest(CashbackTypes.DELETE_CASHBACK_REQUEST, deleteCashback),

    //Levels
    takeLatest(LevelTypes.GET_LEVELS_REQUEST, getLevels),
    takeLatest(LevelTypes.GET_LEVEL_REQUEST, getLevelByID),
    takeLatest(LevelTypes.CREATE_LEVEL_REQUEST, createLevel),
    takeLatest(LevelTypes.UPDATE_LEVEL_REQUEST, updateLevel),
    takeLatest(LevelTypes.DELETE_LEVEL_REQUEST, deleteLevel),

    //Network
    takeLatest(NetworkTypes.GET_NETWORKS_REQUEST, getNetworks),
    takeLatest(NetworkTypes.GET_NETWORK_REQUEST, getNetworkByID),
    takeLatest(NetworkTypes.GET_NETWORK_BY_OWNER_REQUEST, getNetworkByOwner),
    takeLatest(
      NetworkTypes.GET_NETWORK_PRODUCTIVE_SUMMARY_REQUEST,
      getNetworkProductiveSummary
    ),

    //Wallet
    takeLatest(WalletTypes.GET_WALLETS_REQUEST, getWallets),
    takeLatest(WalletTypes.GET_WALLET_REQUEST, getWalletsByID),
    takeLatest(WalletTypes.GET_WALLET_BY_USER_REQUEST, getWalletByUser),
    takeLatest(WalletTypes.UPDATE_WALLET_REQUEST, updateWallet),

    //Money
    takeLatest(MoneyTypes.CREATE_MONEY_REQUEST, createMoney),
    takeLatest(MoneyTypes.UPDATE_MONEY_REQUEST, updateMoney),
    takeLatest(MoneyTypes.GET_MONEY_BY_USER_PLAN_INSTALLMENTS_REQUEST, getMoneyByUserPlanInstallments),

    takeLatest(MoneyTypes.GET_MONEYS_REQUEST, getMoneys),
    takeLatest(MoneyTypes.GET_MONEY_REQUEST, getMoneyByID),
    takeLatest(MoneyTypes.GET_MONEY_BY_USER_REQUEST, getMoneyByUser),

    takeLatest(
      MoneyTypes.GET_MONEY_BY_USER_CASHBACK_REQUEST,
      getMoneyByUserCashback
    ),
    takeLatest(
      MoneyTypes.GET_MONEY_BY_USER_BONUS_ACCREDITED_REQUEST,
      getMoneyByUserBonusAccredited
    ),
    takeLatest(
      MoneyTypes.GET_MONEY_BY_USER_BONUS_ACCESSION_REQUEST,
      getMoneyByUserBonusAccession
    ),

    takeLatest(MoneyTypes.GET_MONEY_ADMIN_DASH_REQUEST, getMoneyAdminDash),
    takeLatest(
      MoneyTypes.GET_MONEY_ADMIN_DASH_DETAIL_REQUEST,
      getMoneyAdminDashDetail
    ),
    takeLatest(
      MoneyTypes.GET_MONEY_USER_BY_TYPE_SUMMARY_REQUEST,
      getMoneyUserByTypeSummary
    ),
    takeLatest(MoneyTypes.GET_MONEY_SALES_REQUEST, getMoneySalesByAccredited),

    //Positions
    takeLatest(PositionTypes.GET_POSITIONS_REQUEST, getPositions),
    takeLatest(PositionTypes.GET_POSITION_REQUEST, getPositionByID),
    takeLatest(PositionTypes.CREATE_POSITION_REQUEST, createPosition),
    takeLatest(PositionTypes.UPDATE_POSITION_REQUEST, updatePosition),
    takeLatest(PositionTypes.DELETE_POSITION_REQUEST, deletePosition),

    //Graduations
    takeLatest(GraduationTypes.GET_GRADUATIONS_REQUEST, getGraduations),
    takeLatest(GraduationTypes.GET_GRADUATION_REQUEST, getGraduationByID),
    takeLatest(GraduationTypes.CREATE_GRADUATION_REQUEST, createGraduation),
    takeLatest(GraduationTypes.UPDATE_GRADUATION_REQUEST, updateGraduation),
    takeLatest(GraduationTypes.DELETE_GRADUATION_REQUEST, deleteGraduation),

    //Supports
    takeLatest(SupportTypes.GET_SUPPORTS_REQUEST, getSupports),
    takeLatest(SupportTypes.GET_SUPPORT_REQUEST, getSupportByID),
    takeLatest(SupportTypes.GET_SUPPORTS_BY_USER_REQUEST, getSupportByUser),
    takeLatest(SupportTypes.CREATE_SUPPORT_REQUEST, createSupport),
    takeLatest(
      SupportTypes.CREATE_SUPPORT_COMMENT_REQUEST,
      createSupportComment
    ),
    takeLatest(SupportTypes.UPDATE_SUPPORT_REQUEST, updateSupport),
    takeLatest(SupportTypes.DELETE_SUPPORT_REQUEST, deleteSupport),

    //PaymentMethods
    takeLatest(
      PaymentMethodTypes.GET_PAYMENT_METHODS_REQUEST,
      getPaymentMethods
    ),
    takeLatest(
      PaymentMethodTypes.GET_PAYMENT_METHOD_REQUEST,
      getPaymentMethodByID
    ),
    takeLatest(
      PaymentMethodTypes.CREATE_PAYMENT_METHOD_REQUEST,
      createPaymentMethod
    ),
    takeLatest(
      PaymentMethodTypes.UPDATE_PAYMENT_METHOD_REQUEST,
      updatePaymentMethod
    ),
    takeLatest(
      PaymentMethodTypes.DELETE_PAYMENT_METHOD_REQUEST,
      deletePaymentMethod
    ),

    //Services
    takeLatest(ServicesTypes.GET_SERVICES_REQUEST, getServices),
    takeLatest(ServicesTypes.GET_SERVICE_REQUEST, getServiceByID),
    takeLatest(ServicesTypes.CREATE_SERVICE_REQUEST, createService),
    takeLatest(ServicesTypes.UPDATE_SERVICE_REQUEST, updateService),
    takeLatest(ServicesTypes.DELETE_SERVICE_REQUEST, deleteService),
    //Sales
    takeLatest(SalesTypes.GET_SALES_REQUEST, getSalesByID),
    takeLatest(SalesTypes.CREATE_SALES_REQUEST, createSales),
    takeLatest(SalesTypes.UPDATE_SALES_REQUEST, updateSales),
    takeLatest(SalesTypes.DELETE_SALES_REQUEST, deleteService),

    //Invoice
    takeLatest(InvoiceTypes.GET_INVOICE_REQUEST, getinvoice),
    takeLatest(InvoiceTypes.CREATE_INVOICE_REQUEST, createinvoice),
    takeLatest(InvoiceTypes.GET_INVOICE_BY_USER_REQUEST, getInvoiceByUser),
    takeLatest(
      InvoiceTypes.GET_INVOICE_ESTIMETED_BY_USER_REQUEST,
      getInvoiceEstimetedByUser
    ),
    takeLatest(InvoiceTypes.GET_INVOICE_BY_ID_REQUEST, getinvoiceByID),
    takeLatest(InvoiceTypes.UPDATE_INVOICE_REQUEST, updateinvoice),
    takeLatest(InvoiceTypes.DELETE_INVOICE_REQUEST, deleteinvoice),

    //Bank Accounts
    takeLatest(
      BankAccountsTypes.GET_BANK_ACCOUNT_REQUEST,
      getBankAccountByUser
    ),
    takeLatest(
      BankAccountsTypes.CREATE_BANK_ACCOUNT_REQUEST,
      createBankAccount
    ),
    takeLatest(
      BankAccountsTypes.UPDATE_BANK_ACCOUNT_REQUEST,
      updateBankAccount
    ),
    takeLatest(
      BankAccountsTypes.DELETE_BANK_ACCOUNT_REQUEST,
      deleteBankAccount
    ),

    //Withdraw
    takeLatest(
      WithdrawTypes.GET_WITHDRAW_REQUESTS_REQUEST,
      getWithdrawRequests
    ),

    //Store
    takeLatest(StoreTypes.CREATE_STORE_REQUEST, createStore),
    takeLatest(StoreTypes.GET_STORES_REQUEST, getStores),
    takeLatest(StoreTypes.GET_STORE_REQUEST, getStoreByID),
    takeLatest(StoreTypes.UPDATE_STORE_REQUEST, updateStore),
    takeLatest(StoreTypes.DELETE_STORE_SUCCESS, deleteStore),

    //Generic Search
    takeLatest(GenericSearchTypes.GENERIC_SEARCH_REQUEST, getGenericSearch),
    takeLatest(ReportsType.GET_ENTITIES_REQUEST, getEntities),
    takeLatest(ReportsType.GET_ENTITY_COLUMNS_REQUEST, getEntityColumns),
    takeLatest(ReportsType.GET_REPORT_DATA_REQUEST, getReports),

    //Checkout
    takeLatest(CheckoutTypes.CREATE_TRANSACTION_REQUEST, createTransaction),

    //FAQ
    takeLatest(FAQTypes.CREATE_QUESTION_REQUEST, createQuestion),
    takeLatest(FAQTypes.GET_QUESTIONS_REQUEST, getQuestions),
    takeLatest(FAQTypes.GET_QUESTION_REQUEST, getQuestionByID),
    takeLatest(FAQTypes.UPDATE_QUESTION_REQUEST, updateQuestion),
    takeLatest(FAQTypes.DELETE_QUESTION_REQUEST, deleteQuestion),

    //Tutorials
    takeLatest(TutorialTypes.CREATE_TUTORIAL_REQUEST, createTutorial),
    takeLatest(TutorialTypes.GET_TUTORIALS_REQUEST, getTutorials),
    takeLatest(TutorialTypes.GET_TUTORIAL_REQUEST, getTutorialByID),
    takeLatest(TutorialTypes.UPDATE_TUTORIAL_REQUEST, updateTutorial),
    takeLatest(TutorialTypes.DELETE_TUTORIAL_REQUEST, deleteTutorial),

    //Materials
    takeLatest(MaterialTypes.CREATE_MATERIAL_REQUEST, createMaterial),
    takeLatest(MaterialTypes.GET_MATERIALS_REQUEST, getMaterials),
    takeLatest(MaterialTypes.GET_MATERIAL_REQUEST, getMaterialByID),
    takeLatest(MaterialTypes.UPDATE_MATERIAL_REQUEST, updateMaterial),
    takeLatest(MaterialTypes.DELETE_MATERIAL_REQUEST, deleteMaterial),

    //Notifications
    takeLatest(
      NotificationTypes.CREATE_NOTIFICATION_REQUEST,
      createNotification
    ),
    takeLatest(NotificationTypes.GET_NOTIFICATIONS_REQUEST, getNotifications),
    takeLatest(
      NotificationTypes.GET_NOTIFICATIONS_BY_USER_REQUEST,
      getNotificationsByuser
    ),

    takeLatest(
      NotificationTypes.SET_AS_READED_NOTIFICATION_BY_USER_REQUEST,
      setAsReadedNotificationByUser
    )
  ])
}
