export const Types = {
  GET_PLANS_REQUEST: "plans/GET_PLANS_REQUEST",
  GET_PLANS_SUCCESS: "plans/GET_PLANS_SUCCESS",
  GET_PLANS_FAILURE: "plans/GET_PLANS_FAILURE",

  GET_PLAN_REQUEST: "plans/GET_PLAN_REQUEST",
  GET_PLAN_SUCCESS: "plans/GET_PLAN_SUCCESS",
  GET_PLAN_FAILURE: "plans/GET_PLAN_FAILURE",

  CREATE_PLAN_REQUEST: "plans/CREATE_PLAN_REQUEST",
  CREATE_PLAN_SUCCESS: "plans/CREATE_PLAN_SUCCESS",
  CREATE_PLAN_FAILURE: "plans/CREATE_PLAN_FAILURE",

  UPDATE_PLAN_REQUEST: "plans/UPDATE_PLAN_REQUEST",
  UPDATE_PLAN_SUCCESS: "plans/UPDATE_PLAN_SUCCESS",
  UPDATE_PLAN_FAILURE: "plans/UPDATE_PLAN_FAILURE",

  DELETE_PLAN_REQUEST: "plans/DELETE_PLAN_REQUEST",
  DELETE_PLAN_SUCCESS: "plans/DELETE_PLAN_SUCCESS",
  DELETE_PLAN_FAILURE: "plans/DELETE_PLAN_FAILURE",
};

const INITIAL_STATE = {
  list: [],
  plan: {},
  loading: false,
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_PLANS_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.GET_PLANS_SUCCESS:
      return { ...state, loading: false, list: action.payload, error: null };

    case Types.GET_PLANS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.GET_PLAN_REQUEST:
      return { ...state, loading: true, plan: {}, error: null };

    case Types.GET_PLAN_SUCCESS:
      return { ...state, loading: false, plan: action.payload, error: null };

    case Types.GET_PLAN_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.CREATE_PLAN_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.CREATE_PLAN_SUCCESS:
      return { ...state, loading: false, error: null };

    case Types.CREATE_PLAN_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.UPDATE_PLAN_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.UPDATE_PLAN_SUCCESS:
      return { ...state, loading: false, plan: {}, error: null };

    case Types.UPDATE_PLAN_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case Types.DELETE_PLAN_REQUEST:
      return { ...state, loading: true, error: null };

    case Types.DELETE_PLAN_SUCCESS:
      return { ...state, loading: false, error: null };

    case Types.DELETE_PLAN_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export const Creators = {
  getPlansRequest: (payload) => ({
    type: Types.GET_PLANS_REQUEST,
    payload,
  }),

  getPlansSuccess: (payload) => ({
    type: Types.GET_PLANS_SUCCESS,
    payload,
  }),

  getPlansFailure: (payload) => ({
    type: Types.GET_PLANS_FAILURE,
    payload,
  }),

  getPlanRequest: (payload) => ({
    type: Types.GET_PLAN_REQUEST,
    payload,
  }),

  getPlanSuccess: (payload) => ({
    type: Types.GET_PLAN_SUCCESS,
    payload,
  }),

  getPlanFailure: (payload) => ({
    type: Types.GET_PLAN_FAILURE,
    payload,
  }),

  createPlanRequest: (payload) => ({
    type: Types.CREATE_PLAN_REQUEST,
    payload,
  }),

  createPlanSuccess: (payload) => ({
    type: Types.CREATE_PLAN_SUCCESS,
    payload,
  }),

  createPlanFailure: (payload) => ({
    type: Types.CREATE_PLAN_FAILURE,
    payload,
  }),

  updatePlanRequest: (payload) => ({
    type: Types.UPDATE_PLAN_REQUEST,
    payload,
  }),

  updatePlanSuccess: (payload) => ({
    type: Types.UPDATE_PLAN_SUCCESS,
    payload,
  }),

  updatePlanFailure: (payload) => ({
    type: Types.UPDATE_PLAN_FAILURE,
    payload,
  }),

  deletePlanRequest: (payload) => ({
    type: Types.DELETE_PLAN_REQUEST,
    payload,
  }),

  deletePlanSuccess: (payload) => ({
    type: Types.DELETE_PLAN_SUCCESS,
    payload,
  }),

  deletePlanFailure: (payload) => ({
    type: Types.DELETE_PLAN_FAILURE,
    payload,
  }),
};
