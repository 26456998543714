export const Types = {
  GET_NOTIFICATIONS_REQUEST: 'notifications/GET_NOTIFICATIONS_REQUEST',
  GET_NOTIFICATIONS_SUCCESS: 'notifications/GET_NOTIFICATIONS_SUCCESS',
  GET_NOTIFICATIONS_FAILURE: 'notifications/GET_NOTIFICATIONS_FAILURE',

  CREATE_NOTIFICATION_REQUEST: 'notifications/CREATE_NOTIFICATION_REQUEST',
  CREATE_NOTIFICATION_SUCCESS: 'notifications/CREATE_NOTIFICATION_SUCCESS',
  CREATE_NOTIFICATION_FAILURE: 'notifications/CREATE_NOTIFICATION_FAILURE',

  GET_NOTIFICATIONS_BY_USER_REQUEST:
    'notifications/GET_NOTIFICATIONS_BY_USER_REQUEST',
  GET_NOTIFICATIONS_BY_USER_SUCCESS:
    'notifications/GET_NOTIFICATIONS_BY_USER_SUCCESS',
  GET_NOTIFICATIONS_BY_USER_FAILURE:
    'notifications/GET_NOTIFICATIONS_BY_USER_FAILURE',

  SET_AS_READED_NOTIFICATION_BY_USER_REQUEST:
    'notifications/SET_AS_READED_NOTIFICATION_BY_USER_REQUEST',
  SET_AS_READED_NOTIFICATION_BY_USER_SUCCESS:
    'notifications/SET_AS_READED_NOTIFICATION_BY_USER_SUCCESS',
  SET_AS_READED_NOTIFICATION_BY_USER_FAILURE:
    'notifications/SET_AS_READED_NOTIFICATION_BY_USER_FAILURE'
}

const INITIAL_STATE = {
  list: [],
  loading: false,
  error: null,
  userNotifications: []
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_NOTIFICATIONS_REQUEST:
      return { ...state, loading: true, error: null }

    case Types.GET_NOTIFICATIONS_SUCCESS:
      return { ...state, loading: false, list: action.payload, error: null }

    case Types.GET_NOTIFICATIONS_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case Types.CREATE_NOTIFICATION_REQUEST:
      return { ...state, loading: true, error: null }

    case Types.CREATE_NOTIFICATION_SUCCESS:
      return { ...state, loading: false, error: null }

    case Types.CREATE_NOTIFICATION_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case Types.GET_NOTIFICATIONS_BY_USER_REQUEST:
      return { ...state, loading: true, error: null }

    case Types.GET_NOTIFICATIONS_BY_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        userNotifications: action.payload
      }
    case Types.GET_NOTIFICATIONS_BY_USER_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case Types.SET_AS_READED_NOTIFICATION_BY_USER_REQUEST:
      return { ...state, loading: true, error: null }

    case Types.SET_AS_READED_NOTIFICATION_BY_USER_SUCCESS:
      return { ...state, loading: false, error: null }

    case Types.SET_AS_READED_NOTIFICATION_BY_USER_FAILURE:
      return { ...state, loading: false, error: action.payload }

    default:
      return state
  }
}

export const Creators = {
  getNotificationsRequest: (payload) => ({
    type: Types.GET_NOTIFICATIONS_REQUEST,
    payload
  }),

  getNotificationsSuccess: (payload) => ({
    type: Types.GET_NOTIFICATIONS_SUCCESS,
    payload
  }),

  getNotificationsFailure: (payload) => ({
    type: Types.GET_NOTIFICATIONS_FAILURE,
    payload
  }),

  createNotificationRequest: (payload) => ({
    type: Types.CREATE_NOTIFICATION_REQUEST,
    payload
  }),

  createNotificationSuccess: (payload) => ({
    type: Types.CREATE_NOTIFICATION_SUCCESS,
    payload
  }),

  createNotificationFailure: (payload) => ({
    type: Types.CREATE_NOTIFICATION_FAILURE,
    payload
  }),

  getNotificationsByUserRequest: (payload) => ({
    type: Types.GET_NOTIFICATIONS_BY_USER_REQUEST,
    payload
  }),

  getNotificationsByUserSuccess: (payload) => ({
    type: Types.GET_NOTIFICATIONS_BY_USER_SUCCESS,
    payload
  }),

  getNotificationsByUserFailure: (payload) => ({
    type: Types.GET_NOTIFICATIONS_BY_USER_FAILURE,
    payload
  }),

  setAsReadedNotificationByUserRequest: (payload) => ({
    type: Types.SET_AS_READED_NOTIFICATION_BY_USER_REQUEST,
    payload
  }),

  setAsReadedNotificationByUserSuccess: (payload) => ({
    type: Types.SET_AS_READED_NOTIFICATION_BY_USER_SUCCESS,
    payload
  }),

  setAsReadedNotificationByUserFailure: (payload) => ({
    type: Types.SET_AS_READED_NOTIFICATION_BY_USER_FAILURE,
    payload
  })
}
