import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import DataTables from "components/Datatable";

import { Link } from 'react-router-dom'

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button
} from 'reactstrap'

import { isEmpty } from 'lodash'

import { Creators as PlanActions } from 'store/ducks/plans'
import { Creators as UserActions } from 'store/ducks/users'
import { Creators as MoneyActions } from 'store/ducks/money'

import Breadcrumb from 'components/Layout/breadcrumb'
import Layout from 'components/Layout'
import LoaderSpinner from 'components/LoaderSpinner'
import { installmentDataTableColumns } from "shared/constants/datatable";

const Plans = () => {
  const dispatch = useDispatch()
  const [listPlans, setListPlans] = useState([])
  const [isMaster, setIsMaster] = useState(false)
  const {
    plans: { list, loading },
    auth: { auth },
    money: { moneyByUserPlanInstallments }

  } = useSelector((state) => state)

  const userPlan = useSelector((state) => state.users.user.plan)

  useEffect(() => {
    dispatch(PlanActions.getPlansRequest())
  }, [dispatch])

  useEffect(() => {
    dispatch(UserActions.getUserRequest(auth.id))
  }, [dispatch, auth.id])

  useEffect(() => {
    dispatch(MoneyActions.getMoneyByUserPlanInstallmentsRequest(`${auth.id}?page=0&limit=50`))
  }, [dispatch, auth.id])
  
  useEffect(() => {
    const currentPlan = userPlan?.[0]
    const indexFromCurrentPlan = list.findIndex(
      (item) => item.id === currentPlan?.id
    )
    const newList = list.slice(indexFromCurrentPlan)

    if (newList[newList.length - 1]?.id === currentPlan?.id) setIsMaster(true)
    setListPlans(newList)
  }, [list, userPlan])

  return (
    <Layout>
      <Breadcrumb parent="Habilitações" title="Habilitações" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>
                  {!!userPlan?.[0].id
                    ? 'Garanta já o seu upgrade!'
                    : 'Garanta já sua habilitação'}
                </h5>
              </CardHeader>
              {loading ? (
                <LoaderSpinner />
              ) : (
                <CardBody className="row">
                  {!isMaster && !isEmpty(listPlans) ? (
                    listPlans?.map((item) => (
                      <Col md="3" sm="6" key={item.id}>
                        <div className="pricingtable">
                          <div className="pricingtable-header">
                            <h3 className="title">{item.name}</h3>
                          </div>
                          <div className="price-value">
                            <span className="currency">{'R$'}</span>
                            <span className="amount">
                              {(item.price / 12).toString().split(".")[0]}
                            </span>
                            <span className="duration">{'/mês'}</span>
                          </div>
                          <ul className="pricing-content">
                            {item.feature01 && (<li>{item.feature01}</li>)}
                            {item.feature02 && (<li>{item.feature02}</li>)}
                            {item.feature03 && (<li>{item.feature03}</li>)}
                            {item.feature04 && (<li>{item.feature04}</li>)}
                          </ul>
                          <div className="pricingtable-signup">
                            <Link
                              to={{
                                pathname: '/checkout',
                                state: {
                                  type: 'plan',
                                  id: item.id,
                                  description: item.description,
                                  price: item.price,
                                  label: `Plano - ${item.description}`
                                }
                              }}
                            >
                              <Button
                                color="primary"
                                size="lg"
                                disabled={userPlan?.[0].id === item.id}
                              >
                                {userPlan?.[0].id === item.id
                                  ? 'Já habilitado'
                                  : 'Fazer upgrade'}
                              </Button>
                            </Link>
                          </div>
                        </div>
                      </Col>
                    ))
                  ) : (
                    <span>Você atingiu a Habilitação máxima</span>
                  )}
                </CardBody>
              )}
              {!isEmpty(moneyByUserPlanInstallments) && (

                <DataTables
                  list={moneyByUserPlanInstallments[0]}
                  customDataTableColumns={installmentDataTableColumns}
                  noActions={true}
                />
              )}

            </Card>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Plans
