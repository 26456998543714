import React, { memo } from 'react'

import ApexCharts from 'react-apexcharts'

import { Card, CardBody, Row, Col } from 'reactstrap'

import { formatToCurrency } from 'utils'
import { cashbackChart } from 'shared/constants/chartdata-dummy'
const Summary = ({ data }) => {
  cashbackChart.series[0].data = data.cashbackChartData
  cashbackChart.series[0].name = 'Cashback'

  return (
    <Card className="earning-card">
      <CardBody className="p-0">
        <Row>
          <Col lg="3" className="earning-content p-0">
            <Row className="m-0 chart-left">
              <Col xl="12" className="p-0 left_side_earning">
                <h5>Resumo</h5>
                <small className="font-roboto">
                  Construção de Ativo
                </small>
              </Col>
              <Col xl="12" className="p-0 left_side_earning">
                <h5>
                  {formatToCurrency(
                    data.cashbackAmount ? data.cashbackAmount : 0
                  )}
                </h5>
                <p className="font-roboto">Total Cashback</p>
              </Col>
              <Col xl="12" className="p-0 left_side_earning">
                <h5>
                  {formatToCurrency(
                    data.soldPlansAmount ? data.soldPlansAmount : 0
                  )}
                </h5>
                <p className="font-roboto">Habilitações vendidos</p>
              </Col>
            </Row>
          </Col>
          <Col xl="9" className="p-0">
            <div className="chart-right">
              <Row className="m-0 p-tb">
                <Col lg="12" className="col-12 p-0 justify-content-end">
                  <div className="inner-top-right">
                    <ul className="d-flex list-unstyled justify-content-end">
                      <li>Cashback</li>
                    </ul>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl="12">
                  <CardBody className="p-0">
                    <div className="current-sale-container">
                      <ApexCharts
                        id="chart-currently"
                        options={cashbackChart.options}
                        series={cashbackChart.series}
                        type="area"
                        height={240}
                      />
                    </div>
                  </CardBody>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        <Row className="border-top m-0 summary-footer">
          <Col xl="4" md="6" sm="12">
            <div className="media p-0">
              <div className="media-left">
                <i className="fa fa-shopping-basket"></i>
              </div>
              <div
                className="media-body"
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <strong>Distribuição loja física</strong>
                <p>
                  {formatToCurrency(
                    data.physicalStoresAmount ? data.physicalStoresAmount : 0
                  )}
                </p>
              </div>
            </div>
          </Col>
          <Col xl="5" md="6" sm="6">
            <div className="media p-0">
              <div className="media-left bg-secondary">
                <i className="icofont icofont-share"></i>
              </div>
              <div
                className="media-body"
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <strong>Distribuição de bonus de adesão de Habilitações</strong>
                <p>
                  {formatToCurrency(
                    data.spreadBoughtPlanBonus ? data.spreadBoughtPlanBonus : 0
                  )}
                </p>
              </div>
            </div>
          </Col>
          <Col xl="3" md="6" sm="6">
            <div className="media p-0">
              <div className="media-left bg-secondary">
                <i className="icofont icofont-spinner-alt-3"></i>
              </div>
              <div
                className="media-body"
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <strong>Estorno</strong>
                <p>{formatToCurrency(data.reversal ? data.reversal : 0)}</p>
              </div>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default memo(Summary)
