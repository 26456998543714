import React from "react";
import { useSelector } from "react-redux";

import { Row, Col } from "reactstrap";

import MyNetwork from "./MyNetwork";

const RankingNetwork = () => {
  const {
    networkByOwner: { top, bottom },
  } = useSelector((state) => state.network);

  return (
    <Row>
      <Col xl="6" md="6" className="xl-100 box-col-12">
        <MyNetwork title="Top 12 - Minha Rede" list={top} />
      </Col>
      {/* <Col xl="6" md="6" className="xl-100 box-col-12">
        <MyNetwork title="Bottom 5 - Minha Rede" list={bottom} />
      </Col> */}
    </Row>
  );
};

export default RankingNetwork;
