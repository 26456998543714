import { put, call } from 'redux-saga/effects'
import { api } from 'services/api'
import { push } from 'connected-react-router'
import { toast } from 'react-toastify'

import { Creators as CashbackActions } from 'store/ducks/cashbacks'

export function* getCashbacks() {
  try {
    const { data } = yield call(api.get, '/cashbacks')
    yield put(CashbackActions.getCashbacksSuccess(data))
  } catch (err) {
    yield put(CashbackActions.getCashbacksFailure(err.message))
  }
}

export function* getCashbackByID(action) {
  try {
    const { data } = yield call(api.get, `/cashbacks/${action.payload}`)
    yield put(CashbackActions.getCashbackSuccess(data))
  } catch (err) {
    yield put(CashbackActions.getCashbackFailure(err))
  }
}

export function* createCashback(action) {
  try {
    const { data } = yield call(api.post, `/cashbacks`, action.payload)
    yield put(CashbackActions.createCashbackSuccess(data))
    yield put(push('/cashbacks'))
    toast.success('Cashback criado com sucesso!')
  } catch (err) {
    toast.error('Erro ao criar cashback')
    yield put(CashbackActions.createCashbackFailure(err))
  }
}

export function* updateCashback(action) {
  try {
    const { data } = yield call(
      api.put,
      `/cashbacks/${action.payload.id}`,
      action.payload
    )
    yield put(CashbackActions.updateCashbackSuccess(data))
    yield put(push('/cashbacks'))
    toast.success('Cashback editado com sucesso!')
  } catch (err) {
    toast.error('Erro ao editar cashback')
    yield put(CashbackActions.updateCashbackFailure(err))
  }
}

export function* deleteCashback(action) {
  try {
    const { data } = yield call(api.delete, `/cashbacks/${action.payload.id}`)
    yield put(CashbackActions.deleteCashbackSuccess(data))
    yield put(CashbackActions.getCashbacksRequest())
    toast.success('Cashback removido com sucesso')
  } catch (err) {
    toast.error('Erro ao remover cashback')
    yield put(CashbackActions.deleteCashbackFailure(err))
  }
}
