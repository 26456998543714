import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { cloneDeep } from 'lodash'

import { Creators as ProfileActions } from 'store/ducks/profiles'
import { menuItems } from 'shared/constants/menu'

export default () => {
  const {
    auth: { auth },
    profiles: { list }
  } = useSelector((state) => state)

  const dispatch = useDispatch();
  for (let index = 0; index < menuItems.length; index++) {
    if (menuItems[index].name === 'Lojas') {

      menuItems[index].path = `https://shop.quantabank.com.br/lojas?id=${auth.id}`;

    }

  }

  const adminProfile = useMemo(() => {
    return list?.find((item) => item.name === 'Perfil Tudo')?.id
  }, [list])

  const accreditedProfile = useMemo(() => {
    return list?.find((item) => item.name === 'Credenciado')?.id
  }, [list])

  const isUserAdmin = useMemo(() => {
    const profileFromAuth = auth && auth.profile?.[0]?.id
    return profileFromAuth === adminProfile && adminProfile
  }, [adminProfile, auth])

  const isUserProfileAccredited = useMemo(() => {
    const profileFromAuth = auth && auth.profile?.[0]?.id
    return profileFromAuth === accreditedProfile && accreditedProfile
  }, [accreditedProfile, auth])


  const menuByRole = useMemo(() => {
    const indexAdminMenuContent = menuItems.findIndex(
      (item) => item.name === 'Administração'
    )

    let commonMenu = cloneDeep(menuItems)

    commonMenu.splice(indexAdminMenuContent, isUserProfileAccredited ? 2 : 3)


    return !!isUserAdmin ? menuItems : commonMenu


  }, [isUserAdmin, isUserProfileAccredited])

  const userInfoToHeader = useMemo(() => {
    const userProfile = auth && auth.profile?.[0]?.name
    const username = auth && auth.name
    const img = auth && auth.icon
    return { userProfile, username, img }
  }, [auth])

  useEffect(() => {
    dispatch(ProfileActions.getProfilesRequest())
  }, [dispatch])

  return {
    isUserAdmin: !!isUserAdmin,
    menuByRole,
    userInfoToHeader
  }
}
