import FAQShow from 'pages/FAQ/Show'
import List from 'pages/FAQ/List'
import Create from 'pages/FAQ/Create'
import Edit from 'pages/FAQ/Edit'

import { BASE_URL } from 'shared/constants/config'

export default [
  { path: `${BASE_URL}/faq`, component: FAQShow },
  { path: `${BASE_URL}/faq/itens`, component: List },
  { path: `${BASE_URL}/faq/criar`, component: Create },
  { path: `${BASE_URL}/faq/editar/:id`, component: Edit }
]
