import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import DataTables from 'components/Datatable'
import TableActions from 'components/TableActions'
import ModalDelete from 'components/ModalDelete'

import { Creators as TutorialsActions } from 'store/ducks/tutorials'
import { tutorialsDataTableColumns } from 'shared/constants/datatable'

import DefaultListPage from 'components/DefaultPage'

const List = () => {
  const dispatch = useDispatch()
  const { list, loading } = useSelector((state) => state.tutorials)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalData, setModalData] = useState({})

  useEffect(() => {
    dispatch(TutorialsActions.getTutorialsRequest())
  }, [dispatch])

  const handleDeleteItem = useCallback(
    (item) => {
      dispatch(TutorialsActions.deleteTutorialRequest(item))
      setModalOpen(false)
    },
    [dispatch]
  )

  const handleOpenModal = (value) => {
    setModalOpen(true)
    setModalData({
      title: 'Excluir tutorial',
      content: `Você tem certeza em excluir o item?`,
      item: value
    })
  }

  return (
    <DefaultListPage
      pageTitle="Tutoriais"
      pageDescription="Gerenciar tutoriais"
      pageSubDescription="Adicione, remove, edite alguns dos itens abaixo"
    >
      <TableActions linkToCreate={'/tutoriais/criar'} />
      <DataTables
        list={list}
        editURL="/tutoriais/editar"
        handleOpenModal={handleOpenModal}
        customDataTableColumns={tutorialsDataTableColumns}
        loading={loading}
      />
      <ModalDelete
        isOpen={modalOpen}
        modalToggle={() => setModalOpen(false)}
        title={modalData.title}
        content={modalData.content}
        deleteItem={handleDeleteItem}
        item={modalData.item}
      />
    </DefaultListPage>
  )
}

export default List
