import React, { useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'reactstrap'
import { X, CheckCircle } from 'react-feather'
import TableActions from 'components/TableActions'

import DataTables from 'components/Datatable'
import DefaultListPage from 'components/DefaultPage'
import ModalAprovement from './ModalAprovement'
import ModalDecline from './ModalDecline'

import { withdrawRequestsDataTableColumns } from 'shared/constants/datatable'
import { Creators as WithdrawActions } from 'store/ducks/withdraw'
import { Creators as WalletActions } from 'store/ducks/wallet'

import {
  requestActionsMainStyle,
  requestActionsButtonStyle,
  buttonIconStyle
} from './style'

const WithdrawRequests = () => {
  const dispatch = useDispatch()

  const {
    wallet: { wallet },
    withdraw: { list },
    auth: { auth }
  } = useSelector((state) => state)

  const [currentPage, setCurrentPage] = useState(0)
  const [modalOpen, setModalOpen] = useState(false)
  const [isAprovement, setIsAprovement] = useState(false)
  const [currentItem, setCurrentItem] = useState(null)

  const calcNewAmount = (currentAmount, amountToUpdate) =>
    Math.sign(Number(currentAmount) - Number(amountToUpdate)) === -1
      ? 0
      : Number(currentAmount) - Number(amountToUpdate)

  const handleChangePage = useCallback(
    (page) => {
      setCurrentPage(page)
      dispatch(
        WithdrawActions.getWithdrawRequestsRequest({ page: page, limit: 10 })
      )
    },
    [dispatch]
  )

  const handleChangeRowsPerPage = useCallback(
    (currentPage, size) => {
      dispatch(
        WithdrawActions.getWithdrawRequestsRequest({
          page: currentPage,
          limit: size
        })
      )
    },
    [dispatch]
  )

  const modalToggle = useCallback(
    (value) => {
      setModalOpen(value)
      if (value === false) {
        setIsAprovement(false)
        setCurrentItem(null)
        dispatch(WalletActions.getWalletByUserSuccess({}))
      }
    },
    [dispatch]
  )

  const handleWithdraw = useCallback(() => {
    const amountToUpdate = calcNewAmount(
      wallet.amount,
      currentItem && currentItem.amount
    ).toFixed(2)

    if (wallet && wallet.id) {
      dispatch(
        WalletActions.updateWalletRequest({
          wallet: wallet.id,
          amount: amountToUpdate.toString(),
          money: { id: currentItem && currentItem.id, status: 'finished' }
        })
      )
      setModalOpen(false)
    }
  }, [wallet, currentItem, dispatch])

  const handleAprovement = useCallback(
    (item) => {
      setIsAprovement(true)
      modalToggle(true)
      setCurrentItem(item)
      dispatch(WalletActions.getWalletByUserRequest(auth && auth.id))
    },
    [auth, dispatch, modalToggle]
  )

  const RequestActions = (item) => (
    <div style={requestActionsMainStyle}>
      <Button
        color="success"
        style={requestActionsButtonStyle}
        onClick={() => handleAprovement(item)}
        disabled={item.status === 'finished'}
      >
        <CheckCircle style={buttonIconStyle} />
      </Button>
      <Button
        color="danger"
        style={requestActionsButtonStyle}
        onClick={() => modalToggle(true)}
        disabled={item.status === 'finished'}
      >
        <X style={buttonIconStyle} />
      </Button>
    </div>
  )

  const handleSearch = useCallback(
    (value) => {
      if (value !== '') {
        dispatch(WithdrawActions.getWithdrawRequestsRequest({ search: value, page: 0, limit: 10 }))
      } else if (list) {
        dispatch(WithdrawActions.getWithdrawRequestsRequest({ page: 0, limit: 10 }))
      }
    },
    [dispatch, list]
  )

  const handleClearSearch = useCallback(() => {
    dispatch(WithdrawActions.getWithdrawRequestsRequest({ page: 0, limit: 10 }))
  }, [dispatch])


  useEffect(() => {
    dispatch(WithdrawActions.getWithdrawRequestsRequest({ page: 0, limit: 10 }))
    dispatch(WalletActions.getWalletByUserSuccess({}))
  }, [dispatch])

  return (
    <DefaultListPage
      pageTitle="Solicitações de saque"
      pageDescription="Gerenciar solicitações"
      pageSubDescription="Adicione, remove, edite alguns dos itens abaixo"
    >
      <TableActions
        hasSearch
        hiddenAddButton
        handleSearch={handleSearch}
        handleClearSearch={handleClearSearch}
      />

      <DataTables
        list={list.data}
        count={list.count}
        customDataTableColumns={withdrawRequestsDataTableColumns}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        hasPagination
        currentPage={currentPage}
        onlyEditable
        customActions={RequestActions}
        defaultActions={false}
      />
      {isAprovement ? (
        <ModalAprovement
          modalOpen={modalOpen}
          modalToggle={modalToggle}
          handleWithdraw={handleWithdraw}
        />
      ) : (
        <ModalDecline modalOpen={modalOpen} modalToggle={modalToggle} />
      )}
    </DefaultListPage>
  )
}

export default WithdrawRequests
