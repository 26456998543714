import { put, call } from 'redux-saga/effects'
import { api } from 'services/api'
import { push } from 'connected-react-router'
import { toast } from 'react-toastify'

import { Creators as SupportActions } from 'store/ducks/support'

export function* getSupports() {
  try {
    const { data } = yield call(api.get, '/supports')
    yield put(SupportActions.getSupportsSuccess(data))
  } catch (err) {
    yield put(SupportActions.getSupportsFailure(err.message))
  }
}

export function* getSupportByID(action) {
  try {
    const { data } = yield call(api.get, `/supports/${action.payload}`)
    yield put(SupportActions.getSupportSuccess(data))
  } catch (err) {
    yield put(SupportActions.getSupportFailure(err))
  }
}
export function* getSupportByUser(action) {
  try {
    const { data } = yield call(api.get, `/supports/user/${action.payload}`)
    yield put(SupportActions.getSupportsByUserSuccess(data))
  } catch (err) {
    yield put(SupportActions.getSupportsByUserFailure(err))
  }
}

export function* createSupport(action) {
  const currentFile = new FormData()
  currentFile.append('file', action.payload.file[0])

  try {
    const response = yield call(api.post, `/supports/file`, currentFile, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    delete action.payload.file
    const body = { ...action.payload, fileUrl: response.data.fileUrl }


    const { data } = yield call(api.post, `/supports`, body)
    yield put(SupportActions.createSupportSuccess(data))
    yield put(push('/suporte'))
    toast.success('Support criado com sucesso!')
  } catch (err) {
    toast.error('Erro ao criar support')
    yield put(SupportActions.createSupportFailure(err))
  }
}
export function* createSupportComment(action) {
  try {
    const { data } = yield call(api.post, `/supports/comment`, action.payload)
    yield put(SupportActions.createSupportCommentSuccess(data))
    toast.success('Support criado com sucesso!')
  } catch (err) {
    toast.error('Erro ao criar support')
    yield put(SupportActions.createSupportCommentFailure(err))
  }
}
export function* updateSupport(action) {
  try {
    const { data } = yield call(
      api.put,
      `/supports/${action.payload.id}`,
      action.payload
    )
    yield put(SupportActions.updateSupportSuccess(data))
    yield put(push('/suporte'))
    toast.success('Support editado com sucesso!')
  } catch (err) {
    toast.error('Erro ao editar support')
    yield put(SupportActions.updateSupportFailure(err))
  }
}

export function* deleteSupport(action) {
  try {
    const { data } = yield call(api.delete, `/supports/${action.payload.id}`)
    yield put(SupportActions.deleteSupportSuccess(data))
    yield put(SupportActions.getSupportsRequest())
    toast.success('Support removido com sucesso')
  } catch (err) {
    toast.error('Erro ao remover support')
    yield put(SupportActions.deleteSupportFailure(err))
  }
}
