import React from "react";

const LoaderSpinner = () => {
  return (
    <div className="loader-box">
      <div className="loader-19"></div>
    </div>
  );
};

export default LoaderSpinner;
