import Show from 'pages/Tutorials/Show'
import List from 'pages/Tutorials/List'
import Create from 'pages/Tutorials/Create'
import Edit from 'pages/Tutorials/Edit'

import { BASE_URL } from 'shared/constants/config'

export default [
  { path: `${BASE_URL}/tutoriais`, component: Show },
  { path: `${BASE_URL}/tutoriais/itens`, component: List },
  { path: `${BASE_URL}/tutoriais/criar`, component: Create },
  { path: `${BASE_URL}/tutoriais/editar/:id`, component: Edit }
]
